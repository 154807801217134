import { useMemo, useEffect } from 'react';
import rangy from 'rangy';

function mergeSelections(allSelections, allSelectionParamValues) {
    // Convert allSelectionParamValues into a map for efficient access
    const paramValuesMap = allSelectionParamValues.reduce((acc, item) => {
        const key = item.selection_id;
        if (!acc[key]) {
            acc[key] = [];
        }
        acc[key].push(item);
        return acc;
    }, {});

    // Merge the two arrays
    return allSelections.map((selectionItem) => {
        const selectionId = selectionItem.selection_id;
        const paramValues = paramValuesMap[selectionId];
        return {
            ...selectionItem,
            parameters: paramValues
                ? paramValues.map((pv) => ({
                      parameter_id: pv.parameter_id,
                      value_id: pv.value_id,
                  }))
                : [],
        };
    });
}

function mergeInfo(mainInfo, parameters) {
    const parametersMap = new Map();

    // Create a map for easy access to parameters by their id.
    parameters.forEach((param) => {
        parametersMap.set(param.id, param);
    });

    // Merge the information.
    mainInfo.forEach((info) => {
        info.parameters = info.parameters.map((p) => {
            const paramDetail = parametersMap.get(p.parameter_id);
            if (paramDetail) {
                const mergedParam = {
                    parameter_id: p.parameter_id,
                    name: paramDetail.name,
                    type: paramDetail.type,
                };

                // If value_id is present, find the corresponding value.
                if (p.value_id) {
                    const valueDetail = paramDetail.values.find((v) => v.id === p.value_id);
                    if (valueDetail) {
                        mergedParam.value_id = p.value_id;
                        mergedParam.value = valueDetail.value;
                    }
                }
                return mergedParam;
            }
            return p; // Return original if no matching parameter found.
        });
    });

    return mainInfo;
}

const xPathToNode = (xpath, contextNode = document) => {
    const node = document.evaluate(xpath, contextNode, null, XPathResult.FIRST_ORDERED_NODE_TYPE, null).singleNodeValue;
    return node;
};

const useComputeHighlights = (isMounted, article, parameters) => {
    useEffect(() => {
        rangy.init();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isMounted]);

    function getTextBetweenNodesWithOffset(startNode, endNode, startOffset, endOffset) {
        const rangyRange = rangy.createRange();

        rangyRange.setStart(startNode, startOffset);

        // HOTFIX: Ensure endOffset does not exceed endNode length for text nodes.
        if (endNode.nodeType === 3) {
            // Adjust endOffset to the maximum available length if it's out of bounds.
            const safeEndOffset = Math.min(endOffset, endNode.length);
            rangyRange.setEnd(endNode, safeEndOffset);
        } else {
            // For non-text nodes, use the original endOffset.
            rangyRange.setEnd(endNode, endOffset);
        }

        // Create a document fragment to hold the cloned range
        const docFrag = rangyRange.cloneContents();

        // Function to recursively get text content from a fragment
        function getNodeText(node) {
            if (node.nodeType === Node.TEXT_NODE) {
                return node.textContent; // Return the text for text nodes
            } else if (node.hasChildNodes()) {
                // Concatenate the textContent of all children
                return Array.from(node.childNodes).map(getNodeText).join('');
            }
            return '';
        }

        // Use the recursive function to get text content of the document fragment
        return getNodeText(docFrag);
    }

    const computedHighlights = useMemo(() => {
        if (!isMounted || !article?.labeling?.comments || !parameters) {
            return [];
        }

        const { comments, parameters: labelingParameters } = article.labeling;

        const allSelections = comments
            .filter((item) => item.selection !== undefined)
            .map((item) => ({
                ...item,
                selection_id: item.selection.id,
            }));

        const allSelectionParamValues = labelingParameters
            .filter((item) => item.selection !== undefined)
            .map((item) => ({
                ...item,
                selection_id: item.selection.id,
            }));

        const mainInfo = mergeSelections(allSelections, allSelectionParamValues);
        const mergedSelections = mergeInfo(mainInfo, parameters);

        const selections = mergedSelections
            .map((row) => {
                const parser = new DOMParser();
                const doc = parser.parseFromString(article.content, 'text/html');

                const startNode = xPathToNode(row.selection.start, doc);
                const endNode = xPathToNode(row.selection.end, doc);

                if (startNode === null || endNode === null) {
                    return null; // Skip this item
                }

                const searchText = getTextBetweenNodesWithOffset(
                    startNode,
                    endNode,
                    row.selection.startOffset,
                    row.selection.endOffset,
                );

                const s1Parameters = row.parameters.filter((i) => i.type === 'S1');
                const s2Parameters = row.parameters.filter((i) => i.type === 'S2');
                const s3Parameters = row.parameters.filter((i) => i.type === 'S3');

                return {
                    id: row.selection_id,
                    highlightedText: searchText,
                    highlight: {
                        startXpath: row.selection.start,
                        endXpath: row.selection.end,
                        startOffset: row.selection.startOffset,
                        endOffset: row.selection.endOffset,
                        startIndex: 0, // Ensure these indices are calculated as needed
                        endIndex: 0, // Ensure these indices are calculated as needed
                    },
                    s1: s1Parameters,
                    s2: s2Parameters,
                    s3: s3Parameters,
                    comment: row.value,
                };
            })
            .filter((item) => item !== null); // Remove null entries;

        return selections.filter((item) => {
            return !(item.s1.length === 0 && item.s2.length === 0 && item.s3.length === 0 && item.comment === '');
        });
    }, [isMounted, article, parameters]);

    return computedHighlights;
};

export default useComputeHighlights;
