import update from 'immutability-helper';
import { ClassificationTypes } from 'core/types';

const initialState = {
    classifications: [],

    isLoading: false,
    isLoaded: false,
};

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case ClassificationTypes.GET_CLASSIFICATIONS_START: {
            return update(state, {
                isLoading: { $set: true },
                isLoaded: { $set: false },
            });
        }
        case ClassificationTypes.GET_CLASSIFICATIONS: {
            let models = [];
            payload.classifications.forEach((m) =>
                models.push({
                    value: m.model.id,
                    label: m.model.configs[0].name,
                    external_id: m.model.external_id,
                }),
            );
            const uniqueIds = [];

            models = models.filter((element) => {
                const isDuplicate = uniqueIds.includes(element.value);
                if (!isDuplicate) {
                    uniqueIds.push(element.value);

                    return true;
                }

                return false;
            });

            payload.classifications.map((m) => {
                const index = models.findIndex((model) => model.value === m.model.id);
                if (models.find((model) => model.value === m.model.id)) {
                    if (models[index]['values']) {
                        models[index]['values'].push({
                            value: m.value,
                            label: m.value,
                        });
                    } else {
                        models[index] = { ...models[index], values: [{ value: m.value, label: m.value }] };
                    }
                }
            });

            return update(state, {
                isLoading: { $set: false },
                isLoaded: { $set: true },
                classifications: { $set: models },
            });
        }
        case ClassificationTypes.GET_CLASSIFICATIONS_ERROR: {
            return update(state, {
                isLoading: { $set: false },
            });
        }
        default: {
            return state;
        }
    }
};
