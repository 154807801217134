import { ClassificationTypes } from 'core/types';
import { ClassificationsService, ToastService } from 'core/services';

export function getClassifications() {
    return async (dispatch) => {
        try {
            dispatch({ type: ClassificationTypes.GET_CLASSIFICATIONS_START });
            const classifications = await ClassificationsService.getClassifications();
            dispatch({ type: ClassificationTypes.GET_CLASSIFICATIONS, payload: { classifications } });
        } catch (error) {
            const message = error?.response?.data?.message;
            ToastService.showGenericError(message);
            dispatch({ type: ClassificationTypes.GET_CLASSIFICATIONS_ERROR });
        }
    };
}
