import React from 'react';
import PropTypes from 'prop-types';
import * as Styles from './styles';
import { Form, Grid } from 'styles/components';
import { Images } from 'styles/constant';
import ClassificationSelect from './ClassificationSelect';

function CustomClassificationSelect(props) {
    const { classifications, values, onChange, add } = props;

    const handleModelChange = (modelId, name, externalId, index) => {
        values[index].classifications = { value: modelId, label: name, externalId: externalId };
        onChange(values);
    };

    const handleAddClassificationModel = () => {
        let value = [];

        value.push({
            classifications: {
                value: classifications[0].value,
                label: classifications[0].label,
                externalId: classifications[0].external_id,
            },
        });
        add(value);
    };

    const handleRemove = (index) => {
        values.splice(index, 1);
        onChange(values);
    };

    const handleClassificationValuesChange = (value, index) => {
        values[index].classifications.values = value;
        onChange(values);
    };

    const handleClassificationProbabilityChange = (name, value, index) => {
        values[index].classifications[name] = value;
        onChange(values);
    };

    const renderAddButton = () => (
        <Styles.AddButton onClick={handleAddClassificationModel}>
            <Grid.Col mr={10}>
                <Styles.Icon src={Images.ADD_CIRCLE} />
            </Grid.Col>
            <Grid.Col>
                <Styles.AddLabel>Add classification model</Styles.AddLabel>
            </Grid.Col>
        </Styles.AddButton>
    );

    return (
        <Styles.Container>
            <Form.Label>Custom classification models</Form.Label>
            <Styles.Content>
                {values.map((classification, index) => (
                    <ClassificationSelect
                        onModelChange={handleModelChange}
                        key={index}
                        value={values}
                        classificationIndex={index}
                        onRemove={() => handleRemove(index)}
                        classifications={classifications}
                        onChange={handleClassificationValuesChange}
                        onProbabilityChange={handleClassificationProbabilityChange}
                    />
                ))}
            </Styles.Content>
            {renderAddButton()}
        </Styles.Container>
    );
}

CustomClassificationSelect.propTypes = {
    classifications: PropTypes.array,
    add: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    values: PropTypes.array,
};

CustomClassificationSelect.defaultProps = {
    values: [],
};

export default CustomClassificationSelect;
