import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { getFromLocalStorage } from 'utils';
import { useIsMounted } from 'hooks';
import { SingleSelect } from '../../../../components';
import { ColumnsSelection, ColumnGroupSelection, ExportTypeSelection } from './Components';
import { AdvancedExportModalKey } from 'components/AdvancedExportModal/Types';
import { advancedExportType } from 'components/AdvancedExportModal/Types';
import './style.scss';

export const AdvancedExportForm = ({
    isVisible,
    recommendedExports,
    exportFields,
    minimalFields,
    checkedItems,
    setCheckedItems,
    exportType,
    setExportType,
    parameters,
    getParametersList,
    setSingleParameter,
}) => {
    const [selectedOption, setOption] = useState();

    const handleExportTypeChange = (value) => {
        setExportType(value.name);
    };

    const handleSelectAll = () => {
        const allKeys = Object.keys(exportFields).reduce((acc, key) => ({ ...acc, [key]: true }), {});
        setCheckedItems(allKeys);
    };

    const handleSelectNone = () => {
        setCheckedItems({});
    };

    const handleSelectRecommended = () => {
        const recommendedKeys = recommendedExports[exportType].reduce((acc, key) => ({ ...acc, [key]: true }), {});
        setCheckedItems(recommendedKeys);
    };

    const handleSelectMinimal = () => {
        const minimalKeys = minimalFields.reduce((acc, key) => ({ ...acc, [key]: true }), {});
        setCheckedItems(minimalKeys);
    };

    const handleSelectLastUsed = () => {
        const lastUsedOptions = getFromLocalStorage(AdvancedExportModalKey);
        if (lastUsedOptions) {
            const lastUsedKeys = lastUsedOptions.columns.reduce((acc, key) => ({ ...acc, [key]: true }), {});
            setCheckedItems(lastUsedKeys);
            setExportType(lastUsedOptions.type);
        }
    };

    const onSingleParameterChange = (event) => {
        setOption(event.value);
        setSingleParameter(event.value);
    };

    const singleParameterOptions =
        parameters && parameters.length > 0 ? parameters.map((item) => ({ label: item.name, value: item.name })) : [];

    const isMounted = useIsMounted();

    useEffect(() => {
        if (isMounted) {
            getParametersList('select');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isMounted]);

    return (
        <div className={`advanced-export ${isVisible ? 'active' : ''}`}>
            <ExportTypeSelection onExportTypeChange={handleExportTypeChange} selectedExportType={exportType} />
            {exportType === advancedExportType.BY_SINGLE_PARAMETER && (
                <div className="by-single-parameter">
                    <div className="label">Single Parameter:</div>
                    <div className="container">
                        <SingleSelect
                            options={singleParameterOptions}
                            value={selectedOption}
                            onChange={onSingleParameterChange}
                            placeholder="Select Single Parameter"
                            name="singleParameter"
                        />
                    </div>
                </div>
            )}
            <ColumnGroupSelection
                handleSelectAll={handleSelectAll}
                handleSelectLastUsed={handleSelectLastUsed}
                handleSelectMinimal={handleSelectMinimal}
                handleSelectNone={handleSelectNone}
                handleSelectRecommended={handleSelectRecommended}
            />
            <ColumnsSelection data={exportFields} checkedItems={checkedItems} setCheckedItems={setCheckedItems} />
        </div>
    );
};

AdvancedExportForm.propTypes = {
    isVisible: PropTypes.bool.isRequired,
    recommendedExports: PropTypes.object.isRequired,
    exportFields: PropTypes.any.isRequired,
    minimalFields: PropTypes.array.isRequired,
    checkedItems: PropTypes.object.isRequired,
    setCheckedItems: PropTypes.func.isRequired,
    setSingleParameter: PropTypes.func.isRequired,
    exportType: PropTypes.string.isRequired,
    setExportType: PropTypes.func.isRequired,
    getParametersList: PropTypes.func.isRequired,
    parameters: PropTypes.any.isRequired,
};
