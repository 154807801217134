import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import {
    Container,
    SearchContainer,
    OptionsContainer,
    SearchInput,
    SearchIcon,
    NoDataContainer,
    NoDataTitle,
} from './styles';
import SelectOption from '../../SelectOption';
import { SharedTypes, TextUtils } from 'utils';
import { Images } from 'styles/constant';
import _ from 'lodash';

function SingleSelectOptions(props) {
    const { options, style, onChange, value, searchable, searchPhrase, disabled, forceOpen } = props;
    const [visibleOptions, setVisibleOptions] = useState(20);
    const [phrase, setPhrase] = useState(searchPhrase);
    const [filteredOptions, setFilteredOptions] = useState(options);
    const optionsContainerRef = useRef(null);
    const noOptions = phrase && filteredOptions.length === 0;

    useEffect(() => {
        const newFilteredOptions = options.filter((option) => TextUtils.startWith(option.label, phrase));
        setFilteredOptions(newFilteredOptions);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [phrase]);

    useEffect(() => {
        setPhrase(searchPhrase);
    }, [searchPhrase]);

    const handleClick = (option) => {
        if (option.external_id) {
            onChange(option);
        } else {
            onChange(option.value);
        }
    };

    const getOptionHeight = () => {
        // Reference to the container
        const container = optionsContainerRef.current;
        if (!container) return 0;

        // Getting the parent of the container and its children
        const containerParent = container.parentElement;
        const parentChildren = containerParent ? containerParent.children : null;

        // Accessing the second child of the parent and its second child
        const secondChild = parentChildren ? parentChildren[0] : null;
        const secondChildsSecondChild = secondChild ? secondChild.children[1] : null;

        // Return the height of the second child's second child, or 41 if not available
        return secondChildsSecondChild ? secondChildsSecondChild.clientHeight : 41;
    };

    const handleScroll = () => {
        // Reference to the container
        const container = optionsContainerRef.current;
        if (!container) return;

        const optionHeight = getOptionHeight();

        // Checking if scroll has reached near the bottom
        const isNearBottom = container.scrollTop + container.clientHeight + optionHeight >= container.scrollHeight;

        if (isNearBottom && filteredOptions.length > visibleOptions) {
            const newVisibleOptions = visibleOptions + 20;
            setVisibleOptions(newVisibleOptions);
        }
    };

    const handleSearch = (event) => {
        const { target } = event;
        const { value } = target;
        setPhrase(value);
    };

    return (
        <Container hide={noOptions && !searchable} style={style} forceOpen={forceOpen}>
            {searchable && (
                <SearchContainer>
                    <SearchInput value={phrase || ''} onChange={handleSearch} className="autofocus" />
                    <SearchIcon src={Images.SEARCH} />
                </SearchContainer>
            )}
            <OptionsContainer onScroll={handleScroll} ref={optionsContainerRef} forceOpen={forceOpen}>
                {noOptions && (
                    <NoDataContainer>
                        <NoDataTitle>No options</NoDataTitle>
                    </NoDataContainer>
                )}
                {_.take(phrase ? filteredOptions : options, visibleOptions).map((option, index) => (
                    <SelectOption
                        disabled={option.value === disabled}
                        selected={value === option.value}
                        option={option}
                        key={index}
                        onClick={() => handleClick(option)}
                    />
                ))}
            </OptionsContainer>
        </Container>
    );
}

SingleSelectOptions.propTypes = {
    options: PropTypes.arrayOf(SharedTypes.OptionType).isRequired,
    onChange: PropTypes.func.isRequired,
    value: SharedTypes.NumberOrString,
    style: PropTypes.object,
    searchable: PropTypes.bool,
    forceOpen: PropTypes.bool,
    disabled: PropTypes.string,
    searchPhrase: PropTypes.string,
};

SingleSelectOptions.defaultProps = {
    style: {},
    value: null,
    searchPhrase: null,
    searchable: true,
    disabled: null,
    forceOpen: null,
};

export default SingleSelectOptions;
