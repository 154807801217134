import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { SharedTypes } from 'utils';
import { Grid } from 'styles/components';
import { Button, Loader } from 'components';
import { CATEGORYLIST, LANGUAGELIST, FORM_DATA } from './config';
import update from 'immutability-helper';
import FilterSettings from './FilterSettings';
import LanguageSettings from './LanguageSettings';
import { FiltersActions } from 'core/actions';
import { Title, Container } from 'pages/Filter/styles';

function FilterForm(props) {
    const { filters, languages, categories, createFilter, isUpdated, isUpdating, getFilters, updateFilter, table } =
        props;
    const categoryList = CATEGORYLIST(categories);
    const languageList = LANGUAGELIST(languages);
    const [filter, setFilter] = useState(false);
    const [selectedFilter, importFilter] = useState(false);
    const [selectedFilterLanguage, importFilterLanguage] = useState(false);
    const [name, setName] = useState('');
    const [vote, setVote] = useState({ label: '0', value: '0' });
    const [languagesSelected, setLanguage] = useState({ language: {}, tabs: [] });
    const [category, setCategory] = useState({});
    const [activeTab, setActiveTab] = useState('');
    const [active, setActive] = useState(true);
    const [primary, setPrimary] = useState(true);
    const [queries, setQueries] = useState({});
    const [namesTranslated, setNamesTranslated] = useState({});
    const [formCleared, setFormCleared] = useState({});
    const history = useHistory();

    useEffect(() => {
        getFilters('filterForm');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        document.title = filter.id ? `Debunk EU - Edit Filter - ${filter.name}` : 'Create New Filter';
    }, [filter]);

    useEffect(() => {
        isUpdated && history.push('/filters');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isUpdated]);

    useEffect(() => {
        const id = history.location.pathname.split('/').pop();
        const urlLang = history.location.search.split('=').pop();

        if (id !== 'create' && table) {
            const currentFilter = filters.find((f) => f.id == id);

            const currentCategory = currentFilter.category
                ? categoryList.find((f) => f.title === currentFilter.category.name).value
                : '';
            const langs = [];
            const qry = {};
            const vote = currentFilter.vote === 0 ? '0' : currentFilter.vote;
            const namesTr = {};

            setFilter(currentFilter);
            importFilter(currentFilter);
            setName(currentFilter.name);
            setVote({ label: vote, value: vote });
            setActive(currentFilter.status);
            setPrimary(currentFilter.primary);
            setCategory({ name: 'categories', value: currentCategory });

            if (currentFilter.lang.length > 0) {
                currentFilter.lang.map((l) => {
                    langs.push(l.lang.iso);
                    namesTr[l.lang.iso] = l.name_translated;
                });
                onLanguageChange({
                    value: languages.filter((l) => langs.includes(l.iso)).map((l) => l.id),
                    name: 'languages',
                });
                onTabChange(urlLang || langs[0]);
                setNamesTranslated(namesTr);
                langs.map((lang) => {
                    qry[lang] = currentFilter.lang.find((f) => f.lang.iso === lang);
                });
                setQueries(qry);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [table]);

    const onLanguageChange = (e) => {
        if (e.value.length < languagesSelected.tabs.length) {
            const confirmation = window.confirm('Any unsaved changes will be lost. Remove language?');
            if (confirmation === false) return;
        }

        const arr = languagesSelected.language.value
            ? languagesSelected.language.value.filter((item) => e.value.includes(item))
            : [];
        const tabs = languagesSelected.tabs.filter((item) => e.value.includes(item.value));

        languageList.map((l) => {
            if (e.value.includes(l.value) && !arr.includes(l.value)) {
                arr.push(l.value);
                tabs.push(l);
            }
        });

        setLanguage((prevState) => ({
            ...prevState,
            language: { ...e, value: arr },
            tabs,
        }));

        if (!tabs[0]) {
            setActiveTab('');
            setQueries({});
        } else {
            activeTab.length === 0 && setActiveTab(tabs[0].key);
            const lastItem = tabs[tabs.length - 1].key;
            const emptyQry = { query: '', lang: { iso: lastItem } };

            if (tabs.length < languagesSelected.tabs.length) {
                const qry = {};
                tabs.map((lang) => {
                    const oldQry = filter.lang && filter.lang.find((f) => f.lang.iso === lang);

                    qry[lang.key] = queries[lang.key] ? queries[lang.key] : oldQry || emptyQry;
                });

                if (!tabs.find((t) => t.key === activeTab)) setActiveTab(tabs[0].key);

                setQueries(qry);
            } else if (!queries[lastItem]) {
                const oldQry = filter.lang && filter.lang.find((f) => f.lang.iso === lastItem);

                setQueries(
                    update(queries, {
                        [lastItem]: { $set: oldQry || emptyQry },
                    }),
                );
            }
        }
    };

    const onTabChange = (e) => {
        const qry = queries[e] ? queries[e] : filter.lang ? filter.lang.find((f) => f.lang.iso === e) : {};

        setActiveTab(e);
        if (!formCleared[e]) {
            setQueries(
                update(queries, {
                    [e]: { $set: qry || { query: '', lang: { iso: e } } },
                }),
            );
        }
    };

    const onFilterSelect = (e) => {
        if (e.value) {
            const selection = filters.find((f) => f.id === e.value);
            importFilter(selection);
        } else {
            importFilter(false);
        }
    };

    const onQueryImport = () => {
        const qry = selectedFilter.lang
            ? selectedFilter.lang.find((l) => l.filter_lang_id === selectedFilterLanguage.value)
            : {};

        if (qry) {
            setQueries(
                update(queries, {
                    [activeTab]: {
                        $set: {
                            filter_lang_id: null,
                            query: qry.query,
                            lang: { iso: activeTab },
                        },
                    },
                }),
            );
        }
    };

    const onQueryChange = (e) => {
        setQueries(
            update(queries, {
                [activeTab]: {
                    $set: {
                        ...queries[activeTab],
                        query: JSON.stringify(e),
                        lang: { iso: activeTab },
                    },
                },
            }),
        );
    };

    const onClearLanguageForm = () => {
        const confirmation = window.confirm(
            'The structure and all the data for this language will be lost. Are you sure you want to clear the form?',
        );
        if (confirmation === false) {
            return;
        }
        const newQueries = { ...queries };
        delete newQueries[activeTab];
        setQueries(newQueries);
        setFormCleared({ ...formCleared, [activeTab]: true });
    };

    const onSubmit = () => {
        const data = FORM_DATA(
            filter,
            queries,
            languages,
            namesTranslated,
            category,
            vote,
            name,
            active,
            categoryList,
            primary,
        );
        if (data) {
            if (filter.id) updateFilter(filter.id, data.filter, data.languages);
            else createFilter(data.filter, data.languages);
        }
    };

    const updateNamesTranslated = (e) => {
        setNamesTranslated(
            update(namesTranslated, {
                [activeTab]: {
                    $set: e.value,
                },
            }),
        );
    };

    const onCancel = () => {
        history.push('/filters');
    };

    return (
        <>
            {!table ? (
                <Grid.Row pt={20} justifyContent="center">
                    <Loader />
                </Grid.Row>
            ) : (
                <>
                    <Grid.FixedContainer sticky style={{ maxWidth: 'none' }} id="fixed-filter-container">
                        <Grid.FixedContainer>
                            <Grid.Row pt={20} pb={20} justifyContent="space-between">
                                <Grid.Col>
                                    {!filter ? (
                                        <Title>Create filter</Title>
                                    ) : (
                                        <Title>
                                            Edit filter <span>{filter.name}</span>
                                        </Title>
                                    )}
                                </Grid.Col>
                                <Grid.Col>
                                    <Grid.Row>
                                        <Grid.Col mr={10}>
                                            <Button onClick={onCancel} label="Cancel" type="TERTIARY" />
                                        </Grid.Col>
                                        <Grid.Col>
                                            <Button
                                                onClick={onSubmit}
                                                label="Submit"
                                                disabled={name.length === 0}
                                                loading={isUpdating}
                                            />
                                        </Grid.Col>
                                    </Grid.Row>
                                </Grid.Col>
                            </Grid.Row>
                        </Grid.FixedContainer>
                    </Grid.FixedContainer>

                    <Container>
                        <Grid.FixedContainer>
                            <FilterSettings
                                name={name}
                                active={active}
                                category={category}
                                categoryList={categoryList}
                                languageList={languageList}
                                languagesSelected={languagesSelected}
                                onLanguageChange={onLanguageChange}
                                setActive={setActive}
                                setCategory={setCategory}
                                setName={setName}
                                setVote={setVote}
                                vote={vote}
                                primary={primary}
                                setPrimary={setPrimary}
                            />
                            {activeTab.length > 0 && (
                                <LanguageSettings
                                    nameTranslated={namesTranslated[activeTab]}
                                    setNameTranslated={updateNamesTranslated}
                                    onQueryImport={onQueryImport}
                                    onQueryChange={onQueryChange}
                                    queries={queries}
                                    importFilterLanguage={importFilterLanguage}
                                    selectedFilterLanguage={selectedFilterLanguage}
                                    selectedFilter={selectedFilter}
                                    languagesSelected={languagesSelected}
                                    activeTab={activeTab}
                                    filters={filters}
                                    onFilterSelect={onFilterSelect}
                                    onTabChange={onTabChange}
                                    onClear={onClearLanguageForm}
                                />
                            )}
                        </Grid.FixedContainer>
                    </Container>
                </>
            )}
        </>
    );
}

FilterForm.propTypes = {
    filters: PropTypes.arrayOf(SharedTypes.FilterType).isRequired,
    languages: PropTypes.arrayOf(SharedTypes.LanguageType).isRequired,
    categories: PropTypes.arrayOf(SharedTypes.CategoryType).isRequired,
    createFilter: PropTypes.func.isRequired,
    isUpdating: PropTypes.bool.isRequired,
    isUpdated: PropTypes.bool.isRequired,
    table: PropTypes.bool,
    getFilters: PropTypes.func.isRequired,
    updateFilter: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
    const { filters, languages, categories } = state;
    const { isUpdating, isUpdated, table } = filters;

    return {
        isUpdating,
        isUpdated,
        table,
        filters: filters.filters,
        languages: languages.languages,
        categories: categories.categories,
    };
}

const mapDispatchToProps = {
    getFilters: FiltersActions.getFilters,
    createFilter: FiltersActions.createFilter,
    updateFilter: FiltersActions.updateFilter,
};

export default connect(mapStateToProps, mapDispatchToProps)(FilterForm);
