import { useState, useEffect, useCallback } from 'react';

const STORAGE_KEY_HIGHLIGHTS = 'labelling-highlights';

// Helper function to get a unique storage key for an article
const getArticleKey = (article) => {
    return article ? `id=${article.id}&parentId=${article.parentId}` : null;
};

const useStorageHighlights = () => {
    const [isKeyPresent, setIsKeyPresent] = useState(false);

    // Function to check the presence of the key in local storage
    const checkKeyPresence = useCallback(() => {
        const item = localStorage.getItem(STORAGE_KEY_HIGHLIGHTS);
        setIsKeyPresent(item !== null);
    }, []);

    useEffect(() => {
        // Initial check for the item's presence
        checkKeyPresence();

        // Handler for storage changes
        const handleStorageChange = (event) => {
            if (event.key === STORAGE_KEY_HIGHLIGHTS) {
                checkKeyPresence();
            }
        };

        // Add event listener for storage changes
        window.addEventListener('storage', handleStorageChange);

        // Cleanup function to remove the event listener
        return () => {
            window.removeEventListener('storage', handleStorageChange);
        };
    }, [checkKeyPresence]);

    // Fetch highlights from storage for a given article
    const fetchHighlights = useCallback((article) => {
        const articleKey = getArticleKey(article);
        const data = JSON.parse(localStorage.getItem(STORAGE_KEY_HIGHLIGHTS) || '{}');
        return data[articleKey] || null;
    }, []);

    // Save highlights to storage for a given article
    const saveHighlights = useCallback(
        (article, highlights) => {
            const articleKey = getArticleKey(article);
            const currentData = JSON.parse(localStorage.getItem(STORAGE_KEY_HIGHLIGHTS) || '{}');
            const updatedData = { ...currentData, [articleKey]: highlights };
            localStorage.setItem(STORAGE_KEY_HIGHLIGHTS, JSON.stringify(updatedData));
            checkKeyPresence(); // Update the presence status after saving
        },
        [checkKeyPresence],
    );

    // Remove all highlights from storage
    const removeAllFromStorage = useCallback(() => {
        localStorage.removeItem(STORAGE_KEY_HIGHLIGHTS);
        checkKeyPresence(); // Update the presence status after removal
    }, [checkKeyPresence]);

    // Return the helper functions along with the isKeyPresent state
    return { fetchHighlights, saveHighlights, removeAllFromStorage, isKeyPresent };
};

export default useStorageHighlights;
