import moment from 'moment';

export function DateTimeFormatter(dateStr) {
    const date = dateStr ? moment(dateStr) : null;

    if (!date) {
        return { format: () => '', date: '', time: '' };
    }

    const formattedDate = date.format('YYYY-MM-DD');
    const formattedTime = date.format('HH:mm');

    const format = () => `${formattedDate}\n${formattedTime}`;

    return { format, date: formattedDate, time: formattedTime };
}
