import React, { useEffect } from 'react';
import { ManualImportList } from 'containers';
import { Title, Container } from './styles';
import { Grid } from 'styles/components';
import PropTypes from 'prop-types';
import { ImportedArticleActions } from 'features/article/actions';
import { connect } from 'react-redux';
import { Loader } from 'components';
import { useIsMounted } from 'hooks';

function ManualImportsPage(props) {
    const { getImportedArticles, importedArticles, isLoaded, isLoading } = props;

    const isMounted = useIsMounted();

    useEffect(() => {
        document.title = 'Debunk EU - List of Imported Articles';

        if (!isLoading && isMounted) {
            getImportedArticles();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isMounted]);

    return (
        <>
            <Container>
                <Grid.FixedContainer>
                    <Title>Imported Articles</Title>
                    {isLoading && (
                        <Grid.Row mt={30} justifyContent="center">
                            <Loader />
                        </Grid.Row>
                    )}
                </Grid.FixedContainer>
                {isLoaded && <ManualImportList importedArticles={importedArticles} />}
            </Container>
        </>
    );
}

ManualImportsPage.propTypes = {
    isLoading: PropTypes.bool.isRequired,
    isLoaded: PropTypes.bool.isRequired,
    importedArticles: PropTypes.array,
    getImportedArticles: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
    const { importedArticles } = state;
    const { isLoading, isLoaded } = importedArticles;
    return { importedArticles: importedArticles.importedArticles, isLoading, isLoaded };
}

const mapDispatchToProps = {
    getImportedArticles: ImportedArticleActions.getImportedArticles,
};

export default connect(mapStateToProps, mapDispatchToProps)(ManualImportsPage);
