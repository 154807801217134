import React, { useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { MultiSelect } from 'components';
import { Wrapper } from '../styles';
import { OPTIONS } from './config';
import SearchFilter from './components/SearchFilter';

const getMultiSelectOptions = (options, idLowerCase, languages) => {
    const multiSelectOptions = OPTIONS(options, idLowerCase, languages);
    if (idLowerCase === 'last login') {
        return multiSelectOptions.sort((a, b) => new Date(b.value) - new Date(a.value));
    }
    return multiSelectOptions;
};

function getUserFilters(filteredRows) {
    if (Array.isArray(filteredRows) && filteredRows.length > 0 && filteredRows[0].original?.user_filters) {
        const uniqueUsers = new Set();
        const users = filteredRows
            .reduce((users, row) => {
                return users.concat(
                    row.original.user_filters
                        .map((filter) => filter.user)
                        .filter((user) => {
                            if (!uniqueUsers.has(user.id)) {
                                uniqueUsers.add(user.id);
                                return true;
                            }
                            return false;
                        }),
                );
            }, [])
            .sort((a, b) => a.username.localeCompare(b.username))
            .map((user) => ({ value: user.id, label: user.username }));

        users.unshift({ value: -1, label: '[Not assigned]' });
        return users;
    } else {
        return [];
    }
}

function ColumnFilter({
    column: { setFilter, preFilteredRows, id, filter: columnFilter },
    handleCheckedCells,
    checkedCells,
    languages,
    state: { filters },
}) {
    const idLowerCase = id.toLowerCase();
    const [, setRows] = useState([]);

    useEffect(() => {
        setRows(preFilteredRows);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const options = useMemo(() => {
        if (columnFilter === 'assigned_users') {
            return getUserFilters(preFilteredRows);
        } else {
            const options = new Set();
            preFilteredRows.forEach((row) => {
                options.add({ value: row.values[id], label: row.values[id] ? row.values[id].toString() : '' });
            });
            return [...options.values()];
        }
    }, [columnFilter, id, preFilteredRows]);

    if (columnFilter === 'search') {
        return (
            <SearchFilter
                id={id}
                onSearchChange={(value) => {
                    setFilter(value);
                }}
                value={filters.find((x) => x.id === id)?.value}
            />
        );
    }

    const filter = (e) => {
        let allValues = [];

        if (
            idLowerCase === 'languages' ||
            idLowerCase === 'create date' ||
            idLowerCase === 'update date' ||
            idLowerCase === 'specialization in topic subcategory' ||
            idLowerCase === 'last login' ||
            idLowerCase === 'last sent'
        ) {
            let arr = [];
            e.value.map((val) => {
                options.map((opt) => {
                    opt.value.includes(val) && !arr.includes(opt.value) && arr.push(opt.value);
                    return opt;
                });
                return val;
            });
            allValues = arr;
        } else {
            allValues = e.value;
        }

        allValues
            .map((o) => {
                return parseFloat(o.value).toString() === o ? parseFloat(o) : o;
            })
            .filter(Boolean);

        setFilter(e.value.length > 0 ? allValues : undefined);
    };

    const multiSelectOptions = getMultiSelectOptions(options, idLowerCase, languages);

    return (
        <Wrapper
            onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
            }}
        >
            <MultiSelect
                label=""
                options={multiSelectOptions}
                value={checkedCells ? checkedCells.checkedCells[id] : checkedCells}
                onChange={(e) => {
                    handleCheckedCells(e);
                    filter(e);
                }}
                name={id}
                placeholder=""
            />
        </Wrapper>
    );
}

ColumnFilter.propTypes = {
    column: PropTypes.object.isRequired,
    state: PropTypes.object.isRequired,
    handleCheckedCells: PropTypes.func.isRequired,
    checkedCells: PropTypes.object,
    languages: PropTypes.array,
    initialState: PropTypes.object,
};

export default ColumnFilter;
