export const PARAMETER_LIST_CREATE_PARAMETER_START = 'PARAMETER_LIST_CREATE_PARAMETER_START';
export const PARAMETER_LIST_CREATE_PARAMETER = 'PARAMETER_LIST_CREATE_PARAMETER';
export const PARAMETER_LIST_UPDATE_PARAMETERS_START = 'PARAMETER_LIST_UPDATE_PARAMETERS_START';
export const PARAMETER_LIST_GET_PARAMETERS_LIST_START = 'PARAMETER_LIST_GET_PARAMETERS_LIST_START';
export const PARAMETER_LIST_GET_PARAMETERS_LIST = 'PARAMETER_LIST_GET_PARAMETERS_LIST';
export const PARAMETER_LIST_UPDATE_PARAMETERS_SUCCESS = 'PARAMETER_LIST_UPDATE_PARAMETERS_SUCCESS';
export const PARAMETER_LIST_UPDATE_PARAMETERS_VALUE_SUCCESS = 'PARAMETER_LIST_UPDATE_PARAMETERS_VALUE_SUCCESS';
export const PARAMETER_LIST_UPDATE_STATUS_PARAMETERS_START = 'PARAMETER_LIST_UPDATE_STATUS_PARAMETERS_START';
export const PARAMETER_LIST_DELETE_PARAMETERS_START = 'PARAMETER_LIST_DELETE_PARAMETERS_START';
export const PARAMETER_LIST_ENABLE_PARAMETERS_SUCCESS = 'PARAMETER_LIST_ENABLE_PARAMETERS_SUCCESS';
export const PARAMETER_LIST_DISABLE_PARAMETERS_SUCCESS = 'PARAMETER_LIST_DISABLE_PARAMETERS_SUCCESS';
export const PARAMETER_LIST_DELETE_PARAMETERS_SUCCESS = 'PARAMETER_LIST_DELETE_PARAMETERS_SUCCESS';
export const PARAMETER_LIST_ENABLE_PARAMETERS_VALUE_SUCCESS = 'PARAMETER_LIST_ENABLE_PARAMETERS_VALUE_SUCCESS';
export const PARAMETER_LIST_DISABLE_PARAMETERS_VALUE_SUCCESS = 'PARAMETER_LIST_DISABLE_PARAMETERS_VALUE_SUCCESS';
export const PARAMETER_LIST_DELETE_PARAMETERS_VALUE_SUCCESS = 'PARAMETER_LIST_DELETE_PARAMETERS_VALUE_SUCCESS';
export const PARAMETER_LIST_GET_PARAMETER_LIST_ERROR = 'PARAMETER_LIST_GET_PARAMETER_LIST_ERROR';
export const PARAMETER_LIST_STATUS_ERROR = 'PARAMETER_LIST_STATUS_ERROR';
export const PARAMETER_LIST_DELETE_ERROR = 'PARAMETER_LIST_DELETE_ERROR';
