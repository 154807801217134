import styled from 'styled-components';
import { Colors } from 'styles/constant';

export const Container = styled.div`
    border-bottom: 1px solid ${Colors.BACKGROUND};
    height: 60px;
    display: flex;
    align-items: center;
`;

export const Name = styled.span`
    color: ${Colors.BLACK};
    font-size: 14px;
    cursor: pointer;
`;
