import React from 'react';
import PropTypes from 'prop-types';
import { Total } from './styles';
import { Grid } from 'styles/components';
import { Input, Button, Checkbox } from 'components';

function ChartBottom(props) {
    const {
        total,
        title,
        updateTitle,
        setTitle,
        setShowLabels,
        showLabels,
        showRelativeValue,
        setShowRelativeValue,
        isAllChecked,
    } = props;

    return (
        <>
            <Grid.Row mb={20}>
                <Checkbox name="showLabels" onChange={setShowLabels} checked={showLabels} label="Show labels" />
                <Checkbox
                    style={{ marginLeft: '10px' }}
                    name="showRelativeValue"
                    onChange={setShowRelativeValue}
                    checked={showRelativeValue}
                    label="Show relative value"
                />
                {isAllChecked ? (
                    <Total>The chart is based on the {total} articles, which met your filters.</Total>
                ) : null}
            </Grid.Row>
            <Grid.Row mb={10}>
                <Input onChange={(e) => setTitle(e.value)} name="title" value={title} />
            </Grid.Row>
            <Grid.Row>
                <Button onClick={updateTitle} label="Update title" type="SECONDARY" />
            </Grid.Row>
        </>
    );
}

ChartBottom.propTypes = {
    total: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    updateTitle: PropTypes.func.isRequired,
    setTitle: PropTypes.func.isRequired,
    setShowLabels: PropTypes.func,
    showLabels: PropTypes.bool,
    setShowRelativeValue: PropTypes.func,
    showRelativeValue: PropTypes.bool,
    isAllChecked: PropTypes.bool,
};

export default ChartBottom;
