import React from 'react';
import * as Styles from './styles';
import { Loader } from 'components';
import { Grid } from 'styles/components';
import { useFilter } from 'hooks';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

function LabelingTabs(props) {
    const { filter, updateFilter } = useFilter();
    const {
        isReservedLoading,
        isLabeledLoading,
        isSkippedLoading,
        isWaitingLoading,
        labeledCount,
        reservedCount,
        skippedCount,
        waitingCount,
    } = props;
    const disabled = isReservedLoading || isWaitingLoading || isLabeledLoading || isSkippedLoading;

    const onSelect = (key) => {
        updateFilter({ ...filter, labeling_status: key });
    };

    const renderTab = (key, label, count, isLoading) => {
        const active = key === filter.labeling_status;
        return (
            <Styles.Tab onClick={() => onSelect(key)} active={active} disabled={disabled}>
                <Grid.Col mr={7}>
                    <Styles.Title active={active}>{label}</Styles.Title>
                </Grid.Col>
                <Grid.Col>
                    {isLoading && <Loader size={18} />}
                    {!isLoading && <Styles.Title active={active}>({count})</Styles.Title>}
                </Grid.Col>
            </Styles.Tab>
        );
    };

    return (
        <Styles.Container>
            {renderTab(undefined, 'Reserved', reservedCount, isReservedLoading)}
            {renderTab('Not labeled', 'Waiting', waitingCount, isWaitingLoading)}
            {renderTab('Labeled', 'Labelled', labeledCount, isLabeledLoading)}
            {renderTab('Skipped', 'Skipped', skippedCount, isSkippedLoading)}
        </Styles.Container>
    );
}

LabelingTabs.propTypes = {
    isReservedLoading: PropTypes.bool,
    isLabeledLoading: PropTypes.bool,
    isSkippedLoading: PropTypes.bool,
    isWaitingLoading: PropTypes.bool,
    labeledCount: PropTypes.number,
    reservedCount: PropTypes.number,
    skippedCount: PropTypes.number,
    waitingCount: PropTypes.number,
};

function mapStateToProps(state) {
    const { articlesCount } = state;
    const {
        isReservedLoading,
        isLabeledLoading,
        isSkippedLoading,
        isWaitingLoading,
        labeledCount,
        reservedCount,
        skippedCount,
        waitingCount,
    } = articlesCount;
    return {
        isReservedLoading,
        isLabeledLoading,
        isSkippedLoading,
        isWaitingLoading,
        labeledCount,
        reservedCount,
        skippedCount,
        waitingCount,
    };
}

export default connect(mapStateToProps)(LabelingTabs);
