import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import ColumnFilter from './ColumnFilter';
import EditableCell from './EditableCell';
import TableTopButtons from './TableTopButtons';
import Checkbox from '../Checkbox';
import {
    useTable,
    useFilters,
    useGlobalFilter,
    useSortBy,
    useBlockLayout,
    useExpanded,
    usePagination,
} from 'react-table';
import {
    TableBody,
    TableRow,
    TableCell,
    TableCellHeader,
    TableHead,
    TableMain,
    HeaderWrapper,
    HeaderTitle,
    HeaderFilter,
    TableWrapper,
    SortIndicator,
} from './styles';
import { Grid } from 'styles/components';
import { connect } from 'react-redux';
import { Colors } from 'styles/constant';
import { Pagination } from 'components';
import hexToRgba from 'hex-to-rgba';
import { getFromSessionStorage, saveToSessionStorage, removeFromSessionStorage } from 'utils';

const PAGE_SIZE = 25;

const paginateRows = (rows, pageIndex) => rows.slice(pageIndex * PAGE_SIZE, (pageIndex + 1) * PAGE_SIZE);

function Table({
    columns,
    data,
    type,
    preSelected,
    updateData,
    hiddenColumns,
    editableCells,
    languages,
    checkboxes,
    fixedWidth,
    renderRowSubComponent,
    onCheckboxChange,
    showSelected,
    setShowSelected,
    cellStyle,
    storageKey,
    onClearCells,
}) {
    const [checkedCells, setCells] = useState();
    const [checkedRows, setRows] = useState();
    const filterTypes = React.useMemo(
        () => ({
            multiple: (rows, id, filterValue) => {
                const res = rows.filter((row) => {
                    const rowValue = row.values[id];
                    return rowValue !== undefined ? filterValue.includes(rowValue) : true;
                });
                return res;
            },
            search: (rows, id, filterValue) => {
                return rows.filter((row) => row.values[id]?.toLowerCase().includes(filterValue?.toLowerCase()));
            },
            assigned_users: (rows, id, filterValue) => {
                const notAssigned = filterValue.includes(-1);
                const assignedValues = filterValue.some((x) => x !== -1);

                // returns not assigned users || any other selected user
                return rows.filter(({ original: { user_filters } }) => {
                    if (user_filters === undefined) {
                        return true;
                    }

                    // If the value -1 is in the filterValue array, return the rows with unassigned users
                    if (notAssigned && user_filters.length === 0) {
                        return true;
                    }
                    // If there are any other selected user in the filterValue array, return the rows with selected users
                    if (assignedValues && user_filters.some((filter) => filterValue.includes(filter.user.id))) {
                        return true;
                    }
                    return false;
                });
            },
        }),
        [],
    );
    const defaultColumn = React.useMemo(
        () => ({
            Filter: ColumnFilter,
            Cell: EditableCell,
        }),
        [],
    );

    const updateMyData = (rowIndex, columnId, value) => {
        const id = data[rowIndex].id;
        updateData(rowIndex, columnId, value, id);
        checkedRows && !checkedRows.checkedRows.includes(id) && handleCheckedRows(id);

        if (rows) {
            const newRows = [...rows];

            newRows[rowIndex].values = {
                ...newRows[rowIndex].values,
                [columnId]: value,
            };
        }
    };

    const handleCheckedCells = (e) => {
        const newCells = checkedCells ? { ...checkedCells.checkedCells } : { ...checkedCells };
        newCells[e.name] = e.value.filter((v, i, a) => a.findIndex((t) => t === v) === i);

        setCells((prevState) => ({
            ...prevState,
            checkedCells: newCells,
        }));
    };

    const tableStateFromStorage = getFromSessionStorage(storageKey);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        setAllFilters,
        prepareRow,
        getToggleHideAllColumnsProps,
        allColumns,
        visibleColumns,
        pageCount,
        gotoPage,
        state: tableState,
    } = useTable(
        {
            autoResetFilters: false,
            autoResetExpanded: false,
            autoResetGroupBy: false,
            autoResetSelectedRows: false,
            autoResetSortBy: false,
            autoResetRowState: false,
            checkboxes,
            columns,
            data,
            defaultColumn,
            filterTypes,
            checkedCells,
            editableCells,
            handleCheckedCells,
            updateMyData,
            languages,
            showSelected,
            initialState: tableStateFromStorage || {
                hiddenColumns,
                pageIndex: 0,
                pageSize: PAGE_SIZE,
            },
        },
        useFilters,
        useGlobalFilter,
        useSortBy,
        useBlockLayout,
        useExpanded,
        usePagination,
    );

    useEffect(() => {
        setRows((prevState) => ({
            ...prevState,
            initialRows: rows,
        }));

        const savedFilters = tableStateFromStorage ? tableStateFromStorage.filters : [];
        if (savedFilters.length) {
            setCells((prevState) => ({
                ...prevState,
                checkedCells: savedFilters.reduce((result, filter) => {
                    return {
                        ...result,
                        [filter.id]: filter.value,
                    };
                }, {}),
            }));
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        saveToSessionStorage(storageKey, tableState);
    }, [storageKey, tableState]);

    useEffect(() => {
        if (tableStateFromStorage) {
            const previousFilters = JSON.stringify(tableStateFromStorage.filters);
            const currentFilters = JSON.stringify(tableState.filters);
            if (previousFilters !== currentFilters) {
                gotoPage(0);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tableState.filters]);

    useEffect(() => {
        if (!checkedRows) {
            const preSel = rows.filter((r) => preSelected.includes(r.original.id)).map((r) => r.original.id);
            setRows((prevState) => ({
                ...prevState,
                checkedRows: preSel,
            }));
            onCheckboxChange && onCheckboxChange(preSel);
        } else {
            onCheckboxChange && onCheckboxChange(checkedRows.checkedRows);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [checkedRows]);

    const clearCells = () => {
        setCells((prevState) => ({
            ...prevState,
            checkedCells: {},
        }));
        setAllFilters([]);
        removeFromSessionStorage(storageKey);
        gotoPage(0);
        onClearCells && onClearCells();
    };

    const handleCheckedRows = (id) => {
        let rowsList = checkedRows ? checkedRows.checkedRows : [];

        if (rowsList.includes(id)) {
            rowsList = rowsList.filter((row) => row !== id);
        } else {
            rowsList.push(id);
        }

        setRows((prevState) => ({
            ...prevState,
            checkedRows: rowsList,
        }));
    };

    const toggleAllRows = () => {
        let rowsList = checkedRows ? checkedRows.checkedRows : [];
        let allRows = rows.map((row) => row.original.id);

        if (rowsList.filter((row) => allRows.includes(row)).length < allRows.length) {
            selectAll();
        } else {
            selectNone();
        }
    };

    const selectAll = () => {
        let rowsList = checkedRows ? checkedRows.checkedRows : [];
        let allRows = rows.map((row) => row.original.id);

        rowsList = [...rowsList, ...allRows].filter((v, i, a) => a.findIndex((t) => t === v) === i);

        setRows((prevState) => ({
            ...prevState,
            checkedRows: rowsList,
        }));
    };

    const selectNone = () => {
        let rowsList = checkedRows ? checkedRows.checkedRows : [];
        let allRows = rows.map((row) => row.original.id);

        rowsList = rowsList.filter((row) => !allRows.includes(row));

        setRows((prevState) => ({
            ...prevState,
            checkedRows: rowsList,
        }));
    };

    return (
        <>
            <Grid.Row>
                <TableTopButtons
                    checkboxes={checkboxes}
                    getToggleHideAllColumnsProps={getToggleHideAllColumnsProps}
                    allColumns={allColumns}
                    rows={rows}
                    selectAll={selectAll}
                    selectNone={selectNone}
                    checkedRows={checkedRows}
                    clearCells={clearCells}
                    preSelected={preSelected}
                    setRows={setRows}
                    setShowSelected={setShowSelected}
                    showSelected={showSelected}
                    type={type}
                />
            </Grid.Row>
            <Grid.Row>
                <TableWrapper fixedWidth={fixedWidth}>
                    <Pagination gotoPage={gotoPage} pageCount={pageCount} pageIndex={tableState.pageIndex} />
                    <TableMain {...getTableProps()}>
                        <TableHead>
                            {headerGroups.map((headerGroup, i) => (
                                <TableRow key={i} {...headerGroup.getHeaderGroupProps()}>
                                    {checkboxes && (
                                        <TableCellHeader width={50} checkboxes={checkboxes}>
                                            <HeaderWrapper>
                                                <HeaderTitle />
                                                <HeaderFilter>
                                                    <Checkbox
                                                        name="all"
                                                        checked={
                                                            rows.filter(
                                                                (r) =>
                                                                    checkedRows &&
                                                                    checkedRows.checkedRows.includes(r.original.id),
                                                            ).length === rows.length
                                                        }
                                                        onChange={toggleAllRows}
                                                    />
                                                </HeaderFilter>
                                            </HeaderWrapper>
                                        </TableCellHeader>
                                    )}
                                    {headerGroup.headers.map((column) => (
                                        <TableCellHeader
                                            bold
                                            key={i}
                                            {...column.getHeaderProps(column.getSortByToggleProps())}
                                        >
                                            <HeaderWrapper>
                                                <HeaderTitle>
                                                    {column.render('Header')}
                                                    <SortIndicator>
                                                        {column.isSorted ? (column.isSortedDesc ? '↓' : '↑') : ''}
                                                    </SortIndicator>
                                                </HeaderTitle>
                                                <HeaderFilter>
                                                    {column.canFilter ? column.render('Filter') : null}
                                                </HeaderFilter>
                                            </HeaderWrapper>
                                        </TableCellHeader>
                                    ))}
                                </TableRow>
                            ))}
                        </TableHead>
                        <TableBody {...getTableBodyProps()}>
                            {paginateRows(rows, tableState.pageIndex).map((row) => {
                                prepareRow(row);
                                return (
                                    <React.Fragment key={row.id}>
                                        <TableRow key={row.id} {...row.getRowProps()}>
                                            {checkboxes && (
                                                <TableCell width={50} checkboxes={checkboxes}>
                                                    <Checkbox
                                                        style={{ justifyContent: 'center' }}
                                                        name={row.id}
                                                        checked={
                                                            checkedRows &&
                                                            checkedRows.checkedRows.includes(row.original.id)
                                                        }
                                                        onChange={() => handleCheckedRows(row.original.id)}
                                                    />
                                                </TableCell>
                                            )}
                                            {row.cells.map((cell, i) => {
                                                return (
                                                    <TableCell
                                                        bold
                                                        border={!!renderRowSubComponent}
                                                        bg={
                                                            renderRowSubComponent
                                                                ? hexToRgba(Colors.SECONDARY, 0.3)
                                                                : 'transparent'
                                                        }
                                                        key={i}
                                                        {...cell.getCellProps()}
                                                        style={cellStyle ? cellStyle(row.original) : undefined}
                                                    >
                                                        {cell.render('Cell')}
                                                    </TableCell>
                                                );
                                            })}
                                        </TableRow>
                                        {renderRowSubComponent && renderRowSubComponent({ row, visibleColumns })}
                                    </React.Fragment>
                                );
                            })}
                        </TableBody>
                    </TableMain>
                    <Pagination gotoPage={gotoPage} pageCount={pageCount} pageIndex={tableState.pageIndex} />
                </TableWrapper>
            </Grid.Row>
        </>
    );
}

Table.propTypes = {
    storageKey: PropTypes.string.isRequired,
    style: PropTypes.object,
    columns: PropTypes.array.isRequired,
    languages: PropTypes.array,
    data: PropTypes.array.isRequired,
    type: PropTypes.string.isRequired,
    preSelected: PropTypes.oneOfType([PropTypes.array, PropTypes.bool]),
    updateData: PropTypes.func,
    hiddenColumns: PropTypes.array,
    editableCells: PropTypes.array,
    checkboxes: PropTypes.bool,
    fixedWidth: PropTypes.bool,
    renderRowSubComponent: PropTypes.func,
    onCheckboxChange: PropTypes.func,
    showAssigned: PropTypes.bool,
    setShowSelected: PropTypes.func,
    showSelected: PropTypes.bool,
    cellStyle: PropTypes.func,
    onClearCells: PropTypes.func,
};

Table.defaultProps = {
    style: {},
};

function mapStateToProps(state) {
    const { languages } = state;
    return { languages: languages.languages };
}

export default connect(mapStateToProps)(Table);
