import { DomainsTypes } from 'core/types';
import { DomainsService, ToastService } from 'core/services';

export function getDomains() {
    return async (dispatch) => {
        try {
            dispatch({ type: DomainsTypes.GET_DOMAINS_START });
            const domains = await DomainsService.getDomains();
            dispatch({ type: DomainsTypes.GET_DOMAINS, payload: { domains } });
        } catch (error) {
            const message = error?.response?.data?.message;
            ToastService.showGenericError(message);
            dispatch({ type: DomainsTypes.GET_DOMAINS_ERROR });
        }
    };
}

export function updateDomains(id, request) {
    return async (dispatch) => {
        try {
            dispatch({ type: DomainsTypes.UPDATE_DOMAINS_START });
            await DomainsService.updateDomains(id, request);
            dispatch({ type: DomainsTypes.UPDATE_DOMAINS });
        } catch (error) {
            const message = error?.response?.data?.message;
            ToastService.showGenericError(message);
            dispatch({ type: DomainsTypes.UPDATE_DOMAINS_ERROR });
        }
    };
}

export function getUserDomains(id) {
    return async (dispatch) => {
        try {
            dispatch({ type: DomainsTypes.GET_DOMAINS_START });
            const domains = await DomainsService.getUserDomains(id);
            dispatch({ type: DomainsTypes.GET_USER_DOMAINS, payload: { domains } });
        } catch (error) {
            const message = error?.response?.data?.message;
            ToastService.showGenericError(message);
            dispatch({ type: DomainsTypes.GET_DOMAINS_ERROR });
        }
    };
}

export function resetUpdating() {
    return async (dispatch) => {
        try {
            dispatch({ type: DomainsTypes.RESET_UPDATE_DOMAINS });
        } catch (error) {
            const message = error?.response?.data?.message;
            ToastService.showGenericError(message);
            dispatch({ type: DomainsTypes.GET_DOMAINS_ERROR });
        }
    };
}
