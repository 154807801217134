import { store } from 'core/store';
import httpClient from '../httpClient';
import { TextUtils } from 'utils';
import { ParameterTypesList } from 'constant';

const baseUrl = '/parameters';

export const getParameters = async () => {
    return httpClient.get(`${baseUrl}?type=*&subset=labeling`);
};

export const getParametersBySubset = async (subset) => {
    return httpClient.get(`${baseUrl}?type=*&subset=${subset}`);
};

export const getParametersList = async (subset, list) => {
    let url = `${baseUrl}?type=*&subset=${subset}`;
    if (list) {
        url += `&page=${list}`;
    }
    return httpClient.get(url);
};

export const addParameter = async (parameter) => {
    return httpClient.post('/parameters?subset=labeling', parameter);
};

export const createParameter = async (parameter) => {
    return httpClient.post(`${baseUrl}?subset=parameterForm`, parameter);
};

export const updateParameter = (id, parameter) => {
    return httpClient.put(`${baseUrl}/${id}`, parameter);
};

export const deleteParameter = (id) => {
    return httpClient.delete(`${baseUrl}/${id}`);
};

export const enableParameter = (id) => {
    return httpClient.get(`${baseUrl}/${id}/enable`);
};

export const disableParameter = (id) => {
    return httpClient.get(`${baseUrl}/${id}/disable`);
};

export const getParameterById = (id) => {
    const parameters = store.getState().parameters.parameters;
    return parameters.find((parameter) => parameter.id === id);
};

export const getParameterValueById = (id, valueId) => {
    const parameter = getParameterById(id);
    const values = parameter && parameter.values ? parameter.values : [];
    return values.find((parameterValue) => parameterValue.id === valueId);
};

export const getParameterValueByValue = (id, value) => {
    const parameter = getParameterById(id);
    const values = parameter.values || [];
    return values.find((parameterValue) => parameterValue.value === value);
};

export const isA2Parameter = (id) => {
    const parameter = getParameterById(id);
    if (!parameter) return false;
    return parameter.type === ParameterTypesList.A2;
};

export const isA2ParameterKey = (key) => {
    if (!key.startsWith('parameters')) return false;
    return isA2Parameter(TextUtils.getParameterId(key));
};
