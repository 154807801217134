export const ADD_HIGHLIGHT_START = 'ADD_HIGHLIGHT_START';
export const ADD_HIGHLIGHT_SUCCESS = 'ADD_HIGHLIGHT_SUCCESS';
export const ADD_HIGHLIGHT_ERROR = 'ADD_HIGHLIGHT_ERROR';

export const ADD_HIGHLIGHTS = 'ADD_HIGHLIGHTS';

export const REMOVE_HIGHLIGHT = 'REMOVE_HIGHLIGHT';
export const REMOVE_HIGHLIGHT_START = 'REMOVE_HIGHLIGHT_START';
export const REMOVE_HIGHLIGHT_SUCCESS = 'REMOVE_HIGHLIGHT_SUCCESS';
export const REMOVE_HIGHLIGHT_ERROR = 'REMOVE_HIGHLIGHT_ERROR';

export const UPDATE_HIGHLIGHT = 'UPDATE_HIGHLIGHT';
export const UPDATE_HIGHLIGHT_START = 'UPDATE_HIGHLIGHT_START';
export const UPDATE_HIGHLIGHT_SUCCESS = 'UPDATE_HIGHLIGHT_SUCCESS';
export const UPDATE_HIGHLIGHT_ERROR = 'UPDATE_HIGHLIGHT_ERROR';

export const FETCH_HIGHLIGHTS_START = 'FETCH_HIGHLIGHTS_START';
export const FETCH_HIGHLIGHTS_SUCCESS = 'FETCH_HIGHLIGHTS_SUCCESS';
export const FETCH_HIGHLIGHTS_ERROR = 'FETCH_HIGHLIGHTS_ERROR';

export const REMOVE_ALL_HIGHLIGHTS = 'REMOVE_ALL_HIGHLIGHTS';

export const ADD_HIGHLIGHT_PARAMETERS = 'ADD_HIGHLIGHT_PARAMETERS';
