import React from 'react';
import PropTypes from 'prop-types';
import { Buttons } from 'styles/constant';
import Button from 'components/Button';

export function ColumnGroupSelection({
    handleSelectAll,
    handleSelectNone,
    handleSelectRecommended,
    handleSelectMinimal,
    handleSelectLastUsed,
}) {
    return (
        <div className="column-selection-group">
            <div>Columns:</div>
            <div>
                <Button
                    className="select-column-button"
                    label="All"
                    type={Buttons.TERTIARY}
                    onClick={handleSelectAll}
                />
                <Button
                    className="select-column-button"
                    label="None"
                    type={Buttons.TERTIARY}
                    onClick={handleSelectNone}
                />
                <Button
                    className="select-column-button"
                    label="Recommended"
                    type={Buttons.TERTIARY}
                    onClick={handleSelectRecommended}
                />
                <Button
                    className="select-column-button"
                    label="Minimal"
                    type={Buttons.TERTIARY}
                    onClick={handleSelectMinimal}
                />
                <Button
                    className="select-column-button"
                    label="Last used"
                    type={Buttons.TERTIARY}
                    onClick={handleSelectLastUsed}
                />
            </div>
        </div>
    );
}

ColumnGroupSelection.propTypes = {
    handleSelectAll: PropTypes.func.isRequired,
    handleSelectNone: PropTypes.func.isRequired,
    handleSelectRecommended: PropTypes.func.isRequired,
    handleSelectMinimal: PropTypes.func.isRequired,
    handleSelectLastUsed: PropTypes.func.isRequired,
};
