import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Canvas } from './styles';
import charts from '../charts';
import ChartSettingsModal from '../../ChartSettingsModal';

function ChartMain(props) {
    const {
        graphId,
        width,
        height,
        grouped,
        title,
        chartSettingsModal,
        setChartSettingsModal,
        chartSettingsData,
        handleMainOnChange,
        handleMainChildOnChange,
        chartsModal,
        selectAll,
    } = props;

    useEffect(() => {
        charts.createCharts();
        title.length > 0 && charts.updateTitle(title);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleOnChange = (data, index) => {
        handleMainOnChange(data, index);
    };

    const handleChildOnChange = (data, index, parentIndex) => {
        handleMainChildOnChange(data, index, parentIndex);
    };

    const updateChartData = (data) => {
        chartSettingsData(data);
    };

    return (
        <>
            <Canvas id={`chart-${graphId}`} width={width || 0} height={grouped ? 'auto' : height || 0} />
            <ChartSettingsModal
                charts={chartsModal}
                handleModalOnChange={(data, index) => handleOnChange(data, index)}
                handleModalChildOnChange={(data, index, parentIndex) => handleChildOnChange(data, index, parentIndex)}
                visible={chartSettingsModal}
                onClose={() => setChartSettingsModal(false)}
                updateChart={(data) => updateChartData(data)}
                selectAll={selectAll}
            />
        </>
    );
}

ChartMain.propTypes = {
    grouped: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
    graphId: PropTypes.number.isRequired,
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
    printSize: PropTypes.number.isRequired,
    initialHeight: PropTypes.number.isRequired,
    initialWidth: PropTypes.number.isRequired,
    chart: PropTypes.object.isRequired,
    chartSettingsModal: PropTypes.bool,
    setChartSettingsModal: PropTypes.func,
    chartSettingsData: PropTypes.func,
    chartsModal: PropTypes.object.isRequired,
    handleMainOnChange: PropTypes.func.isRequired,
    handleMainChildOnChange: PropTypes.func.isRequired,
    selectAll: PropTypes.func,
};

export default ChartMain;
