import update from 'immutability-helper';
import { FiltersTypes, FilterLanguagesTypes, AuthTypes } from 'core/types';

const initialState = {
    filters: [],
    isLoading: false,
    isLoaded: false,
    isUpdating: false,
    isUpdated: false,
    isDeleting: false,
    isDeleted: false,
    table: false,
};

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case FiltersTypes.GET_FILTERS_START: {
            return update(state, {
                isLoading: { $set: true },
                isLoaded: { $set: false },
                table: { $set: false },
            });
        }

        case FiltersTypes.GET_FILTERS: {
            return update(state, {
                isLoading: { $set: false },
                filters: { $set: payload.filters },
                isLoaded: { $set: true },
                isUpdating: { $set: false },
                isUpdated: { $set: false },
                table: { $set: payload.table },
            });
        }

        case FiltersTypes.UPDATE_FILTERS_START: {
            return update(state, {
                isUpdating: { $set: true },
                isUpdated: { $set: false },
            });
        }

        case FiltersTypes.CREATE_FILTER:
        case FiltersTypes.UPDATE_FILTER: {
            return update(state, {
                isUpdating: { $set: false },
                isUpdated: { $set: true },
            });
        }
        case FilterLanguagesTypes.REMOVE_FILTER_LANGUAGE: {
            const index = state.filters.findIndex((f) => f.id === payload.id);

            return update(state, {
                isDeleting: { $set: false },
                isDeleted: { $set: true },
                filters: {
                    [index]: {
                        $set: payload,
                    },
                },
            });
        }

        case FiltersTypes.REMOVE_FILTER: {
            const filters = state.filters;
            return update(state, {
                isDeleting: { $set: false },
                isDeleted: { $set: true },
                filters: {
                    $set: filters.filter((item) => item.id !== payload),
                },
            });
        }

        case FiltersTypes.GET_FILTERS_ERROR:
        case AuthTypes.LOGOUT: {
            return update(state, {
                table: { $set: true },
                isLoading: { $set: false },
                isLoaded: { $set: true },
                isUpdating: { $set: false },
                isUpdated: { $set: false },
            });
        }

        default: {
            return state;
        }
    }
};
