import React from 'react';
import { EmailButton } from './styles';
import { Grid } from 'styles/components';
import { Icon } from '../../components';
import { EDIT_BLACK, EYE_HIDE } from '../../styles/constant/images';
import { useHistory } from 'react-router-dom';

export const COLUMNS = [
    {
        Header: 'User',
        accessor: (row) => {
            let user = [row.username];
            if (row.user_email) user.push(row.user_email);
            return user.join('\r\n');
        },
        filter: 'search',
    },
    {
        Header: 'Newsletter',
        accessor: (row) => {
            let user = [];
            let emails = [];

            if (row.email) user = row.email.split(', ');
            if (row.emails) emails = [...row.emails.map((e) => e.parsed_schedule)].join();

            return user.join('\r\n') + '\r\n' + emails;
        },
        filter: 'search',
        Cell: function CellRenderer(content) {
            const row = content.row.original;
            let user = [];
            let emails = [];
            const history = useHistory();

            if (row.email) user = row.email.split(', ');
            if (row.emails)
                emails = row.emails.map((e, i) => {
                    return (
                        <Grid.Row key={i} style={{ flexWrap: 'wrap' }}>
                            {e.parsed_schedule.split(';').map((item, index) => {
                                return (
                                    <EmailButton key={index} active={e.status}>
                                        {item}
                                    </EmailButton>
                                );
                            })}
                            <Grid.Row mt={3} style={{ display: 'inline-flex', width: 'auto' }}>
                                <Icon
                                    small
                                    gray
                                    source={EYE_HIDE}
                                    title="Preview"
                                    onClick={() => history.push(`test/send-email-one-user/${row.id}/${e.id}`)}
                                />
                                <Icon
                                    small
                                    gray
                                    source={EDIT_BLACK}
                                    title="Edit"
                                    onClick={() => history.push(`/email/${e.id}`)}
                                />
                            </Grid.Row>
                        </Grid.Row>
                    );
                });

            return (
                <div>
                    <div>{user.join('\r\n') + '\r\n'}</div>
                    {emails}
                </div>
            );
        },
    },
    {
        Header: 'Role',
        accessor: 'role' || '',
        filter: 'multiple',
    },
    {
        Header: 'Active',
        accessor: (row) => (row.active ? 'Active' : 'Inactive'),
        filter: 'multiple',
    },
    {
        Header: 'Last login',
        accessor: (row) => {
            let user = [];
            if (row.last_login) {
                user.push(row.last_login.slice(0, 10));
                user.push(row.last_login.slice(11, 16));
            }
            return user.join(' ');
        },
        filter: 'search',
    },
    {
        Header: 'Impersonated at',
        accessor: (row) => {
            let impersonatedAt = [];
            if (row.impersonated_at) {
                impersonatedAt.push(row.impersonated_at.slice(0, 10));
                impersonatedAt.push(row.impersonated_at.slice(11, 16));
            }
            return impersonatedAt.join(' ');
        },
        filter: 'search',
    },
    {
        Header: 'Last email usage',
        accessor: (row) => {
            let lastEmail = [];
            if (row.last_email_usage) {
                lastEmail.push(row.last_email_usage.slice(0, 10));
                lastEmail.push(row.last_email_usage.slice(11, 16));
            }
            return lastEmail.join(' ');
        },
        filter: 'search',
    },
    {
        Header: 'Organisation',
        accessor: (row) => (row.organisation ? row.organisation.name : ''),
        filter: 'multiple',
    },
];
