import React, { useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudUploadAlt } from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';
import './FileUploader.scss';

const FileUploader = ({ setFiles }) => {
    const fileInputRef = useRef(null);

    const handleDrop = (e) => {
        e.preventDefault();
        const newFiles = Array.from(e.dataTransfer.files);
        setFiles(newFiles);
    };

    const handleDragOver = (e) => {
        e.preventDefault();
    };

    const handleFileSelect = (e) => {
        e.preventDefault(); // Prevent the default button click behavior
        if (e.target.files) {
            const newFiles = Array.from(e.target.files);
            setFiles(newFiles);
        }
    };

    const handleDropZoneClick = () => {
        fileInputRef.current.click();
    };

    const handleButtonClick = (e) => {
        e.stopPropagation(); // Stop event propagation to prevent the drop zone click event
        handleDropZoneClick();
    };

    return (
        <div
            className="drop-zone"
            onDrop={(e) => handleDrop(e)}
            onDragOver={(e) => handleDragOver(e)}
            onClick={handleDropZoneClick}
        >
            <input type="file" style={{ display: 'none' }} ref={fileInputRef} onChange={handleFileSelect} multiple />
            <FontAwesomeIcon icon={faCloudUploadAlt} className="cloud-icon" />
            <p>Drag and drop files here to upload</p>
            <button className="select-file-btn" onClick={handleButtonClick}>
                or select files to upload
            </button>
        </div>
    );
};

FileUploader.propTypes = {
    setFiles: PropTypes.func.isRequired,
};

export default FileUploader;
