import styled from 'styled-components';
import { Colors } from 'styles/constant';

export const Text = styled.div`
    font-size: 16px;
    color: ${Colors.BLACK};
    font-weight: 500;
    line-height: 1.4;
`;

export const Img = styled.img`
    width: 30px;
    height: 30px;
    object-fit: cover;
    cursor: pointer;
    margin-right: ${(props) => (props.mr !== null ? props.mr : 0)}px;
`;
