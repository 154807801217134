/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { FaExpandAlt, FaCompressAlt, FaTrash } from 'react-icons/fa';
import './styles.scss'; // Import the updated SCSS file

const LabellingInfo = ({ highlights, onRowClick, isTableMinimized, setIsTableMinimized, handleRemoveHighlight }) => {
    const [selectedRowIndex, setSelectedRowIndex] = useState(null);

    const toggleTableMinimize = () => {
        setIsTableMinimized(!isTableMinimized);
    };

    const handleRowClick = (id) => {
        setSelectedRowIndex(id);
        onRowClick(id);
    };

    const handleRemove = (event, rowId) => {
        event.stopPropagation(); // Prevent row click when clicking the remove button
        const highlight = highlights.find((i) => i.id === rowId);
        handleRemoveHighlight(highlight);
    };

    const tableData =
        highlights && highlights.length > 0
            ? [...highlights].sort((a, b) => {
                  const startIndexA = Number(a.highlight?.startIndex || 0);
                  const startIndexB = Number(b.highlight?.startIndex || 0);
                  return startIndexA - startIndexB;
              })
            : [];

    const truncateText = (text, maxLength) => {
        if (text.length > maxLength) {
            return text.substring(0, maxLength) + '...';
        }
        return text;
    };

    return (
        <div className="labelling-info">
            <button className="toggle-button" onClick={toggleTableMinimize}>
                {isTableMinimized ? <FaExpandAlt /> : <FaCompressAlt />}
            </button>
            {!isTableMinimized && (
                <table className="info-table">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Content</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {tableData.map((item, index) => (
                            <tr
                                key={index}
                                onClick={() => handleRowClick(item.id)}
                                className={selectedRowIndex === item.id ? 'active' : ''}
                            >
                                <td>{index + 1}</td>
                                <td>{truncateText(item.highlightedText, 60)}</td>
                                <td>
                                    <button onClick={(event) => handleRemove(event, item.id)} className="remove-button">
                                        <FaTrash />
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            )}
        </div>
    );
};

function mapStateToProps(state) {
    const { parameters, article, articles, similarArticles, highlights } = state;
    return {
        highlights: highlights.highlights,
        parameters: parameters.parameters,
        isArticleLoaded: article.isLoaded,
        similarArticles: similarArticles.similarArticles,
        articles: articles.articles,
        article: article.article,
    };
}

export default connect(mapStateToProps)(LabellingInfo);
