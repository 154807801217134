import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { Container } from './styles';

import SingleSelectOptions from './SingleSelectOptions';
import SingleSelectContainer from './SingleSelectContainer';
import { SharedTypes } from 'utils';
import { Form } from 'styles/components';
import Dropdown from '../Dropdown';

function SingleSelect(props) {
    const {
        label,
        dirty,
        error,
        placeholder,
        value,
        options,
        onChange,
        name,
        clearable,
        searchable,
        disabled,
        forceOpen,
        top,
    } = props;
    const ref = useRef();

    const handleOnClear = (event) => {
        event.stopPropagation();
        onChange({ name, value: null });
    };

    const handleOnChange = (newValue) => {
        if (newValue !== value) {
            if (newValue.external_id) {
                onChange(newValue);
            } else {
                onChange({ name, value: newValue });
            }
        }
        ref.current.close();
    };

    const renderButton = () => (
        <SingleSelectContainer
            forceOpen={forceOpen}
            onClear={handleOnClear}
            clearable={clearable}
            value={value}
            options={options}
            placeholder={placeholder}
        />
    );

    const renderDropdown = () => (
        <SingleSelectOptions
            forceOpen={forceOpen}
            disabled={disabled}
            searchable={searchable}
            value={value}
            onChange={handleOnChange}
            options={options}
        />
    );

    return (
        <Container>
            {label && <Form.Label>{label}</Form.Label>}
            <Dropdown
                ref={ref}
                renderButton={renderButton}
                renderDropdown={renderDropdown}
                forceOpen={forceOpen}
                error={!!dirty && !!error}
                top={top}
            />
            {dirty && error && <Form.Error>{error}</Form.Error>}
        </Container>
    );
}

SingleSelect.propTypes = {
    options: PropTypes.arrayOf(SharedTypes.OptionType).isRequired,
    onChange: PropTypes.func.isRequired,
    onBlur: PropTypes.func,
    searchable: PropTypes.bool,
    name: PropTypes.string.isRequired,
    value: SharedTypes.NumberOrString,
    dirty: PropTypes.bool,
    error: PropTypes.string,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    clearable: PropTypes.bool,
    forceOpen: PropTypes.bool,
    disabled: PropTypes.string,
    top: PropTypes.bool,
};

SingleSelect.defaultProps = {
    onBlur: () => {},
    searchable: true,
    value: null,
    label: null,
    dirty: false,
    forceOpen: false,
    error: null,
    placeholder: null,
    disabled: null,
    clearable: true,
};

export default SingleSelect;
