import React from 'react';
import PropTypes from 'prop-types';
import Modal from '../Modal';
import Button from '../Button';
import { Buttons } from 'styles/constant';
import { Loader } from 'components';
import { Content, Footer } from './styles';
import { Grid } from '../../styles/components';

function ExportStatusModal(props) {
    const { visible, onClose } = props;

    const renderContent = () => (
        <>
            <Grid.Row justifyContent="center" mb={20}>
                <Loader />
            </Grid.Row>
            <Grid.Row justifyContent="center">
                <Content>
                    The file is about to be generated. This may take a few minutes. This dialog will close automatically
                    when finish exporting. You can also close this dialog and the export will process in the background.
                </Content>
            </Grid.Row>
        </>
    );

    const renderFooter = () => (
        <Footer>
            <Button label="Close" type={Buttons.TERTIARY} onClick={onClose} />
        </Footer>
    );

    return (
        <Modal
            style={{ width: 580 }}
            title="Please wait..."
            renderContent={renderContent}
            onClose={onClose}
            visible={visible}
            renderFooter={renderFooter}
        />
    );
}

ExportStatusModal.propTypes = {
    onClose: PropTypes.func.isRequired,
    visible: PropTypes.bool,
};

ExportStatusModal.defaultProps = {
    visible: false,
};

export default ExportStatusModal;
