const byLabelingParameters = [
    'articleNo',
    'count',
    'parameter',
    'parameterType',
    'subparameter',
    'comment',
    'labelingStatus',
    'timeSpentLabeling',
    'timeSpentReLabeling',
    'labeledByUser',
    'labelledDate',
    'year',
    'quarter',
    'month',
    'week',
    'articleDate',
    'articleDateTime',
    'domainDescription',
    'sourceNameStandard',
    'language',
    'headline',
    'url',
    'importanceScore',
    'relevanceScore',
    'totalComment',
    'totalShare',
    'totalReaction',
    'totalView',
    'debunkReachCurrent',
    'author',
];

const recommendedExports = {
    simple: [
        'articleNo',
        'count',
        'year',
        'quarter',
        'month',
        'week',
        'articleDate',
        'articleDateTime',
        'domainDescription',
        'sourceNameStandard',
        'language',
        'headline',
        'url',
        'importanceScore',
        'relevanceScore',
        'totalComment',
        'totalShare',
        'totalReaction',
        'totalView',
        'debunkReachCurrent',
        'author',
    ],
    byFilters: [
        'articleNo',
        'count',
        'filters',
        'attentionTimes',
        'filterSentiments',
        'category',
        'categorySentiments',
        'subcat1',
        'subcat1Sentiments',
        'subcat2',
        'subcat2Sentiments',
        'subcat3',
        'subcat3Sentiments',
        'subcat4',
        'subcat4Sentiments',
        'year',
        'quarter',
        'month',
        'week',
        'articleDate',
        'articleDateTime',
        'domainDescription',
        'sourceNameStandard',
        'language',
        'headline',
        'url',
        'importanceScore',
        'relevanceScore',
        'totalComment',
        'totalShare',
        'totalReaction',
        'totalView',
        'debunkReachCurrent',
        'author',
    ],
    byLabelingParameters: byLabelingParameters,
    byMlClassifications: [
        'articleNo',
        'count',
        'modelName',
        'mlClass',
        'year',
        'quarter',
        'month',
        'week',
        'articleDate',
        'articleDateTime',
        'domainDescription',
        'sourceNameStandard',
        'language',
        'headline',
        'url',
        'importanceScore',
        'relevanceScore',
        'totalComment',
        'totalShare',
        'totalReaction',
        'totalView',
        'debunkReachCurrent',
        'author',
    ],
    byNamedEntities: [
        'articleNo',
        'count',
        'entityType',
        'entityName',
        'year',
        'quarter',
        'month',
        'week',
        'articleDate',
        'articleDateTime',
        'domainDescription',
        'sourceNameStandard',
        'language',
        'headline',
        'url',
        'importanceScore',
        'relevanceScore',
        'totalComment',
        'totalShare',
        'totalReaction',
        'totalView',
        'debunkReachCurrent',
        'author',
    ],
    byExternalLinks: [
        'articleNo',
        'count',
        'externalLinks',
        'year',
        'quarter',
        'month',
        'week',
        'articleDate',
        'articleDateTime',
        'domainDescription',
        'sourceNameStandard',
        'language',
        'headline',
        'url',
        'importanceScore',
        'relevanceScore',
        'totalComment',
        'totalShare',
        'totalReaction',
        'totalView',
        'debunkReachCurrent',
        'author',
    ],
    bySingleParameter: byLabelingParameters,
    topWords: [],
};

export default recommendedExports;
