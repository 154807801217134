/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { AutoComplete, Button } from 'components';
import { Mapper, TextUtils } from 'utils';
import { Buttons, Images } from 'styles/constant';
import { ParametersService } from 'core/services';
import { useAddParameter } from 'hooks';
import { ParameterTypesList } from 'constant';
import { Icon } from 'components/SelecedLabelingOption/styles';
import './styles.scss';

function S3ParameterSelector({ parameter, onParameterValueAdd }) {
    const { addParameterValue, isLoading } = useAddParameter();
    const selectedParameter = parameter;
    const [inputValue, setInputValue] = useState({ label: null, value: null });
    const parameterValues = selectedParameter.values;

    const handleParameterAddition = () => {
        if (selectedParameter.id) {
            if (inputValue.value || (!inputValue.value && !inputValue.label)) {
                submitValue(selectedParameter.id, inputValue.value);
            }

            if (!inputValue.value && inputValue.label) {
                addNewParameterValue();
            }
        }
    };

    const addNewParameterValue = () => {
        const request = {
            parameter_id: selectedParameter.id,
            values: [{ value: TextUtils.trim(inputValue.label) }],
            type: ParameterTypesList.S3,
        };

        addParameterValue(request, (parameter) => {
            const newParameterValue = ParametersService.getParameterValueByValue(parameter.id, request.values[0].value);
            submitValue(parameter.id, newParameterValue.id);
        });
    };

    const submitValue = (parameterId, parameterValueId) => {
        onParameterValueAdd({
            id: parameterValueId,
            parameterId: parameterId,
            type: ParameterTypesList.S3,
            value: inputValue.label,
        });
        setInputValue({ label: null, value: null });
    };

    const handleInputChange = (event) => {
        const { value } = event;
        setInputValue(value);
    };

    const getOptions = () => {
        const options = Mapper.mapParameterValuesToOptions(parameterValues);
        return options;
    };

    const clearInput = () => {
        setInputValue({ label: null, value: null });
    };

    return (
        <div className="S3ParameterSelector">
            <div className="parameter-input">
                <AutoComplete
                    disabled={isLoading}
                    value={inputValue}
                    onChange={handleInputChange}
                    placeholder="Select or enter new"
                    name="S3 parameter values"
                    options={getOptions()}
                />
                {inputValue.label && (
                    <div className="clear-input-button">
                        <Icon onClick={clearInput} src={Images.CLOSE_GREY} />
                    </div>
                )}
            </div>
            <div className="submit-button">
                <Button
                    disabled={isLoading || inputValue.label == null}
                    onClick={handleParameterAddition}
                    loading={isLoading}
                    type={Buttons.SECONDARY}
                    label="Add to list"
                />
            </div>
        </div>
    );
}

export default S3ParameterSelector;
