import { StorageKeys } from 'constant';

const saveToStorage = (key, data, storage) => {
    storage.setItem(
        key,

        JSON.stringify(data),
    );
};

const getFromStorage = (key, storage) => JSON.parse(storage.getItem(key));

const removeFromStorage = (key, storage) => {
    storage.removeItem(key);
};

export const clearSessionStorage = () => {
    removeFromSessionStorage(StorageKeys.FiltersList);
    removeFromSessionStorage(StorageKeys.Users);
};

export const saveToSessionStorage = (key, data) => saveToStorage(key, data, sessionStorage);
export const getFromSessionStorage = (key) => getFromStorage(key, sessionStorage);
export const removeFromSessionStorage = (key) => removeFromStorage(key, sessionStorage);

export const saveToLocalStorage = (key, data) => saveToStorage(key, data, localStorage);
export const getFromLocalStorage = (key) => getFromStorage(key, localStorage);
export const removeFromLocalStorage = (key) => removeFromStorage(key, localStorage);
