import { SavedSearchesTypes } from 'core/types';
import { SavedSearchesService, ToastService } from 'core/services';

export function getSavedSearches() {
    return async (dispatch) => {
        try {
            dispatch({ type: SavedSearchesTypes.GET_SAVED_SEARCHES_START });
            const { data } = await SavedSearchesService.getSavedSearches();
            dispatch({ type: SavedSearchesTypes.GET_SAVED_SEARCHES, payload: { savedSearches: data } });
        } catch (error) {
            const message = error?.response?.data?.message;
            ToastService.showGenericError(message);
            dispatch({ type: SavedSearchesTypes.GET_SAVED_SEARCHES_ERROR });
        }
    };
}

export function addSavedSearch(request, callback) {
    return async (dispatch) => {
        try {
            dispatch({ type: SavedSearchesTypes.ADD_SAVED_SEARCHES_START });
            await SavedSearchesService.addSavedSearch(request);
            const { data } = await SavedSearchesService.getSavedSearches();
            dispatch({ type: SavedSearchesTypes.ADD_SAVED_SEARCHES, payload: { savedSearches: data } });
            ToastService.showSuccess('Search successfully added');
            callback();
        } catch (error) {
            const message = error?.response?.data?.message;
            ToastService.showGenericError(message);
            dispatch({ type: SavedSearchesTypes.ADD_SAVED_SEARCHES_ERROR });
        }
    };
}

export function deleteSavedSearch(id, callback) {
    return async (dispatch) => {
        try {
            dispatch({ type: SavedSearchesTypes.DELETE_SAVED_SEARCH_START });
            await SavedSearchesService.deleteSavedSearch(id);
            const { data } = await SavedSearchesService.getSavedSearches();
            dispatch({ type: SavedSearchesTypes.DELETE_SAVED_SEARCH, payload: { savedSearches: data } });
            ToastService.showSuccess('Search successfully deleted');
            callback();
        } catch (error) {
            const message = error?.response?.data?.message;
            ToastService.showGenericError(message);
            dispatch({ type: SavedSearchesTypes.DELETE_SAVED_SEARCH_ERROR });
        }
    };
}

export function updateSavedSearch(id, request, callback) {
    return async (dispatch) => {
        try {
            dispatch({ type: SavedSearchesTypes.UPDATE_SAVED_SEARCH_START });
            await SavedSearchesService.updateSavedSearch(id, request);
            dispatch({ type: SavedSearchesTypes.UPDATE_SAVED_SEARCH });
            ToastService.showSuccess('Search successfully updated');
            callback();
        } catch (error) {
            const message = error?.response?.data?.message;
            ToastService.showGenericError(message);
            dispatch({ type: SavedSearchesTypes.UPDATE_SAVED_SEARCH_ERROR });
        }
    };
}
