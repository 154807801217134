import styled from 'styled-components';
import { Colors } from 'styles/constant';

export const Container = styled.div`
    width: 480px;
    background-color: ${Colors.WHITE};
    border-radius: 5px;
    max-width: 100%;
`;

export const Header = styled.div`
    padding: 20px;
    border-bottom: 1px solid ${Colors.MODAL_BORDER};
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

export const Content = styled.div`
    padding: 20px;
    border-bottom: 1px solid ${Colors.MODAL_BORDER};
`;

export const Footer = styled.div`
    padding: 20px;
`;

export const Title = styled.span`
    font-size: 18px;
    font-weight: 700;
    color: ${Colors.BLACK};
`;

export const CloseIcon = styled.img`
    cursor: pointer;
`;

export const FixedContent = styled.div`
    position: fixed;
`;
