import React, { useEffect, useState } from 'react';
import { ParametersList } from 'containers';
import { Title, Container } from './styles';
import { Grid } from '../../styles/components';
import PropTypes from 'prop-types';
import { SharedTypes } from '../../utils';
import { ParametersListActions } from '../../core/actions';
import { connect } from 'react-redux';
import { Loader } from '../../components';

function ParametersPage(props) {
    const { parameters, isLoaded, isLoading, getParametersList } = props;
    const [fetched, setFetched] = useState(false);

    useEffect(() => {
        document.title = 'Debunk EU - List of Parameters';
        // Makes sure that new subset is fetched when navigating from other pages
        if (!fetched) {
            getParametersList('parameterTable', 'parameterList');
            setFetched(true);
        }
    }, [getParametersList, fetched]);

    return (
        <>
            <Container>
                <Grid.FixedContainer>
                    <Title>Parameters</Title>
                    {isLoading && (
                        <Grid.Row mt={30} justifyContent="center">
                            <Loader />
                        </Grid.Row>
                    )}
                </Grid.FixedContainer>
                {isLoaded && <ParametersList parameters={parameters} updateFilters={() => {}} />}
            </Container>
        </>
    );
}

ParametersPage.propTypes = {
    isLoading: PropTypes.bool.isRequired,
    isLoaded: PropTypes.bool.isRequired,
    parameters: PropTypes.arrayOf(SharedTypes.ParameterType),
    getParametersList: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
    const { parametersList } = state;
    const { isLoading, isLoaded } = parametersList;
    return { parameters: parametersList.parameters, isLoading, isLoaded };
}

const mapDispatchToProps = {
    getParametersList: ParametersListActions.getParametersList,
};

export default connect(mapStateToProps, mapDispatchToProps)(ParametersPage);
