import httpClient from '../httpClient';

const baseUrl = '/searches';

export const getSavedSearches = async () => {
    return httpClient.get(baseUrl);
};

export const addSavedSearch = async (request) => {
    return httpClient.post(baseUrl, request);
};

export const deleteSavedSearch = async (id) => {
    return httpClient.delete(`${baseUrl}/${id}`);
};

export const updateSavedSearch = async (id, request) => {
    return httpClient.put(`${baseUrl}/${id}`, request);
};
