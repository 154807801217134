/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import './styles.scss';

export const Tab = ({ children }) => <div>{children}</div>;

export const Tabs = ({ children }) => {
    const [activeTab, setActiveTab] = useState(0);

    const switchTab = (index) => {
        setActiveTab(index);
    };

    // Convert children to an array
    const childrenArray = React.Children.toArray(children);

    return (
        <div className="labeling-tabs">
            <div className="labeling-tabs-header">
                {childrenArray.map((child, index) => (
                    <div
                        key={index}
                        onClick={() => switchTab(index)}
                        className={index === activeTab ? 'tab active' : 'tab'}
                    >
                        <b>{child.props.title}</b>
                    </div>
                ))}
            </div>
            <div className="labeling-tabs-content">{childrenArray[activeTab]}</div>
        </div>
    );
};
