export const COLUMNS = [
    {
        Header: 'Author',
        accessor: (row) => row.author,
        filter: 'search',
    },
    {
        Header: 'Content',
        accessor: (row) => row.content,
        filter: 'search',
    },
    {
        Header: 'Context',
        accessor: (row) => row.context,
        filter: 'multiple',
    },
    {
        Header: 'Date',
        accessor: (row) => {
            return new Date(row.date).toLocaleDateString();
        },
        filter: 'multiple',
    },
    {
        Header: 'Language',
        accessor: (row) => row.language?.iso,
        filter: 'multiple',
    },
    {
        Header: 'Likes',
        accessor: (row) => row.likes,
        filter: 'search',
    },
    {
        Header: 'Comments',
        accessor: (row) => row.comments,
        filter: 'search',
    },
    {
        Header: 'Shares',
        accessor: (row) => row.shares,
        filter: 'search',
    },
    {
        Header: 'Title',
        accessor: (row) => row.title,
        filter: 'search',
    },
    {
        Header: 'Url',
        accessor: (row) => row.url,
        filter: 'search',
    },
];
