import React, { useEffect, useState } from 'react';
import { Button, Input } from 'components';
import { Grid } from 'styles/components';
import PropTypes from 'prop-types';
import { ParametersListActions } from '../../core/actions';
import { SharedTypes } from 'utils';
import { connect } from 'react-redux';
import { FORM_DATA, INITIAL_STATE } from './config';
import update from 'immutability-helper';
import { Container } from './styles';
import { Title } from '../Filter/styles';
import { useHistory } from 'react-router-dom';

function ParameterValueForm(props) {
    const { parameterValue, updateParameterValue, isUpdated, isUpdating } = props;
    const [currentParameterValue, setCurrentParameterValue] = useState(INITIAL_STATE);
    const [parrentParamId, setParrentParamId] = useState(null);
    const [fetched, setFetched] = useState(false);
    const history = useHistory();

    useEffect(() => {
        // Makes sure that new subset is fetched when navigating from other pages
        if (!fetched) {
            setFetched(true);
        }
    }, [fetched]);

    useEffect(() => {
        isUpdated && history.push('/parameters');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isUpdated]);

    useEffect(() => {
        setCurrentParameterValue(history.location.state.p);
        setParrentParamId(history.location.state.parameter.id);

        document.title = `Debunk EU - Edit Parameter Value - ${history.location.state.p.value}`;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [parameterValue]);

    const onSubmit = () => {
        const data = FORM_DATA(currentParameterValue);
        updateParameterValue(data.id, data, parrentParamId);
    };

    const handleOnChange = (e) => {
        const { name, value } = e;

        setCurrentParameterValue(
            update(currentParameterValue, {
                [name]: { $set: value },
            }),
        );
    };

    return (
        <>
            <Grid.FixedContainer sticky style={{ maxWidth: 'none' }}>
                <Grid.FixedContainer>
                    <Grid.Row pt={20} pb={20} justifyContent="space-between">
                        <Grid.Col>
                            <Title>
                                Edit parameter value <span>{currentParameterValue.value}</span>
                            </Title>
                        </Grid.Col>
                        <Grid.Col>
                            <Grid.Row>
                                <Grid.Col mr={10}>
                                    <Button
                                        onClick={() => history.push('/parameters')}
                                        label="Cancel"
                                        type="TERTIARY"
                                    />
                                </Grid.Col>
                                <Grid.Col>
                                    <Button onClick={onSubmit} label="Submit" loading={isUpdating} />
                                </Grid.Col>
                            </Grid.Row>
                        </Grid.Col>
                    </Grid.Row>
                </Grid.FixedContainer>
                <Container>
                    <Grid.FixedContainer>
                        <section>
                            <Grid.Row mb={20}>
                                <Grid.Col flex={1}>
                                    <Input
                                        label="Value"
                                        name="value"
                                        value={currentParameterValue.value}
                                        onChange={(e) => handleOnChange(e)}
                                    />
                                </Grid.Col>
                            </Grid.Row>
                            <Grid.Row mb={20}>
                                <Grid.Col flex={1}>
                                    <Input
                                        label="User description"
                                        name="user_description"
                                        value={currentParameterValue.user_description}
                                        onChange={(e) => handleOnChange(e)}
                                    />
                                </Grid.Col>
                            </Grid.Row>
                        </section>
                    </Grid.FixedContainer>
                </Container>
            </Grid.FixedContainer>
        </>
    );
}

ParameterValueForm.propTypes = {
    updateParameterValue: PropTypes.func.isRequired,
    parameters: PropTypes.arrayOf(SharedTypes.ParameterType),
    parameterValue: PropTypes.object,
    isUpdating: PropTypes.bool.isRequired,
    isUpdated: PropTypes.bool.isRequired,
};

function mapStateToProps(state) {
    const { parametersList } = state;
    const { isUpdating, isUpdated, parameterValue } = parametersList;
    return { isUpdating, isUpdated, parameterValue };
}

const mapDispatchToProps = {
    updateParameterValue: ParametersListActions.updateParameterValue,
};

export default connect(mapStateToProps, mapDispatchToProps)(ParameterValueForm);
