import { jsonToCSV } from 'react-papaparse';

export const chartToCsvString = (data, chartType) => {
    const config = {
        delimiter: '\t',
    };

    switch (chartType) {
        case 'grouped bar': {
            const groupedBarTable = setDataArrays(data.data);
            const csv = jsonToCSV(
                {
                    fields: groupedBarTable.head,
                    data: groupedBarTable.body,
                },
                config,
            );

            return csv;
        }

        case 'stacked bar':
        case 'line bar':
        case 'dynamics': {
            const groupedDynamicsTable = setDataArraysWithHeads(data.data);
            const csv = jsonToCSV(
                {
                    fields: groupedDynamicsTable.head,
                    data: groupedDynamicsTable.body,
                },
                config,
            );

            return csv;
        }

        default: {
            const groupedBarTable = setBarDataArrays(data.data);
            const csv = jsonToCSV(
                {
                    fields: groupedBarTable.head,
                    data: groupedBarTable.body,
                },
                config,
            );

            return csv;
        }
    }
};

const setDataArrays = (data) => {
    let currentFirstColumn = '';

    const body = data.map((d) => {
        if (d.parent.length) {
            currentFirstColumn = d.parent;
        }

        return [currentFirstColumn, d.name, d.value];
    });

    return {
        head: [],
        body: body,
    };
};

const setDataArraysWithHeads = (data) => {
    const head = [''];
    let index = data.at(-1)?.index;
    let averageColumnCount = Math.ceil(data.length / index);

    const body = data.reduce((acc, cur, currentIndex, arr) => {
        if (!head.find((x) => x === cur.name)) {
            head.push(cur.name);
        }

        // case when a last column value is missing
        if (
            head.length - 1 === averageColumnCount && // to check if all columns are added to head array
            currentIndex < data.length + 1 && // to prevent infinite loop
            cur.index !== arr[currentIndex + 1]?.index && // checks if the second currant element will be from second table row
            cur.name !== head.at(-1) // if the current element is the last in the current row, checks if the current aren't the last column
        ) {
            if (!acc[cur.index]) {
                return [...acc, [cur.parent, cur.value, 0]];
            }

            return [...acc.slice(0, acc.length - 1), [...acc[cur.index], cur.value, 0]];
        }

        if (cur.parent.length) {
            return [...acc, [cur.parent, cur.value]];
        }

        return [...acc.slice(0, acc.length - 1), [...acc[cur.index], cur.value]];
    }, []);

    return {
        head: head,
        body: body,
    };
};

const setBarDataArrays = (data) => {
    const body = data.map((d) => [d.parent, d.value]);

    return {
        head: [],
        body: body,
    };
};
