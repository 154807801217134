export const STORAGE_KEY_LABELLING = 'labelling';
export const STORAGE_KEY_HIGHLIGHTS = 'labelling-highlights';

export const saveToStorage = (data, storageKey, identifier) => {
    localStorage.setItem(
        storageKey,
        JSON.stringify({
            [identifier]: data,
        }),
    );
};

export const getFromStorage = (identifier, storageKey) => {
    const data = JSON.parse(localStorage.getItem(storageKey));
    if (data) {
        return data[identifier];
    }
    return null;
};

export const removeFromStorage = () => {
    localStorage.removeItem(STORAGE_KEY_LABELLING);
    localStorage.removeItem(STORAGE_KEY_HIGHLIGHTS);
};
