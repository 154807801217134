import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { SharedTypes } from 'utils';
import { StorageKeys } from 'constant';
import { useHistory } from 'react-router-dom';
import { COLUMNS } from './config';
import { StyledIcon } from './styles';
import { EDIT, REMOVE } from 'styles/constant/images';
import renderRow from './SubparametersList';
import { Grid } from '../../styles/components';
import { Table, Slider } from 'components';
import { ParametersListActions } from '../../core/actions';
import { usePermission } from 'hooks';
import { getFromSessionStorage, saveToSessionStorage, removeFromSessionStorage } from 'utils';
import FilterListLayout from '../FiltersList/FilterListLayout';

const SEARCH_STORAGE_KEY = 'ParametersListSearchInput';

const filterBySearchInputValue = (data, value) => {
    let arr = data
        .map((param) => {
            const values = param.values.filter((v) => v.value.toLowerCase().includes(value.toLowerCase()));
            const isInParamName = param.name.toLowerCase().includes(value.toLowerCase());

            if (!isInParamName && !values.length) {
                return null;
            }

            if (isInParamName) {
                return { ...param, values: values };
            }

            return { ...param, values: values };
        })
        .filter(Boolean);
    if (value.length === 0) arr = false;
    return arr;
};

function ParametersList({
    parameters,
    enableParameter,
    disableParameter,
    deleteParameter,
    deleteParameterValue,
    disableParameterValue,
    enableParameterValue,
    isUpdating,
    statusIsUpdating,
}) {
    const history = useHistory();
    const [searchInputValue, setSearchInputValue] = useState('');
    const [filtered, setFiltered] = useState(false);
    const [expanded, setExpanded] = useState([]);
    const hasManageParameterPermission = usePermission('ROLE_MANAGE_PARAMETERS');

    const removeParameter = (id) => {
        const confirm = window.confirm('Remove parameter?');
        confirm && deleteParameter(id);
    };

    const removeParameterValue = (parameterId, id) => {
        const confirm = window.confirm('Remove value?');
        confirm && deleteParameterValue(parameterId, id);
    };

    useEffect(() => {
        if (parameters) {
            const searchInputFromStorage = getFromSessionStorage(SEARCH_STORAGE_KEY);
            if (searchInputFromStorage) {
                setSearchInputValue(searchInputFromStorage);
                setFiltered(filterBySearchInputValue(parameters, searchInputFromStorage));
            } else {
                setFiltered(filterBySearchInputValue(parameters, searchInputValue));
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [parameters]);

    const renderRowSubComponent = ({ row, visibleColumns }) =>
        renderRow(
            parameters,
            row,
            visibleColumns,
            hasManageParameterPermission,
            history,
            changeValueStatus,
            removeParameterValue,
            isUpdating,
            expanded,
            expand,
            statusIsUpdating,
            filtered,
        );

    const changeStatus = (id, status) => {
        if (status === 1) disableParameter(id);
        else enableParameter(id);
    };

    const changeValueStatus = (id, status) => {
        if (status === 1) disableParameterValue(id);
        else enableParameterValue(id);
    };

    const expand = (id, valuesCount) => {
        const parameter = parameters.find((p) => p.id === id);
        if (!expanded.find((parameter) => parameter.id === id)) {
            setExpanded([...expanded, { id: id, valuesLoaded: 200 }]);
        } else {
            const paramIndex = expanded.findIndex((parameter) => parameter.id === id);
            if (parameter.values.length > valuesCount) {
                const expandedParamValue = [...expanded];
                expandedParamValue[paramIndex] = {
                    id: id,
                    valuesLoaded: expandedParamValue[paramIndex].valuesLoaded + 200,
                };
                setExpanded(expandedParamValue);
            } else {
                expanded.splice(paramIndex, 1);
                setExpanded([...expanded]);
            }
        }
    };

    const onInputChange = (value) => {
        setSearchInputValue(value);
        setFiltered(filterBySearchInputValue(parameters, value));
        saveToSessionStorage(SEARCH_STORAGE_KEY, value);
    };

    return (
        <FilterListLayout
            searchInputValue={searchInputValue}
            onSearchInputChange={onInputChange}
            onCreateNewClick={() => history.push('/parameter/create')}
            createNewButtonDisabled={!hasManageParameterPermission}
        >
            <Table
                storageKey={StorageKeys.ParametersList}
                editableCells={COLUMNS.filter((c) => c.input).map((c) => c.Header)}
                updateData={() => {}}
                columns={[
                    ...COLUMNS,
                    {
                        Header: 'Actions',
                        accessor: (row) => (row.status ? 'Available' : 'Unavailable'),
                        filter: 'multiple',
                        Cell: function CellRenderer(row) {
                            return (
                                <Grid.Row justifyContent="center">
                                    <StyledIcon
                                        gray
                                        source={EDIT}
                                        onClick={() => history.push(`/parameter/${row.row.original.id}`)}
                                        disabled={!hasManageParameterPermission}
                                    />
                                    <StyledIcon
                                        gray
                                        source={REMOVE}
                                        onClick={() => removeParameter(row.row.original.id)}
                                        iconSmall
                                        disabled={!hasManageParameterPermission}
                                    />
                                    <Slider
                                        name="status"
                                        disabled={statusIsUpdating}
                                        value={row.row.original.status}
                                        onChange={() => changeStatus(row.row.original.id, row.row.original.status)}
                                        label="Status"
                                    />
                                </Grid.Row>
                            );
                        },
                    },
                ]}
                data={filtered || parameters.filter((v, i, a) => a.findIndex((t) => t.id === v.id) === i)}
                hiddenColumns={['Create date', 'Created by']}
                type="parameters"
                history={history}
                preSelected={[]}
                renderRowSubComponent={renderRowSubComponent}
                onClearCells={() => {
                    removeFromSessionStorage(SEARCH_STORAGE_KEY);
                    setSearchInputValue('');
                    setFiltered(parameters);
                }}
            />
        </FilterListLayout>
    );
}

ParametersList.propTypes = {
    isUpdating: PropTypes.bool.isRequired,
    isUpdated: PropTypes.bool.isRequired,
    statusIsUpdating: PropTypes.bool.isRequired,
    statusIsUpdated: PropTypes.bool.isRequired,
    isDeleting: PropTypes.bool.isRequired,
    isDeleted: PropTypes.bool.isRequired,
    parameters: PropTypes.arrayOf(SharedTypes.ParameterType),
    deleteParameter: PropTypes.func.isRequired,
    deleteParameterValue: PropTypes.func.isRequired,
    updateFilters: PropTypes.func.isRequired,
    enableParameter: PropTypes.func.isRequired,
    disableParameter: PropTypes.func.isRequired,
    enableParameterValue: PropTypes.func.isRequired,
    disableParameterValue: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
    const { parametersList } = state;
    const { isUpdating, isUpdated, statusIsUpdating, statusIsUpdated, isDeleting, isDeleted } = parametersList;
    return {
        isUpdating,
        isUpdated,
        statusIsUpdating,
        statusIsUpdated,
        isDeleting,
        isDeleted,
        parameters: parametersList.parameters,
    };
}

const mapDispatchToProps = {
    deleteParameter: ParametersListActions.deleteParameter,
    deleteParameterValue: ParametersListActions.deleteParameterValue,
    enableParameter: ParametersListActions.enableParameter,
    disableParameter: ParametersListActions.disableParameter,
    enableParameterValue: ParametersListActions.enableParameterValue,
    disableParameterValue: ParametersListActions.disableParameterValue,
};

export default connect(mapStateToProps, mapDispatchToProps)(ParametersList);
