export const useD3ToPng = () => {
    const inlineStyles = (source, target) => {
        // inline style from source element to the target (detached) one
        const computed = window.getComputedStyle(source);
        for (const styleKey of computed) {
            target.style[styleKey] = computed[styleKey];
        }

        // recursively call inlineStyles for the element children
        for (let i = 0; i < source.children.length; i++) {
            inlineStyles(source.children[i], target.children[i]);
        }
    };

    const copyToCanvas = ({ target, width, height }) => {
        let svgData = new XMLSerializer().serializeToString(target);
        let canvas = document.createElement('canvas');

        canvas.width = width;
        canvas.height = height;

        let ctxt = canvas.getContext('2d');

        let img = document.createElement('img');

        img.setAttribute('src', 'data:image/svg+xml;base64,' + btoa(unescape(encodeURIComponent(svgData))));
        return new Promise((resolve) => {
            img.onload = () => {
                ctxt.drawImage(img, 0, 0);
                resolve(canvas.toDataURL('image/png', 1));
            };
        });
    };

    async function generatePng(chartId, name, printSize) {
        const downloadLink = document.createElement('a');
        document.body.appendChild(downloadLink);

        const downloadImage = ({ file, name }) => {
            downloadLink.download = `${name}.png`;
            downloadLink.href = file;
            downloadLink.click();
        };

        const originalSource = document.querySelector(chartId);

        const source = originalSource.cloneNode(true);

        const initialSize = originalSource.getBoundingClientRect();

        const initialWidth = initialSize.width;
        const initialHeight = initialSize.height;

        const ratio = (initialHeight * 100) / initialWidth;

        const width = printSize * 2;
        const height = ratio ? width * (ratio / 100) : (width * initialHeight) / initialWidth;

        source.setAttribute('width', width);
        source.setAttribute('height', height);

        // Create a new SVG element similar to the source one to avoid modifying the source element.
        const target = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
        target.innerHTML = source.innerHTML;
        for (const attr of source.attributes) {
            target.setAttribute(attr.name, attr.value);
        }

        // Set all the css styles inline on the target element based on the styles of the source element
        inlineStyles(source, target);

        target.style.background = 'white';

        //Copy all html to a new canvas
        const file = await copyToCanvas({
            source,
            target,
            width,
            height,
        });

        downloadImage({ file, name });
        return file;
    }

    return { generatePng };
};

export default useD3ToPng;
