import React, { useEffect } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Switch, useHistory } from 'react-router-dom';
import { Header, ProtectedRoute } from 'containers';
import * as Styles from './styles';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { InitialActions, ParametersActions } from 'core/actions';
import {
    CategoriesPage,
    ChartListPage,
    CreateChartPage,
    DomainsPage,
    FilterPage,
    FiltersPage,
    UsersPage,
    EmailsPage,
    EmailsTemplatePage,
    OrganisationsPage,
    OrganisationPage,
    ParameterPage,
    ParameterValuePage,
    ParametersPage,
    UserPage,
    NotFoundPage,
    ManualImportPage,
    ManualImportsPage,
    ErrorPage,
} from 'pages';
import ArticlesLayout from '../ArticlesLayout';
import { PageLoader } from 'components';
import { useUpdateSearch } from 'hooks';
import { TranslateUtils } from 'utils';

const AdminLayout = (props) => {
    const { getInitialData, getParameters, isLoaded } = props;
    const history = useHistory();
    useUpdateSearch();

    useEffect(() => {
        getInitialData();
    }, [getInitialData]);

    useEffect(() => {
        const container = document.getElementById('google_translate_element');
        container && container.classList.contains('show') && TranslateUtils.handleTranslateContainer();
    }, [history.location.pathname]);

    useEffect(() => {
        const interval = setInterval(getParameters, 120000);
        return () => clearInterval(interval);
    }, [getParameters]);

    if (!isLoaded) {
        return <PageLoader />;
    }

    return (
        <ErrorBoundary FallbackComponent={ErrorPage}>
            <Styles.Container>
                <Styles.Header>
                    <Header />
                </Styles.Header>
                <Switch>
                    <ProtectedRoute path="/chart/:id" component={CreateChartPage} permission="ROLE_CREATE_CHARTS" />
                    <ProtectedRoute path="/users" render={(props) => <UsersPage {...props} key={Date.now()} />} />
                    <ProtectedRoute path="/user/:id" component={UserPage} permission="ROLE_CREATE_USERS" />
                    <ProtectedRoute
                        path="/chart-list"
                        permission="ROLE_CREATE_CHARTS"
                        render={(props) => <ChartListPage {...props} key={Date.now()} />}
                    />
                    <ProtectedRoute path="/user-domains/:id" component={DomainsPage} permission="ROLE_ASSIGN_DOMAINS" />
                    <ProtectedRoute
                        path="/user-filters/:id"
                        component={CategoriesPage}
                        permission="ROLE_ASSIGN_FILTERS"
                    />
                    <ProtectedRoute path="/filters" render={(props) => <FiltersPage {...props} key={Date.now()} />} />
                    <ProtectedRoute path="/filter/:id" component={FilterPage} permission="ROLE_CREATE_FILTERS" />
                    <ProtectedRoute
                        path="/parameters"
                        render={(props) => <ParametersPage {...props} key={Date.now()} />}
                    />
                    <ProtectedRoute
                        path="/parameter/:id"
                        component={ParameterPage}
                        permission="ROLE_MANAGE_PARAMETERS"
                    />
                    <ProtectedRoute
                        path="/parameters-value/:id"
                        component={ParameterValuePage}
                        permission="ROLE_MANAGE_PARAMETERS"
                    />
                    <ProtectedRoute path="/emails" render={(props) => <EmailsPage {...props} key={Date.now()} />} />
                    <ProtectedRoute path="/email/:id" component={EmailsTemplatePage} permission="ROLE_CREATE_EMAILS" />
                    <ProtectedRoute
                        path="/organisations"
                        render={(props) => <OrganisationsPage {...props} key={Date.now()} />}
                    />
                    <ProtectedRoute
                        path="/organisation/:id"
                        component={OrganisationPage}
                        permission="ROLE_CREATE_ORGANISATIONS"
                    />
                    <ProtectedRoute exact path="/" component={ArticlesLayout} />
                    <ProtectedRoute path="/search" component={ArticlesLayout} />
                    <ProtectedRoute path="/labelling" component={ArticlesLayout} permission="ROLE_LABEL" />
                    <ProtectedRoute path="/charts" permission="ROLE_VIEW_CHARTS" component={ArticlesLayout} />

                    <ProtectedRoute
                        path="/import-publications"
                        render={(props) => <ManualImportsPage {...props} key={Date.now()} />}
                    />
                    <ProtectedRoute
                        path="/import-publication/:id"
                        component={ManualImportPage}
                        {...props}
                        permission=""
                    />

                    <ProtectedRoute component={NotFoundPage} />
                </Switch>
            </Styles.Container>
        </ErrorBoundary>
    );
};

AdminLayout.propTypes = {
    getParameters: PropTypes.func.isRequired,
    getInitialData: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    isLoaded: PropTypes.bool.isRequired,
};

const mapDispatchToProps = {
    getInitialData: InitialActions.getInitialData,
    getParameters: ParametersActions.getParameters,
};

function mapStateToProps(state) {
    const { initial } = state;
    const { isLoading, isLoaded } = initial;
    return { isLoading, isLoaded };
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminLayout);
