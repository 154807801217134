import styled from 'styled-components/macro';
import { Colors } from 'styles/constant';
import hexToRgba from 'hex-to-rgba';
import { NumberUtils } from 'utils';

export const Container = styled.div`
    position: relative;
    border: ${(props) => (props.error ? `1px solid ${Colors.DANGER}` : 'unset')};
    border-radius: ${(props) => (props.error ? '5px' : 'unset')};
`;

export const ButtonContainer = styled.div`
    opacity: ${(props) => (props.disabled ? 0.6 : 1)};

    &:hover {
        cursor: ${(props) => (props.disabled ? 'no-drop' : 'pointer')};
    }
`;

export const DropdownContainer = styled.div`
    display: ${({ active, display = 'block' }) => (active ? display : 'none')};
    z-index: ${(props) => (props.forceOpen ? 1 : 999)};
    position: ${(props) => (props.forceOpen ? 'relative' : 'absolute')};
    top: ${(props) => (props.top ? 'auto' : '100%')};
    bottom: ${(props) => (props.top ? '100%' : 'auto')};
    left: 0;
    background-color: ${Colors.WHITE};
    box-shadow: ${(props) => (props.forceOpen ? 'none' : `0 0 20px 0 ${hexToRgba(Colors.BLACK, 0.1)}`)};
    border-radius: ${(props) => (props.forceOpen ? '0 0 5px 5px' : '5px')};
    right: ${(props) => props.right};
    left: ${(props) => props.left};
    width: ${(props) => (NumberUtils.isNumeric(props.width) ? `${props.width}px` : props.width)};
    max-height: 50vh;
    overflow-x: hidden;
    overflow-y: auto;
    border: ${(props) => (props.forceOpen ? `1px solid ${Colors.BACKGROUND}` : 'none')};
    min-height: ${(props) => (props.large ? '560px' : props.forceOpen ? '323px' : 'auto')};
`;
