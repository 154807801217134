export const INITIAL_STATE = {
    id: null,
    type: '',
    parameter: '',
    organisation: null,
    user_description: '',
    max_values: '',
    values: [],
    status: 1,
};

export const SET_PARAMETER = function (parameter) {
    let subParameterValues = [];

    if (parameter.values) {
        parameter.values.map((v) => {
            subParameterValues.push({ label: v.value, id: v.id });
        });
    }

    return {
        id: parameter.id,
        parameter: parameter.name,
        type: parameter.type,
        status: parameter.status,
        values: subParameterValues,
        organisation: parameter.organisation ? parameter.organisation.id : null,
        user_description: parameter.user_description,
        max_values: parameter.max_values,
    };
};

export const FORM_DATA = function (parameter) {
    let subParameterValue = [];

    parameter.values.map((v) => {
        subParameterValue.push({ value: v.label, id: v ? v.id : null });
    });

    return {
        id: parameter.id || null,
        parameter: parameter.parameter,
        organisation_id: parameter.organisation,
        status: parameter.status,
        type: parameter.type,
        values: subParameterValue,
        user_description: parameter.user_description,
        max_values: parameter.max_values,
    };
};
