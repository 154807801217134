import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Container, Content, OptionContainer } from './styles';
import { Form } from 'styles/components';
import { SharedTypes } from 'utils';
import Radio from '../Radio';

function ExpandedSelect(props) {
    const { label, style, value, dirty, error, onChange, name, options, horizontal, large, userForm } = props;
    const [query, setQuery] = useState('');

    const handleOnChange = (optionValue) => {
        onChange({ name, value: optionValue === value ? null : optionValue });
    };

    return (
        <Container style={style}>
            {label && <Form.Label>{label}</Form.Label>}
            {userForm ? (
                <input placeholder="Enter username" onChange={(event) => setQuery(event.target.value)} />
            ) : null}
            {
                <Content horizontal={horizontal} large={large}>
                    {options
                        .filter((option) => {
                            if (query === '') {
                                return option;
                            } else if (option.label.toLowerCase().includes(query.toLowerCase())) {
                                return option;
                            }
                        })
                        .map((option) => (
                            <OptionContainer
                                horizontal={horizontal}
                                onClick={() => handleOnChange(option.value)}
                                key={option.value}
                                title={option.label}
                            >
                                <Radio
                                    disabled
                                    label={option.label}
                                    checked={value === option.value}
                                    name={option.label}
                                />
                            </OptionContainer>
                        ))}
                </Content>
            }
            {dirty && error && <Form.Error>{error}</Form.Error>}
        </Container>
    );
}

ExpandedSelect.propTypes = {
    options: PropTypes.arrayOf(SharedTypes.OptionType).isRequired,
    onChange: PropTypes.func.isRequired,
    onBlur: PropTypes.func,
    name: PropTypes.string.isRequired,
    value: SharedTypes.NumberOrString,
    dirty: PropTypes.bool,
    error: PropTypes.string,
    label: PropTypes.string,
    style: PropTypes.object,
    disabled: PropTypes.bool,
    horizontal: PropTypes.bool,
    large: PropTypes.bool,
    userForm: PropTypes.bool,
};

ExpandedSelect.defaultProps = {
    style: {},
    label: null,
    value: null,
    dirty: false,
    error: null,
    disabled: false,
    onBlur: () => {},
    horizontal: false,
    large: false,
};

export default ExpandedSelect;
