import React from 'react';
import { TableCell, TableRow } from '../../../components/Table/styles';
import { Grid } from '../../../styles/components';
import { EDIT, REMOVE } from 'styles/constant/images';
import { Button, Icon, Slider } from 'components';

export default function renderRow(
    parameters,
    row,
    visibleColumns,
    hasManageParameterPermission,
    history,
    changeValueStatus,
    removeParameterValue,
    isUpdating,
    expanded,
    expand,
    statusIsUpdating,
    filtered,
) {
    let parameter;
    parameter = parameters.find((p) => p.id == row.original.id);
    if (filtered) parameter = filtered.find((p) => p.id == row.original.id);
    const parameterExpanded = expanded.find((e) => e.id == parameter.id);

    if (parameter.values && parameter.values.length > 0 && visibleColumns.length > 0) {
        let values = 5;
        let buttonText;
        if (parameterExpanded) {
            values = parameterExpanded.valuesLoaded;
        } else {
            values = 5;
        }

        if (parameter.values.length > values) {
            buttonText = 'Load more...';
        } else {
            buttonText = 'Load less...';
        }
        const rows = parameter.values.slice(0, values).map((p, i) => {
            const bindColumns = visibleColumns
                .filter(
                    (column) =>
                        column.id === 'Name' ||
                        column.id === 'Status' ||
                        column.id === 'Count' ||
                        column.id === 'Actions' ||
                        column.id === 'Create date' ||
                        column.id === 'Type' ||
                        column.id === 'Organisation' ||
                        column.id === 'Update date',
                )
                .map((c) => c.id);

            return (
                <TableRow key={i}>
                    {bindColumns.includes('Name') && (
                        <TableCell key={1}>
                            <p>{p.value}</p>
                        </TableCell>
                    )}
                    {bindColumns.includes('Count') && (
                        <TableCell key={2}>
                            <p>{p.count ? p.count : '0'}</p>
                        </TableCell>
                    )}
                    {bindColumns.includes('Type') && <TableCell key={3}>{p.type ? p.type : '-'}</TableCell>}
                    {bindColumns.includes('Organisation') && (
                        <TableCell key={4}>{p.organisation ? p.organisation : '-'}</TableCell>
                    )}
                    {bindColumns.includes('Actions') && (
                        <TableCell key={5}>
                            <Grid.Row justifyContent="center">
                                <Icon
                                    gray
                                    source={EDIT}
                                    onClick={() => history.push(`/parameters-value/${p.id}`, { p, parameter })}
                                    disabled={!hasManageParameterPermission}
                                />
                                <Icon
                                    gray
                                    source={REMOVE}
                                    onClick={() => removeParameterValue(parameter.id, p.id)}
                                    iconSmall
                                    disabled={!hasManageParameterPermission}
                                />
                                <Slider
                                    name="status"
                                    disabled={statusIsUpdating}
                                    value={p.status}
                                    onChange={() => changeValueStatus(p.id, p.status)}
                                    label="Status"
                                />
                            </Grid.Row>
                        </TableCell>
                    )}
                </TableRow>
            );
        });

        return (
            <>
                {visibleColumns.length > 0 && rows}
                <tr>
                    <td>
                        {parameter.values.length > 5 ? (
                            <Button
                                style={{ marginLeft: '3px' }}
                                onClick={() => expand(parameter.id, values)}
                                label={buttonText}
                                type="TERTIARY"
                            />
                        ) : null}
                    </td>
                </tr>
            </>
        );
    }
}
