export const COLUMNS = [
    {
        Header: 'Name',
        accessor: (row) => row.name || '-',
        filter: 'search',
    },
    {
        Header: 'Count',
        accessor: (row) => row.count || '0',
        filter: 'multiple',
    },
    {
        Header: 'Type',
        accessor: (row) => row.type,
        filter: 'multiple',
    },
    {
        Header: 'Organisation',
        accessor: (row) => (row.organisation ? row.organisation.name : '-'),
        filter: 'multiple',
    },
];
