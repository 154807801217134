import React, { useState } from 'react';
import * as Styles from './styles';
import { AutoComplete, Button } from 'components';
import { Mapper, SharedTypes, TextUtils } from 'utils';
import { Grid } from 'styles/components';
import { Buttons, Images } from 'styles/constant';
import { ParametersActions } from '../../core/actions';
import PropTypes from 'prop-types';
import { useAddParameter } from 'hooks';
import { ParametersService } from 'core/services';
import { ParameterTypesList } from 'constant';
import { Icon } from '../../components/SelecedLabelingOption/styles';
import ParameterValueModal from '../ParameterValueModal';
import { connect } from 'react-redux';

function ParameterA3AutoComplete(props) {
    const { parameter, onParameterValueAdd, selectedParameterValues, disabled, updateParameterValue } = props;
    const { addParameterValue, isLoading } = useAddParameter();
    const [localParameterValue, setLocalParameterValue] = useState({ label: null, value: null });
    const [parametersValueModalVisible, setParametersValueModalVisible] = useState(false);

    const handleAdd = () => {
        localParameterValue.value ? dispatchValue(localParameterValue.value) : handleAddNewValue();
    };

    const handleAddNewValue = () => {
        const request = {
            parameter_id: parameter.id,
            values: [{ value: TextUtils.trim(localParameterValue.label) }],
            type: ParameterTypesList.A3,
        };

        addParameterValue(request, (parameter) => {
            const parameterValue = ParametersService.getParameterValueByValue(parameter.id, request.values[0].value);
            dispatchValue(parameterValue.id);
        });
    };

    const dispatchValue = (id) => {
        onParameterValueAdd({ id, parameterId: parameter.id, type: ParameterTypesList.A3 });
        setLocalParameterValue({ label: null, value: null });
    };

    const handleChange = (event) => {
        const { value } = event;
        setLocalParameterValue(value);
    };

    const getParameterValuesOptions = () => {
        const selectedParameterValuesIds = selectedParameterValues.map((parameterValue) => parameterValue.id);
        const filteredValues = parameter.values.filter(
            (parameterValue) => !selectedParameterValuesIds.includes(parameterValue.id),
        );
        return Mapper.mapParameterValuesToOptions(filteredValues);
    };

    const isAlreadyExist = () => {
        return (
            !localParameterValue.value &&
            !!ParametersService.getParameterValueByValue(parameter.id, localParameterValue.label)
        );
    };

    const clearParameterInput = () => {
        setLocalParameterValue({ label: null, value: null });
    };

    const saveParameterValue = (data) => {
        updateParameterValue(data.id, data, parameter.id);
        handleChange({ value: { label: data.value, value: localParameterValue.value } });
    };

    return (
        <Styles.Container>
            <Grid.Row mb={10}>
                <AutoComplete
                    disabled={disabled}
                    value={localParameterValue}
                    label={parameter.name}
                    onChange={handleChange}
                    placeholder="Select or enter new"
                    name="Parameter values"
                    options={getParameterValuesOptions()}
                />
                {localParameterValue.label && (
                    <Grid.Col ml={-30} mt={28} style={{ zIndex: 1 }}>
                        <Icon onClick={clearParameterInput} src={Images.CLOSE_GREY} />
                    </Grid.Col>
                )}
                {localParameterValue.label && (
                    <Grid.Col ml={-50} mt={28} style={{ zIndex: 1 }}>
                        <Icon onClick={() => setParametersValueModalVisible(true)} src={Images.EDIT} />
                    </Grid.Col>
                )}
            </Grid.Row>
            <Grid.Row>
                <Button
                    onClick={handleAdd}
                    loading={isLoading}
                    disabled={!localParameterValue.label || isAlreadyExist()}
                    type={Buttons.SECONDARY}
                    label="Add to list"
                />
            </Grid.Row>
            <ParameterValueModal
                data={localParameterValue}
                updateParameter={saveParameterValue}
                visible={parametersValueModalVisible}
                onClose={() => setParametersValueModalVisible(false)}
            />
        </Styles.Container>
    );
}

ParameterA3AutoComplete.propTypes = {
    disabled: PropTypes.bool,
    parameter: SharedTypes.ParameterType.isRequired,
    onParameterValueAdd: PropTypes.func.isRequired,
    updateParameterValue: PropTypes.func.isRequired,
    selectedParameterValues: PropTypes.arrayOf(SharedTypes.LabelingParameterValueType),
};

ParameterA3AutoComplete.defaultProps = {
    selectedParameterValues: [],
    disabled: false,
};
const mapDispatchToProps = {
    updateParameterValue: ParametersActions.updateParameterValue,
};

export default connect(null, mapDispatchToProps)(ParameterA3AutoComplete);
