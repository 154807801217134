import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { SharedTypes } from 'utils';
import { StorageKeys } from 'constant';
import { useHistory } from 'react-router-dom';
import { COLUMNS, HIDDEN_COLUMNS } from './config';
import { StyledIcon, SearchIconBig } from './styles';
import { EDIT, REMOVE, SEARCH_WHITE } from 'styles/constant/images';
import renderRow from './FilterLanguageList';
import { Grid } from '../../styles/components';
import { Table } from 'components';
import { FiltersActions, FilterLanguagesActions } from '../../core/actions';
import { usePermission } from 'hooks';
import { getFromSessionStorage, saveToSessionStorage, removeFromSessionStorage } from 'utils';
import FilterListLayout from './FilterListLayout';

const SEARCH_STORAGE_KEY = 'FiltersListSearchInput';

const filterBySearchInputValue = (data, value) => {
    let arr = [];

    data.map((f) => {
        let queryArr = [];

        if (f.lang && f.lang.length > 0) {
            f.lang.map((l) => {
                if (l.query && l.query.length > 0) {
                    try {
                        JSON.parse(l.query, (key, value) => {
                            key === 'value' && queryArr.push(value.toLowerCase());
                        });
                    } catch (err) {
                        queryArr = [...queryArr, ...l.query.split(',').map((v) => v.toLowerCase())];
                    }
                }
            });
            queryArr.filter((q) => q.includes(value.toLowerCase())).length > 0 && arr.push(f);
        }
    });
    if (value.length === 0) {
        arr = false;
    }
    return arr;
};

function FiltersList({ filters, deleteFilter, isUpdated, updateFilters, removeFilterLanguage }) {
    const history = useHistory();
    const [searchInputValue, setSearchInputValue] = useState('');
    const [filtered, setFiltered] = useState(false);
    const hasCreateFiltersPermission = usePermission('ROLE_CREATE_FILTERS');

    useEffect(() => {
        isUpdated && updateFilters();

        const searchInputFromStorage = getFromSessionStorage(SEARCH_STORAGE_KEY);
        if (searchInputFromStorage) {
            setSearchInputValue(searchInputFromStorage);
            setFiltered(filterBySearchInputValue(filters, searchInputFromStorage));
        } else {
            !filtered && setFiltered(filters);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setFiltered, updateFilters, isUpdated]);

    // eslint-disable-next-line no-unused-vars
    const onLanguageDelete = (filter, language) => {
        const confirm = window.confirm('Remove language from filter?');
        confirm && removeFilterLanguage(language.filter_lang_id);
    };

    const removeFilter = (id) => {
        const confirm = window.confirm('Remove filter?');
        if (confirm) {
            deleteFilter(id).then(() => {
                setFiltered(filtered.filter((item) => item.id !== id));
            });
        }
    };

    const onInputChange = (value) => {
        setSearchInputValue(value);
        setFiltered(filterBySearchInputValue(filters, value));
        saveToSessionStorage(SEARCH_STORAGE_KEY, value);
    };

    const renderRowSubComponent = ({ row, visibleColumns }) =>
        renderRow(filters, row, visibleColumns, onLanguageDelete, hasCreateFiltersPermission, history);

    return (
        <FilterListLayout
            searchInputValue={searchInputValue}
            onSearchInputChange={onInputChange}
            onCreateNewClick={() => history.push('/filter/create')}
            createNewButtonDisabled={!hasCreateFiltersPermission}
        >
            <Table
                storageKey={StorageKeys.FiltersList}
                editableCells={COLUMNS.filter((c) => c.input).map((c) => c.Header)}
                updateData={() => {}}
                columns={[
                    ...COLUMNS,
                    {
                        Header: 'Actions',
                        Cell: function CellRenderer(row) {
                            return (
                                <Grid.Row>
                                    <SearchIconBig source={SEARCH_WHITE} disabled={!hasCreateFiltersPermission} />
                                    <StyledIcon
                                        gray
                                        source={EDIT}
                                        onClick={() => history.push(`/filter/${row.row.original.id}`)}
                                        disabled={!hasCreateFiltersPermission}
                                    />
                                    <StyledIcon
                                        gray
                                        source={REMOVE}
                                        onClick={() => removeFilter(row.row.original.id)}
                                        iconSmall
                                        disabled={!hasCreateFiltersPermission}
                                    />
                                </Grid.Row>
                            );
                        },
                    },
                ]}
                data={filtered || filters.filter((v, i, a) => a.findIndex((t) => t.id === v.id) === i)}
                hiddenColumns={HIDDEN_COLUMNS}
                type="filters"
                history={history}
                preSelected={[]}
                renderRowSubComponent={renderRowSubComponent}
                onClearCells={() => {
                    removeFromSessionStorage(SEARCH_STORAGE_KEY);
                    setSearchInputValue('');
                    setFiltered(filters);
                }}
            />
        </FilterListLayout>
    );
}

FiltersList.propTypes = {
    isUpdating: PropTypes.bool.isRequired,
    isUpdated: PropTypes.bool.isRequired,
    filters: PropTypes.arrayOf(SharedTypes.FilterType),
    deleteFilter: PropTypes.func.isRequired,
    updateFilters: PropTypes.func.isRequired,
    removeFilterLanguage: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
    const { filters } = state;
    const { isUpdating, isUpdated } = filters;
    return { isUpdating, isUpdated };
}

const mapDispatchToProps = {
    deleteFilter: FiltersActions.deleteFilter,
    removeFilterLanguage: FilterLanguagesActions.removeFilterLanguage,
};

export default connect(mapStateToProps, mapDispatchToProps)(FiltersList);
