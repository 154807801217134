import React from 'react';
import PropTypes from 'prop-types';
import { FiX } from 'react-icons/fi';
import './style.scss';

const HighlightWarningPopup = ({ onClose }) => {
    return (
        <div className="highlightWarningPopup">
            <div>
                This article has a lot of label filters, we cannot load all of them, therefore we reduced their number.
                <button onClick={onClose}>
                    <FiX />
                </button>
            </div>
        </div>
    );
};

HighlightWarningPopup.propTypes = {
    onClose: PropTypes.func.isRequired,
};

export default HighlightWarningPopup;
