import { useD3ToPng } from './useD3ToPng';
import { useChartJsToPng } from './useChartJsToPng';

export const useChartImageExport = ({ printSize, initialWidth, initialHeight }) => {
    const D3ToPng = useD3ToPng();
    const ChartJsToPng = useChartJsToPng();

    async function generatePng(grouped, chartId, fileName) {
        if (grouped) {
            await D3ToPng.generatePng(chartId, fileName, printSize);
        } else {
            await ChartJsToPng.generatePng(chartId, fileName, printSize, initialWidth, initialHeight);
        }
    }

    return { generatePng };
};

export default useChartImageExport;
