import { FilterLanguagesTypes, FiltersTypes } from 'core/types';
import { FilterLanguagesService, ToastService } from 'core/services';

export function removeFilterLanguage(languageId) {
    return async (dispatch) => {
        try {
            dispatch({ type: FiltersTypes.UPDATE_FILTERS_START });
            const filter = await FilterLanguagesService.removeFilterLanguage(languageId);
            dispatch({ type: FilterLanguagesTypes.REMOVE_FILTER_LANGUAGE, payload: filter });
        } catch (error) {
            const message = error?.response?.data?.message;
            ToastService.showGenericError(message);
            dispatch({ type: FiltersTypes.GET_FILTERS_ERROR });
        }
    };
}
