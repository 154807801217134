import PropTypes from 'prop-types';
import uniqBy from 'lodash/uniqBy';
import update from 'immutability-helper';
import * as Styles from './styles';
import { Grid } from 'styles/components';
import { Button, Textarea, PositivitySelect, SelectedLabelingOption, LabelingTimer, RouterPrompt } from 'components';
import React, { useState, useRef } from 'react';
import { CategoriesService, FiltersService, ParametersService } from 'core/services';
import { Buttons } from 'styles/constant';
import ParameterA3AutoComplete from '../ParameterA3AutoComplete';
import ParametersA2AutoComplete from '../ParametersA2AutoComplete';
import _ from 'lodash';
import ParameterA1MultiSelect from '../ParameterA1MultiSelect';
import { useLabeling } from 'features/article';
import { ParameterTypesList } from 'constant';
import { useArticlesCount } from 'features/articles/hooks';
import ParametersTemplates from '../ParametersTemplates';
import ParametersTemplateModal from 'containers/ParametersTemplateModal';
import ParametersNotSavedModal from 'containers/ParametersNotSavedModal';
import Collapse from 'react-collapse';

const initialSentimentsVisible = {
    filter: 0,
    category: 0,
};

export const initialSentimentsOpen = {
    filter: true,
    category: true,
};

const getA1ParameterValues = (formData, id) => {
    const parameters = formData.parameters || [];
    return parameters.filter((parameter) => parameter.parameterId == id && parameter.type === ParameterTypesList.A1);
};

const getA2ParametersValues = (formData) => {
    const parameters = formData.parameters || [];
    return parameters.filter((parameter) => parameter.type === ParameterTypesList.A2);
};

const getA3ParameterValues = (formData, id) => {
    const parameters = formData.parameters || [];
    return parameters.filter((parameter) => parameter.parameterId == id && parameter.type === ParameterTypesList.A3);
};

export const Form = ({
    article,
    formData,
    highlights,
    disabled,
    handleParameterValueClear,
    disableInitial,
    handleSentimentChange,
    filter,
    handleTemplateChange,
    buttonDisabled,
    isArticleLoaded,
    skip,
    parameters,
    handleParameterValueAdd,
    handleChange,
    showInitial,
    initialFormData,
    clear,
    shouldBlockRoute,
    onClearStorageState,
}) => {
    const [sentimentsVisible, setSentimentsVisible] = useState(initialSentimentsVisible);
    const [sentimentsOpen, setSentimentsOpen] = useState(initialSentimentsOpen);
    const [addModalVisible, setAddModalVisible] = useState(false);
    const [parametersModalVisible, setParametersModalVisible] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const { labelArticle } = useLabeling();
    const { getSkippedArticlesCount, getLabeledArticlesCount, getReservedArticlesCount, getWaitingArticlesCount } =
        useArticlesCount();
    const timeSpentRef = useRef();

    const handleTimerChange = (event) => {
        timeSpentRef.current = event.value;
    };

    const getCategories = () => {
        if (!article) {
            return [];
        }

        return article.categories
            .map((category) => CategoriesService.getCategoryById(category.id))
            .filter((category) => !!category);
    };

    const getFilters = () => {
        if (!article) {
            return [];
        }

        return article.filters.map((filter) => FiltersService.getFilterById(filter.id)).filter((filter) => !!filter);
    };

    const getSentimentValue = (id, type) => {
        const sentiments = formData.sentiments || [];
        const sentiment = sentiments.find((sentiment) => sentiment.id == id && sentiment.type === type);
        return sentiment ? sentiment.value : null;
    };

    const renderParameterValue = (value, index, noTitle) => {
        const parameter = ParametersService.getParameterById(value.parameterId);
        const parameterValue = ParametersService.getParameterValueById(value.parameterId, value.id);

        return parameter ? (
            <SelectedLabelingOption
                key={index}
                disabled={disabled}
                onClear={() => handleParameterValueClear(value)}
                title={!noTitle ? parameter.name : null}
                label={parameterValue ? parameterValue.value : null}
            />
        ) : null;
    };

    const toggleSentimentsOpen = (type) => {
        setSentimentsOpen(
            update(sentimentsOpen, {
                [type]: { $set: !sentimentsOpen[type] },
            }),
        );
        disableInitial(type);
    };

    const renderSentiments = (array, type) => {
        return uniqBy(array, 'id').map((item) => (
            <Grid.Row key={item.id} mb={20}>
                <PositivitySelect
                    label={item.name}
                    onChange={({ value }) => handleSentimentChange({ id: item.id, value, type })}
                    value={getSentimentValue(item.id, type)}
                    name={`${type === 'filter' ? 'filters' : 'categories'}[${item.id}]`}
                />
            </Grid.Row>
        ));
    };

    const getSentimentsVisible = (type) => {
        const array = type === 'filter' ? getFilters() : getCategories();
        const ids = type === 'filter' ? filter.filters_ids : filter.categories_ids;
        let visibleArray = [];

        if (type === 'filter' && array.length === 1) visibleArray = array;
        else {
            array.map((item) => {
                if (ids && ids.includes(item.id)) visibleArray.push(item);
                else
                    formData.sentiments.map((sentiment) => {
                        sentiment.type === type && sentiment.id === item.id && visibleArray.push(item);
                    });
            });
        }

        sentimentsVisible[type] !== visibleArray.length &&
            setSentimentsVisible(
                update(sentimentsVisible, {
                    [type]: { $set: visibleArray.length },
                }),
            );

        return renderSentiments(visibleArray, type);
    };

    const submit = () => {
        let inputsEmpty = true;
        document.querySelectorAll('#labelingContainer input').forEach((item) => {
            if (item.value) inputsEmpty = false;
        });

        if (!inputsEmpty) {
            setParametersModalVisible(true);
            return;
        }

        let filteredParameters = formData.parameters.filter((param) => !['S1', 'S2', 'S3'].includes(param.type));

        let filteredFormData = {
            ...formData, // This keeps the other properties (comment, sentiments) as is
            parameters: filteredParameters,
        };

        const formDataToSubmit = {
            ...filteredFormData,
            time_spent: timeSpentRef.current,
            section_parameters: highlights,
        };

        onClearStorageState();
        setIsSubmitting(true);

        // Waiting some time that RouterPrompt would get updated and wouldn't block openNextArticle
        setTimeout(() => {
            labelArticle(
                article.id,
                article.parentId,
                formDataToSubmit,
                skip,
                () => {
                    if (skip) {
                        filter.labeling_status === 'Not labeled'
                            ? getWaitingArticlesCount()
                            : getLabeledArticlesCount();
                        getSkippedArticlesCount();
                    } else {
                        getLabeledArticlesCount();
                        getSkippedArticlesCount();
                    }
                    !filter.labeling_status && getReservedArticlesCount();
                },
                article.labelingStatus,
            );
            setIsSubmitting(false);
        }, 200);

        document.getElementById('parametersContainer').scrollTop = 0;
    };

    const renderA2ParameterValues = () => {
        const a2Values = getA2ParametersValues(formData);
        return a2Values.map((value, index) => renderParameterValue(value, index));
    };

    return (
        <Styles.Container id="labelingContainer">
            <RouterPrompt
                shouldBlockRoute={!isSubmitting && shouldBlockRoute}
                title="You have unsaved changes. It will be lost if you proceed!"
                onLeave={onClearStorageState}
            />
            <Styles.Header>
                <Grid.Col mr={10}>
                    <ParametersTemplates disabled={disabled} onChange={handleTemplateChange} />
                </Grid.Col>
                <Grid.Col>
                    <LabelingTimer
                        buttonDisabled={buttonDisabled}
                        isArticleLoaded={isArticleLoaded}
                        setLabelingTime={handleTimerChange}
                    />
                </Grid.Col>
                <Grid.Col alignItems="flex-end" flex={1}>
                    <Button disabled={buttonDisabled} label={skip ? 'Skip' : 'Next'} onClick={submit} />
                </Grid.Col>
            </Styles.Header>
            <Styles.Content disabled={disabled} id="parametersContainer">
                {_.orderBy(
                    parameters.filter((parameter) => parameter.type === 'A1'),
                    ['id'],
                    ['asc'],
                ).map((parameter) => (
                    <Grid.Row mb={20} key={parameter.id}>
                        <ParameterA1MultiSelect
                            disabled={disabled}
                            selectedParameterValues={getA1ParameterValues(formData, parameter.id)}
                            parameter={parameter}
                            onParameterValueAdd={handleParameterValueAdd}
                            onParameterValueClear={handleParameterValueClear}
                        />
                    </Grid.Row>
                ))}
                <Grid.Row mb={20}>
                    <Grid.Col flex={1}>
                        <ParametersA2AutoComplete
                            disabled={disabled}
                            onParameterValueAdd={handleParameterValueAdd}
                            selectedParametersValues={getA2ParametersValues(formData)}
                            parameters={parameters.filter((parameter) => parameter.type === 'A2')}
                        />
                        <Styles.ValuesContainer>{renderA2ParameterValues()}</Styles.ValuesContainer>
                    </Grid.Col>
                </Grid.Row>
                {_.orderBy(
                    parameters.filter((parameter) => parameter.type === 'A3'),
                    ['id'],
                    ['asc'],
                ).map((parameter) => (
                    <Grid.Row mb={20} key={parameter.id}>
                        <Grid.Col flex={1}>
                            <ParameterA3AutoComplete
                                disabled={disabled}
                                onParameterValueAdd={handleParameterValueAdd}
                                parameter={parameter}
                                selectedParameterValues={getA3ParameterValues(formData, parameter.id)}
                            />
                            <Styles.ValuesContainer>
                                {getA3ParameterValues(formData, parameter.id).map((value, index) =>
                                    renderParameterValue(value, index, true),
                                )}
                            </Styles.ValuesContainer>
                        </Grid.Col>
                    </Grid.Row>
                ))}
                <Grid.Row mb={20}>
                    <Textarea
                        height={140}
                        disabled={disabled}
                        label="Comment"
                        value={formData.comment}
                        onChange={handleChange}
                        name="comment"
                    />
                </Grid.Row>
                {article && article.categories.length > 0 && (
                    <Grid.Col flex={1}>
                        <Styles.CollapseHeader
                            open={
                                (sentimentsOpen['category'] && !showInitial['category']) ||
                                (showInitial['category'] && sentimentsVisible['category'] > 0)
                            }
                            onClick={
                                showInitial['category'] && getCategories().length > sentimentsVisible['category']
                                    ? () => disableInitial('category')
                                    : () => toggleSentimentsOpen('category')
                            }
                        >
                            Category sentiments
                        </Styles.CollapseHeader>
                        <Collapse isOpened={sentimentsOpen['category']}>
                            <Styles.Container>
                                {showInitial['category']
                                    ? getSentimentsVisible('category')
                                    : renderSentiments(getCategories(), 'category')}
                            </Styles.Container>
                        </Collapse>
                    </Grid.Col>
                )}
                {article && article.filters.length > 0 && (
                    <Grid.Col flex={1}>
                        <Styles.CollapseHeader
                            open={
                                (sentimentsOpen['filter'] && !showInitial['filter']) ||
                                (showInitial['filter'] && sentimentsVisible['filter'] > 0)
                            }
                            onClick={
                                showInitial['filter'] && getFilters().length > sentimentsVisible['filter']
                                    ? () => disableInitial('filter')
                                    : () => toggleSentimentsOpen('filter')
                            }
                        >
                            Filter sentiments
                        </Styles.CollapseHeader>
                        <Collapse isOpened={sentimentsOpen['filter']}>
                            <Styles.Container>
                                {showInitial['filter']
                                    ? getSentimentsVisible('filter')
                                    : renderSentiments(getFilters(), 'filter')}
                            </Styles.Container>
                        </Collapse>
                    </Grid.Col>
                )}
            </Styles.Content>
            <Styles.Footer>
                <Styles.FooterSection>
                    <Grid.Col flex={1} mr={10}>
                        <Button disabled={buttonDisabled} label={skip ? 'Skip' : 'Next'} onClick={submit} />
                    </Grid.Col>
                    <Grid.Col mr={10}>
                        <Button
                            disabled={disabled || !formData.parameters || formData.parameters.length === 0}
                            type={Buttons.SECONDARY}
                            label="Save as template"
                            onClick={() => setAddModalVisible(true)}
                        />
                    </Grid.Col>
                    <Grid.Col>
                        <Button
                            disabled={disabled || _.isEqual(formData, initialFormData)}
                            type={Buttons.TERTIARY}
                            label="Clear"
                            onClick={clear}
                        />
                    </Grid.Col>
                </Styles.FooterSection>
            </Styles.Footer>
            <ParametersTemplateModal
                data={formData}
                visible={addModalVisible}
                onClose={() => setAddModalVisible(false)}
            />
            <ParametersNotSavedModal
                visible={parametersModalVisible}
                onClose={() => setParametersModalVisible(false)}
            />
        </Styles.Container>
    );
};

Form.propTypes = {
    article: PropTypes.object,
    formData: PropTypes.object,
    highlights: PropTypes.any,
    disabled: PropTypes.bool,
    handleParameterValueClear: PropTypes.func,
    disableInitial: PropTypes.func,
    handleSentimentChange: PropTypes.func,
    filter: PropTypes.object,
    handleTemplateChange: PropTypes.func,
    buttonDisabled: PropTypes.bool,
    isArticleLoaded: PropTypes.bool,
    handleTimerChange: PropTypes.func,
    skip: PropTypes.bool,
    submit: PropTypes.func,
    parameters: PropTypes.array,
    handleParameterValueAdd: PropTypes.func,
    handleChange: PropTypes.func,
    showInitial: PropTypes.object,
    initialFormData: PropTypes.object,
    clear: PropTypes.func,
    shouldBlockRoute: PropTypes.bool,
    onClearStorageState: PropTypes.func,
};
