import { LabellingHighlight as types } from 'core/types';

export const setLabellingHighlight = (highlightData) => ({
    type: types.SET_LABELLING_HIGHLIGHT,
    payload: highlightData,
});

export const resetLabellingHighlight = () => ({
    type: types.RESET_LABELLING_HIGHLIGHT,
});
