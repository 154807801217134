const STORAGE_KEY = 'labelling';

export const saveToStorage = (data, identifier) => {
    localStorage.setItem(
        STORAGE_KEY,
        JSON.stringify({
            [identifier]: data,
        }),
    );
};

export const getFromStorage = (identifier) => {
    const data = JSON.parse(localStorage.getItem(STORAGE_KEY));
    if (data) {
        return data[identifier];
    }
    return null;
};

export const removeFromStorage = () => {
    localStorage.removeItem(STORAGE_KEY);
};

export function initDragElement() {
    let pos1 = 0;
    let pos2 = 0;
    let pos3 = 0;
    let pos4 = 0;
    const videoElement = document.getElementById('contentVideo');
    const header = document.getElementById('contentVideoHeader');

    let elmnt = null;
    let currentZIndex = 100;

    videoElement.onmousedown = function () {
        this.style.zIndex = '' + ++currentZIndex;
    };

    if (header) {
        header.onmousedown = dragMouseDown;
    }

    function dragMouseDown(e) {
        elmnt = videoElement;
        elmnt.style.zIndex = '' + ++currentZIndex;

        e = e || window.event;
        // get the mouse cursor position at startup:
        pos3 = e.clientX;
        pos4 = e.clientY;
        document.onmouseup = closeDragElement;
        // call a function whenever the cursor moves:
        document.onmousemove = elementDrag;
    }

    function elementDrag(e) {
        if (!elmnt) {
            return;
        }

        e = e || window.event;
        // calculate the new cursor position:
        pos1 = pos3 - e.clientX;
        pos2 = pos4 - e.clientY;
        pos3 = e.clientX;
        pos4 = e.clientY;
        // set the element's new position:
        elmnt.style.top = elmnt.offsetTop - pos2 + 'px';
        elmnt.style.left = elmnt.offsetLeft - pos1 + 'px';
    }

    function closeDragElement() {
        /* stop moving when mouse button is released:*/
        document.onmouseup = null;
        document.onmousemove = null;
        window.getSelection()?.removeAllRanges();
    }
}

export function initResizeElement() {
    const videoEl = document.getElementById('contentVideo');
    let element = null;
    let startX;
    let startY;
    let startWidth;
    let startHeight;

    const p = videoEl;

    const right = document.createElement('div');
    right.className = 'resizer-right';
    p.appendChild(right);
    right.addEventListener('mousedown', initDrag, false);
    right.parentPopup = p;

    const bottom = document.createElement('div');
    bottom.className = 'resizer-bottom';
    p.appendChild(bottom);
    bottom.addEventListener('mousedown', initDrag, false);
    bottom.parentPopup = p;

    const both = document.createElement('div');
    both.className = 'resizer-both';
    p.appendChild(both);
    both.addEventListener('mousedown', initDrag, false);
    both.parentPopup = p;

    function initDrag(e) {
        element = this.parentPopup;

        startX = e.clientX;
        startY = e.clientY;
        startWidth = parseInt(document.defaultView.getComputedStyle(element).width, 10);
        startHeight = parseInt(document.defaultView.getComputedStyle(element).height, 10);
        document.documentElement.addEventListener('mousemove', doDrag, false);
        document.documentElement.addEventListener('mouseup', stopDrag, false);
    }

    function doDrag(e) {
        element.style.width = startWidth + e.clientX - startX + 'px';
        element.style.height = startHeight + e.clientY - startY + 'px';
    }

    function stopDrag() {
        document.documentElement.removeEventListener('mousemove', doDrag, false);
        document.documentElement.removeEventListener('mouseup', stopDrag, false);
        window.getSelection()?.removeAllRanges();
    }
}

export function generateUniqueId() {
    const timestamp = new Date().getTime(); // Current time in milliseconds
    const randomPortion = Math.random().toString(36).substring(2, 15); // A random string
    return `${timestamp}-${randomPortion}`;
}
