import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { COLUMNS } from './config';
import { Container } from './styles';
import { Grid } from 'styles/components';
import { Table, Button } from 'components';

function ManualImportList(props) {
    const { importedArticles } = props;
    const history = useHistory();

    return (
        <Container>
            <Grid.FixedContainer>
                <Grid.Row mb={20}>
                    <Button
                        onClick={() => history.push('import-publication/new')}
                        label="Import new"
                        disabled={false}
                        style={{
                            margin: '0 6px 0 2px',
                        }}
                    />
                    <Button
                        onClick={() => history.push('/import-publication/batch')}
                        label="Import batch"
                        disabled={false}
                        style={{
                            margin: '0 2px 0 6px',
                        }}
                    />
                </Grid.Row>
            </Grid.FixedContainer>
            <Table
                storageKey="ImportedArticlesList"
                checkboxes={false}
                editableCells={[]}
                updateData={() => {}}
                columns={[...COLUMNS]}
                data={importedArticles}
                hiddenColumns={[]}
                type="manualImport"
                history={history}
                preSelected={[]}
            />
        </Container>
    );
}

ManualImportList.propTypes = {
    importedArticles: PropTypes.array,
};

export default ManualImportList;
