import { ParametersTypes } from 'core/types';
import { ParametersService, ParametersValuesService, ToastService } from 'core/services';

export function getParameters() {
    return async (dispatch) => {
        try {
            dispatch({ type: ParametersTypes.GET_PARAMETERS_START });
            const parameters = await ParametersService.getParameters();
            dispatch({ type: ParametersTypes.GET_PARAMETERS, payload: { parameters } });
        } catch (error) {
            const message = error?.response?.data?.message;
            ToastService.showGenericError(message);
            dispatch({ type: ParametersTypes.GET_PARAMETERS_ERROR });
        }
    };
}

export function updateParameter(id, parameter) {
    return async (dispatch) => {
        try {
            dispatch({ type: ParametersTypes.UPDATE_PARAMETERS_START });
            await ParametersService.updateParameter(id, parameter);
            dispatch({ type: ParametersTypes.UPDATE_PARAMETERS_SUCCESS, payload: parameter });
            ToastService.showSuccess('Parameter successfully updated');
        } catch (error) {
            ToastService.showGenericError(error.response.data.message);
            dispatch({ type: ParametersTypes.GET_PARAMETERS_ERROR });
        }
    };
}

export function updateParameterValue(id, parameterValue, parameterId) {
    return async (dispatch) => {
        try {
            dispatch({ type: ParametersTypes.UPDATE_PARAMETERS_START });
            await ParametersValuesService.updateParameterValue(id, parameterValue);
            dispatch({
                type: ParametersTypes.UPDATE_PARAMETERS_VALUE_SUCCESS,
                payload: { parameterValue, parameterId },
            });
            ToastService.showSuccess('Parameter value successfully updated');
        } catch (error) {
            const message = error?.response?.data?.message;
            ToastService.showGenericError(message);
            dispatch({ type: ParametersTypes.GET_PARAMETERS_ERROR });
        }
    };
}
