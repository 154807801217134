import { combineReducers } from 'redux';
import auth from './auth';
import categories from './categories';
import domains from './domains';
import languages from './languages';
import filters from './filters';
import parameters from './parameters';
import users from './users';
import charts from './charts';
import organisations from './organisations';
import parameter from './parameter';
import parametersTemplates from './parametersTemplates';
import savedSearches from './savedSearches';
import search from './search';
import initial from './initial';
import emails from './emails';
import parametersList from './parametersList';
import classifications from './classifications';
import ml from './ml';
import highlight from './highlight';
import labellingHighlightReducer from './labellingHighlight';
import { articleReducer, importedArticleReducer } from 'features/article';
import { articlesReducer, similarArticlesReducer, articlesCountReducer } from 'features/articles';

const rootReducer = combineReducers({
    similarArticles: similarArticlesReducer,
    articles: articlesReducer,
    article: articleReducer,
    articlesCount: articlesCountReducer,
    importedArticles: importedArticleReducer,
    auth,
    categories,
    domains,
    languages,
    filters,
    parameters,
    users,
    charts,
    organisations,
    parameter,
    parametersTemplates,
    savedSearches,
    search,
    initial,
    emails,
    parametersList,
    classifications,
    ml,
    highlights: highlight,
    labellingHighlight: labellingHighlightReducer,
});

export { rootReducer };
