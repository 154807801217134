/* eslint-disable no-unused-vars */
import Chart from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import * as outlabels from 'chartjs-plugin-piechart-outlabels';
import scatterChartLabels from './scatterChartLabels';
import { CHART_LOGO } from 'styles/constant/images';
import * as d3 from 'd3';

let printSize = 1920,
    logo = CHART_LOGO,
    fontSize = 14,
    lineHeight = 0.8,
    customTitle = '',
    language = null,
    graphArray = [],
    graphMain = {},
    graphId = null,
    graphSettings = {},
    grData = [],
    lastPeriods = [],
    chart = '',
    chartPrint = '',
    type = '',
    stacked = false,
    grouped = false,
    labels = [],
    labelKeys = [],
    rows = [],
    groupedLegendItems = [],
    groupedData = [],
    parentWidth = 0,
    width = 0,
    height = 0,
    initialWidth = 993,
    initialHeight = 993 * 0.6,
    maxX = 0,
    backgroundColors = [
        'rgba(35, 31, 32, 1)',
        'rgba(215, 29, 36, 1)',
        'rgba(152, 143, 145, 1)',
        'rgba(245, 118, 123, 1)',
        'rgba(226, 227, 228, 1)',
        'rgba(255, 191, 193, 1)',
        'rgba(0,0,0, 1)',
        'rgba(76, 76, 76, 1)',
        'rgba(153, 153, 153, 1)',
        'rgba(229, 229, 229, 1)',
    ],
    legend = false,
    padding = 0,
    sTermsArray = [],
    svg = '',
    drawn = false,
    print = false,
    responsive = false,
    titleRight = null,
    scatterPoints = [],
    showDataLabels = true,
    showRelativeValue = true,
    currentTotal = 0,
    tempTranslations = {
        lt: {
            other: 'kita',
        },
        ru: {
            other: 'другие',
        },
        lv: {
            other: 'cits',
        },
        et: {
            other: 'muu',
        },
        mk: {
            other: 'други',
        },
        sr: {
            other: 'други',
        },
        sq: {
            other: 'tjetër',
        },
        be: {
            other: 'іншы',
        },
        pl: {
            other: 'inny',
        },
        uk: {
            other: 'інший',
        },
        fr: {
            other: 'autre',
        },
        fi: {
            other: 'muut',
        },
        de: {
            other: 'andere',
        },
        el: {
            other: 'άλλα',
        },
        es: {
            other: 'otro',
        },
        sl: {
            other: 'druga',
        },
        sk: {
            other: 'ostatné',
        },
        ro: {
            other: 'alte',
        },
        pt: {
            other: 'de outros',
        },
        no: {
            other: 'annen',
        },
        it: {
            other: 'altro',
        },
        ga: {
            other: 'eile',
        },
        is: {
            other: 'annað',
        },
        hu: {
            other: 'más',
        },
        nl: {
            other: 'anders',
        },
        da: {
            other: 'andet',
        },
        cs: {
            other: 'jiný',
        },
        hr: {
            other: 'drugo',
        },
        bg: {
            other: 'друг',
        },
        bs: {
            other: 'drugi',
        },
        sv: {
            other: 'andra',
        },
        hy: {
            other: 'այլ',
        },
        az: {
            other: 'digər',
        },
        ka: {
            other: 'სხვა',
        },
        zh: {
            other: '其他',
        },
    };

function createCharts() {
    setType();
    getDimensions();

    if (graphSettings.rows.length === 1) {
        translateTicks();
        formDatasetArrays();
        translateLabels();
        createChart();
        print && createPrintChart();
    } else {
        createGrouped();
    }
    return { height, type, grData, chart, chartPrint, svg, sTermsArray, labels, width };
}

// ========== Main charts (charts.js) ============

function createPrintChart() {
    chartPrint && chartPrint.options && chartPrint.destroy();

    const ctx2 = document.getElementById('print');
    const chartDataPrint = setGraphOptions(true);

    chartPrint = new Chart(ctx2, {
        type: chartDataPrint.type,
        data: chartDataPrint.data,
        options: chartDataPrint.options,
    });

    setCustomTranslations(chartPrint);

    !drawn && initiatePlugins();
    drawn = true;
}

function createSimpleDynamics(labels, data, width) {
    chart && chart.options && chart.destroy();
    const ctx = document.getElementById('dynamics');
    fontSize = (width * 14) / initialWidth;
    fontSize += 3;

    setGlobal();

    chart = new Chart(ctx, {
        type: 'line',
        data: {
            labels,
            datasets: [
                {
                    data,
                    backgroundColor: backgroundColors.map((c) => c.replace(', 1)', ', .5)'))[1],
                    pointBackgroundColor: backgroundColors[1],
                    borderColor: backgroundColors[1],
                    borderWidth: 2,
                },
            ],
        },
        options: {
            responsive: true,
            maintainAspectRatio: false,
            canvas: {
                backgroundImage: logo,
            },
            chartArea: {
                backgroundColor: 'transparent',
            },
            layout: {
                padding: {
                    right: 25,
                    left: 0,
                    top: 20,
                },
            },
            legend: {
                display: false,
            },
            title: {
                display: false,
            },
            scales: {
                yAxes: [
                    {
                        color: '#000',
                        gridLines: {
                            display: false,
                        },
                        ticks: {
                            precision: 0,
                            autoskip: true,
                            lineHeight,
                            fontSize,
                            callback: function (value) {
                                if (!isNaN(value)) {
                                    let num = value.toString();
                                    let nums = num.replace(/,/g, '');
                                    value = parseInt(nums).toLocaleString().replace(/,/g, ' ');
                                }
                                return value;
                            },
                        },
                    },
                ],
                xAxes: [
                    {
                        color: '#000',
                        offset: false,
                        gridLines: {
                            display: false,
                        },
                        ticks: {
                            autoskip: true,
                            fontSize,
                        },
                    },
                ],
            },
            plugins: {
                datalabels: {
                    display: function (context) {
                        if (context.dataIndex === 0 || (data && data.length > 20)) return false;
                        return 'auto';
                    },
                    anchor: 'end',
                    align: 'end',
                    formatter: function (value) {
                        if (value === 0) return '';

                        if (!isNaN(parseFloat(value))) {
                            let num = value.toString();
                            let nums = num.replace(/,/g, '');
                            value = parseInt(nums).toLocaleString().replace(/,/g, ' ');
                        }
                        return value;
                    },
                    font: function () {
                        return {
                            weight: 'normal',
                            size: fontSize + 1,
                            lineHeight: 1,
                        };
                    },
                    labels: {
                        font: {
                            color: '#000',
                        },
                    },
                },
            },
        },
    });

    !drawn && initiatePlugins();
    drawn = true;
}

function createChart() {
    chart && chart.options && chart.destroy();

    const ctx = document.getElementById(`chart-${graphId}`);
    const chartData = setGraphOptions(false);

    chart = new Chart(ctx, {
        type: chartData.type,
        data: chartData.data,
        options: chartData.options,
    });

    setCustomTranslations(chart);

    !print && initiatePlugins();
    // drawn = true;
}

function setType() {
    if (type === 'bar') type = 'horizontalBar';
    if (type === 'donut') type = 'doughnut';

    if (type === 'dynamics') {
        type = 'line';
        legend = true;
    }

    if (type === 'line bar') {
        type = ['line', 'bar'];
        legend = true;
    }

    if (type === 'stacked bar') {
        stacked = true;
        legend = true;
        type = 'horizontalBar';
    }

    if (type === 'grouped bar') {
        grouped = true;
        legend = true;
        type = 'horizontalBar';
    }

    return type;
}

function getLineDataSets() {
    const labelsTemp = [];
    const sTerms = [];
    const vals = Object.values(graphMain);
    const data = [];
    vals.map((child) => {
        Object.values(child.children).map((c) => {
            !labelsTemp.includes(c.title) && labelsTemp.push(c.title);
            !sTerms.includes(c.search_term) && sTerms.push(c.search_term);
        });
    });

    for (let i = 0; i < vals.length; i++) {
        let items = [];
        labelsTemp.map(() => items.push(0));
        data.push(items);
    }

    vals.map((v, index) => {
        Object.values(v.children).map((c, i) => {
            const item = Object.values(v.children)[i].children;
            labelsTemp.map((l, ii) => {
                if (c.title == l) {
                    data[index][ii] = Object.values(item)[0] ? Object.values(item)[0].value : 0;
                }
            });
        });
    });

    labels = labelsTemp;
    sTermsArray = sTerms;
    grData = data;
}

function getDataSets(e, index, childrenCount, parentTitle, placeIndex) {
    if (!index) index = 0;

    if (!grData[index]) {
        grData[index] = [];
        lastPeriods[index] = [];
    }

    // ====== IF ARRAY =====
    if (e.children && Array.isArray(e.children)) {
        if (e.type == 'scatter') {
            e.children.shift();
        }
        e.children.map((c) => getDataSets(c, index, e.children.length));
    }

    if (e.type === 'column') {
        if (!grouped) {
            stacked = true;
        }

        !sTermsArray.includes(e.search_term) && sTermsArray.push(e.search_term);

        if (groupedLegendItems.length === 0) {
            if (!labels.includes(e.title)) {
                labels = [...labels, e.title];
            }
        } else {
            labels = groupedLegendItems;
        }

        labels.map((label, ii) => {
            if (e.title === label) {
                index = ii;
            }
            return label;
        });

        if (!grData[index]) {
            grData[index] = [];
            lastPeriods[index] = [];
        }
        if (Object.keys(e.children).length > 0) {
            grData[index][placeIndex] = Object.values(e.children)[0].value;
        }
        return;
    }

    if (parentTitle && e.type === 'group') {
        Object.keys(e.children).map((c) => !groupedLegendItems.includes(c) && groupedLegendItems.push(c));
    }

    if (e.value) {
        grData[index].push(e.value);
        graphSettings.last_period && lastPeriods[index].push(e.last_period_value);
        if ((stacked || grouped) && graphSettings.values.length > 1 && !labels.includes(e.title))
            labels = [...labels, e.title];
    }

    if (!e.value && !e.children) {
        grData[index].push(0);
        graphSettings.last_period && lastPeriods[index].push(e.last_period_value);
    }

    if (e.children) {
        if (graphSettings.columns && graphSettings.columns.length === 0 && labels.length === 0) {
            Object.keys(e.children).map((c) => labels.push(e.children[c].title));
        }
        Object.keys(e).map((k, i) => {
            if (k === 'children') {
                const mainObj = Object.values(e)[i];
                Object.keys(mainObj)[i] === 'children' && getDataSets(Object.values(mainObj)[i], i);

                if (typeof mainObj === 'object') {
                    Object.values(mainObj).map((k, i) => {
                        if (typeof k === 'object') {
                            getDataSets(k, i, Object.keys(mainObj).length, e.title ? e.title : 'No title', index);
                        }
                    });
                }
            }
        });
    }
}

// ========== Form chart.js options ============

function setLabelOptions(ctx, labels, xAxis, type) {
    return {
        labels,
        chartHeight: ctx.chart.height,
        chartWidth: ctx.chart.width,
        minXValue: ctx.chart.scales[xAxis]['min'],
        maxXValue: ctx.chart.scales[xAxis]['max'],
        minYValue: ctx.chart.scales[type]['min'],
        maxYValue: ctx.chart.scales[type]['max'],
        data: ctx.dataset.data,
        fontsize: fontSize,
        canvasContext: ctx.chart.ctx,
        pointRadius: ctx.chart.options.elements.point.radius,
    };
}

function getStringLengths(lbls) {
    let longestGroup = '';
    let longestStrTxt = '';
    Object.keys(graphMain).map((k) => {
        if (longestGroup.length < k.length) longestGroup = k;
    });
    if (longestGroup.length > 20) longestGroup = longestGroup.substring(0, 17).toUpperCase() + '...:';
    else longestGroup = longestGroup.toUpperCase() + ': ';

    let longestSub = 0;
    let longestSubTxt = '';
    rows.map((r) => {
        if (r.split('|').pop().length > longestSub) {
            longestSubTxt = r.split('|').pop();
            longestSub = r.split('|').pop().length;
        }
    });

    let longestStr = 0;

    lbls.map((t) => {
        if (t.length > 120) t = t.substr(0, 125);
        const middle = getMiddle(t);
        const topLine = createHiddenElement(t.substr(0, middle));
        const bottomLine = createHiddenElement(t.substr(middle));
        const widthTemp = t.length >= 60 ? (topLine > bottomLine ? topLine : bottomLine) : createHiddenElement(t);
        if (widthTemp > longestStr) {
            longestStr = widthTemp;
            longestStrTxt = t.length >= 60 ? (topLine > bottomLine ? t.substr(0, middle) : t.substr(middle)) : t;
        }
    });

    if (longestStr > 120) {
        longestStr = 120;
    }
    return { longestStrTxt, longestSubTxt, longestGroup };
}

function getWidths(data) {
    let optionLabels = setOptionLabels(data.datasetIndex);
    const options = setLabelOptions(data, optionLabels, 'x-axis-0', 'horizontalBar', fontSize);
    scatterChartLabels.setOptions(options);

    let width = scatterChartLabels.getLabelWidth(data.dataIndex);
    if (graphSettings.values.filter((v) => v.col === 'debunkReachAccumulated').length > 0) width = width + 10;
    const barWidth = scatterChartLabels.getPointsPerPixel('x') * data.dataset.data[data.dataIndex] - width;

    return width > barWidth;
}

function setOptionLabels(index) {
    return grData[index].map((l) => {
        if (!isNaN(parseFloat(l))) {
            let num = l.toString();
            let nums = num.replace(/,/g, '');
            l = parseInt(nums).toLocaleString().replace(/,/g, ' ');
        }
        return l;
    });
}

function formDatasetArrays() {
    if (type === 'line') {
        getLineDataSets(graphMain);
    } else Object.values(graphMain).forEach(getDataSets);

    grData = grData
        .filter((item) => item.length > 0)
        .map((item) => {
            for (let i = 0; i < item.length; i++) {
                if (!item[i]) item[i] = 0;
            }
            return item;
        });

    if (grData.length === 0) {
        return grData;
    }

    if (stacked && graphSettings.columns && graphSettings.columns.length === 0) {
        grData = grData.reverse();
        labels = labels.reverse();
        groupedLegendItems = groupedLegendItems.reverse();
    }

    type === 'scatter' &&
        grData.map((item, key) => {
            if (key % 2 === 0) {
                item.map((i, index) => {
                    scatterPoints.push({
                        x: i,
                        y: grData[key + 1] && grData[key + 1][index] ? grData[key + 1][index] : 0,
                    });
                });
            }
        });

    if (scatterPoints.length > 0)
        scatterPoints = scatterPoints.map((p) => {
            if (p.x < p.y) {
                let pointOld = { ...p };
                p['y'] = pointOld.x;
                p['x'] = pointOld.y;
            }
            return p;
        });

    if (type === 'pie' || type === 'doughnut') {
        const grDataNew = [];
        const lastNew = [];

        grData.map((dataset) => {
            let newDataset = [];
            const total = dataset.reduce((a, b) => a + (b || 0), 0);
            let others = 0;
            dataset.map((d, i) => {
                const percentage = parseFloat(((d / total) * 100).toFixed(1));
                if (percentage > 2 || (i === dataset.length - 1 && others === 0)) {
                    newDataset.push(d);
                } else {
                    labelKeys = labelKeys.map((l, index) => {
                        if (i === index) {
                            return language && tempTranslations[language.iso]
                                ? tempTranslations[language.iso].other
                                : 'other';
                        } else return l;
                    });
                    others = others + d;
                }
            });
            newDataset.push(others);
            newDataset = newDataset.filter((d) => d !== 0);
            grDataNew.push(newDataset);
        });

        lastPeriods.length > 0 &&
            lastPeriods.map((period) => {
                const newDataset = [];
                const total = period.reduce((a, b) => a + (b || 0), 0);
                let others = 0;
                period.map((d) => {
                    const percentage = parseFloat(((d / total) * 100).toFixed(1));
                    if (percentage > 2) {
                        newDataset.push(d);
                    } else {
                        others = others + d;
                    }
                });
                newDataset.push(others);
                lastNew.push(newDataset);
            });

        grData = grDataNew;
        lastPeriods = lastNew;
    }
}

function splitText(value) {
    const middle = getMiddle(value);
    const s1 = value.substr(0, middle);
    const s2 = value.substr(middle);

    value = [s1, s2];
    return value;
}

function setGraphOptions(print) {
    let titles = labelKeys;
    let datasets = [];
    let secondYmax = 0;
    let fontSize = setFontSize();
    if (print) fontSize = (fontSize * printSize) / width;

    const { longestStrTxt, longestSubTxt, longestGroup } = getStringLengths(titles);
    const mappable = type === 'line' ? titles : stacked ? labels : Object.keys(graphMain);

    padding === 0 && getPadding(longestStrTxt, longestSubTxt, longestGroup, fontSize);
    backgroundColors = [
        ...backgroundColors,
        ...Array(parseInt(Object.keys(graphMain).length / backgroundColors.length))
            .fill(backgroundColors)
            .flat(),
    ];

    grData.map((d, i) => {
        mappable.map((l, index) => {
            if (i === index) {
                datasets.push({
                    type: Array.isArray(type) ? type[index] : '',
                    label: l,
                    data: type === 'scatter' ? scatterPoints : d,
                    yAxisID: Array.isArray(type) ? type[index] : type,
                    backgroundColor: function (data) {
                        if (type === 'pie' || type === 'doughnut') {
                            if (
                                backgroundColors[0] === backgroundColors[d.length - 1] ||
                                backgroundColors[d.length - 1] === 'rgba(0,0,0, 1)'
                            ) {
                                backgroundColors[d.length - 1] = backgroundColors[1];
                            }
                        }

                        return data.dataset.type === 'line'
                            ? 'transparent'
                            : type === 'line'
                            ? backgroundColors.map((c) => c.replace(', 1)', ', .5)'))[data.datasetIndex]
                            : type === 'pie' || type === 'doughnut'
                            ? backgroundColors
                            : !stacked && !grouped && type === 'horizontalBar'
                            ? backgroundColors
                            : stacked || grouped
                            ? backgroundColors[index]
                            : backgroundColors[i];
                    },
                    pointBackgroundColor: function (data) {
                        if (type === 'line') return backgroundColors[data.datasetIndex];
                        if (data.dataset.type === 'line') return backgroundColors[0];
                    },
                    borderColor: function (data) {
                        if (type === 'line') return backgroundColors[data.datasetIndex];
                        if (data.dataset.type === 'line') return backgroundColors[0];
                    },
                    borderWidth: function (data) {
                        return data.dataset.type === 'line' || type === 'line' ? 2 : 0;
                    },
                    stack: stacked ? 1 : i,
                    barPercentage: 0.98,
                    categoryPercentage: 0.98,
                    maxBarThickness: print ? (60 * printSize) / width : 60,
                });
            }
        });
    });

    const ticks = {
        beginAtZero: true,
        min: 0,
        precision: 0,
        autoSkip: false,
        lineHeight,
        padding: 0,
        fontSize,
        callback: function (value) {
            if (!isNaN(value)) {
                let num = value.toString();
                let nums = num.replace(/,/g, '');
                value = parseInt(nums).toLocaleString().replace(/,/g, ' ');
            }

            if (value.length > 120) value = value.substring(0, 120) + '...';

            if (value.length >= 60) {
                value = splitText(value);
            }

            return value;
        },
    };

    const scatterTicks = {
        max: 1,
        min: -1,
        padding: 10,
        beginAtZero: true,
        fontSize,
    };

    const xTicks = {
        beginAtZero: true,
        precision: 0,
        fontSize,
        callback: function (value) {
            if (!isNaN(value)) {
                let num = value.toString();
                let nums = num.replace(/,/g, '');
                value = parseInt(nums).toLocaleString().replace(/,/g, ' ');
            }
            return value && value.split(', ');
        },
    };

    grData.map((data) => {
        data.map((item) => {
            if (item > maxX) maxX = item;
        });
    });

    if (Array.isArray(type)) {
        grData[1] &&
            grData[1].map((d) => {
                if (d > secondYmax) secondYmax = d;
            });
    }

    if (type === 'horizontalBar' && maxX < 10 && !stacked && !grouped) {
        xTicks.max = maxX + 1;
    }

    let options = {
        canvas: {
            backgroundImage: logo,
        },
        chartArea: {
            backgroundColor: 'transparent',
        },
        responsive: true,
        maintainAspectRatio: false,
        title: {
            fontSize: fontSize + 4,
            padding: type === 'scatter' ? 40 : 10,
        },
        layout: {
            padding: {
                top: type === 'scatter' ? 0 : 30,
                right: type === 'scatter' ? 40 : 25,
                left: type === 'scatter' ? 30 : 0,
            },
        },
        legend: {
            display: legend || stacked,
            position: 'bottom',
            labels: {
                fontSize,
            },
        },
        tooltips: {
            enabled: true,
            position: 'nearest',
            fontSize: 14,
            callbacks: {
                label: function (tooltipItem) {
                    if (!isNaN(parseFloat(tooltipItem.value))) {
                        let num = tooltipItem.value.toString();
                        let nums = num.replace(/,/g, '');
                        tooltipItem.value = parseInt(nums).toLocaleString().replace(/,/g, ' ');
                    }

                    if (type === 'line') {
                        return `${Object.keys(graphMain)[tooltipItem.datasetIndex]}: ${tooltipItem.value}`;
                    }

                    if ((stacked || grouped) && graphSettings.columns && graphSettings.columns.length === 0) {
                        return `${labels[tooltipItem.datasetIndex]}: ${tooltipItem.value}`;
                    }

                    if (graphSettings.columns && graphSettings.columns.length > 0) {
                        if (tooltipItem.value === '0') return '';
                        return `${labels[tooltipItem.datasetIndex]}: ${tooltipItem.value}`;
                    }

                    if (type === 'scatter') {
                        if (tooltipItem.datasetIndex !== 0) return '';
                        return `${Object.keys(graphMain)[tooltipItem.index]}`;
                    }

                    if (graphSettings.last_period && lastPeriods[tooltipItem.datasetIndex][tooltipItem.index]) {
                        return `${tooltipItem.value} (${lastPeriods[tooltipItem.datasetIndex][tooltipItem.index]})`;
                    }

                    return tooltipItem.value;
                },
            },
        },
        scales: {
            yAxes: [
                {
                    id: Array.isArray(type) ? type[0] : type,
                    color: '#000',
                    lineWidth: 3,
                    stacked: stacked,
                    grouped: grouped,
                    gridLines: {
                        color: 'rgba(229, 229, 229, 1)',
                        display: !type.includes('Bar'),
                    },
                    afterFit: function (scale) {
                        if (type !== 'line') {
                            let initialWidth = scale.width;

                            getPadding(longestStrTxt, longestSubTxt, longestGroup, print);

                            let txtWidth = createHiddenElement(longestStrTxt);
                            if (print) txtWidth = (txtWidth * printSize) / width;

                            if (padding > 0) {
                                scale.width = padding + 30;
                            } else if (initialWidth < txtWidth && type === 'horizontalBar') {
                                scale.width = txtWidth + 30;
                            }
                        }
                    },
                    ticks: type === 'scatter' ? scatterTicks : ticks,
                    scaleLabel: {
                        labelString: '',
                        display: true,
                        fontSize: fontSize + 1,
                        fontStyle: 'bold',
                        padding: {
                            top: 0,
                        },
                    },
                },
                {
                    id: Array.isArray(type) ? type[1] : '',
                    display: Array.isArray(type),
                    position: 'right',
                    gridLines: {
                        color: 'rgba(255, 191, 193, .3)',
                        //   zeroLineColor: '#000'
                    },
                    ticks: {
                        fontColor: backgroundColors[1],
                        min: 0,
                        precision: 0,
                        fontSize,
                        callback: function (value) {
                            if (!isNaN(value)) {
                                let num = value.toString();
                                let nums = num.replace(/,/g, '');
                                value = parseInt(nums).toLocaleString().replace(/,/g, ' ');
                            }
                            return value;
                        },
                    },
                    scaleLabel: {
                        labelString: '',
                        display: true,
                        fontSize: fontSize + 1,
                        fontStyle: 'bold',
                    },
                },
            ],
            xAxes: [
                {
                    color: '#000',
                    offset: Array.isArray(type),
                    gridLines: {
                        color: 'rgba(229, 229, 229, 1)',
                        display: type.includes('Bar') || type === 'scatter',
                    },
                    ticks: xTicks,
                    scaleLabel: {
                        labelString: '',
                        display: true,
                        fontSize: fontSize + 1,
                        fontStyle: 'bold',
                    },
                },
            ],
        },
        plugins: {
            datalabels: {
                display: showDataLabels,
                offset: function (ctx) {
                    if (type === 'scatter') {
                        const options = setLabelOptions(ctx, Object.keys(graphMain), 'x-axis-1', 'scatter');
                        scatterChartLabels.setOptions(options);
                        const offset = scatterChartLabels.getOffset(ctx.dataIndex);

                        if (offset) {
                            return offset;
                        }
                    }

                    return 0;
                },
                anchor: function (data) {
                    if (data.dataset.type === 'line') return 'start';

                    if (type === 'line' || data.dataset.type === 'bar') return 'end';

                    if (type.includes('Bar') && !stacked) {
                        const isTextWider = getWidths(data);
                        if (isTextWider) return 'end';
                        return 'center';
                    }
                    return 'center';
                },
                align: function (data) {
                    if (data.dataset.type === 'line') return 'start';

                    if (type === 'scatter') {
                        if (data.dataset.data[data.dataIndex].y >= 0) {
                            return 'top';
                        } else return 'bottom';
                    }

                    if (type === 'line' || data.dataset.type === 'bar') return 'end';
                    if (type.includes('Bar') && !stacked) {
                        const isTextWider = getWidths(data);
                        if (isTextWider) return 'end';
                        return 'center';
                    }
                    return 'center';
                },
                formatter: function (value, data) {
                    if (value === 0) return '';

                    if (!isNaN(parseFloat(value))) {
                        let num = value.toString();
                        let nums = num.replace(/,/g, '');
                        value = parseInt(nums).toLocaleString().replace(/,/g, ' ');
                    }

                    if (type === 'scatter') {
                        return `${Object.keys(graphMain)[data.dataIndex]}`;
                    }

                    if (type.includes('Bar')) {
                        const isTextWider = getWidths(data);
                        if (isTextWider && stacked) return '';
                    }

                    if (graphSettings.last_period) {
                        if (lastPeriods[data.datasetIndex][data.dataIndex])
                            return `${value} (${lastPeriods[data.datasetIndex][data.dataIndex]})`;
                        else return `${value} (0)`;
                    }

                    if (showRelativeValue) {
                        const value = data.dataset.data[data.dataIndex];

                        if (value === 0) return '';

                        const lastPeriodTotal =
                            lastPeriods[data.datasetIndex] &&
                            lastPeriods[data.datasetIndex].reduce((a, b) => a + (b || 0), 0);
                        const percentageLastPeriod =
                            lastPeriods[data.datasetIndex] &&
                            parseFloat(
                                ((lastPeriods[data.datasetIndex][data.dataIndex] / lastPeriodTotal) * 100).toFixed(1),
                            );

                        const percentage = parseFloat(((value / currentTotal) * 100).toFixed(1));

                        if (graphSettings.last_period) {
                            if (lastPeriods[data.datasetIndex][data.dataIndex])
                                return `${percentage}% (${percentageLastPeriod}%)`;
                            else return `${percentage}% (0%)`;
                        } else {
                            return `${percentage}%`;
                        }
                    }

                    return value;
                },
                font: function () {
                    const size = fontSize;

                    return {
                        weight: 'normal',
                        size,
                        lineHeight: 1,
                    };
                },
                labels: {
                    font: {
                        color: function (data) {
                            if (type === 'horizontalBar') {
                                const index = data.dataIndex;
                                const color = backgroundColors[data.datasetIndex];
                                const isTextWider = getWidths(data);

                                if (
                                    isTextWider || // if label is outside
                                    (backgroundColors[0] !== 'rgba(215, 29, 36, 1)' &&
                                        !stacked &&
                                        !grouped &&
                                        (index % 10 === 4 || index % 10 === 5 || index % 10 === 9)) || // if simple bar
                                    color === 'rgba(226, 227, 228, 1)' ||
                                    color === 'rgba(229, 229, 229, 1)' ||
                                    color === 'rgba(255, 191, 193, 1)'
                                )
                                    return '#000'; // if stacked/grouped
                            }

                            if (
                                type === 'scatter' ||
                                type === 'dynamics' ||
                                (Array.isArray(type) && data.dataset.type === 'line') ||
                                type === 'line'
                            ) {
                                return '#000';
                            }
                            if (data.dataset.type === 'bar') {
                                return backgroundColors[1];
                            }

                            return '#fff';
                        },
                    },
                },
            },
        },
        onHover: function (e, chart) {
            const chartt = this.getElementAtEvent(e)[0];
            if (e.target.style.cursor !== 'wait') {
                if (chart[0] && chartt) {
                    let parent = Object.values(graphMain)[type === 'line' ? chartt._datasetIndex : chartt._index];
                    if (parent.search_term) {
                        e.target.style.cursor = 'pointer';
                    }
                } else e.target.style.cursor = 'default';
            }
        },
    };

    if (type !== 'line') {
        options.legend.labels.generateLabels = function () {
            return labels.map((label, i) => {
                return {
                    text: label,
                    lineWidth: 0,
                    datasetIndex: i,
                    fillStyle: backgroundColors[i],
                    strokeStyle: backgroundColors[i],
                };
            });
        };
    }

    if (graphSettings.columns && graphSettings.columns.length > 0) {
        options.tooltips.callbacks.title = (tooltipItem) => {
            return tooltipItem[0].label;
        };
    }

    let optionsPie = {
        canvas: {
            backgroundImage: logo,
        },
        chartArea: {
            backgroundColor: 'transparent',
        },
        responsive: true,
        maintainAspectRatio: false,
        layout: {
            padding: {
                top: 0,
                left: 0,
                right: 0,
                bottom: print ? (80 * printSize) / width : 80,
            },
        },
        scales: {
            yAxes: [
                {
                    display: false,
                    gridLines: {
                        display: false,
                    },
                },
            ],
            xAxes: [
                {
                    display: false,
                    gridLines: {
                        display: false,
                    },
                },
            ],
        },
        title: {
            fontSize: fontSize + 4,
            padding: print ? (30 * printSize) / width : 30,
            display: true,
            lineHeight,
        },
        legend: {
            display: false,
            position: 'right',
            labels: {
                fontSize,
            },
        },
        tooltips: {
            callbacks: {
                label: function (tooltipItem, data) {
                    const dataset = data.datasets[tooltipItem.datasetIndex];
                    const currentValue = dataset.data[tooltipItem.index];

                    if (currentValue === 0) return '';

                    if (!isNaN(parseFloat(tooltipItem.value))) {
                        let num = tooltipItem.value.toString();
                        let nums = num.replace(/,/g, '');
                        tooltipItem.value = parseInt(nums).toLocaleString().replace(/,/g, ' ');
                    }

                    if (graphSettings.last_period) {
                        if (lastPeriods[tooltipItem.datasetIndex][tooltipItem.index])
                            return `${currentValue} (${lastPeriods[tooltipItem.datasetIndex][tooltipItem.index]})`;
                        else return `${currentValue} (0)`;
                    } else {
                        return `${currentValue}`;
                    }
                },
                title: function (tooltipItem, data) {
                    return data.labels[tooltipItem[0].index];
                },
            },
        },
        plugins: {
            outlabels: {
                display: true,
                text: function (data) {
                    const value = data.dataset.data[data.dataIndex];

                    if (value === 0) return '';

                    const lastPeriodTotal =
                        lastPeriods[data.datasetIndex] &&
                        lastPeriods[data.datasetIndex].reduce((a, b) => a + (b || 0), 0);
                    const percentageLastPeriod =
                        lastPeriods[data.datasetIndex] &&
                        parseFloat(
                            ((lastPeriods[data.datasetIndex][data.dataIndex] / lastPeriodTotal) * 100).toFixed(1),
                        );
                    const total = data.dataset.data.reduce((a, b) => a + (b || 0), 0);
                    const percentage = parseFloat(((value / total) * 100).toFixed(1));

                    const prevValues = [];
                    if (percentage < 2) {
                        data.dataset.data.map((item, i) => {
                            if (data.dataIndex > i) {
                                prevValues.push(parseFloat(data.dataset.data[i]));
                            }
                        });
                        return `${titles[data.dataIndex]}: ${(
                            100 -
                            (prevValues.reduce((a, b) => a + (b || 0), 0) / total) * 100
                        ).toFixed(1)}%`;
                    }

                    if (graphSettings.last_period) {
                        if (lastPeriods[data.datasetIndex][data.dataIndex])
                            return `${titles[data.dataIndex]}: ${percentage}% (${percentageLastPeriod}%)`;
                        else return `${titles[data.dataIndex]}: ${percentage}% (0%)`;
                    } else {
                        return `${titles[data.dataIndex]}: ${percentage}%`;
                    }
                },
                color: '#000',
                lineColor: function (data) {
                    return backgroundColors[data.dataIndex];
                },
                backgroundColor: 'white',
                stretch: 20,
                textAlign: 'center',
                padding: 0,
                lineWidth: 1,
                font: {
                    size: fontSize,
                    minSize: fontSize,
                    family: 'Raleway',
                },
            },
            datalabels: {
                display: showDataLabels,
                font: function () {
                    const size = fontSize;

                    return {
                        weight: 'normal',
                        size,
                        lineHeight: 1,
                    };
                },
                labels: {
                    font: {
                        color: function (data) {
                            const index = data.dataIndex;
                            const color = backgroundColors[index];
                            if (
                                color === 'rgba(226, 227, 228, 1)' ||
                                color === 'rgba(229, 229, 229, 1)' ||
                                color === 'rgba(255, 191, 193, 1)'
                            )
                                return '#000';
                            return '#fff';
                        },
                    },
                },
            },
        },
        onHover: (e, chartElement) => {
            if (e.target.style.cursor !== 'wait') {
                e.target.style.cursor = chartElement[0] ? 'pointer' : 'default';
            }
        },
    };

    if (!responsive) {
        options.events = [];
        options.responsive = false;
        optionsPie.events = [];
        optionsPie.responsive = false;
    }

    const chartData = {
        type,
        data: {
            labels: type === 'line' ? labels : titles,
            datasets,
        },
        options: type === 'pie' || type === 'doughnut' ? optionsPie : options,
    };

    if (type === 'line') {
        Chart.defaults.global.tooltips.callbacks.title = function () {
            return '';
        };
    }

    let legendItemWidth = (width * 10) / initialWidth;
    if (print) legendItemWidth = (legendItemWidth * printSize) / width;

    Chart.defaults.global.legend.labels.usePointStyle = true;
    Chart.defaults.global.legend.labels.boxWidth = legendItemWidth;
    Chart.defaults.global.legend.labels.padding = legendItemWidth;
    setGlobal();

    return chartData;
}

function setGlobal() {
    Chart.defaults.global.defaultFontFamily = "'Raleway', sans-serif";
    Chart.defaults.global.defaultFontColor = '#000';
    Chart.defaults.global.defaultFontSize = fontSize;
    Chart.defaults.global.tooltips.bodyFontSize = 14;
    Chart.defaults.global.tooltips.titleFontSize = 14;
    Chart.defaults.global.tooltips.footerFontSize = 14;
}

// ========== Chart.js plugins ============

function initiatePlugins() {
    Chart.plugins.register({
        beforeDraw: function (chrt) {
            const ctx = chrt.chart.ctx;
            ctx.fillStyle = !graphId ? 'transparent' : 'white';
            ctx.fillRect(0, 0, chrt.chart.width, chrt.chart.height);
        },
        afterDatasetsDraw: function (chrt) {
            let t = chrt.chart.config.type;
            t !== 'pie' && t !== 'doughnut' && drawLeftLine(chrt);
            drawLogo(chrt);
        },
    });
}

function drawLogo(chrt) {
    let roundChart = type === 'pie';
    let chartArea = chrt.chartArea;
    let ctx = chrt.chart.ctx;

    if (chrt.config.options.canvas) {
        if (chrt.config.options.canvas.backgroundImage) {
            let image = new Image();
            let imgWidth = graphId ? (chrt.width * 108) / initialWidth : 72;
            let imgHeight = graphId ? (chrt.width * 28.252) / initialWidth : 12.17;

            if (type === 'doughnut') {
                imgWidth = chrt.innerRadius * 2 - 5;
                imgHeight = imgWidth / 4.971;
            }

            let left = graphId
                ? (chartArea.right - (chrt.width * 100) / initialWidth) * (roundChart && !titleRight ? 0.8 : 1)
                : chartArea.right - 80;
            let y = !graphId
                ? chartArea.bottom - 25
                : type === 'horizontalBar' || type === 'scatter'
                ? chartArea.bottom - (chrt.width * 30) / initialWidth
                : roundChart && graphSettings.translations.length > 0
                ? chartArea.top - (chrt.width * 60) / initialWidth
                : roundChart
                ? chartArea.top - (chrt.width * 30) / initialWidth
                : (chrt.width * 30) / initialWidth;

            if (type === 'doughnut') {
                left = chrt.width / 2 - imgWidth / 2;
                y = chrt.height / 2 - imgHeight / 2;
            }

            image.src = logo;
            ctx.save();
            ctx.globalAlpha = 0.6;
            ctx.drawImage(image, left, y, imgWidth, imgHeight);
            ctx.restore();
        }
    }
}

function drawLeftLine(chrt) {
    let ctx = chrt.chart.ctx;
    let xAxe = chrt.config.options.scales.xAxes[0];
    let xScale = chrt.scales[xAxe.id];
    let yAxe = chrt.config.options.scales.yAxes[0];
    let yScale = chrt.scales[yAxe.id];

    // BORDER
    ctx.strokeStyle = '#000';
    ctx.lineWidth = document.body.clientWidth < 500 ? 1 : 2;
    ctx.save();
    ctx.beginPath();
    ctx.moveTo(type === 'line' ? xScale.left - 3 : xScale.left + 0.5, yScale.bottom + 10);
    ctx.lineTo(type === 'line' ? xScale.left - 3 : xScale.left + 0.5, yScale.top);
    ctx.stroke();
    ctx.restore();
}

// ========== Translate chart.js ============

function translateLabels() {
    const currentLang = language && language.iso ? language.iso : null;
    let labelsNew = [];
    let labelsOld = labels;
    let labelObjects = [];

    if (currentLang) {
        Object.values(graphMain).map((v) => {
            const children = Object.values(v.children);
            children.map((c) => {
                if (labelsOld.includes(c.title)) {
                    labelsOld = labelsOld.filter((l) => l !== c.title);
                    labelObjects.push(c);
                }
            });
            return v;
        });

        labelObjects.map((l) => {
            if (l.translations[currentLang]) labelsNew.push(l.translations[currentLang].text);
            else labelsNew.push(l.translations['not_translated'].text);
        });
    } else {
        labelsNew = labelsOld;
    }

    labels = labelsNew;
}

function translateTicks() {
    const lbls = [];
    const currentLang = language ? language.iso : 'not_translated';

    Object.values(graphMain).map((v) => {
        if (v.translations && v.translations[currentLang]) {
            lbls.push(v.translations[currentLang].text);
        } else {
            lbls.push(v.title);
        }
    });

    labelKeys = lbls;
}

// ========== Common functions ============

function setMainValues(settings, main, translations, id, showLabels, relativeValue, currentChartTotal) {
    resetAll();
    graphSettings = settings;
    graphMain = main;
    graphId = id;
    type = settings.type.type;
    showDataLabels = showLabels;
    showRelativeValue = relativeValue;
    currentTotal = currentChartTotal;
}

function setOptionalValues(lang, elWidth, printOption, responsiveness) {
    language = lang;
    parentWidth = elWidth;
    print = printOption;
    responsive = responsiveness;
}

function resetAll() {
    parentWidth = false;
    d3.selectAll('svg').remove();
    customTitle = '';
    fontSize = 14;
    lineHeight = 0.8;
    graphMain = {};
    graphId = null;
    lastPeriods = [];
    grData = [];
    labels = [];
    labelKeys = [];
    rows = [];
    groupedLegendItems = [];
    scatterPoints = [];
    type = '';
    stacked = false;
    grouped = false;
    legend = false;
    maxX = 0;
    backgroundColors = [
        'rgba(35, 31, 32, 1)',
        'rgba(215, 29, 36, 1)',
        'rgba(152, 143, 145, 1)',
        'rgba(245, 118, 123, 1)',
        'rgba(226, 227, 228, 1)',
        'rgba(255, 191, 193, 1)',
        'rgba(0,0,0, 1)',
        'rgba(76, 76, 76, 1)',
        'rgba(153, 153, 153, 1)',
        'rgba(229, 229, 229, 1)',
    ];
    padding = 0;
    svg = '';
    responsive && chart.options && chart.destroy();
    responsive && chartPrint.options && chartPrint.destroy();
    chart = '';
    chartPrint = '';
    titleRight = null;
    language = null;
    document.querySelectorAll('.d3-tooltip').forEach((e) => e.remove());
}

function getDimensions() {
    if (!parentWidth || parentWidth === 0) {
        const parent = document.getElementById(`parent-${graphId}`);
        parentWidth = parent ? parent.clientWidth : width;
    }

    initialWidth = 993;
    initialHeight = type === 'donut' || type === 'doughnut' || type === 'pie' ? initialWidth * 0.4 : initialWidth * 0.6;
    if (parentWidth > initialWidth) parentWidth = initialWidth;
    width = parentWidth;
    height = (parentWidth * initialHeight) / initialWidth;
    return { width, height };
}

function setCustomTranslations(chart) {
    let translationsTemp = [];

    if (language && graphSettings.translations) {
        graphSettings.translations.map((t) => {
            if (t.language.id === language.id) {
                translationsTemp.push(t);
            }
            return t;
        });
    }

    if (svg) {
        translationsTemp.map((t) => {
            if (t.slot === 'yAxis[0]') {
                svg.append('text')
                    .attr('transform', 'rotate(-90)')
                    .attr('y', 0)
                    .attr('x', 0 - height / 2)
                    .attr('dy', '1em')
                    .attr('text-anchor', 'middle')
                    .attr('fill', '#000')
                    .style('font', `${fontSize}px Raleway`)
                    .text(t.value);
            }
            if (t.slot === 'xAxis[0]') {
                svg.append('text')
                    .attr('y', height)
                    .attr('x', width / 2)
                    .attr('dy', '1em')
                    .attr('text-anchor', 'middle')
                    .attr('fill', '#000')
                    .style('font', `${fontSize}px Raleway`)
                    .text(t.value);
            }
            if (t.slot === 'title') {
                svg.selectAll('.chartTitle').text(customTitle.length > 0 ? customTitle : t.value);
            }
        });
    } else if (chart && chart.options) {
        translationsTemp.map((t) => {
            if (t.slot === 'xAxis[0]') {
                chart.options.scales.xAxes[0].scaleLabel.labelString = t.value;
            }
            if (t.slot === 'yAxis[0]') {
                chart.options.scales.yAxes[0].scaleLabel.labelString = t.value;
            }
            if (t.slot === 'yAxis[1]') {
                if (chart.options.scales.yAxes[1]) chart.options.scales.yAxes[1].scaleLabel.labelString = t.value;
            }
            if (t.slot === 'legend') {
                const color = backgroundColors[1];
                if (type === 'horizontalBar') backgroundColors = backgroundColors.map(() => color);

                chart.options.legend.display = true;
                chart.options.legend.labels.generateLabels = function () {
                    return [
                        {
                            text: t.value,
                            lineWidth: 0,
                            datasetIndex: 0,
                            fillStyle: color,
                            strokeStyle: color,
                        },
                    ];
                };
            }
            if (t.slot === 'title') {
                let value = t.value;
                let w = createHiddenElement(value, print, true);
                let logoWidth = (width * 90) / initialWidth;

                if (w > width - 2 * logoWidth) {
                    changePositionX();
                }

                if (w > width) {
                    value = splitText(value, value.length / 2);
                    changePositionX();
                }

                chart.options.title.display = true;
                chart.options.layout.padding.top = 0;
                chart.options.title.text = value;
            }
        });
        chart.update();
    }
}

function changePositionX() {
    titleRight = true;
}

function setFontSize() {
    const isPie = type === 'pie' || type === 'doughnut';
    fontSize = (width * 14) / initialWidth;

    let tickValues = Object.keys(graphMain),
        amount = isPie ? grData[0].length : tickValues.length,
        barHeight = isPie ? height : height / amount;

    if (graphSettings.rows.length > 1) {
        tickValues = [];
        Object.values(graphMain).map((v) => {
            Object.values(v.children).map((c) => {
                rows.push(c.title);
                tickValues.push(c.title);
            });
        });
        barHeight = (height - 60) / rows.length;
    }

    if (2 * fontSize > barHeight) {
        fontSize = tickValues.filter((v) => v.length > 60).length > 0 ? barHeight / 2 : barHeight;
    }

    if (fontSize > 14) fontSize = 14;

    if (barHeight > 2 * fontSize) {
        let lineHeightNew = 0.8 + barHeight / fontSize / 10;
        if (lineHeightNew > 1.4) lineHeightNew = 1.4;
        lineHeight = lineHeightNew;
    }

    if (fontSize > 2 && fontSize < 14 && isPie) fontSize -= 1;

    return fontSize;
}

function createHiddenElement(txt, print, bold) {
    const oldHidden = document.getElementById('hiddenElement');
    oldHidden && oldHidden.remove();

    const newDiv = document.createElement('div');
    const newContent = document.createTextNode(txt);

    newDiv.setAttribute('id', 'hiddenElement');
    newDiv.appendChild(newContent);
    newDiv.style.display = 'inline-block';
    newDiv.style.overflow = 'hidden';
    newDiv.style.height = '0';
    newDiv.style.color = 'transparent';
    newDiv.style.fontSize = fontSize + 'px';
    newDiv.style.fontFamily = '"Raleway", sans-serif';
    if (bold) {
        newDiv.style.fontWeight = '500';
        newDiv.style.fontSize = fontSize + 4 + 'px';
    }

    document.body.appendChild(newDiv);

    if (print) return (newDiv.clientWidth * printSize) / width;

    return newDiv.clientWidth;
}

function getPadding(longestStrTxt, longestSubTxt, longestGroup, print) {
    padding =
        longestStrTxt.length < 60 && graphSettings.rows.length === 1 ? 0 : createHiddenElement(longestStrTxt, print);

    graphArray = graphArray.map((g) => {
        if (g.id === graphId) g = { ...g, padding };
        return g;
    });
}

function updateTitle(title) {
    if (svg) {
        svg.selectAll('.chartTitle').text(title);
    } else if (chart && chart.options) {
        chart.options.title.display = true;
        let value = title;

        let w = createHiddenElement(value, print, true);
        let width = document.getElementById(`chart-${graphId}`).clientWidth;
        let logoWidth = (width * 90) / initialWidth;

        if (w > width - 2 * logoWidth) {
            changePositionX();
        }

        if (w > width) {
            value = splitText(value, value.length / 2);
            changePositionX();
        }

        chart.options.title.text = value;
        chart.update();

        if (chartPrint && chartPrint.options) {
            chartPrint.options.title.display = true;
            chartPrint.options.title.text = value;
            chartPrint.update();
        }
    }
}

function getValues() {
    return {
        type,
        grData,
        graphMain,
        sTermsArray,
        stacked,
        labels: stacked && graphSettings.columns && graphSettings.columns.length > 0 ? labels : labelKeys,
    };
}

function getMiddle(str) {
    let middle = 60;
    let before = str.lastIndexOf(' ', middle);
    const after = str.indexOf(' ', middle + 1);

    if (before === -1 && after === -1) {
        middle = str.length / 2;
    } else if (before === -1 || (after !== -1 && middle - before >= after - middle)) {
        middle = after;
    } else {
        middle = before;
    }
    return middle;
}

// ========== Grouped charts (d3) ============

function setColors() {
    let bgArr = backgroundColors;

    while (bgArr.length <= Object.keys(graphMain).length) {
        bgArr = [...bgArr, ...backgroundColors];
    }
    backgroundColors = bgArr;
}

function wrapText(text, width) {
    text.each(function () {
        let text = d3.select(this),
            words = text.text().split(/\s+/).reverse(),
            word,
            line = [],
            x = text.attr('x'),
            y = text.attr('y'),
            dy = 0,
            tspan = text
                .text(null)
                .append('tspan')
                .attr('x', x)
                .attr('y', y)
                .attr('dy', dy + 'em');

        while ((word = words.pop())) {
            line.push(word);
            tspan.text(line.join(' '));

            if (tspan.node().getComputedTextLength() > width + 6) {
                // ~ width of ': '
                line.pop();
                tspan.text(line.join(' '));
                line = [word];
                dy = word === words[0] ? 0 : lineHeight;
                tspan = text
                    .append('tspan')
                    .attr('x', x)
                    .attr('y', y)
                    .attr('dy', dy + 'em')
                    .text(word);
            }
        }
    });
}

function getGroupedData() {
    const data = [];
    const currentLang = language ? language.iso : 'not_translated';
    let padding = 0,
        parentPadding = 0,
        labelColors = [];

    Object.values(graphMain).map((v, i) => {
        if (v.translations && v.translations[currentLang]) {
            v.title = v.translations[currentLang].text;
        }

        if (!v.title.includes(': ')) {
            v.title += ': ';
        }

        if (v.title.length > 120) v.title = v.title.substring(0, 120) + '...';
        let string = v.title;

        if (string.length > 60) {
            const middle = getMiddle(string);
            string = string.substr(0, middle);
        }
        let newPadd = createHiddenElement(string);
        if (newPadd > parentPadding) {
            parentPadding = newPadd;
        }

        Object.values(v.children).map((c, ii) => {
            if (c.translations && c.translations[currentLang]) {
                c.title = c.translations[currentLang].text;
            }
            if (c.title.length > 120) c.title = c.title.substring(0, 120) + '...';
            let string = c.title;

            if (string.length > 60) {
                const middle = getMiddle(string);
                string = string.substr(0, middle);
            }
            let item = {
                index: i,
                name: c.title,
                parent: ii === 0 ? v.title : '',
                parent_search_term: v.search_term,
                search_term: c.search_term,
            };

            padding = createHiddenElement(string) > padding ? createHiddenElement(string) : padding;

            if (c.children) {
                Object.values(c.children).map((cc) => {
                    item.value = cc.value;
                });
            } else {
                item.name = c.title;
                if (item.parent.match(/: +$/)) {
                    // Replace the colon and all trailing spaces
                    item.parent = item.parent.replace(/: +$/, '');
                }
                item.value = c.value;
            }

            data.push(item);

            let color = backgroundColors[i];

            if (color !== undefined && (i % 10 === 4 || i % 10 === 5 || i % 10 === 9)) {
                let CC = color.replace('rgba(', '').replace(')', '').split(','),
                    CR = Math.floor(parseInt(CC[0]) - 50),
                    CG = Math.floor(parseInt(CC[1]) - 50),
                    CB = Math.floor(parseInt(CC[2]) - 50);
                color = 'rgba(' + CR + ',' + CG + ',' + CB + ', 1)';
            }
            labelColors.push(color);
        });
    });

    groupedData = data;

    return { data: groupedData, padding, parentPadding: parentPadding, labelColors };
}

function createGrouped() {
    setColors();
    rows = [];
    let fontSize = setFontSize();

    svg = '';
    d3.select(`#parent-${graphId}`).selectAll('svg').remove();
    if (document.getElementById(`chart-${graphId}`)) document.getElementById(`chart-${graphId}`).style.display = 'none';

    const settings = getGroupedData();
    const { data, padding, parentPadding, labelColors } = settings;

    const margin = { top: 30, right: 20, bottom: 30, left: 40 + padding + parentPadding },
        barPadding = 1 / data.length + 0.05,
        y = d3
            .scaleBand()
            .domain(d3.range(data.length))
            .rangeRound([0, height - margin.bottom - margin.top])
            .padding(barPadding > 0.7 ? 0.7 : barPadding),
        x = d3
            .scaleLinear()
            .domain([0, d3.max(data, (d) => d.value)])
            .range([margin.left, width - margin.right]),
        yAxis = (g) =>
            g.attr('transform', `translate(${margin.left},${margin.top})`).call(
                d3
                    .axisLeft(y)
                    .tickFormat((i) => data[i].name)
                    .tickSizeOuter(0),
            ),
        yAxis1 = (g) =>
            g.attr('transform', `translate(0,${margin.top})`).call(
                d3
                    .axisLeft(y)
                    .tickFormat((i) => data[i].parent)
                    .tickSizeOuter(0),
            ),
        xAxis = (g) =>
            g
                .attr('transform', `translate(0,${height - margin.bottom})`)
                .call(
                    d3
                        .axisBottom(x)
                        .ticks(width / 90)
                        .tickFormat(function (d) {
                            let num = d.toString();
                            let nums = num.replace(/,/g, '');
                            return parseInt(nums).toLocaleString().replace(/,/g, ' ');
                        }),
                )
                .call((g) => g.select('.domain').remove()),
        color = d3.scaleOrdinal().range(backgroundColors);

    svg = d3
        .select(`#parent-${graphId}`)
        .append('svg')
        .attr('viewBox', [0, 0, width, height + margin.bottom])
        .style('font', `${fontSize}px Raleway`);

    svg.append('rect').attr('width', '100%').attr('height', '100%').attr('fill', 'white');

    const xAxisMain = svg.append('g').call(xAxis);

    xAxisMain
        .selectAll('text')
        .attr('fill', (d) => (d.toString().includes('.') ? 'none' : '#000'))
        .style('font', `${fontSize}px Raleway`)
        .call((txt) =>
            txt
                .style('text-anchor', 'end')
                .attr('dx', '-.8em')
                .attr('dy', '.15em')
                .attr('transform', (d, i, arr) => {
                    const avg = (width - margin.left) / arr.length - 10;
                    let rotate = false;
                    [...arr].map((item) => {
                        const itemWidth = createHiddenElement(item.innerHTML);
                        if (itemWidth > avg) rotate = true;
                    });
                    return rotate ? 'rotate(-20)' : 'none';
                }),
        );

    xAxisMain
        .selectAll('line')
        .attr('y1', 0)
        .attr('y2', height - margin.top - margin.bottom)
        .attr('transform', `translate(0, ${height - margin.top - margin.bottom})`)
        .attr('stroke', (d) => (d.toString().includes('.') ? 'none' : backgroundColors[9]));

    const div = d3.select(`#parent-${graphId}`).append('div').attr('class', 'd3-tooltip');

    svg.append('g')
        .selectAll('rect')
        .data(data)
        .join('rect')
        .attr('class', 'bar')
        .attr('transform', `translate(0,${margin.top})`)
        .attr('x', x(0))
        .attr('y', (d, i) => y(i))
        .attr('width', (d) => x(d.value) - x(0))
        .attr('height', y.bandwidth())
        .attr('fill', (d) => color(d.index))
        .on('mouseover', function (e, d) {
            if (!responsive) return;

            let rect = d3.select(this);

            rect.classed('hover', true).style('cursor', 'pointer');

            div.transition().duration(300).style('opacity', 1);
            div.html(d.name ? d.name + ': ' + d.value : d.value)
                .style('font-size', fontSize)
                .style('left', e.layerX + 'px')
                .style('top', parseFloat(rect.attr('y')) + margin.top + 'px');
        })
        .on('mouseout', function () {
            d3.select(this).classed('hover', false).style('cursor', 'default');

            div.transition().duration(300).style('opacity', 0);
        });

    svg.append('g')
        .selectAll('rect text')
        .data(data)
        .attr('text-anchor', 'end')
        .join('text')
        .attr('class', 'value')
        .attr('fill', (d) => {
            if (!showDataLabels) return 'transparent';
            if (d.index % 10 === 4 || d.index % 10 === 5 || d.index % 10 === 9) return '#000';
            return '#fff';
        })
        .attr('x', (d) => {
            const txtWidth = createHiddenElement(d.value.toString());
            return x(d.value / 2) - txtWidth / 2;
        })
        .attr('y', (d, i) => margin.top + y(i) + y.bandwidth() / 2)
        .attr('dy', '0.35em')
        .attr('dx', -4)
        .text((d) => {
            let num = d.value.toString();
            let nums = num.replace(/,/g, '');
            return parseInt(nums).toLocaleString().replace(/,/g, ' ');
        })
        .call((text) =>
            text
                .filter(function (d) {
                    const txtWidth = createHiddenElement(d.value.toString());
                    let barWidth = 0;

                    svg.selectAll('rect.bar').each(function (el) {
                        if (JSON.stringify(el) === JSON.stringify(d)) {
                            barWidth = parseInt(d3.select(this).attr('width'));
                        }
                    });

                    return txtWidth > barWidth - 20;
                }) // short bars
                .attr('x', (d) => x(d.value))
                .attr('dx', +4)
                .attr('fill', !showDataLabels ? 'transparent' : 'black')
                .attr('text-anchor', 'start'),
        );

    const yAxisChild = svg.append('g').call(yAxis);

    yAxisChild
        .selectAll('.tick text')
        .style('font', `${fontSize}px Raleway`)
        .attr('dy', 0)
        .attr('fill', (d) => {
            return labelColors[d];
        })
        .call(wrapText, padding);

    yAxisChild.selectAll('line').attr('stroke', 'none');

    yAxisChild.selectAll('.domain').attr('stroke-width', 2);

    const yAxisParent = svg.append('g').call(yAxis1);

    yAxisParent
        .selectAll('text')
        .style('font', `${fontSize}px Raleway`)
        .attr('fill', (d) => {
            return labelColors[d];
        })
        .attr('dy', 0)
        .attr('text-anchor', 'start')
        .attr('x', 4)
        .call(wrapText, parentPadding);

    yAxisParent.selectAll('.domain, line').attr('stroke', 'none');

    svg.append('svg:image')
        .attr('x', width - margin.right - (width * 90) / initialWidth - 10)
        .attr('y', height - margin.top - margin.bottom)
        .attr('width', (width * (90 * 1.3)) / initialWidth)
        .attr('height', (width * (15.21 * 1.3)) / initialWidth)
        .attr('opacity', 0.6)
        .attr('xlink:href', logo);

    svg.append('text')
        .attr('class', 'chartTitle')
        .attr('y', 0)
        .attr('x', width / 2)
        .attr('dy', '1em')
        .attr('text-anchor', 'middle')
        .attr('fill', '#000')
        .style('font', `600 ${fontSize * 1.3}px Raleway`)
        .text(customTitle);

    setCustomTranslations();
}

export default {
    createCharts,
    setMainValues,
    setOptionalValues,
    getDimensions,
    resetAll,
    updateTitle,
    getValues,
    createSimpleDynamics,
    getGroupedData,
};
