import { ParametersListTypes } from 'core/types';
import { ParametersService, ParametersValuesService, ToastService } from 'core/services';

export function getParametersList(subset = '', list) {
    return async (dispatch) => {
        dispatch({ type: ParametersListTypes.PARAMETER_LIST_GET_PARAMETERS_LIST_START });

        try {
            const parameters = await ParametersService.getParametersList(subset, list);
            const payload = subset === 'select' ? { parameters: [], singleParameters: parameters } : { parameters };

            dispatch({ type: ParametersListTypes.PARAMETER_LIST_GET_PARAMETERS_LIST, payload });
        } catch (error) {
            const message = error?.response?.data?.message;
            ToastService.showGenericError(message);
            dispatch({ type: ParametersListTypes.PARAMETER_LIST_GET_PARAMETER_LIST_ERROR });
        }
    };
}

export function createParameter(parameter) {
    return async (dispatch) => {
        try {
            dispatch({ type: ParametersListTypes.PARAMETER_LIST_CREATE_PARAMETER_START });
            const newParameter = await ParametersService.createParameter(parameter);
            dispatch({ type: ParametersListTypes.PARAMETER_LIST_CREATE_PARAMETER, payload: newParameter });
            ToastService.showSuccess('Parameter successfully created');
        } catch (error) {
            const message = error?.response?.data?.message;
            ToastService.showGenericError(message);
            dispatch({ type: ParametersListTypes.PARAMETER_LIST_GET_PARAMETER_LIST_ERROR });
        }
    };
}

export function updateParameter(id, parameter) {
    return async (dispatch) => {
        try {
            dispatch({ type: ParametersListTypes.PARAMETER_LIST_UPDATE_PARAMETERS_START });
            await ParametersService.updateParameter(id, parameter);
            dispatch({ type: ParametersListTypes.PARAMETER_LIST_UPDATE_PARAMETERS_SUCCESS, payload: parameter });
            ToastService.showSuccess('Parameter successfully updated');
        } catch (error) {
            const message = error?.response?.data?.message;
            ToastService.showGenericError(message);
            dispatch({ type: ParametersListTypes.PARAMETER_LIST_GET_PARAMETER_LIST_ERROR });
        }
    };
}

export function updateParameterValue(id, parameterValue, parameterId) {
    return async (dispatch) => {
        try {
            dispatch({ type: ParametersListTypes.PARAMETER_LIST_UPDATE_PARAMETERS_START });
            await ParametersValuesService.updateParameterValue(id, parameterValue);
            dispatch({
                type: ParametersListTypes.PARAMETER_LIST_UPDATE_PARAMETERS_VALUE_SUCCESS,
                payload: { parameterValue, parameterId },
            });
            ToastService.showSuccess('Parameter value successfully updated');
        } catch (error) {
            const message = error?.response?.data?.message;
            ToastService.showGenericError(message);
            dispatch({ type: ParametersListTypes.PARAMETER_LIST_GET_PARAMETER_LIST_ERROR });
        }
    };
}

export function enableParameter(id) {
    return async (dispatch) => {
        try {
            dispatch({ type: ParametersListTypes.PARAMETER_LIST_UPDATE_STATUS_PARAMETERS_START });
            const parameter = await ParametersService.enableParameter(id);
            dispatch({ type: ParametersListTypes.PARAMETER_LIST_ENABLE_PARAMETERS_SUCCESS, payload: { parameter } });
        } catch (error) {
            const message = error?.response?.data?.message;
            ToastService.showGenericError(message);
            dispatch({ type: ParametersListTypes.PARAMETER_LIST_STATUS_ERROR });
        }
    };
}

export function disableParameter(id) {
    return async (dispatch) => {
        try {
            dispatch({ type: ParametersListTypes.PARAMETER_LIST_UPDATE_STATUS_PARAMETERS_START });
            const parameter = await ParametersService.disableParameter(id);
            dispatch({ type: ParametersListTypes.PARAMETER_LIST_DISABLE_PARAMETERS_SUCCESS, payload: { parameter } });
        } catch (error) {
            const message = error?.response?.data?.message;
            ToastService.showGenericError(message);
            dispatch({ type: ParametersListTypes.PARAMETER_LIST_STATUS_ERROR });
        }
    };
}

export function deleteParameter(id) {
    return async (dispatch) => {
        try {
            dispatch({ type: ParametersListTypes.PARAMETER_LIST_DELETE_PARAMETERS_START });
            await ParametersService.deleteParameter(id);
            dispatch({ type: ParametersListTypes.PARAMETER_LIST_DELETE_PARAMETERS_SUCCESS, payload: id });
        } catch (error) {
            const message = error?.response?.data?.message;
            ToastService.showGenericError(message);
            dispatch({ type: ParametersListTypes.PARAMETER_LIST_DELETE_ERROR });
        }
    };
}

export function enableParameterValue(id) {
    return async (dispatch) => {
        try {
            dispatch({ type: ParametersListTypes.PARAMETER_LIST_UPDATE_STATUS_PARAMETERS_START });
            const parameterValue = await ParametersValuesService.enableParameterValue(id);
            dispatch({
                type: ParametersListTypes.PARAMETER_LIST_ENABLE_PARAMETERS_VALUE_SUCCESS,
                payload: { parameterValue },
            });
        } catch (error) {
            const message = error?.response?.data?.message;
            ToastService.showGenericError(message);
            dispatch({ type: ParametersListTypes.PARAMETER_LIST_STATUS_ERROR });
        }
    };
}

export function disableParameterValue(id) {
    return async (dispatch) => {
        try {
            dispatch({ type: ParametersListTypes.PARAMETER_LIST_UPDATE_STATUS_PARAMETERS_START });
            const parameterValue = await ParametersValuesService.disableParameterValue(id);
            dispatch({
                type: ParametersListTypes.PARAMETER_LIST_DISABLE_PARAMETERS_VALUE_SUCCESS,
                payload: { parameterValue },
            });
        } catch (error) {
            const message = error?.response?.data?.message;
            ToastService.showGenericError(message);
            dispatch({ type: ParametersListTypes.PARAMETER_LIST_STATUS_ERROR });
        }
    };
}

export function deleteParameterValue(parameterId, id) {
    return async (dispatch) => {
        try {
            dispatch({ type: ParametersListTypes.PARAMETER_LIST_DELETE_PARAMETERS_START });
            await ParametersValuesService.deleteParameterValue(id);
            dispatch({
                type: ParametersListTypes.PARAMETER_LIST_DELETE_PARAMETERS_VALUE_SUCCESS,
                payload: { parameterId, id },
            });
        } catch (error) {
            const message = error?.response?.data?.message;
            ToastService.showGenericError(message);
            dispatch({ type: ParametersListTypes.PARAMETER_LIST_DELETE_ERROR });
        }
    };
}
