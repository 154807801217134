import { store } from 'core/store';
import { TextUtils } from 'utils';

export function checkAvailableParameters(search) {
    const { parameters } = store.getState().parameters;
    const { categories } = store.getState().categories;
    const { domains } = store.getState().domains;
    const { filters } = store.getState().filters;
    const { languages } = store.getState().languages;
    const { users } = store.getState().users;
    let parameterMissing = false;

    Object.entries(search).map((item) => {
        let [key, value] = item;

        if (key.includes('parameters')) {
            handleParameters(key, value);
        } else if (key.includes('classifications')) {
            handleClassifications(value);
        } else if (key.includes('extracted_entities')) {
            handleExtractedEntities(value);
        } else if (Array.isArray(value)) {
            handleArrayValues(key, value);
        }

        return item;
    });

    return { search, parameterMissing };

    // Handle parameters
    function handleParameters(key, value) {
        const id = TextUtils.getParameterId(key);
        const parameter = parameters.find((p) => p.id === id);

        if (parameter) {
            if (value.length > 0) {
                value = value.filter((v) => {
                    let valueExists = parameter.values.find((val) => val.id === v);
                    if (!valueExists) {
                        parameterMissing = true;
                        parameter.type !== 'A2' && delete search[key];
                        return false;
                    }
                    return true;
                });
            }

            if (parameter.type === 'A2') {
                search[key] = value.length > 0 ? value : [''];
            }
        } else {
            delete search[key];
            parameterMissing = true;
        }
    }

    // Handle classifications
    function handleClassifications(value) {
        if (value.length <= 0) {
            parameterMissing = true;
        }
    }

    // Handle extracted entities
    function handleExtractedEntities(value) {
        if (value.length <= 0) {
            parameterMissing = true;
        }
    }

    // Handle array values
    function handleArrayValues(key, value) {
        let list;

        switch (key) {
            case 'categories_ids':
                list = categories;
                break;
            case 'site_ids':
                list = domains;
                break;
            case 'filters_ids':
                list = filters;
                break;
            case 'lang':
                list = languages;
                break;
            default:
                list = users;
        }

        if (key === 'lang') {
            const langValues = value.filter((val) => {
                if (list.length > 0) {
                    const exists = list.find((obj) => {
                        if (key === 'lang') {
                            return obj.iso === val;
                        } else {
                            return obj.id === val;
                        }
                    });

                    if (!exists) {
                        parameterMissing = true;
                        return false; // Exclude the value from the filtered array
                    }

                    return true; // Include the value in the filtered array
                }
            });

            if (langValues.length > 0) {
                search[key] = langValues;
            }

            search[key] = value;
        }

        if (value.length === 0) {
            delete search[key];
        }
    }
}
