import React, { useEffect, useState } from 'react';
import { Grid } from 'styles/components';
import PropTypes from 'prop-types';
import { EmailsActions } from 'core/actions';
import { connect } from 'react-redux';
import { Loader } from 'components';
import { useHistory } from 'react-router-dom';

function EmailRedirectPage(props) {
    const { isLoading, isLoaded, convertEmailHash, redirectUrl } = props;
    const [hash, setHash] = useState(false);
    const history = useHistory();

    useEffect(() => {
        document.title = 'Debunk EU - Please wait. Loading…';
        const h = history.location.pathname.split('/').pop();
        setHash(h);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        hash && convertEmailHash(hash);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hash]);

    useEffect(() => {
        if (hash && !!isLoaded) {
            if (redirectUrl.includes('http')) {
                const link = document.createElement('a');
                link.href = redirectUrl;
                link.rel = 'noopener noreferrer';
                link.click();
            }
            history.push(redirectUrl.replace('/', '/search'));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [redirectUrl]);

    return (
        <>
            <Grid.Row mt={100} justifyContent="center">
                {isLoading && <Loader />}
            </Grid.Row>
        </>
    );
}

EmailRedirectPage.propTypes = {
    isLoading: PropTypes.bool.isRequired,
    isLoaded: PropTypes.bool.isRequired,
    convertEmailHash: PropTypes.func.isRequired,
    redirectUrl: PropTypes.string,
};

function mapStateToProps(state) {
    const { emails } = state;
    const { isLoading, isLoaded, redirectUrl } = emails;
    return { isLoading, isLoaded, redirectUrl };
}
const mapDispatchToProps = {
    convertEmailHash: EmailsActions.convertEmailHash,
};

export default connect(mapStateToProps, mapDispatchToProps)(EmailRedirectPage);
