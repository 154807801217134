import React, { useEffect, useState } from 'react';
import { Container, Section, Title, SmallText, Label, Icon, Name } from 'pages/User/styles';
import { Grid } from 'styles/components';
import { EYE_SHOW, EYE_HIDE } from 'styles/constant/images';
import {
    Button,
    ExpandedSelect,
    Input,
    MultiSelect,
    SingleSelect,
    Checkbox,
    Loader,
    Modal,
    DatePicker,
} from 'components';
import { Buttons } from 'styles/constant';
import PropTypes from 'prop-types';
import { SharedTypes } from 'utils';
import { UsersActions, AuthActions, LanguagesActions, ParametersActions } from 'core/actions';
import { connect } from 'react-redux';
import update from 'immutability-helper';
import { INITIAL_WEIGHTS, INITIAL_STATE, ROLES } from './config';
import moment from 'moment';

const DATE_NOW = new Date(8640000000000000);

function UserForm(props) {
    const {
        users,
        organisations,
        createUser,
        history,
        updateUser,
        isUpdated,
        isUpdating,
        user,
        getUser,
        isUserLoaded,
        getLanguages,
        getParameters,
        getProfile,
        profile,
    } = props;
    const [currentUser, setUser] = useState(INITIAL_STATE);
    const [changePassword, setChangePassword] = useState(false);
    const [passwordType, setPasswordType] = useState('password');
    const [passwordTypeModal, setPasswordTypeModal] = useState('password');
    const [weightDefault, setWeightDefault] = useState(INITIAL_WEIGHTS);
    const [modalVisible, setModalVisible] = useState(false);
    const [availableLanguages, setAvailableLanguages] = useState([]);

    useEffect(() => {
        if (isUpdated) {
            if (currentUser && currentUser.id && currentUser.id === profile.id) {
                getProfile();
                getLanguages();
                getParameters();
            }
            currentUser.password ? setModalVisible(true) : history.push('/users');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isUpdated]);

    useEffect(() => {
        const id = history.location.pathname.split('/').pop();
        id !== 'new' ? getUser(id) : getUser();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        user.organisation &&
            organisations.length > 0 &&
            onLanguageListChange(user.organisation.id ? user.organisation.id : user.organisation);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [organisations, user]);

    useEffect(() => {
        document.title = user.id ? `Debunk EU - Edit User - ${user.username}` : 'Create New User';
        if (user.id) {
            const current = user;
            const weights = { ...INITIAL_WEIGHTS };
            if (user.user_languages) {
                current.languages = user.user_languages.map((l) => l.language.id);
                delete current['user_languages'];
            }
            if (user.expires_at) {
                current.expires_at = new Date(user.expires_at);
            }
            Object.entries(user).map((entry) => {
                const [key] = entry;
                if (key.includes('weight')) {
                    weights[key] = false;
                }
            });
            setWeightDefault(weights);
            setUser(current);
        } else {
            generatePass();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);

    const generatePass = () => {
        let string = '';
        const characters =
            'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%^&*()_+~`|}{[]:;?><,./-=\\';
        const charactersLength = characters.length;

        for (let i = 0; i < 15; i++) {
            string += characters.charAt(Math.floor(Math.random() * charactersLength));
        }

        if (/[0-9]+/.test(string) && /[A-z]+/.test(string) && /[\W]+/.test(string) && /.{15,}$/.test(string)) {
            setUser(
                update(currentUser, {
                    ['password']: { $set: string },
                }),
            );
        } else {
            generatePass();
        }
    };

    const onDefaultChange = (e) => {
        const { name, value } = e;
        setWeightDefault(
            update(weightDefault, {
                [name]: { $set: value },
            }),
        );
    };

    const onChange = (e) => {
        const { name, value } = e;
        const values = [];

        if (name === 'organisation') onLanguageListChange(value);
        else
            setUser(
                update(currentUser, {
                    [name]: {
                        $set: values.length > 0 ? values.filter((v, i, a) => a.indexOf(v) === i).join(',') : value,
                    },
                }),
            );
    };

    const onLanguageListChange = (id) => {
        const langs = organisations
            .find((organisation) => organisation.id === id)
            .languages.map((l) => {
                return { label: l.name, value: l.id };
            });
        const { languages } = currentUser;
        const validLanguages = [];
        languages.map((l) => {
            if (langs.find((lang) => lang.value === l)) validLanguages.push(l);
            return l;
        });
        setUser(
            update(currentUser, {
                languages: { $set: validLanguages },
                organisation: { $set: id },
            }),
        );
        setAvailableLanguages(langs);
    };

    const onBlur = (e) => {
        const { name } = e;
        const value = currentUser[name];

        setUser(
            update(currentUser, {
                [name]: { $set: value.replace(/[^0-9]+?$/, '') },
            }),
        );
    };

    const onSubmit = () => {
        const params = { ...currentUser };

        params.languages = params.languages.map((l) => {
            return { id: l, translate_to: null };
        });

        if (params.expires_at != null) {
            params.expires_at = moment(params.expires_at).format('YYYY-MM-DD');
        }

        if (weightDefault.user_vote_weight) params['user_vote_weight'] = null;
        if (weightDefault.repost_weight) params['repost_weight'] = null;
        if (weightDefault.alexa_weight) params['alexa_weight'] = null;
        if (weightDefault.facebook_comment_weight) params['facebook_comment_weight'] = null;
        if (weightDefault.facebook_share_weight) params['facebook_share_weight'] = null;
        if (weightDefault.facebook_reaction_weight) params['facebook_reaction_weight'] = null;
        if (weightDefault.view_weight) params['view_weight'] = null;
        if (currentUser.id && !changePassword) delete params['password'];

        if (params.password) {
            const { password } = params;
            if (
                !(
                    /[0-9]+/.test(password) &&
                    /[A-z]+/.test(password) &&
                    /[\W]+/.test(password) &&
                    /.{15,}$/.test(password)
                )
            ) {
                alert(
                    'The password must be at least 15 characters long and should contain at least 1 number and 1 special character',
                );
                return;
            }
        }

        if (!currentUser.id) {
            createUser(params);
        } else {
            if (currentUser.organisation && currentUser.organisation.id)
                params.organisation = currentUser.organisation.id;
            updateUser(currentUser.id, params);
        }
    };

    const renderModalContent = () => {
        const password = currentUser.password ? currentUser.password.split('') : [];
        return (
            <>
                <Grid.Row mb={15} justifyContent="center">
                    <Title>{currentUser.username}</Title>
                </Grid.Row>
                <Grid.Row justifyContent="center">
                    <Label>{currentUser.user_email}</Label>
                </Grid.Row>
                <Grid.Row justifyContent="center" style={{ width: 170, margin: '20px auto', paddingLeft: 20 }}>
                    <Grid.Col flex={1}>
                        <Label>{passwordTypeModal === 'password' ? password.map(() => '*').join('') : password}</Label>
                    </Grid.Col>
                    <Grid.Col
                        onClick={() => setPasswordTypeModal(passwordTypeModal === 'password' ? 'text' : 'password')}
                    >
                        {passwordTypeModal === 'password' ? (
                            <Icon src={EYE_HIDE} style={{ margin: 0 }} />
                        ) : (
                            <Icon src={EYE_SHOW} style={{ margin: 0 }} />
                        )}
                    </Grid.Col>
                </Grid.Row>
                <Grid.Row justifyContent="center">
                    <Button onClick={copyInfo} label="Copy all to clipboard" />
                </Grid.Row>
            </>
        );
    };

    const copyInfo = () => {
        const baseUrl = window.location.host;
        const { username, password } = currentUser;

        navigator.clipboard.writeText(
            `Access link: ${baseUrl.replace('https://', '')}\r\nUsername: ${username}\r\nPassword: ${password}`,
        );
    };

    return (
        <>
            <Modal
                title="User created"
                renderContent={renderModalContent}
                onClose={() => {
                    setModalVisible(false);
                    history.push('/users');
                }}
                visible={modalVisible}
                renderFooter={() => {}}
            />
            {!isUserLoaded ? (
                <Grid.Row mt={30} justifyContent="center">
                    <Loader />
                </Grid.Row>
            ) : (
                <>
                    <Grid.FixedContainer sticky style={{ maxWidth: 'none' }}>
                        <Grid.FixedContainer>
                            <Grid.Row justifyContent="space-between" pt={20} pb={20}>
                                <Grid.Col>
                                    <Title>
                                        {currentUser.id ? 'Edit ' : 'Create new '}user{' '}
                                        <Name>{currentUser.id && currentUser.username}</Name>
                                    </Title>
                                </Grid.Col>
                                <Grid.Col>
                                    <Grid.Row>
                                        <Grid.Col mr={10}>
                                            <Button
                                                label="Cancel"
                                                type={Buttons.TERTIARY}
                                                onClick={() => history.push('/users')}
                                            />
                                        </Grid.Col>
                                        <Grid.Col>
                                            <Button
                                                disabled={
                                                    !currentUser.languages ||
                                                    (currentUser.languages && currentUser.languages.length === 0) ||
                                                    !currentUser.user_email ||
                                                    !currentUser.username ||
                                                    !currentUser.organisation
                                                }
                                                label="Submit"
                                                loading={isUpdating}
                                                type={Buttons.PRIMARY}
                                                onClick={onSubmit}
                                            />
                                        </Grid.Col>
                                    </Grid.Row>
                                </Grid.Col>
                            </Grid.Row>
                        </Grid.FixedContainer>
                    </Grid.FixedContainer>
                    <Container>
                        <Grid.FixedContainer>
                            <Section>
                                <Grid.Row mb={20} alignItems="flex-start">
                                    <Grid.Col flex={1} mr={15}>
                                        <Input
                                            disabled={!!currentUser.id}
                                            autoComplete="off"
                                            label="Username"
                                            name="username"
                                            value={currentUser.username}
                                            onChange={(e) => onChange(e)}
                                        />
                                    </Grid.Col>
                                    <Grid.Col flex={1}>
                                        <Grid.Row mb={10}>
                                            <Label>Password</Label>
                                        </Grid.Row>
                                        <Grid.Row alignItems="flex-start">
                                            {currentUser.id && (
                                                <Grid.Col mr={10}>
                                                    <Button
                                                        style={{ width: 80 }}
                                                        type={changePassword ? 'SECONDARY' : 'PRIMARY'}
                                                        onClick={() => setChangePassword(!changePassword)}
                                                        label={changePassword ? 'Cancel' : 'Change'}
                                                    />
                                                </Grid.Col>
                                            )}
                                            {(!currentUser.id || changePassword) && (
                                                <Grid.Col>
                                                    <Grid.Row>
                                                        <Grid.Col flex={1}>
                                                            <Input
                                                                autoComplete="new-password"
                                                                label={''}
                                                                type={passwordType}
                                                                name="password"
                                                                disabled={currentUser.id && !changePassword}
                                                                value={currentUser.password}
                                                                onChange={(e) => onChange(e)}
                                                            />
                                                        </Grid.Col>
                                                        <Grid.Col
                                                            onClick={() =>
                                                                setPasswordType(
                                                                    passwordType === 'password' ? 'text' : 'password',
                                                                )
                                                            }
                                                        >
                                                            {passwordType === 'password' ? (
                                                                <Icon src={EYE_HIDE} />
                                                            ) : (
                                                                <Icon src={EYE_SHOW} />
                                                            )}
                                                        </Grid.Col>
                                                    </Grid.Row>
                                                    <Grid.Row justifyContent="flex-end" mt={5}>
                                                        <SmallText>
                                                            The password must be at least 15 characters long and should
                                                            contain at least 1 number and 1 special character
                                                        </SmallText>
                                                    </Grid.Row>
                                                </Grid.Col>
                                            )}
                                        </Grid.Row>
                                    </Grid.Col>
                                </Grid.Row>
                                <Grid.Row mb={20}>
                                    <Grid.Col flex={1} mr={15}>
                                        <Input
                                            label="Newsletter email"
                                            name="email"
                                            value={currentUser.email}
                                            onChange={(e) => onChange(e)}
                                        />
                                    </Grid.Col>
                                    <Grid.Col flex={1}>
                                        <Input
                                            label="User email"
                                            name="user_email"
                                            value={currentUser.user_email}
                                            autoComplete={currentUser.user_email}
                                            onChange={(e) => onChange(e)}
                                        />
                                    </Grid.Col>
                                </Grid.Row>
                                <Grid.Row mb={20}>
                                    <Grid.Col flex={1} mr={15}>
                                        <SingleSelect
                                            label="Organisation"
                                            options={
                                                organisations
                                                    ? organisations.map((item) => {
                                                          return { label: item.name, value: item.id };
                                                      })
                                                    : []
                                            }
                                            name="organisation"
                                            value={
                                                currentUser && currentUser.organisation
                                                    ? currentUser.organisation.id
                                                        ? currentUser.organisation.id
                                                        : currentUser.organisation
                                                        ? currentUser.organisation
                                                        : null
                                                    : null
                                            }
                                            onChange={(e) => onChange(e)}
                                        />
                                    </Grid.Col>
                                    <Grid.Col flex={1}>
                                        <SingleSelect
                                            label="Role"
                                            options={ROLES}
                                            name="role"
                                            value={currentUser.role}
                                            onChange={(e) => onChange(e)}
                                        />
                                    </Grid.Col>
                                </Grid.Row>
                                <Grid.Row mb={20}>
                                    <Grid.Col flex={2} mr={15}>
                                        <DatePicker
                                            label="Expires at"
                                            value={currentUser.expires_at}
                                            onChange={(e) => onChange(e)}
                                            name="expires_at"
                                            selectsRange={false}
                                            maxDate={DATE_NOW}
                                            userForm={true}
                                        />
                                    </Grid.Col>
                                    <Grid.Col mt={30} flex={1}>
                                        <Checkbox
                                            name="agreement_signed"
                                            label="Agreement signed"
                                            checked={currentUser.agreement_signed}
                                            onChange={(e) => onChange(e)}
                                        />
                                    </Grid.Col>
                                    <Grid.Col mt={30} flex={1}>
                                        <Checkbox
                                            name="can_override_filters"
                                            label="Allowed to ignore limitation by filters"
                                            checked={currentUser.can_override_filters}
                                            onChange={(e) => onChange(e)}
                                        />
                                    </Grid.Col>
                                </Grid.Row>
                                <Grid.Row mb={10}>
                                    <Grid.Col flex={1}>
                                        <MultiSelect
                                            forceOpen
                                            options={availableLanguages}
                                            label={
                                                currentUser.organisation
                                                    ? 'Languages'
                                                    : 'Languages (please select organisation to see available languages)'
                                            }
                                            name="languages"
                                            value={
                                                currentUser.languages
                                                    ? currentUser.languages.map((l) => (l.id ? l.id : l))
                                                    : []
                                            }
                                            onChange={(e) => onChange(e)}
                                        />
                                    </Grid.Col>
                                    {!currentUser.id && (
                                        <Grid.Col flex={1} ml={20}>
                                            <ExpandedSelect
                                                large
                                                options={users
                                                    .sort((a, b) => a.username.localeCompare(b.username))
                                                    .map((u) => {
                                                        return { label: u.username, value: u.id };
                                                    })}
                                                label="Assign filters and domains of the following user"
                                                name="like_user"
                                                value={currentUser.like_user}
                                                onChange={(e) => onChange(e)}
                                                userForm={true}
                                            />
                                        </Grid.Col>
                                    )}
                                </Grid.Row>
                                <Grid.Row mb={20}>
                                    <Grid.Col flex={1} mr={15}>
                                        <Grid.Row alignItems="flex-end">
                                            <Grid.Col mr={10} flex={1}>
                                                <Input
                                                    type="number"
                                                    label="Vote weight"
                                                    name="user_vote_weight"
                                                    value={currentUser.user_vote_weight}
                                                    disabled={weightDefault.user_vote_weight}
                                                    onBlur={(e) => onBlur(e)}
                                                    onChange={(e) => onChange(e)}
                                                />
                                            </Grid.Col>
                                            <Grid.Col mb={10} flex={1}>
                                                <Checkbox
                                                    name="user_vote_weight"
                                                    label="Use default"
                                                    checked={weightDefault.user_vote_weight}
                                                    onChange={onDefaultChange}
                                                />
                                            </Grid.Col>
                                        </Grid.Row>
                                    </Grid.Col>
                                    <Grid.Col flex={1}>
                                        <Grid.Row alignItems="flex-end">
                                            <Grid.Col mr={10} flex={1}>
                                                <Input
                                                    type="number"
                                                    label="Repost weight"
                                                    name="repost_weight"
                                                    value={currentUser.repost_weight}
                                                    disabled={weightDefault.repost_weight}
                                                    onBlur={(e) => onBlur(e)}
                                                    onChange={(e) => onChange(e)}
                                                />
                                            </Grid.Col>
                                            <Grid.Col mb={10} flex={1}>
                                                <Checkbox
                                                    name="repost_weight"
                                                    label="Use default"
                                                    checked={weightDefault.repost_weight}
                                                    onChange={onDefaultChange}
                                                />
                                            </Grid.Col>
                                        </Grid.Row>
                                    </Grid.Col>
                                </Grid.Row>
                                <Grid.Row mb={20}>
                                    <Grid.Col flex={1} mr={15}>
                                        <Grid.Row alignItems="flex-end">
                                            <Grid.Col mr={10} flex={1}>
                                                <Input
                                                    type="number"
                                                    label="Alexa weight"
                                                    name="alexa_weight"
                                                    value={currentUser.alexa_weight}
                                                    disabled={weightDefault.alexa_weight}
                                                    onBlur={(e) => onBlur(e)}
                                                    onChange={(e) => onChange(e)}
                                                />
                                            </Grid.Col>
                                            <Grid.Col mb={10} flex={1}>
                                                <Checkbox
                                                    name="alexa_weight"
                                                    label="Use default"
                                                    checked={weightDefault.alexa_weight}
                                                    onChange={onDefaultChange}
                                                />
                                            </Grid.Col>
                                        </Grid.Row>
                                    </Grid.Col>
                                    <Grid.Col flex={1}>
                                        <Grid.Row alignItems="flex-end">
                                            <Grid.Col mr={10} flex={1}>
                                                <Input
                                                    type="number"
                                                    label="FB comment weight"
                                                    name="facebook_comment_weight"
                                                    value={currentUser.facebook_comment_weight}
                                                    disabled={weightDefault.facebook_comment_weight}
                                                    onBlur={(e) => onBlur(e)}
                                                    onChange={(e) => onChange(e)}
                                                />
                                            </Grid.Col>
                                            <Grid.Col mb={10} flex={1}>
                                                <Checkbox
                                                    name="facebook_comment_weight"
                                                    label="Use default"
                                                    checked={weightDefault.facebook_comment_weight}
                                                    onChange={onDefaultChange}
                                                />
                                            </Grid.Col>
                                        </Grid.Row>
                                    </Grid.Col>
                                </Grid.Row>
                                <Grid.Row mb={20}>
                                    <Grid.Col flex={1} mr={15}>
                                        <Grid.Row alignItems="flex-end">
                                            <Grid.Col mr={10} flex={1}>
                                                <Input
                                                    type="number"
                                                    label="View weight"
                                                    name="view_weight"
                                                    value={currentUser.view_weight}
                                                    disabled={weightDefault.view_weight}
                                                    onBlur={(e) => onBlur(e)}
                                                    onChange={(e) => onChange(e)}
                                                />
                                            </Grid.Col>
                                            <Grid.Col mb={10} flex={1}>
                                                <Checkbox
                                                    name="view_weight"
                                                    label="Use default"
                                                    checked={weightDefault.view_weight}
                                                    onChange={onDefaultChange}
                                                />
                                            </Grid.Col>
                                        </Grid.Row>
                                    </Grid.Col>
                                    <Grid.Col flex={1}>
                                        <Grid.Row alignItems="flex-end">
                                            <Grid.Col mr={10} flex={1}>
                                                <Input
                                                    type="number"
                                                    label="FB reaction weight"
                                                    name="facebook_reaction_weight"
                                                    value={currentUser.facebook_reaction_weight}
                                                    disabled={weightDefault.facebook_reaction_weight}
                                                    onBlur={(e) => onBlur(e)}
                                                    onChange={(e) => onChange(e)}
                                                />
                                            </Grid.Col>
                                            <Grid.Col mb={10} flex={1}>
                                                <Checkbox
                                                    name="facebook_reaction_weight"
                                                    label="Use default"
                                                    checked={weightDefault.facebook_reaction_weight}
                                                    onChange={onDefaultChange}
                                                />
                                            </Grid.Col>
                                        </Grid.Row>
                                    </Grid.Col>
                                </Grid.Row>

                                <Grid.Row mb={20}>
                                    <Grid.Col flex={1} mr={15} />
                                    <Grid.Col flex={1}>
                                        <Grid.Row alignItems="flex-end">
                                            <Grid.Col mr={10} flex={1}>
                                                <Input
                                                    type="number"
                                                    label="FB share weight"
                                                    name="facebook_share_weight"
                                                    value={currentUser.facebook_share_weight}
                                                    disabled={weightDefault.facebook_share_weight}
                                                    onBlur={(e) => onBlur(e)}
                                                    onChange={(e) => onChange(e)}
                                                />
                                            </Grid.Col>
                                            <Grid.Col mb={10} flex={1}>
                                                <Checkbox
                                                    name="facebook_share_weight"
                                                    label="Use default"
                                                    checked={weightDefault.facebook_share_weight}
                                                    onChange={onDefaultChange}
                                                />
                                            </Grid.Col>
                                        </Grid.Row>
                                    </Grid.Col>
                                </Grid.Row>
                            </Section>
                        </Grid.FixedContainer>
                    </Container>
                </>
            )}
        </>
    );
}

UserForm.propTypes = {
    users: PropTypes.arrayOf(SharedTypes.UsersListItemType).isRequired,
    user: PropTypes.object,
    history: PropTypes.object.isRequired,
    profile: PropTypes.object.isRequired,
    organisations: PropTypes.arrayOf(PropTypes.object),
    getUser: PropTypes.func.isRequired,
    createUser: PropTypes.func.isRequired,
    updateUser: PropTypes.func.isRequired,
    getProfile: PropTypes.func.isRequired,
    getLanguages: PropTypes.func.isRequired,
    getParameters: PropTypes.func.isRequired,
    isUpdated: PropTypes.bool,
    isUpdating: PropTypes.bool,
    languages: PropTypes.array,
    isUserLoaded: PropTypes.bool,
    isUserLoading: PropTypes.bool,
    maxDate: PropTypes.func,
};

function mapStateToProps(state) {
    const { users, languages, auth } = state;
    const { isUpdated, isUpdating, user, isUserLoading, isUserLoaded } = users;
    return {
        isUpdated,
        isUpdating,
        languages: languages.languages,
        user,
        users: users.users,
        isUserLoaded,
        isUserLoading,
        profile: auth.profile,
    };
}

const mapDispatchToProps = {
    getUser: UsersActions.getUser,
    createUser: UsersActions.createUser,
    updateUser: UsersActions.updateUser,
    getProfile: AuthActions.getProfile,
    getLanguages: LanguagesActions.getLanguages,
    getParameters: ParametersActions.getParameters,
};

export default connect(mapStateToProps, mapDispatchToProps)(UserForm);
