import React from 'react';
import { DateTimeFormatter } from 'components/Table/ColumnFormatter';
import { TableCell, TableRow } from 'components/Table/styles';
import { Colors } from '../../../styles/constant';
import { Grid } from '../../../styles/components';
import { EDIT, REMOVE, SEARCH_WHITE } from 'styles/constant/images';
import { RTLLanguages } from 'constant';
import { Icon } from 'components';
import { SearchIcon } from '../styles';

export default function renderRow(filters, row, visibleColumns, onLanguageDelete, hasCreateFiltersPermission, history) {
    const filter = filters.find((f) => f.id == row.original.id);

    if (filter && filter.lang && filter.lang.length > 0 && visibleColumns.length > 0) {
        const rows = filter.lang.map((row, index) => {
            let translated = row.query && row.query.length > 0;
            let query = 'Not translated';

            if (translated) {
                query = '';
                try {
                    JSON.parse(row.query, (key, value) => {
                        if (key === 'value') query += value + ', ';
                    });
                } catch (err) {
                    query = row.query;
                }
            }

            if (query[query.length - 2] === ',') query = query.slice(0, query.length - 2);

            if (query.length > 150) {
                query = query.slice(0, 200) + '...';
            }

            const bindColumns = visibleColumns
                .filter(
                    (column) =>
                        column.id === 'Languages' ||
                        column.id === 'Name' ||
                        column.id === 'Category' ||
                        column.id === 'Status' ||
                        column.id === 'Vote' ||
                        column.id === 'Create date' ||
                        column.id === 'Created by' ||
                        column.id === 'Update date' ||
                        column.id === 'Updated by' ||
                        column.id === 'Type' ||
                        column.id === 'Actions',
                )
                .map((c) => c.id);

            const language = row.lang.iso;

            const createdAt = DateTimeFormatter(row.created_at);

            const updatedAt = DateTimeFormatter(row.updated_at);

            return (
                <TableRow key={index}>
                    {bindColumns.includes('Languages') && (
                        <TableCell bold key={1} color={translated ? Colors.BLACK : Colors.PRIMARY}>
                            {language.toUpperCase()}
                        </TableCell>
                    )}
                    {visibleColumns.length - bindColumns.length > 0 && (
                        <TableCell
                            colSpan={visibleColumns.length - bindColumns.length}
                            color={translated ? Colors.BLACK : Colors.SECONDARY}
                            key={2}
                        >
                            <p dir={RTLLanguages.includes(language) ? 'rtl' : 'ltr'}>{query}</p>
                        </TableCell>
                    )}
                    {bindColumns.includes('Name') && <TableCell key={3}>{query ? query : '-'}</TableCell>}
                    {bindColumns.includes('Category') && (
                        <TableCell key={4}>{row.category ? row.category : '-'}</TableCell>
                    )}
                    {bindColumns.includes('Status') && <TableCell key={5}>{row.status ? row.status : '-'}</TableCell>}
                    {bindColumns.includes('Vote') && <TableCell key={6}>{row.vote ? row.vote : '-'}</TableCell>}
                    {bindColumns.includes('Create date') && (
                        <TableCell key={7}>
                            {createdAt.date} <br />
                            {createdAt.time} <br />
                        </TableCell>
                    )}
                    {bindColumns.includes('Created by') && (
                        <TableCell key={8}>{row.created_by ? row.created_by.username : '-'}</TableCell>
                    )}
                    {bindColumns.includes('Update date') && (
                        <TableCell key={9}>
                            {updatedAt.date} <br />
                            {updatedAt.time} <br />
                        </TableCell>
                    )}
                    {bindColumns.includes('Updated by') && (
                        <TableCell key={10}>{row.updated_by ? row.updated_by.username : '-'}</TableCell>
                    )}
                    {bindColumns.includes('Type') && <TableCell key={11}>{row.primary ? row.primary : '-'}</TableCell>}
                    {bindColumns.includes('Actions') && (
                        <TableCell key={12}>
                            <Grid.Row>
                                <SearchIcon source={SEARCH_WHITE} disabled={!hasCreateFiltersPermission} />
                                <Icon
                                    gray
                                    source={EDIT}
                                    onClick={() => history.push(`/filter/${filter.id}?language=${row.lang.iso}`)}
                                    disabled={!hasCreateFiltersPermission}
                                />
                                <Icon
                                    gray
                                    source={REMOVE}
                                    onClick={() => onLanguageDelete(filter, row)}
                                    iconSmall
                                    disabled={!hasCreateFiltersPermission}
                                />
                            </Grid.Row>
                        </TableCell>
                    )}
                </TableRow>
            );
        });

        return <>{visibleColumns.length > 0 && rows}</>;
    }
}
