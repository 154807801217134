import React from 'react';
import { DateTimeFormatter } from 'components/Table/ColumnFormatter';
import { UserStatus } from './styles';

export const HIDDEN_COLUMNS = ['Create date', 'Created by', 'Assigned to Users'];

export const COLUMNS = [
    {
        Header: 'Languages',
    },
    {
        Header: 'Name',
        accessor: (row) => row.name || '-',
        filter: 'search',
    },
    {
        Header: 'Category',
        accessor: (row) => (row.category ? row.category.name : '-'),
        filter: 'multiple',
    },
    {
        Header: 'Status',
        accessor: (row) => (row.status ? 'Available' : 'Unavailable'),
        filter: 'multiple',
    },
    {
        Header: 'Vote',
        accessor: (row) => row.vote || '0',
        filter: 'multiple',
    },
    {
        Header: 'Assigned to Users',
        accessor: (row) => row.user_filters,
        Cell: ({ value }) => {
            if (value) {
                const user_filters = value;
                const users = user_filters.map((item, index) => {
                    return (
                        <UserStatus key={index} active={item.user.active}>
                            {item.user.username}
                            &nbsp;
                        </UserStatus>
                    );
                });
                return users;
            }
            return '';
        },
        filter: 'assigned_users',
    },
    {
        Header: 'Create date',
        accessor: (row) => {
            return DateTimeFormatter(row.created_at).format();
        },
        filter: 'search',
    },
    {
        Header: 'Created by',
        accessor: (row) => (row.created_by ? row.created_by.username : '-'),
        filter: 'search',
    },
    {
        Header: 'Update date',
        accessor: (row) => {
            return DateTimeFormatter(row.updated_at).format();
        },
        filter: 'search',
    },
    {
        Header: 'Updated by',
        accessor: (row) => (row.updated_by ? row.updated_by.username : '-'),
        filter: 'search',
    },
    {
        Header: 'Type',
        accessor: (row) => (row.primary ? 'Primary' : 'Secondary'),
        filter: 'multiple',
    },
];
