import { useCallback } from 'react';
import _ from 'lodash'; // Make sure lodash is imported for _.isEqual and _.sortBy

const useHighlightComparison = () => {
    const compareHighlights = useCallback((obj1, obj2) => {
        if (
            obj1.id !== obj2.id ||
            obj1.highlightedText !== obj2.highlightedText ||
            (obj1.comment || '') !== (obj2.comment || '')
        ) {
            return false;
        }

        if (!_.isEqual(obj1.highlight, obj2.highlight)) {
            return false;
        }

        const compareParameterArrays = (arr1, arr2) => {
            if (arr1.length !== arr2.length) return false;
            const sortedArr1 = _.sortBy(arr1, ['parameter_id', 'value_id']);
            const sortedArr2 = _.sortBy(arr2, ['parameter_id', 'value_id']);

            for (let i = 0; i < sortedArr1.length; i++) {
                if (!_.isEqual(sortedArr1[i], sortedArr2[i])) {
                    return false;
                }
            }

            return true;
        };

        return (
            compareParameterArrays(obj1.s1, obj2.s1) &&
            compareParameterArrays(obj1.s2, obj2.s2) &&
            compareParameterArrays(obj1.s3, obj2.s3)
        );
    }, []);

    const compareHighlightsArrays = useCallback(
        (array1, array2) => {
            const sortedArray1 = array1.sort((a, b) => a.id.localeCompare(b.id));
            const sortedArray2 = array2.sort((a, b) => a.id.localeCompare(b.id));

            if (sortedArray1.length !== sortedArray2.length) {
                return false;
            }

            for (let i = 0; i < sortedArray1.length; i++) {
                if (!compareHighlights(sortedArray1[i], sortedArray2[i])) {
                    return false;
                }
            }

            return true;
        },
        [compareHighlights],
    );

    return { compareHighlights, compareHighlightsArrays };
};

export default useHighlightComparison;
