/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React from 'react';
import { Images } from 'styles/constant';
import { connect } from 'react-redux';
import { useDispatch } from 'react-redux';
import { LabellingHighlightActions } from 'core/actions';
import ParametersS2AutoComplete from './ParametersS2AutoComplete';
import './styles.scss';

const ParameterS2Select = (props) => {
    const { labellingHighlight } = props;
    const dispatch = useDispatch();

    const listItems = labellingHighlight.s2.map((value) => ({
        parameterId: value.parameter_id,
        parentName: value.name,
        valueName: value?.value, // Uses optional chaining to safely access 'value'
        valueId: value?.value_id, // Uses optional chaining to safely access 'id'
    }));

    const handleS2ParameterRemove = (itemToRemove) => {
        const { parameterId, valueId } = itemToRemove;

        // Filter out the item to be removed
        const updatedS2 = labellingHighlight.s2.filter(
            (item) => !(item.parameter_id === parameterId && item.value_id === valueId),
        );

        dispatch(
            LabellingHighlightActions.setLabellingHighlight({
                ...labellingHighlight,
                s2: updatedS2,
            }),
        );
    };

    return (
        <>
            <div>
                <ParametersS2AutoComplete />
            </div>
            <div className="parameter-container">
                {listItems.map((item, index) => (
                    <div key={index} className="parameter-item">
                        {item.parentName && <span className="title">{`${item.parentName} \u2192`}</span>}
                        <span className="label">{item.valueName}</span>
                        <img
                            src={Images.CLOSE_WHITE}
                            onClick={() => handleS2ParameterRemove(item)}
                            className="remove-button"
                        />
                    </div>
                ))}
            </div>
        </>
    );
};

function mapStateToProps(state) {
    const { labellingHighlight, article, highlights } = state;
    return {
        labellingHighlight: labellingHighlight,
        article: article.article,
        highlights: highlights,
    };
}

export default connect(mapStateToProps)(ParameterS2Select);
