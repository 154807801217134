/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable react/display-name */
import React, { useState, useEffect } from 'react';
import Draggable from 'react-draggable';
import ParameterS1MultiSelect from './components/ParameterS1MultiSelect';
import ParameterS2Select from './components/ParameterS2Select';
import ParameterS3Select from './components/ParameterS3Select';
import { Tab, Tabs } from './components/LabelingTabs';
import { Images } from 'styles/constant';
import { Loader } from 'components';
import { FaEraser } from 'react-icons/fa';
import { connect } from 'react-redux';
import { LabellingHighlightActions } from 'core/actions';
import { useDispatch } from 'react-redux';
import './styles.scss';

const LabelHighlightsModal = React.forwardRef((props, ref) => {
    const { onClose, width, isLoading, labellingHighlight, highlights } = props;
    const dispatch = useDispatch();
    const modalStyle = {
        width: width,
        maxWidth: '100%', // Ensure modal does not exceed viewport width
        border: '1px solid #ccc',
        background: 'white',
        padding: '10px 15px 15px 15px',
        boxShadow: '0 0 10px rgba(0, 0, 0, 0.5)',
        position: 'absolute',
        top: '50%',
        left: '-400px',
        right: 0,
        marginLeft: 'auto',
        marginRight: 'auto',
        transform: 'translateY(-50%)', // Adjust for vertical centering only
        boxSizing: 'border-box', // Include padding and border in the width
        zIndex: 1000,
    };

    // Local state to hold the textarea value temporarily
    const [temporaryComment, setTemporaryComment] = useState(labellingHighlight.comment);

    // useEffect hook to update temporaryComment when labellingHighlight.comment changes
    useEffect(() => {
        setTemporaryComment(labellingHighlight.comment);
    }, [labellingHighlight.comment]);

    function addValueToHighlights(highlights, parameterId, id) {
        // First, find the parameter once and reuse it
        const param = highlights.parameters.s1.find((i) => i.id === parameterId);

        // Initialize an object to hold the added value information
        let valueToAdd = null;

        // Check if param is defined to avoid runtime errors
        if (param) {
            // Then, find the specific value within this parameter
            const paramVal = param.values.find((z) => z.id === id);

            // Ensure paramVal is found before trying to access its properties
            if (paramVal) {
                // Value is not selected, construct the object to add
                valueToAdd = {
                    parameter_id: parameterId,
                    name: param.name, // Reuse the found parameter's name
                    type: 'S1',
                    value_id: id,
                    value: paramVal.value, // Reuse the found value
                };
            } else {
                console.error('Parameter value not found for id:', id);
            }
        } else {
            console.error('Parameter not found for parameterId:', parameterId);
        }

        // Return the constructed object or null if not found/constructed
        return valueToAdd;
    }

    const handleS1parameterChange = (event) => {
        const { id, value, parameterId } = event;
        // Check if the value is already selected
        const isValueSelected = labellingHighlight.s1.some((item) => item.value_id === id);

        let updatedS1;
        if (isValueSelected) {
            // Value is already selected, remove it from the array
            updatedS1 = labellingHighlight.s1.filter((item) => !(item.value_id === id));
        } else {
            const valueToAdd = addValueToHighlights(highlights, parameterId, id);
            updatedS1 = [...labellingHighlight.s1, valueToAdd];
        }

        dispatch(
            LabellingHighlightActions.setLabellingHighlight({
                ...labellingHighlight,
                s1: updatedS1,
            }),
        );
    };

    // Handle temporary state change
    const handleTextChange = (e) => {
        setTemporaryComment(e.target.value);
    };

    // Handle state update on blur (textarea loses focus)
    const handleBlur = () => {
        const updatedHighlight = {
            ...labellingHighlight,
            comment: temporaryComment,
        };
        dispatch(LabellingHighlightActions.setLabellingHighlight(updatedHighlight));
    };

    const handleClear = () => {
        const updatedHighlight = {
            ...labellingHighlight,
            comment: '',
        };
        dispatch(LabellingHighlightActions.setLabellingHighlight(updatedHighlight));
    };

    const s1ParametersTab = highlights.parameters.s1.map((parameter) => {
        const filteredS1Objects = labellingHighlight.s1.filter((item) => {
            return item.parameter_id === parameter.id;
        });

        const selectedValueIds = filteredS1Objects.map((i) => i.value_id);

        // Condition to check before rendering
        return (
            <ParameterS1MultiSelect
                key={parameter.id}
                parameter={parameter}
                selectedValueIds={selectedValueIds}
                title={parameter.name}
                onClick={handleS1parameterChange}
            />
        );
    });

    const s3ParametersTabs = highlights.parameters.s3.map((parameter, index) => (
        <ParameterS3Select key={`s3${index}`} title={parameter.name} parameter={parameter} />
    ));

    return (
        <Draggable axis="y" defaultPosition={{ x: 0, y: 0 }}>
            <div className="draggable-modal" style={modalStyle}>
                <div className="header">
                    <span className="title">
                        {isLoading && <span className="loader-span"> Loading parameters, please wait...</span>}

                        {isLoading && (
                            <span>
                                <Loader />
                            </span>
                        )}
                    </span>
                    <div>
                        <img
                            className="close-icon"
                            onClick={() => onClose(labellingHighlight.id)}
                            src={Images.CLOSE_GREY}
                        />
                    </div>
                </div>

                {!isLoading && (
                    <div className="draggable-modal-body">
                        <Tabs>
                            {s1ParametersTab}
                            <ParameterS2Select title="Custom Parameters" />
                            {s3ParametersTabs}
                            <Tab key="Comment" title="Comment">
                                <FaEraser className="clear-icon" onClick={handleClear} />
                                <textarea
                                    rows="4"
                                    cols="50"
                                    value={temporaryComment}
                                    onChange={handleTextChange} // Keep this for real-time local updates
                                    onBlur={handleBlur} // Update the main state on blur
                                    placeholder="Type something..."
                                />
                            </Tab>
                        </Tabs>
                    </div>
                )}
            </div>
        </Draggable>
    );
});

function mapStateToProps(state) {
    const { labellingHighlight, highlights } = state;
    return {
        labellingHighlight: labellingHighlight,
        highlights: highlights,
    };
}

// Use the options parameter of the connect function to enable forwarding refs
export default connect(mapStateToProps, null, null, { forwardRef: true })(LabelHighlightsModal);
