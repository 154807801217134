import React, { useState } from 'react';
import * as Styles from './styles';
import { AutoComplete, Button } from 'components';
import { Mapper, SharedTypes, TextUtils } from 'utils';
import { ParametersActions } from '../../core/actions';
import { Grid } from 'styles/components';
import { Buttons, Images } from 'styles/constant';
import PropTypes from 'prop-types';
import { ParametersService } from 'core/services';
import { useAddParameter } from 'hooks';
import { ParameterTypesList } from 'constant';
import { Icon } from '../../components/SelecedLabelingOption/styles';
import { connect } from 'react-redux';
import ParameterModal from '../ParameterModal';
import ParameterValueModal from '../ParameterValueModal';

function ParametersA2AutoComplete(props) {
    const {
        parameters,
        onParameterValueAdd,
        selectedParametersValues,
        disabled,
        updateParameter,
        updateParameterValue,
    } = props;
    const { addParameter, addParameterValue, isLoading } = useAddParameter();
    const [parametersModalVisible, setParametersModalVisible] = useState(false);
    const [parametersValueModalVisible, setParametersValueModalVisible] = useState(false);
    const [localParameter, setLocalParameter] = useState({ label: null, value: null });
    const [localParameterValue, setLocalParameterValue] = useState({ label: null, value: null });

    const handleAdd = () => {
        if (localParameter.value) {
            if (localParameterValue.value || (!localParameterValue.value && !localParameterValue.label)) {
                dispatchValue(localParameter.value, localParameterValue.value);
            }

            if (!localParameterValue.value && localParameterValue.label) {
                handleAddNewValue();
            }
        }

        if (!localParameter.value) {
            handleAddNewParameter();
        }
    };

    const handleAddNewValue = () => {
        const request = {
            parameter_id: localParameter.value,
            values: [{ value: TextUtils.trim(localParameterValue.label) }],
            type: ParameterTypesList.A2,
        };

        addParameterValue(request, (parameter) => {
            const parameterValue = ParametersService.getParameterValueByValue(parameter.id, request.values[0].value);
            dispatchValue(parameter.id, parameterValue.id);
        });
    };

    const handleAddNewParameter = () => {
        const request = {
            parameter: TextUtils.trim(localParameter.label),
            values: [{ value: TextUtils.trim(localParameterValue.label) }],
            type: ParameterTypesList.A2,
        };

        addParameter(request, (parameter) => {
            const parameterValue = ParametersService.getParameterValueByValue(parameter.id, request.values[0].value);
            dispatchValue(parameter.id, parameterValue ? parameterValue.id : null);
        });
    };

    const dispatchValue = (parameterId, parameterValueId) => {
        onParameterValueAdd({ id: parameterValueId, parameterId: parameterId, type: ParameterTypesList.A2 });
        setLocalParameter({ label: null, value: null });
        setLocalParameterValue({ label: null, value: null });
    };

    const handleParameterChange = (event) => {
        const { value } = event;
        setLocalParameter(value);
        setLocalParameterValue({ label: null, value: null });
    };

    const handleParameterValueChange = (event) => {
        const { value } = event;
        setLocalParameterValue(value);
    };

    const getParametersOptions = () => {
        return Mapper.mapParametersToOptions(parameters);
    };

    const getParameterValuesOptions = () => {
        if (!localParameter.value) return [];

        const selectedParameterValuesIds = selectedParametersValues
            .filter((parameterValue) => parameterValue.parameterId === localParameter.value)
            .map((parameterValue) => parameterValue.id);

        const parameter = ParametersService.getParameterById(localParameter.value);
        const values = parameter.values || [];
        const filteredParameterValues = values.filter(
            (parameterValue) => !selectedParameterValuesIds.includes(parameterValue.id),
        );
        return Mapper.mapParameterValuesToOptions(filteredParameterValues);
    };

    const isAlreadyExist = () => {
        return (
            !localParameterValue.value &&
            !localParameterValue.label &&
            !!selectedParametersValues.find(
                (parameterValue) => parameterValue.parameterId === localParameter.value && !parameterValue.id,
            )
        );
    };

    const clearParameterInput = () => {
        setLocalParameter({ label: null, value: null });
        setLocalParameterValue({ label: null, value: null });
    };

    const clearParameterValueInput = () => {
        setLocalParameterValue({ label: null, value: null });
    };

    const saveParameter = (data) => {
        updateParameter(data.id, data);
        handleParameterChange({ value: { label: data.parameter, value: localParameter.value } });
    };

    const saveParameterValue = (data) => {
        updateParameterValue(data.id, data, localParameter.value);
        handleParameterValueChange({ value: { label: data.value, value: localParameterValue.value } });
    };

    return (
        <Styles.Container>
            <Grid.Row mb={10}>
                <Styles.Label>Custom parameters</Styles.Label>
            </Grid.Row>
            <Grid.Row mb={5}>
                <AutoComplete
                    disabled={disabled}
                    value={localParameter}
                    onChange={handleParameterChange}
                    placeholder="Select or enter new"
                    name="A2 parameters"
                    options={getParametersOptions()}
                />
                {localParameter.label && (
                    <Grid.Col ml={-30} style={{ zIndex: 1 }}>
                        <Icon onClick={clearParameterInput} src={Images.CLOSE_GREY} />
                    </Grid.Col>
                )}
                {localParameter.label && (
                    <Grid.Col ml={-50} style={{ zIndex: 1 }}>
                        <Icon onClick={() => setParametersModalVisible(true)} src={Images.EDIT} />
                    </Grid.Col>
                )}
            </Grid.Row>
            <Grid.Row mb={10}>
                <AutoComplete
                    disabled={!localParameter.label || disabled}
                    value={localParameterValue}
                    onChange={handleParameterValueChange}
                    placeholder="Select or enter new"
                    name="A2 parameter values"
                    options={getParameterValuesOptions()}
                />
                {localParameterValue.label && (
                    <Grid.Col ml={-30} style={{ zIndex: 1 }}>
                        <Icon onClick={clearParameterValueInput} src={Images.CLOSE_GREY} />
                    </Grid.Col>
                )}
                {localParameterValue.label && (
                    <Grid.Col ml={-50} style={{ zIndex: 1 }}>
                        <Icon onClick={() => setParametersValueModalVisible(true)} src={Images.EDIT} />
                    </Grid.Col>
                )}
            </Grid.Row>
            <Grid.Row>
                <Button
                    disabled={!localParameter.label || isAlreadyExist()}
                    onClick={handleAdd}
                    loading={isLoading}
                    type={Buttons.SECONDARY}
                    label="Add to list"
                />
            </Grid.Row>
            <ParameterModal
                data={localParameter}
                updateParameter={saveParameter}
                visible={parametersModalVisible}
                onClose={() => setParametersModalVisible(false)}
            />
            <ParameterValueModal
                data={localParameterValue}
                updateParameter={saveParameterValue}
                visible={parametersValueModalVisible}
                onClose={() => setParametersValueModalVisible(false)}
            />
        </Styles.Container>
    );
}

ParametersA2AutoComplete.propTypes = {
    disabled: PropTypes.bool,
    parameters: PropTypes.arrayOf(SharedTypes.ParameterType).isRequired,
    onParameterValueAdd: PropTypes.func.isRequired,
    updateParameter: PropTypes.func.isRequired,
    updateParameterValue: PropTypes.func.isRequired,
    selectedParametersValues: PropTypes.arrayOf(SharedTypes.LabelingParameterValueType),
};

ParametersA2AutoComplete.defaultProps = {
    selectedParametersValues: [],
    disabled: false,
};

const mapDispatchToProps = {
    updateParameter: ParametersActions.updateParameter,
    updateParameterValue: ParametersActions.updateParameterValue,
};

export default connect(null, mapDispatchToProps)(ParametersA2AutoComplete);
