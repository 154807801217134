import update from 'immutability-helper';
import { MLTypes } from 'core/types';

const initialState = {
    availableTypes: [],
    isLoading: false,
    isLoaded: false,
};

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case MLTypes.GET_EXTRACTED_ENTITIES_START: {
            return update(state, {
                isLoading: { $set: true },
                isLoaded: { $set: false },
            });
        }

        case MLTypes.GET_EXTRACTED_ENTITIES: {
            return update(state, {
                isLoading: { $set: false },
                isLoaded: { $set: true },
                availableTypes: { $set: payload.availableTypes },
            });
        }
        case MLTypes.GET_EXTRACTED_ENTITIES_ERROR: {
            return update(state, {
                isLoading: { $set: false },
            });
        }

        default: {
            return state;
        }
    }
};
