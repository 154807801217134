import React from 'react';
import PropTypes from 'prop-types';
import { Radio } from 'components';
import { advancedExportType } from 'components/AdvancedExportModal/Types';

export function ExportTypeSelection(props) {
    const isExportTypeChecked = (type) => props.selectedExportType === type;

    const handleExportTypeChange = (event) => {
        props.onExportTypeChange(event);
    };

    return (
        <div className="export-type-selection">
            <div className="label">Type:</div>
            <div className="container">
                <div className="row">
                    <Radio
                        label="Simple"
                        checked={isExportTypeChecked(advancedExportType.SIMPLE)}
                        name={advancedExportType.SIMPLE}
                        onChange={handleExportTypeChange}
                        style={{ width: '174px' }}
                    />
                    <Radio
                        label="By Filters"
                        checked={isExportTypeChecked(advancedExportType.BY_FILTERS)}
                        name={advancedExportType.BY_FILTERS}
                        onChange={handleExportTypeChange}
                        style={{ width: '152px' }}
                    />
                    <Radio
                        label="By Labeling Parameters"
                        checked={isExportTypeChecked(advancedExportType.BY_LABELING_PARAMETERS)}
                        name={advancedExportType.BY_LABELING_PARAMETERS}
                        onChange={handleExportTypeChange}
                        style={{ width: '187px' }}
                    />
                    <Radio
                        label="By Single Parameter"
                        checked={isExportTypeChecked(advancedExportType.BY_SINGLE_PARAMETER)}
                        name={advancedExportType.BY_SINGLE_PARAMETER}
                        onChange={handleExportTypeChange}
                        style={{ width: '156px' }}
                    />
                </div>
                <div className="row">
                    <Radio
                        label="By ML Classifications"
                        checked={isExportTypeChecked(advancedExportType.BY_ML_CLASSIFICATIONS)}
                        name={advancedExportType.BY_ML_CLASSIFICATIONS}
                        onChange={handleExportTypeChange}
                        style={{ width: '174px' }}
                    />
                    <Radio
                        label="By Named Entities"
                        checked={isExportTypeChecked(advancedExportType.BY_NAMED_ENTITIES)}
                        name={advancedExportType.BY_NAMED_ENTITIES}
                        onChange={handleExportTypeChange}
                        style={{ width: '152px' }}
                    />
                    <Radio
                        label="By External Links"
                        checked={isExportTypeChecked(advancedExportType.BY_EXTERNAL_LINKS)}
                        name={advancedExportType.BY_EXTERNAL_LINKS}
                        onChange={handleExportTypeChange}
                        style={{ width: '187px' }}
                    />
                </div>
            </div>
        </div>
    );
}

ExportTypeSelection.propTypes = {
    selectedExportType: PropTypes.oneOf(Object.values(advancedExportType)).isRequired,
    onExportTypeChange: PropTypes.func.isRequired,
};
