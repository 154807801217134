import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Container, Content, DropdownContainer } from './styles';
import { Images } from 'styles/constant';
import { Grid, Form } from 'styles/components';
import Dropdown from '../Dropdown';
import { DateUtils, SharedTypes } from 'utils';
import RelativeDateSelect from './RelativeDateSelect';
import AbsoluteDateSelect from './AbsoluteDateSelect';

function DatePicker(props) {
    const { label, style, value, dirty, error, onChange, name, selectsRange = true, maxDate, userForm } = props;
    const ref = useRef();
    const [localValue, setLocalValue] = useState(value);

    useEffect(() => {
        setLocalValue(value);
    }, [value]);

    const getValue = () => {
        if (selectsRange) {
            if (value.dateFrom && value.dateTo) {
                return `${value.dateFrom} - ${value.dateTo}`;
            }

            if (value.date) {
                return DateUtils.getRelativeDateLabel(value.date);
            }
        } else {
            return DateUtils.formatDate(value);
        }
    };

    const handleOnClear = (event) => {
        event.stopPropagation();
        onChange({ name, value: null });
    };

    const renderButton = () => (
        <Form.Container error={dirty && error}>
            <Content>
                <Grid.Col mr={10}>
                    <Form.Icon src={Images.CALENDAR} />
                </Grid.Col>
                <Grid.Col flex={1} justifyContent="flex-start">
                    <Form.Value>{getValue()}</Form.Value>
                </Grid.Col>
                {userForm ? (
                    <Grid.Col ml={10}>
                        <Form.Icon size={9} onClick={handleOnClear} src={Images.CLOSE} />
                    </Grid.Col>
                ) : null}
                <Grid.Col ml={10}>
                    <Form.Icon src={Images.DROPDOWN_ARROW} />
                </Grid.Col>
            </Content>
        </Form.Container>
    );

    const handleAbsoluteChange = (date) => {
        if (selectsRange) {
            const [dateFrom, dateTo] = date;
            const rangeValue = { dateFrom: DateUtils.formatDate(dateFrom), dateTo: DateUtils.formatDate(dateTo) };
            setLocalValue(rangeValue);

            if (dateTo) {
                onChange({ name, value: rangeValue });
                ref.current.close();
            }
        } else {
            setLocalValue(date);
            onChange({ name, value: date });
            ref.current.close();
        }
    };

    const handleRelativeChange = (date) => {
        onChange({ name, value: { date } });
        ref.current.close();
    };

    const renderDropdown = () => (
        <DropdownContainer>
            <AbsoluteDateSelect
                value={localValue}
                onChange={handleAbsoluteChange}
                selectsRange={selectsRange}
                maxDate={maxDate}
            />
            {selectsRange && <RelativeDateSelect value={localValue} onChange={handleRelativeChange} />}
        </DropdownContainer>
    );

    return (
        <Container style={style}>
            {label && <Form.Label>{label}</Form.Label>}
            <Dropdown
                onOpen={() => setLocalValue(value)}
                ref={ref}
                right="auto"
                renderButton={renderButton}
                renderDropdown={renderDropdown}
            />
            {dirty && error && <Form.Error>{error}</Form.Error>}
        </Container>
    );
}

DatePicker.propTypes = {
    onChange: PropTypes.func.isRequired,
    onBlur: PropTypes.func,
    name: PropTypes.string.isRequired,
    value: SharedTypes.DateRangeValue,
    dirty: PropTypes.bool,
    error: PropTypes.string,
    label: PropTypes.string,
    style: PropTypes.object,
    disabled: PropTypes.bool,
    selectsRange: PropTypes.bool,
    maxDate: PropTypes.instanceOf(Date),
    userForm: PropTypes.bool,
};

DatePicker.defaultProps = {
    style: {},
    label: null,
    value: null,
    dirty: false,
    onBlur: () => {},
    error: null,
    disabled: false,
};

export default DatePicker;
