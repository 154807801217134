import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Grid } from 'styles/components';
import { Buttons } from 'styles/constant';
import * as Styles from './styles';
import { Button, Input, Modal } from 'components';

const ParametersEditModal = (props) => {
    const { visible, onClose, data, updateParameter } = props;
    const [currentParam, setCurrentParam] = useState({ label: null, value: null });

    useEffect(() => {
        if (currentParam.label !== data.label) setCurrentParam(data);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    const handleOnChange = (e) => {
        const { name, value } = e;
        setCurrentParam({ [name]: value });
    };

    const submit = () => {
        const newData = {
            id: data.value,
            parameter: currentParam.label,
        };
        updateParameter(newData);
        onClose();
    };

    const renderContent = () => (
        <Styles.Content>
            <Input
                label="Parameter name"
                value={currentParam.label}
                onChange={(e) => handleOnChange(e)}
                autoFocus={true}
                name="label"
            />
        </Styles.Content>
    );

    const renderFooter = () => (
        <Grid.Row>
            <Grid.Col flex={1}>
                <Button label="Cancel" type={Buttons.TERTIARY} onClick={onClose} />
            </Grid.Col>
            <Grid.Col>
                <Button label="Save" onClick={submit} />
            </Grid.Col>
        </Grid.Row>
    );

    return (
        <Modal
            title="Edit parameter"
            renderContent={renderContent}
            onClose={onClose}
            visible={visible}
            renderFooter={renderFooter}
        />
    );
};

ParametersEditModal.propTypes = {
    onClose: PropTypes.func.isRequired,
    updateParameter: PropTypes.func.isRequired,
    visible: PropTypes.bool,
    data: PropTypes.object.isRequired,
};

ParametersEditModal.defaultProps = {
    visible: false,
};

export default ParametersEditModal;
