import React, { useEffect, useState, useRef } from 'react';
import { Container, Pause, Text } from './styles';
import { useTimer } from 'hooks';
import { TimeUtils } from 'utils';
import PropTypes from 'prop-types';
import { Modal, Button } from 'components';

const LabelingTimer = (props) => {
    const { isArticleLoaded, setLabelingTime, buttonDisabled } = props;
    const [timerModal, setTimerModal] = useState(false);
    const { timer, isPaused, handleStart, handlePause, handleResume, handleReset } = useTimer(0);

    // Ref to track if the component is still mounted
    const isMounted = useRef(true);

    useEffect(() => {
        // Cleanup function to set isMounted to false when component unmounts
        return () => {
            isMounted.current = false;
        };
    }, []);

    useEffect(() => {
        if (isMounted.current && timer > 0) {
            setLabelingTime({ name: 'time_spent', value: timer });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [timer]);

    useEffect(() => {
        if (isMounted.current && timer > 0 && !isPaused) {
            setTimerModal(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isPaused]);

    useEffect(() => {
        if (isMounted.current) {
            handleReset();
            if (isArticleLoaded && !buttonDisabled) {
                handleStart();
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isArticleLoaded]);

    const renderContent = () => {
        return <Text>Labelling time tracking is paused. Close this dialog to continue your work.</Text>;
    };

    const renderFooter = () => {
        return (
            <Button
                style={{ marginLeft: 'auto' }}
                onClick={() => {
                    if (isMounted.current) {
                        setTimerModal(false);
                        handleResume();
                    }
                }}
                label="Close"
                type="PRIMARY"
            />
        );
    };

    return (
        <>
            <Modal
                onClose={() => {
                    if (isMounted.current) {
                        setTimerModal(false);
                        handleResume();
                    }
                }}
                renderContent={renderContent}
                renderFooter={renderFooter}
                visible={timerModal}
                title="Labelling is paused"
            />
            <Container>
                {TimeUtils.formatTime(timer)}
                <Pause
                    title="Pause labelling time tracking"
                    isPaused={isPaused}
                    onClick={isPaused ? handlePause : handleResume}
                />
            </Container>
        </>
    );
};

LabelingTimer.propTypes = {
    buttonDisabled: PropTypes.bool.isRequired,
    isArticleLoaded: PropTypes.bool.isRequired,
    setLabelingTime: PropTypes.func.isRequired,
};

export default LabelingTimer;
