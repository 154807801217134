const SOCIAL_NETWORK_LIST = [
    { value: 'facebook', label: 'Facebook' },
    { value: 'instagram', label: 'Instagram' },
    { value: 'youtube', label: 'Youtube' },
    { value: 'twitter', label: 'Twitter' },
    { value: 'vkontakte', label: 'VKontakte' },
    { value: 'odnoklassniki', label: 'OK (Odnoklassniki)' },
    { value: 'telegram', label: 'Telegram' },
    { value: 'reddit', label: 'Reddit' },
    { value: 'other', label: 'Other' },
];

export default SOCIAL_NETWORK_LIST;
