import styled from 'styled-components';
import { Colors } from 'styles/constant';

export const Container = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;

    input {
        height: 40px;
        border-radius: 5px;
        font-size: 13px;
        width: 100%;
        color: #000000;
        border: 1px solid #dddddd;
        background-color: #ffffff;
        padding-right: 10px;
        padding-left: 10px;
    }
`;

export const Content = styled.div`
    background-color: ${Colors.WHITE};
    border-radius: 5px;
    max-height: ${(props) => (props.large ? 363 : 275)}px;
    min-height: ${(props) => (props.large ? '363px' : 'auto')};
    overflow-y: auto;
    border: 1px solid ${Colors.BACKGROUND};

    ${(props) =>
        props.horizontal &&
        `
     display: flex;
     flex-direction: row;
  `}
`;

export const OptionContainer = styled.div`
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    padding: 0 10px;
    border-bottom: 1px solid ${Colors.BACKGROUND};

    ${(props) =>
        props.horizontal &&
        `
     border-bottom: none;
     height: 38px;
     border-right: 1px solid ${Colors.BACKGROUND};
  `}

    &:hover {
        cursor: pointer;
    }

    &:last-of-type {
        border-bottom: none;

        ${(props) =>
            props.horizontal &&
            `
      border-right: none;
    `}
    }
`;
