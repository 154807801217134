import styled from 'styled-components';
import { Colors } from 'styles/constant';

export const Container = styled.div`
    overflow-y: auto;
`;

export const Content = styled.div`
    font-size: ${(props) => props.fontSize}px !important;
    color: ${Colors.BLACK};
    line-height: 24px;

    * {
        font-size: inherit !important;
    }

    .timestamps p {
        display: inline-block;
        margin: 8px 0 !important;
    }

    .timestamps p:first-child {
        cursor: pointer;
        color: blue;
        padding-right: 10px;
    }

    .videoContainer {
        position: relative;
    }

    video {
        position: relative;
        width: 100%;
    }

    .videoHeader {
        display: none;
    }

    .contentVideoHeader {
        display: block;
        height: 20px;
        cursor: grab;
        background: #e90010;
    }

    .minimizedVideo {
        position: fixed;
        height: auto;
        width: 426px;
        bottom: 5%;
        right: 5%;
    }

    .minimizedVideoLabelling {
        position: fixed;
        height: auto;
        width: 426px;
        bottom: 5%;
        right: 30em;
    }

    #contentVideo .resizer-right {
        width: 5px;
        height: 100%;
        background: transparent;
        position: absolute;
        right: 0;
        bottom: 0;
        cursor: e-resize;
    }

    #contentVideo .resizer-bottom {
        width: 100%;
        height: 5px;
        background: transparent;
        position: absolute;
        right: 0;
        bottom: 0;
        cursor: n-resize;
    }

    #contentVideo .resizer-both {
        width: 5px;
        height: 5px;
        background: transparent;
        z-index: 10;
        position: absolute;
        right: 0;
        bottom: 0;
        cursor: nw-resize;
    }

    #contentVideo {
        display: inline;
    }

    #contentVideoParentDiv {
        height: auto;
    }
    mark {
        background-color: yellow;
        color: inherit;
    }
`;
