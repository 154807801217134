import { extractTextFromHtml, countOccurrences } from '../filterHighlighter/uniqueTextRange';

export const xPathToNode = (xpath, contextNode = document) => {
    const node = document.evaluate(xpath, contextNode, null, XPathResult.FIRST_ORDERED_NODE_TYPE, null).singleNodeValue;
    return node;
};

export function getTextBetweenNodesWithOffset(rangy, startNode, endNode, startOffset, endOffset) {
    const rangyRange = rangy.createRange();

    rangyRange.setStart(startNode, startOffset);

    // HOTFIX: Ensure endOffset does not exceed endNode length for text nodes.
    if (endNode.nodeType === 3) {
        // Adjust endOffset to the maximum available length if it's out of bounds.
        const safeEndOffset = Math.min(endOffset, endNode.length);
        rangyRange.setEnd(endNode, safeEndOffset);
    } else {
        // For non-text nodes, use the original endOffset.
        rangyRange.setEnd(endNode, endOffset);
    }

    // Create a document fragment to hold the cloned range
    const docFrag = rangyRange.cloneContents();

    // Function to recursively get text content from a fragment
    function getNodeText(node) {
        if (node.nodeType === Node.TEXT_NODE) {
            return node.textContent; // Return the text for text nodes
        } else if (node.hasChildNodes()) {
            // Concatenate the textContent of all children
            return Array.from(node.childNodes).map(getNodeText).join('');
        }
        return '';
    }

    // Use the recursive function to get text content of the document fragment
    return getNodeText(docFrag);
}

export function findStartNodeText(customConsole, searchText, containerElement) {
    const getTextNodesIn = (node) => {
        let textNodes = [];
        const getTextNodes = (node) => {
            if (node.nodeType === Node.TEXT_NODE) {
                textNodes.push(node);
            } else {
                for (let child of node.childNodes) {
                    getTextNodes(child);
                }
            }
        };
        getTextNodes(node);
        return textNodes;
    };

    customConsole.log('[findStartNodeText] Starting to find text in nodes for searchText:', searchText);
    let textNodes = getTextNodesIn(containerElement);

    let runningText = '',
        startNode = null,
        startOffset = -1,
        found = false;

    // Search for the searchText across textNodes
    for (let i = 0; i < textNodes.length; i++) {
        runningText += textNodes[i].nodeValue;
        if (runningText.includes(searchText) && !found) {
            // Mark the point where the searchText is found
            found = true;
            let searchTextPosition = runningText.indexOf(searchText);
            // Walk back to find which node the search text starts in
            let lengthUpToStartOfSearchText = searchTextPosition;
            let currentLength = 0;
            for (let j = 0; j <= i; j++) {
                currentLength += textNodes[j].length;
                if (currentLength > lengthUpToStartOfSearchText && startNode === null) {
                    startNode = textNodes[j];
                    startOffset = textNodes[j].nodeValue.length - (currentLength - lengthUpToStartOfSearchText);
                }
            }
        }

        // Stop adding text if we've found our searchText.
        if (found) break;
    }

    if (startNode) {
        customConsole.log('[findStartNodeText] Found starting node:', startNode);
        customConsole.log('[findStartNodeText] Start offset:', startOffset);
        return { startNode, startOffset };
    } else {
        customConsole.log('[findStartNodeText] Search text not found in any text node.');
        return null;
    }
}

export function findEndNodeText(customConsole, searchText, containerElement) {
    const getTextNodesIn = (node) => {
        let textNodes = [];
        const getTextNodes = (node) => {
            if (node.nodeType === Node.TEXT_NODE) {
                textNodes.push(node);
            } else {
                for (let child of node.childNodes) {
                    getTextNodes(child);
                }
            }
        };
        getTextNodes(node);
        return textNodes;
    };

    customConsole.log('[findEndNodeText] Starting to find text in nodes for searchText:', searchText);
    let textNodes = getTextNodesIn(containerElement);
    let combinedText = '';
    let endNode = null;
    let endOffset = -1;
    let searchTextIndex = -1;

    // Combine text from all text nodes and find the index of the search text
    textNodes.forEach((node) => (combinedText += node.nodeValue));
    searchTextIndex = combinedText.indexOf(searchText);

    if (searchTextIndex === -1) {
        customConsole.log('[findEndNodeText] Search text not found.');
        return null; // searchText not found in the combined text
    }

    // Calculate the end offset index
    let textCount = 0;
    for (let i = 0; i < textNodes.length; i++) {
        const node = textNodes[i];
        const nodeLength = node.nodeValue.length;
        textCount += nodeLength; // Increment text count by current node's text length

        // If the end of the search text is within this node
        if (searchTextIndex + searchText.length <= textCount) {
            endNode = node; // The end node is found
            // Calculate the offset within the current text node
            endOffset = searchText.length + searchTextIndex - (textCount - nodeLength);
            customConsole.log(
                `[findEndNodeText] Text ends in node: ${node.textContent.substring(0, 20)}... at offset: ${endOffset}`,
            );
            break; // Found the end node, break the loop
        }
    }

    return { endNode, endOffset };
}

export function findFirstTextIndex(htmlContent) {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlContent, 'text/html');
    const walker = document.createTreeWalker(doc.body, NodeFilter.SHOW_TEXT, null, false);
    let node;

    while ((node = walker.nextNode())) {
        if (node.nodeValue.trim().length > 0) {
            return htmlContent.indexOf(node.nodeValue);
        }
    }

    return -1; // No non-whitespace text found
}

export function findTextOutsideAlt(html, text) {
    let index = -1;
    let pos = 0;
    while ((pos = html.indexOf(text, pos)) !== -1) {
        // Check if the found position is inside an alt attribute
        const lastAltBeforePos = html.lastIndexOf('alt="', pos);
        const nextQuoteAfterLastAlt = lastAltBeforePos > -1 ? html.indexOf('"', lastAltBeforePos + 5) : -1;
        if (lastAltBeforePos === -1 || pos > nextQuoteAfterLastAlt) {
            index = pos;
            break;
        }
        // Move search position forward
        pos += text.length;
    }
    return index;
}

export const locateTextInHtml = (htmlContent, searchText, leftPaddedText, rightPaddedText) => {
    let foundString = '';
    let foundHtml = '';
    let startIndex = -1;
    let endIndex = -1;

    if (htmlContent.indexOf(searchText) !== -1) {
        startIndex = htmlContent.indexOf(searchText);
    }

    // Modified: Directly check for searchText if leftPaddedText is empty
    if (startIndex == -1 && leftPaddedText === '') {
        const firstTextIndex = findFirstTextIndex(htmlContent);
        if (startIndex === -1) {
            for (let i = 1; i <= htmlContent.length; i++) {
                const htmlSubstring = htmlContent.substring(0, i);
                const plainText = extractTextFromHtml(htmlSubstring);
                const plainTextWithoutSpacesAndNewLines = plainText.replace(/\s+/g, '');
                const searchTextWithoutSpacesAndNewLines = searchText.replace(/\s+/g, '');

                if (plainTextWithoutSpacesAndNewLines.indexOf(searchTextWithoutSpacesAndNewLines) !== -1) {
                    startIndex = firstTextIndex;
                    foundString = plainText;
                    foundHtml = htmlSubstring;
                    endIndex = i;
                    break;
                }
            }
        }
    } else {
        for (let i = 1; i <= htmlContent.length; i++) {
            const htmlSubstring = htmlContent.substring(0, i);
            const plainText = extractTextFromHtml(htmlSubstring);

            if (leftPaddedText !== '') {
                if (startIndex == -1 && plainText.indexOf(leftPaddedText) !== -1) {
                    startIndex = i;
                    continue;
                }
            }

            if (startIndex !== -1 && plainText.indexOf(searchText) !== -1) {
                foundString = plainText;
                foundHtml = htmlSubstring;
                endIndex = i;
                break;
            }
        }
    }

    // If searchText is not found, try finding using rightPaddedText
    if (foundString === '' && rightPaddedText !== '') {
        for (let i = htmlContent.length; i >= 0; i--) {
            const htmlSubstring = htmlContent.substring(0, i);
            const plainText = extractTextFromHtml(htmlSubstring);

            if (plainText.indexOf(rightPaddedText) !== -1) {
                endIndex = i;
                if (htmlContent.indexOf(searchText, i) !== -1) {
                    startIndex = htmlContent.indexOf(searchText, i);
                    foundString = searchText;
                    foundHtml = htmlContent.substring(startIndex, endIndex);
                    break;
                }
            }
        }
    }

    return {
        startIndex: startIndex,
        endIndex: endIndex,
        html: foundHtml,
        text: foundString,
        searchText: searchText,
    };
};

export function correctStartingPoint(content, startIndex, endIndex) {
    // Regular expression to find an HTML tag at the beginning of the string
    let htmlTagRegex = /^<[^>]+>/;

    let newStartIndex = startIndex;
    let substring = '';

    // Flag to indicate if a tag was found at the start
    let tagFound;

    do {
        // Extract the substring from the current start index
        substring = content.substring(newStartIndex, endIndex);

        // Check if the substring starts with an HTML tag
        let match = substring.match(htmlTagRegex);

        tagFound = false;

        if (match) {
            // If a tag is found, update the start index to the end of the tag
            newStartIndex += match[0].length;
            tagFound = true;
        }
    } while (tagFound);

    // Return the new start index and the substring without starting HTML tag
    return { newStartIndex, substring };
}

export function generateXPath(customConsole, node, offset) {
    function generateXPathForNode(node) {
        if (node && node.nodeType === Node.DOCUMENT_NODE) {
            // We've reached the root of the document
            return '';
        }

        const index = getNodeIndex(node);
        const pathSegment =
            node.nodeType === Node.TEXT_NODE ? `text()[${index}]` : `${node.nodeName.toLowerCase()}[${index}]`;
        const parentPath = generateXPathForNode(node.parentNode);

        return `${parentPath}/${pathSegment}`;
    }

    function getNodeIndex(node) {
        if (node.nodeType === Node.TEXT_NODE) {
            let textIndex = 1;
            let sibling = node.previousSibling;
            while (sibling) {
                if (sibling.nodeType === Node.TEXT_NODE) textIndex++;
                sibling = sibling.previousSibling;
            }
            return textIndex;
        } else {
            let elemIndex = 1;
            let sibling = node.previousSibling;
            while (sibling) {
                if (sibling.nodeName === node.nodeName) {
                    elemIndex++;
                }
                sibling = sibling.previousSibling;
            }
            return elemIndex;
        }
    }

    // Adjust for text nodes
    if (node.nodeType === Node.TEXT_NODE && offset !== undefined && offset > 0) {
        // If the offset is greater than the node's length, log an error but attempt to generate an XPath
        if (offset > node.textContent.length) {
            customConsole.log(
                `Warning: Provided offset (${offset}) exceeds the text node's length (${node.textContent.length}).`,
            );
        }

        // No need to split the text node here; XPath can reference the text node directly
        // If splitting logic or specific handling for the offset is needed, it should be implemented accordingly
    }

    const xpath = generateXPathForNode(node);
    customConsole.log('Generated XPath:', xpath);
    return xpath;
}

export function generateXPathOld(customConsole, html, startIndex) {
    function insertTemporaryMarker(html, startIndex) {
        // Generate a unique string, perhaps less prone to duplication than a UUID
        // Making sure it's a string that's unlikely to appear in normal text
        const uniqueId = `TEMP_MARKER_${Date.now()}_${Math.random().toString(36).substr(2, 9)}`;

        // Insert the marker into the HTML string at the specified startIndex
        const updatedHtml = html.slice(0, startIndex) + uniqueId + html.slice(startIndex);

        // Return the updated HTML and the unique marker
        return { updatedHtml, uniqueId };
    }

    function parseHTMLStringToDOM(htmlString) {
        // Create a new instance of DOMParser
        const parser = new DOMParser();

        // Use the parseFromString method to parse the HTML string
        // 'text/html' is the MIME type to parse the string as HTML
        const doc = parser.parseFromString(htmlString, 'text/html');

        // The result is a new HTML document object with the HTML string parsed into a DOM structure
        return doc;
    }

    function findMarkerElement(doc, uniqueMarker) {
        const textNodesXPath = '//text()[contains(., "' + uniqueMarker + '")]';
        const result = doc.evaluate(textNodesXPath, doc, null, XPathResult.ANY_TYPE, null);

        let textNodeContainingMarker;
        let node = result.iterateNext();
        while (node) {
            // Check if the node contains the uniqueMarker
            if (node.nodeValue.includes(uniqueMarker)) {
                textNodeContainingMarker = node;
                break;
            }
            node = result.iterateNext();
        }

        if (textNodeContainingMarker) {
            customConsole.log('Marker found in text node:', textNodeContainingMarker);
        } else {
            customConsole.log('Marker not found in text nodes.');
        }

        return textNodeContainingMarker;
    }

    const generateXPathForElement = (node) => {
        if (node.nodeType === Node.TEXT_NODE) {
            // For text nodes, find the index among other text siblings
            let textIndex = 1; // Start counting from 1 for XPath indexing
            let sibling = node.previousSibling;
            while (sibling) {
                if (sibling.nodeType === Node.TEXT_NODE) {
                    textIndex++; // Increment for every preceding text node
                }
                sibling = sibling.previousSibling;
            }
            // Call the function recursively for the parent node and append the text node index
            return generateXPathForElement(node.parentNode) + `/text()[${textIndex}]`;
        } else if (node.nodeType === Node.ELEMENT_NODE) {
            // For element nodes, construct the XPath as before
            let index = 1;
            let sibling = node.previousSibling;
            while (sibling) {
                if (sibling.nodeType === Node.DOCUMENT_TYPE_NODE) {
                    sibling = sibling.previousSibling;
                    continue;
                }
                if (sibling.nodeName === node.nodeName) {
                    index++;
                }
                sibling = sibling.previousSibling;
            }
            let tagName = node.nodeName.toLowerCase();
            let pathIndex = `[${index}]`;
            return generateXPathForElement(node.parentNode) + `/${tagName}${pathIndex}`;
        } else if (node.nodeType === Node.DOCUMENT_NODE) {
            // Reached the root of the document
            return '';
        }
        // Other node types, such as attribute nodes, could be handled here as needed
    };
    const { updatedHtml, uniqueId } = insertTemporaryMarker(html, startIndex);

    const domDocument = parseHTMLStringToDOM(updatedHtml);

    const markerElement = findMarkerElement(domDocument, uniqueId);

    if (markerElement) {
        const xpathString = generateXPathForElement(markerElement);
        customConsole.log('XPath of the marker:', xpathString);
        return xpathString;
    } else {
        customConsole.log('Element with the specified ID not found');
        return null;
    }
}

export function getTextBetweenNodes(rangy, startNode, endNode) {
    function getTextContentLength(node) {
        let length = 0;

        // Node.TEXT_NODE is 3
        if (node.nodeType === Node.TEXT_NODE) {
            length += node.length;
        } else {
            for (const childNode of node.childNodes) {
                length += getTextContentLength(childNode);
            }
        }

        return length;
    }

    const endNodeLength = getTextContentLength(endNode);

    // Create a Rangy range using the Rangy library
    const rangyRange = rangy.createRange();

    rangyRange.setStart(startNode, 0);
    rangyRange.setEnd(endNode, endNodeLength);

    // Get the text content of the range
    const textContent = rangyRange.toString();
    return textContent;
}

export function findSubstringIndices(str, substring) {
    const startIndex = str.indexOf(substring);
    if (startIndex === -1) {
        return 'Substring not found';
    }
    const endIndex = startIndex + substring.length - 1; // -1 because end index is inclusive
    return { startIndex, endIndex };
}

export function getLeftPaddedText(articleRef, range, paddingLength) {
    let extendedRange = range.cloneRange();

    // Extend the range backwards by the padding length
    extendedRange.moveStart('character', -paddingLength);

    // Create a range representing the start of the articleRef container
    let containerStartRange = document.createRange();
    containerStartRange.selectNodeContents(articleRef.current);
    containerStartRange.collapse(true); // Collapse to the start of the content

    // Check if the extended range starts before the container's start
    if (extendedRange.compareBoundaryPoints(Range.START_TO_START, containerStartRange) < 0) {
        // Adjust the extended range to start at the beginning of the container
        extendedRange.setStart(containerStartRange.startContainer, containerStartRange.startOffset);
    }

    const extendedText = extendedRange.toString();
    const selectedText = range.toString();

    // Calculate the start index of the selected text in the extended text
    const startIndex = Math.max(0, extendedText.indexOf(selectedText));

    // Extract only the left padded text
    const leftText = extendedText.substring(0, startIndex);

    // return `${leftText}${selectedText}`;
    return { leftText, selectedText };
}

// eslint-disable-next-line no-unused-vars
export function getRightPaddedText(articleRef, range, paddingLength) {
    let extendedRange = range.cloneRange();

    // Extend the range forward by the padding length
    extendedRange.moveEnd('character', paddingLength);

    // Create a range representing the end of the articleRef container
    let containerEndRange = document.createRange();
    containerEndRange.selectNodeContents(articleRef.current);
    containerEndRange.collapse(false); // Collapse to the end of the content

    // Check if the extended range ends after the container's end
    if (extendedRange.compareBoundaryPoints(Range.END_TO_END, containerEndRange) > 0) {
        // Adjust the extended range to end at the end of the container
        extendedRange.setEnd(containerEndRange.endContainer, containerEndRange.endOffset);
    }

    const extendedText = extendedRange.toString();
    const selectedText = range.toString();

    // Calculate the end index of the selected text in the extended text
    const endIndex = extendedText.indexOf(selectedText) + selectedText.length;

    // Extract only the right padded text
    const rightText = extendedText.substring(endIndex);

    //return `${selectedText}${rightText}`;
    return { selectedText, rightText };
}

export const getUniqueLeftPadded = (articleRef, plainText, range) => {
    for (let i = 3; i < 300; i++) {
        const { leftText } = getLeftPaddedText(articleRef, range, i);

        if (leftText !== '') {
            const occurrences = countOccurrences(plainText, leftText);

            if (occurrences === 1) {
                return leftText;
            }
        }
    }
    return '';
};

export const getUniqueRightPadded = (articleRef, plainText, range) => {
    for (let i = 3; i < 300; i++) {
        const { rightText } = getRightPaddedText(articleRef, range, i);

        if (rightText !== '') {
            const occurrences = countOccurrences(plainText, rightText);

            if (occurrences === 1) {
                return rightText;
            }
        }
    }
    return '';
};

export function getPaddedText(articleRef, range, paddingLength) {
    let extendedRange = range.cloneRange();

    // Extend the range backwards and forwards by the padding length
    extendedRange.moveStart('character', -paddingLength);
    extendedRange.moveEnd('character', paddingLength);

    // Create ranges representing the start and end of the articleRef container
    let containerStartRange = document.createRange();
    containerStartRange.selectNodeContents(articleRef.current);
    containerStartRange.collapse(true); // Collapse to the start of the content

    let containerEndRange = document.createRange();
    containerEndRange.selectNodeContents(articleRef.current);
    containerEndRange.collapse(false); // Collapse to the end of the content

    // Adjust the extended range if it starts before the container's start
    if (extendedRange.compareBoundaryPoints(Range.START_TO_START, containerStartRange) < 0) {
        extendedRange.setStart(containerStartRange.startContainer, containerStartRange.startOffset);
    }

    // Adjust the extended range if it ends after the container's end
    if (extendedRange.compareBoundaryPoints(Range.END_TO_END, containerEndRange) > 0) {
        extendedRange.setEnd(containerEndRange.endContainer, containerEndRange.endOffset);
    }

    const extendedText = extendedRange.toString();
    const selectedText = range.toString();

    // Calculate the start and end indices of the selected text in the extended text
    const startIndex = Math.max(0, extendedText.indexOf(selectedText));
    const endIndex = startIndex + selectedText.length;

    // Extract the left and right padded texts
    const leftText = extendedText.substring(0, startIndex);
    const rightText = extendedText.substring(endIndex);

    return `${leftText}${selectedText}${rightText}`;
}

export const computeXpath = (customConsole, props, articleRef, rangy, range, selectedText) => {
    // eslint-disable-next-line no-unreachable
    if (selectedText === '') {
        return;
    }

    if (!articleRef) {
        return;
    }

    if (!rangy) {
        return;
    }

    const parser = new DOMParser();
    const containerElement = parser.parseFromString(props.content, 'text/html');

    const plainText = extractTextFromHtml(props.content);
    const occurrences = countOccurrences(plainText, selectedText);
    // const leftPaddedText = getUniqueLeftPadded(articleRef, plainText, range);
    // const rightPaddedText = getUniqueRightPadded(articleRef, plainText, range);

    if (occurrences > 1) {
        for (let i = 1; i < 300; i++) {
            const paddedSearchText = getPaddedText(articleRef, range, i);
            const occurrences = countOccurrences(plainText, paddedSearchText);
            if (occurrences === 1) {
                let uniqueText = paddedSearchText;

                const startNodeInfo = findStartNodeText(customConsole, uniqueText, containerElement);
                const endNodeInfo = findEndNodeText(customConsole, uniqueText, containerElement);

                const startXpath = generateXPath(customConsole, startNodeInfo.startNode, startNodeInfo.startOffset);
                const endXpath = generateXPath(customConsole, endNodeInfo.endNode, endNodeInfo.endOffset);

                if (startXpath !== null && endXpath !== null) {
                    // Create a new instance of DOMParser
                    const parser = new DOMParser();
                    const doc = parser.parseFromString(props.content, 'text/html');

                    const startNode = xPathToNode(startXpath, doc);
                    const endNode = xPathToNode(endXpath, doc);

                    const textBetweenNodes = getTextBetweenNodes(rangy, startNode, endNode);

                    const { startIndex } = findSubstringIndices(textBetweenNodes, selectedText);

                    const startOffset = startIndex;
                    const endOffset = endNode.length - (textBetweenNodes.length - startIndex - selectedText.length);

                    return {
                        highlightedText: selectedText,
                        highlight: {
                            startXpath: startXpath,
                            endXpath: endXpath,
                            startOffset: startOffset,
                            endOffset: endOffset,
                        },
                    };
                }
                break;
            }
        }
    } else {
        const startNodeInfo = findStartNodeText(customConsole, selectedText, containerElement);
        const endNodeInfo = findEndNodeText(customConsole, selectedText, containerElement);

        const startXpath = generateXPath(customConsole, startNodeInfo.startNode, startNodeInfo.startOffset);
        const endXpath = generateXPath(customConsole, endNodeInfo.endNode, endNodeInfo.endOffset);

        if (startXpath !== null && endXpath !== null) {
            // Create a new instance of DOMParser
            const parser = new DOMParser();
            const doc = parser.parseFromString(props.content, 'text/html');

            const startNode = xPathToNode(startXpath, doc);
            const endNode = xPathToNode(endXpath, doc);

            const textBetweenNodes = getTextBetweenNodes(rangy, startNode, endNode);

            const { startIndex } = findSubstringIndices(textBetweenNodes, selectedText);

            const startOffset = startIndex;
            const endOffset = endNode.length - (textBetweenNodes.length - startIndex - selectedText.length);

            return {
                highlightedText: selectedText,
                highlight: {
                    startXpath: startXpath,
                    endXpath: endXpath,
                    startOffset: startOffset,
                    endOffset: endOffset,
                },
            };
        }
    }

    return null;
};
