export const INITIAL_STATE = {
    chartsData: [],
};

export const SET_CHARTS = function (originalChart, chart) {
    let chartsValues = [];

    if (originalChart.data) {
        Object.keys(originalChart.data).forEach((c) => {
            const chartsValuesChildren = [];

            if (Object.keys(originalChart.data[c].children).length >= 1) {
                Object.keys(originalChart.data[c].children).forEach((k) => {
                    if (k !== 'debunkReachDomain') {
                        chartsValuesChildren.push({ label: k, value: !!chart.data?.[c]?.children?.[k] });
                    }
                });
            }

            chartsValues.push({ label: c, value: !!chart.data[c], children: chartsValuesChildren });
        });
    }

    const isAllChecked = checkRecursiveIsAllChecked(chartsValues);

    return {
        chartsData: chartsValues,
        isAllChecked,
    };
};

const checkRecursiveIsAllChecked = (arr) => {
    return arr.every((x) => {
        if (x.children) {
            return x.value && checkRecursiveIsAllChecked(x.children);
        }

        return x.value;
    });
};
