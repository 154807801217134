import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Modal from '../Modal';
import { Button, Tabs } from 'components';
import { saveToLocalStorage } from 'utils';
import { AdvancedExportModalKey, advancedExportType } from './Types';
import { AdvancedExportForm } from './Forms/AdvancedExportForm';
import { StandardExportForm } from './Forms/StandardExportForm';
import { Buttons } from 'styles/constant';
import { Grid } from 'styles/components';
import { connect } from 'react-redux';
import { ParametersListActions } from '../../core/actions';
import './style.scss';

export const ExportMode = {
    STANDARD: 'Standard',
    ADVANCED: 'Advanced',
};

function AdvancedExportModal(props) {
    const {
        visible,
        onClose,
        onExport,
        recommendedExports,
        exportFields,
        minimalFields,
        getParametersList,
        parameters,
    } = props;

    const [exportType, setExportType] = useState(advancedExportType.SIMPLE);

    const [checkedItems, setCheckedItems] = useState({});

    const [singleParameter, setSingleParameter] = useState();

    const handleExportClick = () => {
        const columns = Object.entries(checkedItems)
            .filter(([, value]) => value === true)
            .map(([key]) => key);

        onExport({ columns: columns, type: exportType, singleParameter: singleParameter });
        saveToLocalStorage(AdvancedExportModalKey, { columns: columns, type: exportType });
    };

    const handleQuickExport = (type) => {
        onExport({ columns: recommendedExports[type], type });
        saveToLocalStorage(AdvancedExportModalKey, { columns: recommendedExports[type], type });
    };

    const onTabChange = (e) => {
        setActiveTab(e);
    };

    const [activeTab, setActiveTab] = useState(ExportMode.STANDARD);

    const showSimpleForm = activeTab === ExportMode.STANDARD;

    const tabs = [
        { key: ExportMode.STANDARD, title: ExportMode.STANDARD },
        { key: ExportMode.ADVANCED, title: ExportMode.ADVANCED },
    ];

    const renderContent = () => (
        <div className="export-search-modal-content">
            <Grid.Row mb={20}>
                <Tabs onSelect={(e) => onTabChange(e)} active={activeTab} tabs={tabs} />
            </Grid.Row>
            <Grid.Row mb={20}>
                <div className="export-forms">
                    <StandardExportForm isVisible={showSimpleForm} handleQuickExport={handleQuickExport} />
                    <AdvancedExportForm
                        isVisible={!showSimpleForm}
                        checkedItems={checkedItems}
                        exportFields={exportFields}
                        minimalFields={minimalFields}
                        recommendedExports={recommendedExports}
                        setCheckedItems={setCheckedItems}
                        exportType={exportType}
                        setExportType={setExportType}
                        parameters={parameters}
                        getParametersList={getParametersList}
                        setSingleParameter={setSingleParameter}
                    />
                </div>
            </Grid.Row>
        </div>
    );

    const renderFooter = () => (
        <div className="export-search-modal-footer">
            {!showSimpleForm && <Button label="Export" type={Buttons.PRIMARY} onClick={handleExportClick} />}
            <Button label="Close" type={Buttons.TERTIARY} onClick={onClose} />
        </div>
    );

    return (
        <Modal
            style={{ width: 880 }}
            title="Export search"
            renderContent={renderContent}
            onClose={onClose}
            visible={visible}
            renderFooter={renderFooter}
        />
    );
}

AdvancedExportModal.propTypes = {
    onClose: PropTypes.func.isRequired,
    onExport: PropTypes.func.isRequired,
    visible: PropTypes.bool,
    recommendedExports: PropTypes.any,
    minimalFields: PropTypes.any,
    exportFields: PropTypes.any,
    getParametersList: PropTypes.func.isRequired,
    parameters: PropTypes.any.isRequired,
};

AdvancedExportModal.defaultProps = {
    visible: false,
};

function mapStateToProps(state) {
    const { parametersList } = state;
    const { isLoading, isLoaded } = parametersList;
    return { parameters: parametersList.singleParameters, isLoading, isLoaded };
}

const mapDispatchToProps = {
    getParametersList: ParametersListActions.getParametersList,
};

export default connect(mapStateToProps, mapDispatchToProps)(AdvancedExportModal);
