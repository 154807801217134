import rangy from 'rangy';
import 'rangy/lib/rangy-textrange'; // For text range support

function insertUniqueSpans(htmlString, startIndex, endIndex) {
    // Check if the start index and end index are within the string length
    if (startIndex > htmlString.length || endIndex < startIndex) {
        throw new Error('Invalid indices');
    }

    // Insert the end span first so that it doesn't change the start index position
    const endSpan = '<span id="unique-end"></span>';
    htmlString = htmlString.slice(0, endIndex) + endSpan + htmlString.slice(endIndex);

    // Insert the start span
    const startSpan = '<span id="unique-start"></span>';
    htmlString = htmlString.slice(0, startIndex) + startSpan + htmlString.slice(startIndex);

    return htmlString;
}

export function countOccurrences(mainStr, searchStr) {
    let occurrences = [];
    let index = mainStr.indexOf(searchStr);

    while (index !== -1) {
        occurrences.push({
            start: index,
            stop: index + searchStr.length,
        });
        index = mainStr.indexOf(searchStr, index + 1);
    }

    return occurrences.length;
}

function getAllTextNodes(element) {
    var textNodes = [];
    // Recursive function to visit node and its children
    function visitNode(node) {
        // If the current node is a text node, add it to the list
        if (node.nodeType === Node.TEXT_NODE) {
            textNodes.push(node);
        } else {
            // Otherwise, if it's an element node, visit its children
            for (var i = 0; i < node.childNodes.length; i++) {
                visitNode(node.childNodes[i]);
            }
        }
    }

    // Start the traversal from the provided element
    visitNode(element);

    return textNodes;
}

function getRangeExtendedText(leftPaddingCount, rightPaddingCount, startElement, endElement) {
    // Helper function to get the next text node in the document, in depth-first order
    function getNextTextNode(node) {
        while (node && (node.nodeType !== Node.TEXT_NODE || !node.nodeValue.trim())) {
            // Skip empty text nodes
            if (node.firstChild) {
                node = node.firstChild;
            } else {
                while (node && !node.nextSibling) {
                    node = node.parentNode;
                }
                if (node) {
                    node = node.nextSibling;
                }
            }
        }
        return node;
    }

    // Helper function to get the previous text node in the document, in depth-first order
    function getPreviousTextNode(node) {
        while (node && (node.nodeType !== Node.TEXT_NODE || !node.nodeValue.trim())) {
            // Skip empty text nodes
            if (node.lastChild) {
                node = node.lastChild;
            } else {
                while (node && !node.previousSibling) {
                    node = node.parentNode;
                }
                if (node) {
                    node = node.previousSibling;
                }
            }
        }
        return node;
    }

    // Helper function to collect text from nodes
    function collectTextFromNodes(node, count, backward = false) {
        let text = '';
        while (node && text.length < count) {
            if (backward) {
                text = node.nodeValue + text;
            } else {
                text += node.nodeValue;
            }
            node = backward ? getPreviousTextNode(node.previousSibling) : getNextTextNode(node.nextSibling);
        }
        return backward ? text.slice(-count) : text.substring(0, count);
    }

    // Find the range between the unique start and end elements
    var range = rangy.createRange();
    range.setStartAfter(startElement);
    range.setEndBefore(endElement);

    // Get the padding text
    const startText = collectTextFromNodes(getPreviousTextNode(startElement), leftPaddingCount, true);
    const endText = collectTextFromNodes(getNextTextNode(endElement), rightPaddingCount, false);

    // Get the text within the original range
    const middleText = range.toString();

    // Combine the text
    return startText + middleText + endText;
}

export function extractTextFromHtml(htmlString) {
    var parser = new DOMParser();
    var doc = parser.parseFromString(htmlString, 'text/html');
    return doc.body.textContent || '';
}

export function extractUniqueTextRange(htmlRawString, startSubstring, endSubstring) {
    const modifiedHtmlString = insertUniqueSpans(htmlRawString, startSubstring, endSubstring);

    // Parse the HTML string into a new DOM element
    const parser = new DOMParser();
    const doc = parser.parseFromString(modifiedHtmlString, 'text/html');
    const startElement = doc.getElementById('unique-start');
    const endElement = doc.getElementById('unique-end');

    const originalSearchText = getRangeExtendedText(0, 0, startElement, endElement);

    const allTextNodes = getAllTextNodes(doc.body);

    // Concatenate all text node values into one string
    const allTextValues = allTextNodes.map((node) => node.nodeValue.trim()).join(' ');

    // Replace all spaces with an empty string
    const fullTextWithoutSpaces = allTextValues.replace(/\s+/g, '');
    const searchTextWithoutSpaces = originalSearchText.replace(/\s+/g, '');

    const searchTextOccurrences = countOccurrences(fullTextWithoutSpaces, searchTextWithoutSpaces);

    if (searchTextOccurrences > 1) {
        for (let i = 1; i <= Math.floor(allTextValues.length / 2); i++) {
            const paddedSearchText = getRangeExtendedText(i, i, startElement, endElement);

            const paddedSearchTextNoSpaces = paddedSearchText.replace(/\s+/g, '');

            const paddedTextOccurrences = countOccurrences(fullTextWithoutSpaces, paddedSearchTextNoSpaces);
            if (paddedTextOccurrences === 1) {
                return paddedSearchText;
            }
        }
    } else {
        return originalSearchText;
    }
    return '';
}
