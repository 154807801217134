import update from 'immutability-helper';
import { AuthTypes } from 'core/types';
import { ArticlesTypes } from 'features/articles/types';
import { ArticleTypes } from 'features/article/types';
import { LabelingStates } from 'constant';
import recommendedExports from './recommendedExports';

const initialState = {
    articles: [],
    count: 0,
    isLoading: false,
    isLoaded: false,
    isReserving: false,
    isCancelingReserved: false,
    chartData: {},
    isExporting: false,
    recommendedFields: recommendedExports,
    exportFields: [],
    minimalFields: [
        'articleNo',
        'count',
        'articleDateTime',
        'sourceNameStandard',
        'language',
        'headline',
        'url',
        'debunkReachCurrent',
        'author',
    ],
};

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case ArticlesTypes.GET_RESERVED_ARTICLES_START:
        case ArticlesTypes.GET_ARTICLES_START: {
            return update(state, {
                isLoading: { $set: true },
                isLoaded: { $set: false },
                articles: { $set: [] },
                chartData: { $set: {} },
            });
        }

        case ArticlesTypes.GET_EXPORT_FIELDS_START: {
            return update(state, {
                isLoading: { $set: true },
                isLoaded: { $set: false },
                exportFields: { $set: [] },
            });
        }

        case ArticlesTypes.GET_EXPORT_FIELDS: {
            return update(state, {
                isLoading: { $set: false },
                isLoaded: { $set: true },
                exportFields: { $set: payload.exportFields },
            });
        }

        case ArticlesTypes.EXPORT_SEARCH_START: {
            return update(state, {
                isExporting: { $set: true },
            });
        }

        case ArticlesTypes.EXPORT_SEARCH: {
            return update(state, {
                isExporting: { $set: false },
            });
        }

        case ArticlesTypes.EXPORT_SEARCH_ERROR: {
            return update(state, {
                isExporting: { $set: false },
            });
        }

        case ArticlesTypes.RESERVE_ARTICLES_START: {
            return update(state, {
                isReserving: { $set: true },
            });
        }

        case ArticlesTypes.RESERVE_ARTICLES: {
            return update(state, {
                isReserving: { $set: false },
            });
        }

        case ArticlesTypes.CANCEL_RESERVED_ARTICLES_START: {
            return update(state, {
                isLoading: { $set: true },
                isLoaded: { $set: false },
                isCancelingReserved: { $set: true },
            });
        }

        case ArticleTypes.LABEL_ARTICLE_START: {
            const index = state.articles.findIndex(
                (article) => article.id === payload.id && article.parentId === payload.parentId,
            );
            if (index === -1) return state;

            return update(state, {
                articles: {
                    [index]: {
                        labelingState: { $set: LabelingStates.PENDING },
                    },
                },
            });
        }

        case ArticlesTypes.REMOVE_ARTICLE: {
            const index = state.articles.findIndex(
                (article) => article.id === payload.id && article.parentId === payload.parentId,
            );
            if (index === -1) return state;
            const filteredArticles = state.articles.filter((_, i) => i !== index);

            return update(state, {
                articles: { $set: filteredArticles },
            });
        }

        case ArticleTypes.LABEL_ARTICLE_ERROR: {
            const index = state.articles.findIndex(
                (article) => article.id === payload.id && article.parentId === payload.parentId,
            );
            if (index === -1) return state;

            return update(state, {
                articles: {
                    [index]: {
                        labelingState: { $set: LabelingStates.FAIL },
                        labelingData: { $set: payload.data },
                    },
                },
            });
        }

        case ArticleTypes.LABEL_ARTICLE: {
            const index = state.articles.findIndex(
                (article) => article.id === payload.id && article.parentId === payload.parentId,
            );
            if (index === -1) return state;

            return update(state, {
                articles: {
                    [index]: {
                        labelingState: { $set: LabelingStates.SUCCESS },
                    },
                },
            });
        }

        case ArticlesTypes.MARK_EXPIRED: {
            const index = state.articles.findIndex(
                (article) => article.id === payload.id && article.parentId === payload.parentId,
            );
            if (index === -1) return state;

            return update(state, {
                articles: {
                    [index]: {
                        expired: { $set: true },
                    },
                },
            });
        }

        case ArticlesTypes.ARTICLE_BOOKMARK: {
            const index = state.articles.findIndex(
                (article) => article.id === payload.id && article.parentId === payload.parentId,
            );
            if (index === -1) return;

            return update(state, {
                articles: { [index]: { isBookmarked: { $set: payload.isBookmarked } } },
            });
        }

        case ArticlesTypes.ARTICLE_VOTE: {
            const index = state.articles.findIndex(
                (article) => article.id === payload.id && article.parentId === payload.parentId,
            );
            if (index === -1) return;

            return update(state, {
                articles: { [index]: { votes: { $set: payload.votes } } },
            });
        }

        case ArticlesTypes.ARTICLE_HIDE_FOR_ALL: {
            const index = state.articles.findIndex(
                (article) => article.id === payload.id && article.parentId === payload.parentId,
            );
            if (index === -1) return;

            return update(state, {
                articles: {
                    [index]: {
                        isHiddenForAll: { $set: payload.isHiddenForAll },
                    },
                },
            });
        }

        case ArticlesTypes.ARTICLE_HIDE_FOR_ME: {
            const index = state.articles.findIndex(
                (article) => article.id === payload.id && article.parentId === payload.parentId,
            );
            if (index === -1) return;

            return update(state, {
                articles: {
                    [index]: {
                        isHiddenForMe: { $set: payload.isHiddenForMe },
                    },
                },
            });
        }

        case ArticlesTypes.GET_RESERVED_ARTICLES:
        case ArticlesTypes.CANCEL_RESERVED_ARTICLES:
        case ArticlesTypes.GET_ARTICLES: {
            return update(state, {
                isLoading: { $set: false },
                isLoaded: { $set: true },
                isReserving: { $set: false },
                isCancelingReserved: { $set: false },
                articles: { $set: payload.articles },
                count: { $set: payload.count },
                chartData: { $set: payload.chartData },
            });
        }

        case ArticlesTypes.RESERVE_ARTICLES_ERROR:
        case ArticlesTypes.GET_RESERVED_ARTICLES_ERROR:
        case ArticlesTypes.CANCEL_RESERVED_ARTICLES_ERROR:
        case ArticlesTypes.GET_ARTICLES_ERROR:
        case AuthTypes.LOGOUT: {
            return initialState;
        }

        default: {
            return state;
        }
    }
};
