import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Form } from 'styles/components';
import { Images } from 'styles/constant';
import { SharedTypes } from 'utils';
import SelectedOption from '../../SelectedOption';
import { ValueContainer, MoreContainer } from './styles';

function MultiSelectContainer(props) {
    const { value, style, placeholder, onClear, options, onClearAll, forceOpen } = props;

    const renderContent = () => {
        const option = options.find((option) => option.value === value[0]);
        const optionLabel = (() => {
            if (option) {
                return option.label;
            }
            if (value.length) {
                return value[0];
            }
            return 'Loading...';
        })();

        return (
            <Grid.Row>
                <ValueContainer>
                    <SelectedOption label={optionLabel} onClear={() => onClear(value[0])} />
                </ValueContainer>
                {value.length > 1 && (
                    <MoreContainer>
                        <SelectedOption label={`+${value.length - 1}`} />
                    </MoreContainer>
                )}
            </Grid.Row>
        );
    };

    return (
        <Form.Container multi style={forceOpen ? { borderRadius: '5px 5px 0 0', borderBottom: 'none' } : style}>
            <Form.ValueContainer mr={10}>
                {value.length > 0 && renderContent()}
                {value.length === 0 && placeholder && <Form.Placeholder multi>{placeholder}</Form.Placeholder>}
            </Form.ValueContainer>
            {value.length > 0 && (
                <Grid.Col mr={10}>
                    <Form.Icon size={9} onClick={onClearAll} src={Images.CLOSE} />
                </Grid.Col>
            )}
            {!forceOpen && (
                <Grid.Col mr={5}>
                    <Form.Icon src={Images.DROPDOWN_ARROW} />
                </Grid.Col>
            )}
        </Form.Container>
    );
}

MultiSelectContainer.propTypes = {
    value: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
    options: PropTypes.arrayOf(SharedTypes.OptionType).isRequired,
    onClear: PropTypes.func.isRequired,
    onClearAll: PropTypes.func.isRequired,
    style: PropTypes.object,
    placeholder: PropTypes.string,
    forceOpen: PropTypes.bool,
};

MultiSelectContainer.defaultProps = {
    style: {},
    selectedOptions: [],
    placeholder: null,
    forceOpen: false,
};

export default MultiSelectContainer;
