import httpClient from '../httpClient';
import { store } from 'core/store';

const baseUrl = '/classifications';

export const getClassifications = async () => {
    return httpClient.get(`${baseUrl}`);
};

export const getClassificationById = (id) => {
    const classifications = store.getState().classifications.classifications;
    return classifications.find((classifications) => classifications.value == id);
};
