import React, { useEffect } from 'react';

const useIsMounted = () => {
    const isMounted = React.useRef(false);
    useEffect(() => {
        isMounted.current = true;
        return () => (isMounted.current = false);
    }, []);
    return isMounted;
};

export default useIsMounted;
