import React from 'react';
import { Buttons as ButtonTypes } from 'styles/constant';
import Button from 'components/Button';
import { advancedExportType } from 'components/AdvancedExportModal/Types';
import PropTypes from 'prop-types';
import './style.scss';

export const StandardExportForm = ({ handleQuickExport, isVisible }) => {
    return (
        <div className={`standard-export ${isVisible ? 'active' : ''}`}>
            <div className="column">
                <div className="row">
                    <Button
                        label="Simple"
                        type={ButtonTypes.PRIMARY}
                        onClick={() => handleQuickExport(advancedExportType.SIMPLE)}
                    />
                </div>
                <div className="row">
                    <Button
                        label="By Filters"
                        type={ButtonTypes.PRIMARY}
                        onClick={() => handleQuickExport(advancedExportType.BY_FILTERS)}
                    />
                </div>
            </div>
            <div className="column">
                <div className="row">
                    <Button
                        label="By Labeling Parameters"
                        type={ButtonTypes.PRIMARY}
                        onClick={() => handleQuickExport(advancedExportType.BY_LABELING_PARAMETERS)}
                    />
                </div>
                <div className="row">
                    <Button
                        label="By Ml Classifications"
                        type={ButtonTypes.PRIMARY}
                        onClick={() => handleQuickExport(advancedExportType.BY_ML_CLASSIFICATIONS)}
                    />
                </div>
            </div>
            <div className="column">
                <div className="row">
                    <Button
                        className="inline-button"
                        label="By Named Entities"
                        type={ButtonTypes.PRIMARY}
                        onClick={() => handleQuickExport(advancedExportType.BY_NAMED_ENTITIES)}
                    />
                    <Button
                        className="inline-button"
                        label="By External Links"
                        type={ButtonTypes.PRIMARY}
                        onClick={() => handleQuickExport(advancedExportType.BY_EXTERNAL_LINKS)}
                    />
                </div>
                <div className="row">
                    <Button
                        label="Top Words"
                        type={ButtonTypes.PRIMARY}
                        onClick={() => handleQuickExport(advancedExportType.TOP_WORDS)}
                    />
                </div>
            </div>
        </div>
    );
};

StandardExportForm.propTypes = {
    handleQuickExport: PropTypes.func.isRequired,
    isVisible: PropTypes.bool.isRequired,
};
