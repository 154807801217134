export const COLUMNS = [
    {
        Header: 'ID',
        accessor: 'id',
        filter: 'multiple',
    },
    {
        Header: 'Organisation',
        accessor: 'name',
        filter: 'search',
    },
];
