import React from 'react';
import PropTypes from 'prop-types';
import Modal from '../../components/Modal';
import { Buttons } from 'styles/constant';
import { Content, Footer } from './styles';
import Button from '../../components/Button';

function DuplicateUrlModal(props) {
    const { visible, onModalClose, onModalSubmit } = props;

    const renderContent = () => <Content>Are you sure you want to upload?</Content>;

    const renderFooter = () => (
        <Footer>
            <Button label="Force submit" type={Buttons.PRIMARY} onClick={onModalSubmit} />
            <Button label="Cancel" type={Buttons.SECONDARY} onClick={onModalClose} />
        </Footer>
    );

    return (
        <Modal
            style={{ width: 580 }}
            title="The URL already exists in the system!"
            renderContent={renderContent}
            onClose={onModalClose}
            visible={visible}
            renderFooter={renderFooter}
        />
    );
}

DuplicateUrlModal.propTypes = {
    visible: PropTypes.bool,
    onModalClose: PropTypes.func,
    onModalSubmit: PropTypes.func,
};

DuplicateUrlModal.defaultProps = {
    visible: false,
};

export default DuplicateUrlModal;
