import { useSelector } from 'react-redux';

export const usePermission = (permission) => {
    const {
        profile: { permissions },
    } = useSelector((state) => state.auth);

    return permissions ? permissions.includes(permission) : false;
};

export const usePermissions = (permissionsToCheck) => {
    const { permissions } = useSelector((state) => state.auth.profile);

    if (!permissions) {
        return null;
    }

    return permissionsToCheck.reduce((result, val) => [...result, permissions.includes(val)], []);
};