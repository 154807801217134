import { ArticleTypes } from 'features/article/types';
import { ArticleService } from 'features/article/services';
import { ToastService } from 'core/services';

export function importArticle(data) {
    return async (dispatch) => {
        try {
            dispatch({ type: ArticleTypes.IMPORT_ARTICLE_START });
            await ArticleService.importArticle(data);
            dispatch({ type: ArticleTypes.IMPORT_ARTICLE, payload: { data } });
            ToastService.showSuccess('Successfully imported');
        } catch (error) {
            const message = error.message || error?.response?.data?.message || 'error';
            ToastService.showGenericError(message);
            dispatch({ type: ArticleTypes.IMPORT_ARTICLE_ERROR });
            throw error;
        }
    };
}

export function importBatch(data) {
    return async (dispatch) => {
        try {
            dispatch({ type: ArticleTypes.IMPORT_ARTICLE_START });
            await ArticleService.importBatch(data);
            dispatch({ type: ArticleTypes.IMPORT_ARTICLE, payload: { data } });
            ToastService.showSuccess('Successfully imported');
            return true;
        } catch (error) {
            const message = error?.response?.data?.message;
            if (error?.response?.status === 409) {
                throw new Error(message);
            }
            ToastService.showGenericError(message);
            dispatch({ type: ArticleTypes.IMPORT_ARTICLE_ERROR });
            return false;
        }
    };
}

export function getImportedArticles() {
    return async (dispatch) => {
        try {
            dispatch({ type: ArticleTypes.GET_IMPORTED_ARTICLES_START });
            const importedArticles = await ArticleService.getImportedArticles();
            dispatch({ type: ArticleTypes.GET_IMPORTED_ARTICLES, payload: { importedArticles } });
        } catch (error) {
            const message = error?.response?.data?.message;
            ToastService.showGenericError(message);
            dispatch({ type: ArticleTypes.GET_IMPORTED_ARTICLES_ERROR });
        }
    };
}

export function downloadTemplate() {
    return async () => {
        try {
            return await ArticleService.downloadTempalte();
        } catch (error) {
            const message = error?.response?.data?.message;
            ToastService.showGenericError(message);
        }
    };
}
