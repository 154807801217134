import { LabellingHighlight as types } from 'core/types';

const initialHighlight = {
    id: '',
    highlightedText: '',
    highlight: {
        startXpath: '',
        endXpath: '',
        startOffset: '',
        endOffset: '',
        startIndex: 0,
        endIndex: 0,
    },
    s1: [],
    s2: [],
    s3: [],
    comment: '',
};

const labellingHighlightReducer = (state = initialHighlight, action) => {
    switch (action.type) {
        case types.SET_LABELLING_HIGHLIGHT:
            return {
                ...state,
                ...action.payload,
            };
        case types.RESET_LABELLING_HIGHLIGHT:
            return initialHighlight;
        // Handle other actions
        default:
            return state;
    }
};

export default labellingHighlightReducer;
