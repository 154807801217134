// Recursive function to retrieve all text nodes within a given node
export const getTextNodes = (node) => {
    const blockElements = ['DIV', 'P', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6'];

    let results = [];

    // If it's a text node, simply return it.
    if (node.nodeType === 3) {
        return [
            {
                value: node.nodeValue,
                reference: node,
                nodeType: 'TEXT_NODE',
                parentNode: node.parentNode.nodeName,
                node: node,
            },
        ];
    }

    // If the node is a block element and is not the first child of its parent and the previous sibling isn't a whitespace or artificial whitespace, add a whitespace before it.
    if (
        node.nodeType === 1 &&
        blockElements.includes(node.nodeName) &&
        node.previousSibling &&
        !(node.previousSibling.nodeType === 3 && node.previousSibling.nodeValue.trim() === '') &&
        !(node.previousSibling.nodeType === 1 && node.previousSibling.nodeName === 'ARTIFICIAL_WHITESPACE')
    ) {
        results.push({
            value: ' ',
            reference: null, // No real DOM reference for this artificial node
            nodeType: 'ARTIFICIAL_WHITESPACE',
            parentNode: node.nodeName,
            node: node,
        });
    }

    const children = Array.from(node.childNodes);
    results = results.concat(children.flatMap(getTextNodes));

    // If the node is a block element and is not the last child of its parent and the next sibling isn't a whitespace or artificial whitespace, add a whitespace after it.
    if (
        node.nodeType === 1 &&
        blockElements.includes(node.nodeName) &&
        node.nextSibling &&
        !(node.nextSibling.nodeType === 3 && node.nextSibling.nodeValue.trim() === '') &&
        !(node.nextSibling.nodeType === 1 && node.nextSibling.nodeName === 'ARTIFICIAL_WHITESPACE')
    ) {
        results.push({
            value: ' ',
            reference: null, // No real DOM reference for this artificial node
            nodeType: 'ARTIFICIAL_WHITESPACE',
            parentNode: node.nodeName,
            node: node,
        });
    }

    return results;
};

export function getAllNodes(node) {
    var nodes = [];

    if (node.nodeType === Node.ELEMENT_NODE) {
        nodes.push(node);
        var children = node.childNodes;
        for (var i = 0; i < children.length; i++) {
            nodes = nodes.concat(getAllNodes(children[i]));
        }
    }

    return nodes;
}

export function getHtmlBeforeAndAfterTextNode(textNode, charactersBefore, charactersAfter) {
    if (textNode.nodeType !== Node.TEXT_NODE) {
        return null; // Not a text node
    }

    var parentElement = textNode.parentElement;
    if (!parentElement) {
        return null; // No parent element
    }

    var html = parentElement.innerHTML;
    var textContent = textNode.textContent;
    var startIndex = html.indexOf(textContent);
    var endIndex = startIndex + textContent.length;

    var before = '';
    var after = '';

    if (startIndex !== -1 && endIndex !== -1) {
        var startOffset = Math.max(0, startIndex - charactersBefore);
        var endOffset = Math.min(html.length, endIndex + charactersAfter);

        before = html.substring(startOffset, startIndex);
        after = html.substring(endIndex, endOffset);
    }

    return before + textContent + after;
}

// Find indices of a search text within the content
export const getNodeIndicesOfText = (searchText, nodes) => {
    const fullText = nodes.map((node) => node.value).join('');
    let startIndex = 0;
    const indices = [];

    while (startIndex < fullText.length) {
        startIndex = fullText.indexOf(searchText, startIndex);
        if (startIndex === -1) break;

        const endIndex = startIndex + searchText.length - 1;
        indices.push({ start: startIndex, end: endIndex });
        startIndex = endIndex + 1;
    }

    return indices.map((range) => {
        let charCount = 0;
        let startNodeIndex = -1;
        let endNodeIndex = -1;

        for (let i = 0; i < nodes.length; i++) {
            if (startNodeIndex === -1 && charCount + nodes[i].value.length > range.start) {
                startNodeIndex = i;
            }
            if (endNodeIndex === -1 && charCount + nodes[i].value.length > range.end) {
                endNodeIndex = i;
                break;
            }
            charCount += nodes[i].value.length;
        }

        return { startNode: startNodeIndex, endNode: endNodeIndex };
    });
};

// Find occurrences of a substring within an array of strings
export const findStringInArray = (searchString, arr) => {
    const combinedString = arr.join('');
    for (let i = 0; i <= combinedString.length - searchString.length; i++) {
        const segment = combinedString.substr(i, searchString.length);
        if (segment === searchString) {
            const substrings = [];
            let accumulatedLength = 0;

            for (let j = 0; j < arr.length; j++) {
                if (i + searchString.length <= accumulatedLength) break;
                if (i < accumulatedLength + arr[j].length) {
                    const start = Math.max(0, i - accumulatedLength);
                    const end = Math.min(arr[j].length, i + searchString.length - accumulatedLength);
                    substrings.push({
                        index: j,
                        substring: arr[j].substring(start, end),
                    });
                }
                accumulatedLength += arr[j].length;
            }

            return substrings;
        }
    }
    return [];
};

export function findOccurrences(mainStr, searchStr) {
    let occurrences = [];
    let index = mainStr.indexOf(searchStr);

    while (index !== -1) {
        occurrences.push({
            start: index,
            stop: index + searchStr.length,
        });
        index = mainStr.indexOf(searchStr, index + 1);
    }

    return occurrences;
}

// Extract a sub-array given start and stop indices
export const extractSubArray = (arr, start, stop) => {
    return arr.slice(start, stop + 1);
};

export function removeDuplicates(arr) {
    return arr.filter((obj, index, self) => {
        return index === self.findIndex((t) => t.startNode === obj.startNode && t.endNode === obj.endNode);
    });
}

export function removeWhiteSpacesAndNewLines(str) {
    return str.replace(/\s+/g, ' ').trim();
}

export function stripHtmlTags(input) {
    return input.replace(/<\/?[^>]+(>|$)/g, '');
}

export function removeSpacesAndNewlines(inputStr) {
    return inputStr.replace(/[\r\n\s\u00A0]+/g, '');
}

export function getFullTagSubstring(fullHtml, substring) {
    let startIndex = fullHtml.indexOf(substring);
    let endIndex = startIndex + substring.length;

    if (startIndex === -1) {
        throw new Error('Substring not found in the full string');
    }

    // If the substring starts inside a tag, extend the start to get the full tag
    while (startIndex > 0 && fullHtml[startIndex - 1] !== '>') {
        startIndex--;
    }

    // If the substring ends inside a tag, extend the end to get the full tag
    while (endIndex < fullHtml.length && fullHtml[endIndex] !== '<') {
        endIndex++;
    }

    // Get the extended substring with full tags
    const extendedSubstring = fullHtml.slice(startIndex, endIndex);

    return extendedSubstring;
}

// Helper function to get the surrounding text of a specified range within a node
export const getSurroundingText = (range, additionalChars) => {
    // Clone the original range to avoid modifying it
    let extendedRange = range.cloneRange();

    // Extend the start and end positions of the range
    extendedRange.moveStart('character', -additionalChars);
    extendedRange.moveEnd('character', additionalChars);

    // Return the text content of the extended range
    return extendedRange.toString();
};

export const getSurroundingHTML = (range, additionalChars) => {
    // Clone the original range to avoid modifying it
    let extendedRange = range.cloneRange();

    // Extend the start and end positions of the range
    extendedRange.moveStart('character', -additionalChars);
    extendedRange.moveEnd('character', additionalChars);

    // Return the HTML content of the extended range using Rangy's toHtml method
    return extendedRange.toHtml();
};
