export const INITIAL_STATE = {
    id: null,
    value: null,
    user_description: null,
};

export const FORM_DATA = function (parameterValue) {
    return {
        id: parameterValue.id,
        value: parameterValue.value,
        user_description: parameterValue.user_description,
    };
};
