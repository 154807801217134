import update from 'immutability-helper';
import { ParametersListTypes, AuthTypes, ParameterTypes } from 'core/types';

const initialState = {
    parameters: [],
    isLoading: false,
    isLoaded: false,
    isUpdating: false,
    isUpdated: false,
    isDeleting: false,
    isDeleted: false,
    statusIsUpdating: false,
    statusIsUpdated: false,
    parameter: {},
    parameterValue: {},
    singleParameters: [],
};

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case ParametersListTypes.PARAMETER_LIST_GET_PARAMETERS_LIST_START: {
            return update(state, {
                isLoading: { $set: true },
                isLoaded: { $set: false },
                isUpdating: { $set: false },
                isUpdated: { $set: false },
                isDeleting: { $set: false },
                isDeleted: { $set: false },
            });
        }

        case ParametersListTypes.PARAMETER_LIST_GET_PARAMETERS_LIST: {
            return update(state, {
                isLoading: { $set: false },
                isLoaded: { $set: true },
                isUpdated: { $set: false },
                parameters: { $set: payload.parameters },
                singleParameters: { $set: payload.singleParameters },
                parameter: { $set: {} },
                parameterValue: { $set: {} },
            });
        }

        case ParametersListTypes.PARAMETER_LIST_CREATE_PARAMETER_START: {
            return update(state, {
                isUpdating: { $set: true },
                isUpdated: { $set: false },
            });
        }

        case ParametersListTypes.PARAMETER_LIST_CREATE_PARAMETER: {
            const newParameters = state.parameters;
            const parameterIndex = newParameters.findIndex((parameter) => parameter.id === payload.id);
            parameterIndex === -1 ? newParameters.push(payload) : (newParameters[parameterIndex] = payload);

            return update(state, {
                parameters: { $set: newParameters },
                isUpdating: { $set: false },
                isUpdated: { $set: true },
            });
        }

        case ParametersListTypes.PARAMETER_LIST_UPDATE_PARAMETERS_START: {
            return update(state, {
                isUpdating: { $set: true },
                isUpdated: { $set: false },
            });
        }

        case ParametersListTypes.PARAMETER_LIST_UPDATE_PARAMETERS_SUCCESS: {
            const params = state.parameters;
            const findParam = params.find((parameter) => parameter.id === payload.id);
            findParam.name = payload.parameter;
            const index = params.findIndex((parameter) => parameter.id === payload.id);

            return update(state, {
                parameters: {
                    [index]: {
                        $set: findParam,
                    },
                },
                isUpdating: { $set: false },
                isUpdated: { $set: true },
            });
        }

        case ParametersListTypes.PARAMETER_LIST_UPDATE_PARAMETERS_VALUE_SUCCESS: {
            const params = state.parameters;
            const findParam = params.find((parameter) => parameter.id === payload.parameterId);
            const findParamValueIndex = findParam.values.findIndex((v) => v.id === payload.parameterValue.id);
            findParam.values[findParamValueIndex].value = payload.parameterValue.value;
            const index = params.findIndex((parameter) => parameter.id === payload.parameterId);

            return update(state, {
                parameters: {
                    [index]: {
                        $set: findParam,
                    },
                },
                isUpdating: { $set: false },
                isUpdated: { $set: true },
            });
        }

        case ParameterTypes.CREATE_PARAMETER: {
            return update(state, {
                isUpdating: { $set: false },
                isUpdated: { $set: true },
            });
        }

        case ParameterTypes.UPDATE_PARAMETER: {
            return update(state, {
                isUpdating: { $set: false },
                isUpdated: { $set: true },
            });
        }

        case ParameterTypes.UPDATE_PARAMETER_VALUE: {
            return update(state, {
                isUpdating: { $set: false },
                isUpdated: { $set: true },
            });
        }

        case ParametersListTypes.PARAMETER_LIST_UPDATE_STATUS_PARAMETERS_START: {
            return update(state, {
                statusIsUpdating: { $set: true },
                statusIsUpdated: { $set: false },
            });
        }

        case ParametersListTypes.PARAMETER_LIST_DELETE_PARAMETERS_START: {
            return update(state, {
                isDeleting: { $set: true },
                isDeleted: { $set: false },
            });
        }

        case ParametersListTypes.PARAMETER_LIST_ENABLE_PARAMETERS_SUCCESS: {
            const params = state.parameters;
            const index = params.findIndex((parameter) => parameter.id === payload.parameter.id);

            return update(state, {
                parameters: {
                    [index]: {
                        $set: payload.parameter,
                    },
                },
                statusIsUpdating: { $set: false },
                statusIsUpdated: { $set: true },
            });
        }

        case ParametersListTypes.PARAMETER_LIST_DISABLE_PARAMETERS_SUCCESS: {
            const params = state.parameters;
            const index = params.findIndex((parameter) => parameter.id === payload.parameter.id);

            return update(state, {
                parameters: {
                    [index]: {
                        $set: payload.parameter,
                    },
                },
                statusIsUpdating: { $set: false },
                statusIsUpdated: { $set: true },
            });
        }
        case ParametersListTypes.PARAMETER_LIST_DELETE_PARAMETERS_SUCCESS: {
            const params = state.parameters;
            const index = params.findIndex((parameter) => parameter.id === payload);
            params.splice(index, 1);

            return update(state, {
                parameters: {
                    $set: params,
                },
                isDeleting: { $set: false },
                isDeleted: { $set: true },
            });
        }

        case ParametersListTypes.PARAMETER_LIST_ENABLE_PARAMETERS_VALUE_SUCCESS:
        case ParametersListTypes.PARAMETER_LIST_DISABLE_PARAMETERS_VALUE_SUCCESS: {
            const findParam = state.parameters.find(
                (parameter) => parameter.id === payload.parameterValue.parameter.id,
            );
            const findParamValueIndex = findParam.values.findIndex((v) => v.id === payload.parameterValue.id);
            findParam.values[findParamValueIndex].status = payload.parameterValue.status;
            findParam.status = payload.parameterValue.parameter.status;
            const index = state.parameters.findIndex(
                (parameter) => parameter.id === payload.parameterValue.parameter.id,
            );

            return update(state, {
                parameters: {
                    [index]: {
                        $set: findParam,
                    },
                },
                statusIsUpdating: { $set: false },
                statusIsUpdated: { $set: true },
            });
        }

        case ParametersListTypes.PARAMETER_LIST_DELETE_PARAMETERS_VALUE_SUCCESS: {
            const params = state.parameters;
            const findParam = params.find((parameter) => parameter.id === payload.parameterId);
            const findParamIndex = params.findIndex((parameter) => parameter.id === payload.parameterId);
            const findParamValueIndex = findParam.values.findIndex((v) => v.id === payload.id);
            params[findParamIndex].values.splice(findParamValueIndex, 1);

            return update(state, {
                parameters: {
                    $set: params,
                },
                isDeleting: { $set: false },
                isDeleted: { $set: true },
            });
        }

        case ParametersListTypes.PARAMETER_LIST_GET_PARAMETER_LIST_ERROR: {
            return update(state, {
                isUpdating: { $set: false },
            });
        }

        case ParametersListTypes.PARAMETER_LIST_STATUS_ERROR: {
            return update(state, {
                statusIsUpdating: { $set: false },
            });
        }

        case ParametersListTypes.PARAMETER_LIST_DELETE_ERROR: {
            return update(state, {
                isDeleting: { $set: false },
            });
        }
        case AuthTypes.LOGOUT: {
            return initialState;
        }

        default: {
            return state;
        }
    }
};
