/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { AutoComplete, Button } from 'components';
import { Mapper } from 'utils';
import { Buttons, Images } from 'styles/constant';
import { ParametersService } from 'core/services';
import { useAddParameter } from 'hooks';
import { ParameterTypesList } from 'constant';
import { Icon } from 'components/SelecedLabelingOption/styles';
import { connect } from 'react-redux';
import { useDispatch } from 'react-redux';
import { LabellingHighlightActions } from 'core/actions';
import './styles.scss';

function ParametersS2AutoComplete(props) {
    const { labellingHighlight, highlights } = props;
    const dispatch = useDispatch();
    const { addParameterAsync, addParameterValueAsync, isLoading } = useAddParameter();
    const [parentParameter, setParentParameter] = useState({ label: null, value: null });
    const [childParameter, setChildParameter] = useState({ label: null, value: null });

    const handleS2parameterAdd = (data) => {
        const { parameterId, name, value_id, value } = data;

        // Check if value_id or value is null and create the object accordingly
        let valueToAdd;
        if (value_id == null || value == null) {
            valueToAdd = {
                parameter_id: parameterId,
                name: name,
                type: 'S2',
            };
        } else {
            valueToAdd = {
                parameter_id: parameterId,
                name: name,
                type: 'S2',
                value_id: value_id,
                value: value,
            };
        }

        // Check for duplicates
        const isDuplicate = labellingHighlight.s2.some(
            (item) =>
                item.parameter_id === valueToAdd.parameter_id &&
                (item.value_id === valueToAdd.value_id || (valueToAdd.value_id == null && item.value_id == null)),
        );

        if (!isDuplicate) {
            const updatedS2 = [...labellingHighlight.s2, valueToAdd];

            dispatch(
                LabellingHighlightActions.setLabellingHighlight({
                    ...labellingHighlight,
                    s2: updatedS2,
                }),
            );
        } else {
            // Handle the duplicate case, e.g., show an error message
        }
    };

    const handleAsyncAdd = async () => {
        let parameterId = parentParameter.value !== null ? parentParameter.value : null;
        let valueId = childParameter.value !== null ? childParameter.value : null;
        let parentParamResponse = null;
        let childParamResponse = null;

        if (!parentParameter.label) {
            return;
        }

        // create new parameter and child paramter values, call api.
        if (parentParameter.value === null) {
            if (childParameter.label !== null) {
                parentParamResponse = await addNewParameter(parentParameter.label);
                parameterId = parentParamResponse.id;
            } else {
                // if insert new parameter with nullable value
                parentParamResponse = await addNewParameterWithNullValue(parentParameter.label);
                parameterId = parentParamResponse.id;
            }
        }

        if (childParameter.value == null && childParameter.label !== null) {
            const label = childParameter.label;
            childParamResponse = await addNewChildParameter(parameterId, label);

            const item = childParamResponse.values.find((value) => value.value === childParameter.label);
            valueId = item.id;
        }

        // update react state
        if (parameterId !== null) {
            handleS2parameterAdd({
                parameterId: parameterId,
                name: parentParameter.label,
                value_id: null,
                value: null,
            });

            if (childParameter.label !== null) {
                handleS2parameterAdd({
                    parameterId: parameterId,
                    name: parentParameter.label,
                    value_id: valueId,
                    value: childParameter.label,
                });
            }
        }
        //await ParametersService.getParameters();
        clearParameterInput();
    };

    const addNewParameter = async (name) => {
        const request = {
            parameter: name,
            values: [],
            type: ParameterTypesList.S2,
        };

        try {
            const response = await addParameterAsync(request);
            return response;
        } catch (error) {
            console.error('Error adding parameter:', error);
        }
    };

    const addNewParameterWithNullValue = async (name) => {
        const request = {
            parameter: name,
            values: [
                {
                    value: null,
                },
            ],
            type: ParameterTypesList.S2,
        };

        try {
            const response = await addParameterAsync(request);
            return response;
        } catch (error) {
            console.error('Error adding parameter:', error);
        }
    };

    const addNewChildParameter = async (parentId, label) => {
        const request = {
            parameter_id: parentId,
            values: [
                {
                    value: label,
                },
            ],
            type: ParameterTypesList.S2,
        };

        try {
            const response = await addParameterValueAsync(request);
            return response;
        } catch (error) {
            console.error('Error adding parameter:', error);
        }
    };

    const handleParentParameterChange = (event) => {
        const { value } = event;
        setParentParameter(value);
        setChildParameter({ label: null, value: null });
    };

    const handleParameterValueChange = (event) => {
        const { value } = event;
        setChildParameter(value);
    };

    const getParentParametersOptions = () => {
        return Mapper.mapParametersToOptions(highlights.parameters.s2);
    };

    const selectedValues = labellingHighlight.s2.map((value) => ({
        parameterId: value.parameter_id,
        parentName: value.name,
        valueName: value?.value, // Uses optional chaining to safely access 'value'
        valueId: value?.value_id, // Uses optional chaining to safely access 'id'
    }));

    const getChildParametersOptions = () => {
        const selectedParameterValuesIds = selectedValues.filter((i) => i.valueId != null).map((i) => i.valueId);
        if (!parentParameter.value) return [];

        const parameter = ParametersService.getParameterById(parentParameter.value);
        const values = parameter.values || [];
        const filteredParameterValues = values.filter(
            (parameterValue) => !selectedParameterValuesIds.includes(parameterValue.id),
        );
        return Mapper.mapParameterValuesToOptions(filteredParameterValues);
    };

    const isAlreadyExist = () => {
        if (!childParameter.value && !childParameter.label) {
            return selectedValues
                .filter((i) => i.parameterId === parentParameter.value)
                .some((item) => item.valueId === undefined);
        }

        return false;
    };

    const clearParameterInput = () => {
        setParentParameter({ label: null, value: null });
        setChildParameter({ label: null, value: null });
    };

    const clearParameterValueInput = () => {
        setChildParameter({ label: null, value: null });
    };

    const isSubParameterInputDisabled = parentParameter == null || parentParameter.label == null;

    return (
        <div className="ParametersS2AutoComplete">
            <div className="main-parameter">
                <AutoComplete
                    value={parentParameter}
                    onChange={handleParentParameterChange}
                    placeholder="Select or enter new"
                    name="S2 parameters"
                    options={getParentParametersOptions()}
                />
                {parentParameter.label && (
                    <div className="edit-param-button">
                        <Icon onClick={clearParameterInput} src={Images.CLOSE_GREY} />
                    </div>
                )}
            </div>
            <div className="sub-parameter">
                <AutoComplete
                    disabled={isSubParameterInputDisabled}
                    value={childParameter}
                    onChange={handleParameterValueChange}
                    placeholder="Select or enter new"
                    name="S2 parameter values"
                    options={getChildParametersOptions()}
                />
                {childParameter.label && (
                    <div className="edit-param-button">
                        <Icon onClick={clearParameterValueInput} src={Images.CLOSE_GREY} />
                    </div>
                )}
            </div>
            <div className="action-button">
                <Button
                    disabled={!parentParameter.label || isAlreadyExist()}
                    onClick={handleAsyncAdd}
                    loading={isLoading}
                    type={Buttons.SECONDARY}
                    label="Add to list"
                />
            </div>
        </div>
    );
}

function mapStateToProps(state) {
    const { labellingHighlight, highlights } = state;
    return {
        labellingHighlight: labellingHighlight,
        highlights: highlights,
    };
}

export default connect(mapStateToProps)(ParametersS2AutoComplete);
