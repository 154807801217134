import React from 'react';
import { Tab } from '../LabelingTabs';
import PropTypes from 'prop-types';
import './styles.scss';

const ParameterS1MultiSelect = ({ parameter, title, onClick, selectedValueIds }) => {
    const getButtonClassName = (value) => {
        let className = 'button';
        if (selectedValueIds.includes(value.id)) {
            className += ' selected';
        }
        if (value.disabled) {
            className += ' disabled';
        }
        return className;
    };

    return (
        <Tab key={parameter.id} title={title}>
            <div className="parameter-s1-multiselect">
                <div className="value-container">
                    {parameter.values.map((value) => (
                        <div key={value.id} className="value-item">
                            <button
                                className={getButtonClassName(value)}
                                onClick={() => {
                                    if (!value.disabled) {
                                        onClick({ ...value, parameterId: parameter.id });
                                    }
                                }}
                            >
                                {value.value}
                            </button>
                        </div>
                    ))}
                </div>
            </div>
        </Tab>
    );
};

ParameterS1MultiSelect.propTypes = {
    parameter: PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
        values: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
                value: PropTypes.string.isRequired,
                selected: PropTypes.bool,
                disabled: PropTypes.bool,
            }),
        ).isRequired,
    }).isRequired,
    title: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    selectedValueIds: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])).isRequired,
};

export default ParameterS1MultiSelect;
