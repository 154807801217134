import { ParametersTemplatesTypes } from 'core/types';
import { ParametersTemplatesService, ToastService } from 'core/services';

export function getParametersTemplates() {
    return async (dispatch) => {
        try {
            dispatch({ type: ParametersTemplatesTypes.GET_PARAMETERS_TEMPLATES_START });
            const parametersTemplates = await ParametersTemplatesService.getParametersTemplates();
            dispatch({ type: ParametersTemplatesTypes.GET_PARAMETERS_TEMPLATES, payload: { parametersTemplates } });
        } catch (error) {
            const message = error?.response?.data?.message;
            ToastService.showGenericError(message);
            dispatch({ type: ParametersTemplatesTypes.GET_PARAMETERS_TEMPLATES_ERROR });
        }
    };
}

export function addParametersTemplates(request, callback) {
    return async (dispatch) => {
        try {
            dispatch({ type: ParametersTemplatesTypes.ADD_PARAMETERS_TEMPLATE_START });
            await ParametersTemplatesService.addParametersTemplate(request);
            const parametersTemplates = await ParametersTemplatesService.getParametersTemplates();
            dispatch({ type: ParametersTemplatesTypes.ADD_PARAMETERS_TEMPLATE, payload: { parametersTemplates } });
            ToastService.showSuccess('Parameters template successfully added');
            callback();
        } catch (error) {
            const message = error?.response?.data?.message;
            ToastService.showGenericError(message);
            dispatch({ type: ParametersTemplatesTypes.ADD_PARAMETERS_TEMPLATE_ERROR });
        }
    };
}
