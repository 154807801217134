import React from 'react';
import PropTypes from 'prop-types';
import * as Styles from './styles';
import { Form, Grid } from 'styles/components';
import { Images } from 'styles/constant';
import ExtractedEntitiesSelect from './ExtractedEntitiesSelect';

function CustomParametersSelect(props) {
    const { availableTypes, values, onChange, add } = props;

    const handleEntityChange = (type, index) => {
        values[index].extracted_entity_type = type;
        onChange(values);
    };

    const handleAddEntity = () => {
        let value = [];
        value.push({ extracted_entity_type: availableTypes[0], extracted_entity_value: '' });
        add(value);
    };

    const handleRemoveEntity = (index) => {
        values.splice(index, 1);
        onChange(values);
    };

    const handleEntityValuesChange = (value, index) => {
        values[index].extracted_entity_value = value;
        onChange(values);
    };

    const renderAddButton = () => (
        <Styles.AddButton onClick={handleAddEntity}>
            <Grid.Col mr={10}>
                <Styles.Icon src={Images.ADD_CIRCLE} />
            </Grid.Col>
            <Grid.Col>
                <Styles.AddLabel>Add entity</Styles.AddLabel>
            </Grid.Col>
        </Styles.AddButton>
    );

    return (
        <Styles.Container>
            <Form.Label>Extracted entities</Form.Label>
            <Styles.Content>
                {values.map((entity, index) => (
                    <ExtractedEntitiesSelect
                        onEntityChange={(value, index) => handleEntityChange(value, index)}
                        key={index}
                        entityIndex={index}
                        value={entity}
                        onRemove={() => handleRemoveEntity(index)}
                        availableTypes={availableTypes}
                        onChange={(value, index) => handleEntityValuesChange(value, index)}
                    />
                ))}
            </Styles.Content>
            {renderAddButton()}
        </Styles.Container>
    );
}

CustomParametersSelect.propTypes = {
    onChange: PropTypes.func.isRequired,
    add: PropTypes.func.isRequired,
    values: PropTypes.arrayOf(PropTypes.object),
    availableTypes: PropTypes.array,
};

CustomParametersSelect.defaultProps = {
    values: [],
};

export default CustomParametersSelect;
