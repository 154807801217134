import React, { useEffect, useState } from 'react';
import { Button, Input, SingleSelect } from 'components';
import { SharedTypes } from 'utils';
import { Grid } from 'styles/components';
import { Buttons, Images } from 'styles/constant';
import PropTypes from 'prop-types';
import { OrganisationsActions, ParametersListActions } from '../../core/actions';
import { Icon } from '../../components/SelecedLabelingOption/styles';
import { connect, useSelector } from 'react-redux';
import { INITIAL_STATE, SET_PARAMETER } from './config';
import update from 'immutability-helper';
import { Container } from './styles';
import { Title } from '../Filter/styles';
import { useHistory } from 'react-router-dom';
import { FORM_DATA } from './config';

function ParametersForm(props) {
    const { parameters, updateParameter, getOrganisations, createParameter, isUpdated, isUpdating } = props;
    const [currentParameter, setParameter] = useState(INITIAL_STATE);
    const organisations = useSelector((state) => state.organisations.organisations);
    const [currentParameterValue, setCurrentParameterValue] = useState({ label: null, value: null });
    const [fetched, setFetched] = useState(false);
    const history = useHistory();

    useEffect(() => {
        // Makes sure that new subset is fetched when navigating from other pages
        if (!fetched) {
            setFetched(true);
        }
    }, [fetched]);

    useEffect(() => {
        isUpdated && history.push('/parameters');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isUpdated]);

    useEffect(() => {
        getOrganisations();
    }, [getOrganisations]);

    useEffect(() => {
        const id = history.location.pathname.split('/').pop();
        if (id !== 'create') {
            const current = parameters.find((p) => p.id == id);

            const parameter = SET_PARAMETER(current);

            setParameter(
                update(currentParameter, {
                    $set: parameter,
                }),
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [parameters, createParameter]);

    useEffect(() => {
        document.title = currentParameter.id
            ? `Debunk EU - Edit Parameter - ${currentParameter.parameter}`
            : 'Create Parameter';
    });

    const onSubmit = () => {
        const data = FORM_DATA(currentParameter);
        if (data.id) updateParameter(data.id, data);
        else createParameter(data);
    };

    const handleOnChange = (e) => {
        const { name, value } = e;

        setParameter(
            update(currentParameter, {
                [name]: { $set: value },
            }),
        );
    };

    const handleOnParameterValueChange = (e, i) => {
        const { name, value } = e;

        setParameter(
            update(currentParameter, {
                currentParameter: {
                    $set: {
                        ...currentParameter.values,
                        values: currentParameter.values.map((p, index) => {
                            if (i === index) {
                                p[name] = value;

                                return p;
                            }
                        }),
                    },
                },
            }),
        );
    };

    const handleAddParameterValue = () => {
        setParameter(
            update(currentParameter, {
                values: { $push: [{ value: '' }] },
            }),
        );

        setCurrentParameterValue({ value: null });
    };

    const updateOrganisation = (organisationId) => {
        setParameter(
            update(currentParameter, {
                organisation: { $set: organisationId },
            }),
        );
    };

    const clearParameterInput = (name) => {
        setParameter(
            update(currentParameter, {
                [name]: {
                    $set: '',
                },
            }),
        );
    };

    const clearInput = () => {
        setParameter(
            update(currentParameter, {
                name: { $set: { label: null, value: null } },
            }),
        );
    };

    const clearParameterValueByIndex = (i) => {
        const newValues = currentParameter.values.splice(i, 1);
        setParameter(
            update(currentParameter, {
                currentParameter: {
                    $set: {
                        ...currentParameter.values,
                        values: newValues,
                    },
                },
            }),
        );
    };

    return (
        <>
            <Grid.FixedContainer sticky style={{ maxWidth: 'none' }}>
                <Grid.FixedContainer>
                    <Grid.Row pt={20} pb={20} justifyContent="space-between">
                        <Grid.Col>
                            {!currentParameter.id ? (
                                <Title>Create parameter</Title>
                            ) : (
                                <Title>
                                    Edit parameter <span>{currentParameter.parameter}</span>
                                </Title>
                            )}
                        </Grid.Col>
                        <Grid.Col>
                            <Grid.Row>
                                <Grid.Col mr={10}>
                                    <Button
                                        onClick={() => history.push('/parameters')}
                                        label="Cancel"
                                        type="TERTIARY"
                                    />
                                </Grid.Col>
                                <Grid.Col>
                                    <Button onClick={onSubmit} label="Submit" loading={isUpdating} />
                                </Grid.Col>
                            </Grid.Row>
                        </Grid.Col>
                    </Grid.Row>
                </Grid.FixedContainer>
                <Container>
                    <Grid.FixedContainer>
                        <section>
                            <Grid.Row mb={20}>
                                <SingleSelect
                                    label="Organisation"
                                    name="Organisation"
                                    value={currentParameter.organisation}
                                    onChange={(e) => updateOrganisation(e.value)}
                                    options={organisations.map((o) => {
                                        return { label: o.name, value: o.id };
                                    })}
                                />
                            </Grid.Row>
                            <Grid.Row mb={5}>
                                <SingleSelect
                                    label="Type"
                                    searchable={false}
                                    value={currentParameter.type}
                                    onChange={(e) => handleOnChange(e)}
                                    options={[
                                        { label: 'A1 - Parameter with all listed subparameters', value: 'A1' },
                                        { label: 'A2 - Custom parameter and custom subparameter', value: 'A2' },
                                        { label: 'A3 - Parameter with custom subparameters', value: 'A3' },

                                        { label: 'S1 - Parameter with all listed subparameters', value: 'S1' },
                                        { label: 'S2 - Custom parameter and custom subparameter', value: 'S2' },
                                        { label: 'S3 - Parameter with custom subparameters', value: 'S3' },
                                    ]}
                                    name="type"
                                />
                                {currentParameter.label && (
                                    <Grid.Col ml={-30} style={{ zIndex: 1 }}>
                                        <Icon onClick={clearInput} src={Images.CLOSE_GREY} />
                                    </Grid.Col>
                                )}
                            </Grid.Row>
                            <Grid.Row mb={20}>
                                <Grid.Col flex={1}>
                                    <Input
                                        value={currentParameter.parameter}
                                        onChange={(e) => handleOnChange(e)}
                                        name="parameter"
                                        label="Name"
                                    />
                                </Grid.Col>
                                {currentParameter.parameter && (
                                    <Grid.Col ml={-25} mt={28} style={{ zIndex: 1 }}>
                                        <Icon
                                            onClick={() => clearParameterInput('parameter')}
                                            src={Images.CLOSE_GREY}
                                        />
                                    </Grid.Col>
                                )}
                            </Grid.Row>
                            <Grid.Row mb={20}>
                                <Grid.Col flex={1}>
                                    <Input
                                        label="User description"
                                        name="user_description"
                                        value={currentParameter.user_description}
                                        onChange={(e) => handleOnChange(e)}
                                    />
                                </Grid.Col>
                                {currentParameter.user_description && (
                                    <Grid.Col ml={-25} mt={28} style={{ zIndex: 1 }}>
                                        <Icon
                                            onClick={() => clearParameterInput('user_description')}
                                            src={Images.CLOSE_GREY}
                                        />
                                    </Grid.Col>
                                )}
                            </Grid.Row>
                            <Grid.Row mb={20}>
                                <Grid.Col flex={1}>
                                    <Input
                                        label="Max values"
                                        name="max_values"
                                        value={currentParameter.max_values}
                                        onChange={(e) => handleOnChange(e)}
                                    />
                                </Grid.Col>
                                {currentParameter.max_values && (
                                    <Grid.Col ml={-25} mt={28} style={{ zIndex: 1 }}>
                                        <Icon
                                            onClick={() => clearParameterInput('max_values')}
                                            src={Images.CLOSE_GREY}
                                        />
                                    </Grid.Col>
                                )}
                            </Grid.Row>
                            <Grid.Row mb={20}>
                                <Grid.Col flex={1}>
                                    <p>Subparameters</p>
                                </Grid.Col>
                            </Grid.Row>
                            {currentParameter.values.map((v, i) => {
                                return (
                                    <Grid.Row mb={5} key={i}>
                                        <Grid.Col flex={1}>
                                            <Input
                                                value={v.label}
                                                onChange={(e) => handleOnParameterValueChange(e, i)}
                                                placeholder="Select or enter new"
                                                name="label"
                                            />
                                        </Grid.Col>
                                        {v.label && (
                                            <Grid.Col ml={-25} style={{ zIndex: 1 }}>
                                                <Icon
                                                    onClick={() => clearParameterValueByIndex(i)}
                                                    src={Images.CLOSE_GREY}
                                                />
                                            </Grid.Col>
                                        )}
                                    </Grid.Row>
                                );
                            })}
                            <Grid.Row mb={20}>
                                <Grid.Col>
                                    <Button
                                        label="Add subparameter"
                                        onClick={() => handleAddParameterValue(currentParameterValue)}
                                        type={Buttons.SECONDARY}
                                    />
                                </Grid.Col>
                            </Grid.Row>
                            <Grid.Row mb={5}>
                                <SingleSelect
                                    label="Status"
                                    searchable={false}
                                    value={currentParameter.status}
                                    onChange={(e) => handleOnChange(e)}
                                    options={[
                                        { label: 'Disable', value: '0' },
                                        { label: 'Enable', value: 1 },
                                    ]}
                                    name="status"
                                />
                                {currentParameter.label && (
                                    <Grid.Col ml={-30} style={{ zIndex: 1 }}>
                                        <Icon onClick={clearInput} src={Images.CLOSE_GREY} />
                                    </Grid.Col>
                                )}
                            </Grid.Row>
                        </section>
                    </Grid.FixedContainer>
                </Container>
            </Grid.FixedContainer>
        </>
    );
}

ParametersForm.propTypes = {
    disabled: PropTypes.bool,
    parameters: PropTypes.arrayOf(SharedTypes.ParameterType),
    parameter: PropTypes.object,
    getParameters: PropTypes.func.isRequired,
    onParameterValueAdd: PropTypes.func,
    selectedParametersValues: PropTypes.arrayOf(SharedTypes.LabelingParameterValueType),
    updateParameter: PropTypes.func.isRequired,
    createParameter: PropTypes.func.isRequired,
    organisations: PropTypes.arrayOf(PropTypes.object),
    getOrganisations: PropTypes.func.isRequired,
    isUpdating: PropTypes.bool.isRequired,
    isUpdated: PropTypes.bool.isRequired,
};

ParametersForm.defaultProps = {
    selectedParametersValues: [],
    disabled: false,
};

function mapStateToProps(state) {
    const { parametersList } = state;
    const { isUpdating, isUpdated, isLoading, isLoaded, parameter } = parametersList;
    return { parameters: parametersList.parameters, parameter, isLoading, isLoaded, isUpdating, isUpdated };
}

const mapDispatchToProps = {
    getParameters: ParametersListActions.getParametersList,
    updateParameter: ParametersListActions.updateParameter,
    createParameter: ParametersListActions.createParameter,
    getOrganisations: OrganisationsActions.getOrganisations,
};

export default connect(mapStateToProps, mapDispatchToProps)(ParametersForm);
