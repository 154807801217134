import _ from 'lodash';
import { store } from 'core/store';
import { LabelingStates } from 'constant';

export const mapArticle = (article, checkDisableSimilar, status) => {
    const { id, organisation } = store.getState().auth.profile;
    const reservation = getReservation(article.labeling, id);
    let disabled = false;

    if (status && checkDisableSimilar) {
        disabled = article.labelingStatus !== 'Not labeled';
        if (
            reservation &&
            reservation.user_id === id &&
            reservation.project_id === organisation.id &&
            !reservation.time_left
        )
            disabled = true;
        if (!reservation) disabled = true;
    }

    return {
        id: article.id,
        labelingState: LabelingStates.ACTIVE,
        expired: false,
        parentId: Number(article._parent),
        title: article.title,
        locale: article.lang,
        reservation,
        labelingStatus: article.labelingStatus,
        publishDate: article.date_published,
        domain: article.domain,
        similarCount: article.similar ? article.similar.length : 0,
        infometer: mapInfometer(article.infometer),
        votes: article.votes,
        url: article.url,
        isBookmarked: article.isBookmarked,
        isHiddenForMe: article.hidden_for_users ? !!article.hidden_for_users.find((userId) => userId === id) : false,
        isHiddenForAll: article.hidden === 1,
        debunkReachAccumulated: article.debunkReachAccumulated,
        debunkReach: article.debunk_reach,
        filters: article.filters ? article.filters : [],
        disabled,
    };
};

export const countVotes = (votes) => {
    return _.sum(votes.map((vote) => vote.vote));
};

export const getUserVote = (votes) => {
    const { id } = store.getState().auth.profile;
    const userVote = votes.find((vote) => vote.id === id);
    return userVote ? userVote.vote : 0;
};

const mapInfometer = (infometer) => {
    return {
        total: infometer.total.toFixed(2),
        importance: infometer.importance.toFixed(2),
        social: infometer.social.toFixed(2),
        reach: infometer.reach.toFixed(2),
    };
};

const getReservation = (labeling, userId) => {
    if (!labeling) return null;
    if (!labeling.reserved) return null;
    const userReservations = labeling.reserved.filter((reservation) => reservation.user_id === userId);
    return userReservations[userReservations.length - 1];
};
