import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FiCopy, FiEdit3, FiX } from 'react-icons/fi';
import './style.scss';

const HighlightActionsPopup = ({ position, onCopy, onHighlight, onClose, isVisible }) => {
    const [isHighlightVisible, setIsHighlightVisible] = useState(false);

    useEffect(() => {
        const container = document.getElementById('google_translate_element');
        const updateVisibility = () => {
            // Check if the container exists and does not have the 'show' class
            const shouldShow = container && !container.classList.contains('show');
            setIsHighlightVisible(shouldShow);
        };

        // Initial check
        updateVisibility();

        // Optional: If the class can change after initial render, you might want to observe changes
        // This part is optional and can be omitted if the class does not change dynamically
        const observer = new MutationObserver(updateVisibility);
        if (container) {
            observer.observe(container, {
                attributes: true,
                attributeFilter: ['class'],
            });
        }

        // Cleanup function to disconnect the observer
        return () => {
            if (container) {
                observer.disconnect();
            }
        };
    }, []); // Empty dependency array means this effect runs once on mount

    if (!isVisible) return null;

    return (
        <div
            className="popupContainer"
            style={{
                top: `${position.top - 15}px`,
                left: `${position.left + 5}px`,
            }}
        >
            {isHighlightVisible && (
                <button className="iconButton" onClick={onHighlight} aria-label="Highlight">
                    <FiEdit3 />
                </button>
            )}
            <button className="iconButton" onClick={onCopy} aria-label="Copy">
                <FiCopy />
            </button>
            <button className="closeButton" onClick={onClose} aria-label="Close">
                <FiX />
            </button>
        </div>
    );
};

HighlightActionsPopup.propTypes = {
    position: PropTypes.shape({
        top: PropTypes.number.isRequired,
        left: PropTypes.number.isRequired,
    }).isRequired,
    onCopy: PropTypes.func.isRequired,
    onHighlight: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    isVisible: PropTypes.bool.isRequired,
};

export default HighlightActionsPopup;
