export const COLUMNS = (options) => {
    return [
        {
            Header: 'ID',
            accessor: 'id',
            filter: 'multiple',
        },
        {
            Header: 'Organisation',
            accessor: (row) => {
                if (row.organisations) {
                    return row.organisations.map((x) => x.name).join(', ');
                }
                return [];
            },
            filter: 'multiple',
        },
        {
            Header: 'Title',
            accessor: 'name',
            filter: 'search',
        },
        {
            Header: 'Type',
            accessor: (row) => row.type.type,
            filter: 'multiple',
        },
        {
            Header: 'Columns',
            accessor: (row) => {
                let arr = [];
                row.columns &&
                    row.columns.map((c) => {
                        options[c] && arr.push(options[c].title);
                        return c;
                    });
                return arr.join('\r\n');
            },
            filter: 'multiple',
        },
        {
            Header: 'Rows',
            accessor: (row) => {
                let arr = [];
                row.rows &&
                    row.rows.map((r) => {
                        options[r] && arr.push(options[r].title);
                        return r;
                    });
                return arr.join('\r\n');
            },
            filter: 'multiple',
        },
        {
            Header: 'Values',
            accessor: (row) => {
                let arr = [];
                row.values &&
                    row.values.map((v) => {
                        options[v.col] && arr.push(`${options[v.col].title} (${v.calc})`);
                        return v;
                    });
                return arr.join('\r\n');
            },
            filter: 'multiple',
        },
        {
            Header: 'Top',
            accessor: (row) => row.top,
            filter: 'multiple',
        },
        {
            Header: 'Include zero',
            accessor: (row) => (row.include_zero ? 'Yes' : 'No'),
            filter: 'multiple',
        },
        {
            Header: 'Last period',
            accessor: (row) => (row.last_period ? 'Yes' : 'No'),
            filter: 'multiple',
        },
        {
            Header: 'Period',
            accessor: (row) => row.period,
            filter: 'multiple',
        },
    ];
};
