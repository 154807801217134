import { ChartsTypes } from 'core/types';
import { ChartsService, ToastService } from 'core/services';

export function getCharts() {
    return async (dispatch) => {
        try {
            dispatch({ type: ChartsTypes.GET_CHARTS_START });
            const charts = await ChartsService.getCharts();
            dispatch({ type: ChartsTypes.GET_CHARTS, payload: { charts } });
        } catch (error) {
            const message = error?.response?.data?.message;
            ToastService.showGenericError(message);
            dispatch({ type: ChartsTypes.GET_CHARTS_ERROR });
        }
    };
}

export function getChartOptions() {
    return async (dispatch) => {
        try {
            dispatch({ type: ChartsTypes.GET_OPTIONS_START });
            const options = await ChartsService.getChartOptions();
            dispatch({ type: ChartsTypes.GET_CHARTS_OPTIONS, payload: { options } });
        } catch (error) {
            const message = error?.response?.data?.message;
            ToastService.showGenericError(message);
        }
    };
}

export function getChartTypes() {
    return async (dispatch) => {
        try {
            dispatch({ type: ChartsTypes.GET_TYPES_START });
            const types = await ChartsService.getChartTypes();
            dispatch({ type: ChartsTypes.GET_CHARTS_TYPES, payload: { types } });
        } catch (error) {
            const message = error?.response?.data?.message;
            ToastService.showGenericError(message);
        }
    };
}

export function createChart(chart) {
    return async (dispatch) => {
        try {
            dispatch({ type: ChartsTypes.UPDATE_CHARTS_START });
            await ChartsService.createChart(chart);
            dispatch({ type: ChartsTypes.CREATE_CHART });
        } catch (error) {
            const message = error?.response?.data?.message;
            ToastService.showGenericError(message);
            dispatch({ type: ChartsTypes.GET_CHARTS_ERROR });
        }
    };
}

export function updateChart(id, chart) {
    return async (dispatch) => {
        try {
            dispatch({ type: ChartsTypes.UPDATE_CHARTS_START });
            await ChartsService.updateChart(id, chart);
            dispatch({ type: ChartsTypes.CREATE_CHART });
        } catch (error) {
            const message = error?.response?.data?.message;
            ToastService.showGenericError(message);
            dispatch({ type: ChartsTypes.GET_CHARTS_ERROR });
        }
    };
}

export function deleteChart(id) {
    return async (dispatch) => {
        try {
            dispatch({ type: ChartsTypes.UPDATE_CHARTS_START });
            await ChartsService.deleteChart(id);
            dispatch({ type: ChartsTypes.REMOVE_CHART });
        } catch (error) {
            const message = error?.response?.data?.message;
            ToastService.showGenericError(message);
            dispatch({ type: ChartsTypes.GET_CHARTS_ERROR });
        }
    };
}

export function getChart(id, data) {
    return async (dispatch) => {
        try {
            dispatch({ type: ChartsTypes.GET_CHART_START });
            const chart = await ChartsService.getChart(id, data);
            dispatch({ type: ChartsTypes.GET_CHART, payload: { chart } });
        } catch (error) {
            const message = error?.response?.data?.message;
            ToastService.showGenericError(message);
            dispatch({ type: ChartsTypes.GET_CHARTS_ERROR });
        }
    };
}

export function setChart(chart) {
    return async (dispatch) => {
        try {
            dispatch({ type: ChartsTypes.SET_CHART, payload: { chart } });
        } catch (error) {
            const message = error?.response?.data?.message;
            ToastService.showGenericError(message);
        }
    };
}

export function setFilteredChart(chart) {
    return async (dispatch) => {
        try {
            dispatch({ type: ChartsTypes.SET_FILTERED_CHART, payload: { chart } });
        } catch (exception) {
            ToastService.showGenericError();
        }
    };
}

export function getChartArticles(id, data) {
    return async (dispatch) => {
        try {
            dispatch({ type: ChartsTypes.GET_CHART_ARTICLES_START });
            const chartArticles = await ChartsService.getChartArticles(id, data);
            dispatch({ type: ChartsTypes.GET_CHART_ARTICLES, payload: { chartArticles } });
        } catch (error) {
            const message = error?.response?.data?.message;
            ToastService.showGenericError(message);
            dispatch({ type: ChartsTypes.GET_CHART_ARTICLES_ERROR, payload: { exception: error } });
        }
    };
}

export function resetChartArticles() {
    return async (dispatch) => {
        dispatch({ type: ChartsTypes.RESET_CHART_ARTICLES });
    };
}
