import { FiltersTypes } from 'core/types';
import { FiltersService, ToastService } from 'core/services';

export function getFilters(subset = 'select') {
    return async (dispatch) => {
        try {
            dispatch({ type: FiltersTypes.GET_FILTERS_START });
            const filters = await FiltersService.getFilters(subset);
            dispatch({ type: FiltersTypes.GET_FILTERS, payload: { filters, table: subset !== 'select' } });
        } catch (error) {
            const message = error?.response?.data?.message;
            ToastService.showGenericError(message);
            dispatch({ type: FiltersTypes.GET_FILTERS_ERROR });
        }
    };
}

export function createFilter(filter, languages) {
    return async (dispatch) => {
        try {
            dispatch({ type: FiltersTypes.UPDATE_FILTERS_START });
            await FiltersService.createFilter(filter, languages);
            dispatch({ type: FiltersTypes.CREATE_FILTER });
        } catch (error) {
            const message = error?.response?.data?.message;
            ToastService.showGenericError(message);
            dispatch({ type: FiltersTypes.GET_FILTERS_ERROR });
        }
    };
}

export function updateFilter(id, filter, languages) {
    return async (dispatch) => {
        try {
            dispatch({ type: FiltersTypes.UPDATE_FILTERS_START });
            await FiltersService.updateFilter(id, filter, languages);
            dispatch({ type: FiltersTypes.CREATE_FILTER });
        } catch (error) {
            const message = error?.response?.data?.message;
            ToastService.showGenericError(message);
            dispatch({ type: FiltersTypes.GET_FILTERS_ERROR });
        }
    };
}

export function deleteFilter(id) {
    return async (dispatch) => {
        try {
            dispatch({ type: FiltersTypes.UPDATE_FILTERS_START });
            await FiltersService.deleteFilter(id).then(() => {
                ToastService.showSuccess('Filter successfully deleted');
            });
            dispatch({ type: FiltersTypes.REMOVE_FILTER, payload: id });
        } catch (error) {
            if (error.response.status !== 403) {
                ToastService.showGenericError(error);
            } else {
                ToastService.showGenericError('You are not allowed...');
            }

            dispatch({ type: FiltersTypes.GET_FILTERS_ERROR });
            throw new Error(error);
        }
    };
}
