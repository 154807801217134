import styled from 'styled-components';
import { Colors } from 'styles/constant';

export const ModalHeaderWrapper = styled.div`
    background-color: ${Colors.WHITE};
    z-index: 100;
    position: fixed;
    width: 480px;
`;

export const ModalHeader = styled.div`
    padding: 20px;
    border-bottom: 1px solid ${Colors.MODAL_BORDER};
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

export const ButtonsWrapper = styled.div`
    padding: 10px 0;
    display: flex;
    justify-content: center;
`;

export const Content = styled.div`
    margin-top: 100px;
`;
