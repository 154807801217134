/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React from 'react';
import { Images } from 'styles/constant';
import S3ParameterSelector from './ParametersS3AutoComplete';
import { connect } from 'react-redux';
import { useDispatch } from 'react-redux';
import { LabellingHighlightActions } from 'core/actions';
import './styles.scss';

const ParameterS3Select = (props) => {
    const { parameter, labellingHighlight } = props;
    const dispatch = useDispatch();

    const listItems = labellingHighlight.s3
        .map((value) => ({
            parameterId: value.parameter_id,
            parentName: value.name,
            valueName: value?.value, // Uses optional chaining to safely access 'value'
            valueId: value?.value_id, // Uses optional chaining to safely access 'id'
        }))
        .filter((i) => i.parameterId === parameter.id);

    const handleRemove = (item) => {
        const { parameterId, valueId } = item;

        // Filter out the item to be removed
        const updatedS3 = labellingHighlight.s3.filter(
            (parameter) => !(parameter.parameter_id === parameterId && parameter.value_id === valueId),
        );

        dispatch(
            LabellingHighlightActions.setLabellingHighlight({
                ...labellingHighlight,
                s3: updatedS3,
            }),
        );
    };

    const updateSelectedValue = (parameterId, valueId, valueLabel) => {
        // Check if the value is already in the s3 array
        const valueExists = labellingHighlight.s3.some(
            (item) => item.parameter_id === parameterId && item.value_id === valueId,
        );

        // Only add the new item if it doesn't exist
        if (!valueExists) {
            const newValue = {
                parameter_id: parameterId,
                name: parameter.name, // The name should be set appropriately
                type: 'S3',
                value_id: valueId,
                value: valueLabel,
            };

            const updatedS3 = [...labellingHighlight.s3, newValue];

            dispatch(
                LabellingHighlightActions.setLabellingHighlight({
                    ...labellingHighlight,
                    s3: updatedS3,
                }),
            );
        }
    };

    const handleParameterValueAdd = (item) => {
        updateSelectedValue(item.parameterId, item.id, item.value);
    };

    return (
        <>
            <div>
                <S3ParameterSelector
                    disabled={false}
                    onParameterValueAdd={handleParameterValueAdd}
                    selectedParametersValues={[]}
                    parameter={parameter}
                />
            </div>
            <div className="parameter-container">
                {listItems.map((item, index) => (
                    <div key={index} className="parameter-item">
                        {item.parentName && <span className="title">{`${item.parentName} \u2192`}</span>}
                        <span className="label">{item.valueName}</span>
                        <img src={Images.CLOSE_WHITE} onClick={() => handleRemove(item)} className="remove-button" />
                    </div>
                ))}
            </div>
        </>
    );
};

function mapStateToProps(state) {
    const { labellingHighlight } = state;
    return {
        labellingHighlight: labellingHighlight,
    };
}

export default connect(mapStateToProps)(ParameterS3Select);
