import { useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ArticlesActions } from 'features/articles/actions';
import useDeepCompareEffect from 'use-deep-compare-effect';
import usePaging from '../usePaging';
import { useFilter } from 'hooks';
import { useArticlesCount } from 'features/articles/hooks';
import { useLocation } from 'react-router-dom';
import { UrlUtils } from 'utils';
import { xor, omit, debounce, isEqual } from 'lodash';

function useArticles(props) {
    const { labeling } = props;
    const { filter, setFilterValue } = useFilter();
    const { paging, page } = usePaging();
    const { getArticlesCount } = useArticlesCount();
    const dispatch = useDispatch();
    const location = useLocation();
    const { articles, isLoading, isLoaded, isCancelingReserved, isReserving, count, chartData } = useSelector(
        (state) => state.articles,
    );

    const [selectedArticlesIds, setSelectedArticlesIds] = useState([]);
    const sort = { sort: filter.sort, order: filter.order };

    const getArticleHandler = (newFilter, newPaging) => {
        dispatch(ArticlesActions.getArticles({ filter: newFilter, paging: newPaging }));
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debouncedGetArticles = useCallback(
        debounce(function (newFilter, newPaging) {
            getArticleHandler(newFilter, newPaging);
        }, 300),
        [getArticleHandler],
    );

    useDeepCompareEffect(() => {
        if (!filter.chart && !checkParametersDiff({})) {
            if (labeling) {
                getArticlesCount();
            }

            if (!filter.labeling_status && labeling) {
                dispatch(ArticlesActions.getReservedArticles({ sort, paging }));
            } else {
                debouncedGetArticles(filter, paging);
            }
        }
    }, [filter]);

    useEffect(() => {
        const searchParams = UrlUtils.parseSearch(location.search);

        if (page === 1 && !Object.prototype.hasOwnProperty.call(searchParams, 'page')) {
            return;
        } else {
            if (!filter.chart) {
                if (labeling) {
                    getArticlesCount();
                }

                if (!filter.labeling_status && labeling) {
                    dispatch(ArticlesActions.getReservedArticles({ sort, paging }));
                } else {
                    console.log(`page-${Math.floor(new Date().getTime() / 1000)}`);
                    debouncedGetArticles(filter, paging);
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page]);

    useDeepCompareEffect(() => {
        const searchParams = UrlUtils.parseSearch(location.search);

        if (page === 1 && !Object.prototype.hasOwnProperty.call(searchParams, 'page')) {
            return;
        } else {
            if (!filter.chart) {
                if (labeling) {
                    getArticlesCount();
                }

                if (!filter.labeling_status && labeling) {
                    dispatch(ArticlesActions.getReservedArticles({ sort, paging }));
                } else {
                    console.log(`page-${Math.floor(new Date().getTime() / 1000)}`);
                    debouncedGetArticles(filter, paging);
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sort]);

    function compareObjectKeys(object1, object2) {
        for (let key in object1) {
            if (!(key in object2)) {
                return false;
            }
        }

        for (let key in object2) {
            if (!(key in object1)) {
                return false;
            }
        }

        return true;
    }

    const checkParametersDiff = () => {
        let isDifferent = false;
        const searchParams = UrlUtils.parseSearch(location.search);
        const filteredSearchParams = omit(searchParams, 'page');

        for (const [key, value] of Object.entries(filteredSearchParams)) {
            if (key !== 'project_id' && filter[key] && !isEqual(filter[key], value)) {
                isDifferent = true;
                break;
            }
        }

        if (searchParams && Object.keys(searchParams).length !== 0) {
            const isEqual = compareObjectKeys(filter, searchParams);
            if (!isEqual) {
                return true;
            }
        }

        return isDifferent;
    };

    const reserveArticles = (onError) => {
        const reserveFilter = { ...filter };
        dispatch(
            ArticlesActions.reserveArticles(
                { filter: reserveFilter, paging },
                selectedArticlesIds,
                () => {
                    clearSelectedArticlesIds();

                    if (filter.labeling_status === undefined) {
                        dispatch(ArticlesActions.getReservedArticles({ sort, paging }));
                    } else {
                        setFilterValue('labeling_status', undefined);
                    }
                },
                onError,
            ),
        );
    };

    const cancelReservedArticles = () => {
        dispatch(ArticlesActions.cancelReservedArticles({ filter, paging }));
    };

    const selectArticleId = (id) => {
        setSelectedArticlesIds(xor(selectedArticlesIds, [id]));
    };

    const clearSelectedArticlesIds = () => {
        setSelectedArticlesIds([]);
    };

    return {
        articles,
        isLoading,
        isCancelingReserved,
        isReserving,
        isLoaded,
        reserveArticles,
        cancelReservedArticles,
        selectedArticlesIds,
        selectArticleId,
        clearSelectedArticlesIds,
        total: count,
        chartData,
    };
}

export default useArticles;
