import React from 'react';
import PropTypes from 'prop-types';
import { Mapper } from 'utils';
import * as Styles from './styles';
import SingleSelect from '../../SingleSelect';
import Input from '../../Input';
import { Grid } from 'styles/components';
import { Images } from 'styles/constant';
import Icon from '../../Icon';

function ExtractedEntitiesSelect(props) {
    const { availableTypes, onChange, value, onRemove, onEntityChange, entityIndex } = props;

    const handleEntityChange = (event) => {
        const { value } = event;
        onEntityChange(value, entityIndex);
    };

    const handleOnChange = (event) => {
        const { value } = event;
        onChange(value, entityIndex);
    };

    return (
        <Styles.Container>
            <Grid.Row>
                <Grid.Col flex={1} mr={10} style={{ maxWidth: '100%' }}>
                    <Grid.Row mb={5}>
                        <SingleSelect
                            clearable={false}
                            onChange={handleEntityChange}
                            name="extracted_entity_type"
                            value={value.extracted_entity_type}
                            options={Mapper.mapAvailableTypesToOptions(availableTypes)}
                        />
                    </Grid.Row>
                    <Grid.Row>
                        <Input
                            onChange={handleOnChange}
                            value={value.extracted_entity_value}
                            name="extracted_entity_value"
                        />
                    </Grid.Row>
                </Grid.Col>
                <Grid.Col mr={10}>
                    <Icon onClick={() => onRemove(entityIndex)} title="Remove" source={Images.CLOSE_GREY} />
                </Grid.Col>
            </Grid.Row>
        </Styles.Container>
    );
}

ExtractedEntitiesSelect.propTypes = {
    onChange: PropTypes.func.isRequired,
    onEntityChange: PropTypes.func.isRequired,
    onRemove: PropTypes.func.isRequired,
    value: PropTypes.object,
    availableTypes: PropTypes.array,
    entityIndex: PropTypes.number,
};

ExtractedEntitiesSelect.defaultProps = {
    value: null,
};

export default ExtractedEntitiesSelect;
