import React, { useEffect, useState } from 'react';
import { ActiveFilter, ActiveFiltersContainer, Container, ExportBtn } from './styles';
import { Grid } from 'styles/components';
import { SelectedOption, ParametersMissingModal } from 'components';
import {
    CategoriesService,
    DomainsService,
    FiltersService,
    LanguagesService,
    ParametersService,
    UsersService,
    ClassificationsService,
} from 'core/services';
import { LABELING_STATUSES, POSITIVITY_OPTIONS } from 'constant';
import { DateUtils, TextUtils } from 'utils';
import { useFilter, usePermission } from 'hooks';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { store } from 'core/store';

const getSelectedOption = (filter, field, title, resetFilterItem) =>
    filter[field] && (
        <ActiveFilter>
            <SelectedOption onClear={() => resetFilterItem(field, [])} title={title} label={filter[field]} />
        </ActiveFilter>
    );

function ActiveFilters({ isCharts, onExportResults }) {
    const { filter, updateFilter } = useFilter();
    const parametersKeys = Object.keys(filter).filter((key) => key.startsWith('parameters'));
    const entitiesKeys = Object.keys(filter).filter((key) => key.startsWith('extracted_entities'));
    const classificationKeys = Object.keys(filter).filter((key) => key.startsWith('classifications'));
    const history = useHistory();
    const { parametersMissing } = store.getState().search;
    const [parameterMissingModal, setParameterMissingModal] = useState(parametersMissing);
    const hasExportPermission = usePermission('ROLE_EXPORT');

    useEffect(() => {
        setParameterMissingModal(parametersMissing);
    }, [parametersMissing, filter]);

    useEffect(() => {
        const mainHeader = document.getElementById('mainHeader');
        const main = document.getElementById('main');
        if (main && mainHeader) {
            const height = mainHeader.offsetHeight;
            main.style.maxHeight = `calc(100vh - 56px - ${height}px)`;
        }
    }, [history.location]);

    const resetFilterItem = (key, initialValue) => {
        updateFilter({ ...filter, [key]: initialValue });
    };

    const resetClassificationItem = (key, value, initialValue) => {
        updateFilter({ ...filter, [key]: initialValue, [value]: initialValue });
    };

    return (
        <Container isCharts={isCharts}>
            <ParametersMissingModal visible={parameterMissingModal} onClose={() => setParameterMissingModal(false)} />
            <Grid.FluidContainer>
                <ActiveFiltersContainer>
                    {parametersKeys.map((key) => {
                        const parameterId = TextUtils.getParameterId(key);
                        const parameter = ParametersService.getParameterById(parameterId);
                        if (parameter) {
                            return (
                                <ActiveFilter key={key}>
                                    <SelectedOption
                                        onClear={() => resetFilterItem(key, [])}
                                        title={parameter.name}
                                        label={filter[key].map((id) =>
                                            ParametersService.getParameterValueById(parameterId, id)
                                                ? ParametersService.getParameterValueById(parameterId, id).value
                                                : '',
                                        )}
                                    />
                                </ActiveFilter>
                            );
                        }
                        return <React.Fragment key={key} />;
                    })}
                    {filter['filters[must]'] && (
                        <ActiveFilter>
                            <SelectedOption
                                onClear={() => resetFilterItem('filters[must]', null)}
                                title="Keywords"
                                label={filter['filters[must]']}
                            />
                        </ActiveFilter>
                    )}
                    {filter.from && filter.to && (
                        <ActiveFilter>
                            <SelectedOption title="Date range" label={`${filter.from} - ${filter.to || ''}`} />
                        </ActiveFilter>
                    )}
                    {filter.date && (
                        <ActiveFilter>
                            <SelectedOption title="Date" label={DateUtils.getRelativeDateLabel(filter.date)} />
                        </ActiveFilter>
                    )}
                    {filter.override_user_domains === 1 && (
                        <ActiveFilter>
                            <SelectedOption
                                onClear={() => resetFilterItem('override_user_domains', [])}
                                title="Ignore domains limitations"
                                label={filter.override_user_domains === 1 ? 'True' : 'False'}
                            />
                        </ActiveFilter>
                    )}
                    {filter.override_user_filters === 1 && (
                        <ActiveFilter>
                            <SelectedOption
                                onClear={() => resetFilterItem('override_user_filters', [])}
                                title="Ignore filters limitations"
                                label={filter.override_user_filters === 1 ? 'True' : 'False'}
                            />
                        </ActiveFilter>
                    )}
                    {filter.lang && (
                        <ActiveFilter>
                            <SelectedOption
                                onClear={() => resetFilterItem('lang', [])}
                                title="Languages"
                                label={filter.lang.map((iso) => LanguagesService.getLanguageByIso(iso).name)}
                            />
                        </ActiveFilter>
                    )}
                    {filter.categories_ids && (
                        <ActiveFilter>
                            <SelectedOption
                                onClear={() => resetFilterItem('categories_ids', [])}
                                title="Categories"
                                label={filter.categories_ids.map((id) => CategoriesService.getCategoryById(id).name)}
                            />
                        </ActiveFilter>
                    )}
                    {filter.site_ids && (
                        <ActiveFilter>
                            <SelectedOption
                                onClear={() => resetFilterItem('site_ids', [])}
                                title="Domains"
                                label={filter.site_ids.map((id) => DomainsService.getDomainById(id).full_domain)}
                            />
                        </ActiveFilter>
                    )}
                    {filter.filters_ids && (
                        <ActiveFilter>
                            <SelectedOption
                                onClear={() => resetFilterItem('filters_ids', [])}
                                title="Filters"
                                label={filter.filters_ids.map((id) => FiltersService.getFilterById(id).name)}
                            />
                        </ActiveFilter>
                    )}
                    {filter.filtersSentiments && (
                        <ActiveFilter>
                            <SelectedOption
                                onClear={() => resetFilterItem('filtersSentiments', [])}
                                title="Filters sentiment"
                                label={filter.filtersSentiments.map(
                                    (value) => POSITIVITY_OPTIONS.find((status) => status.value === value).label,
                                )}
                            />
                        </ActiveFilter>
                    )}
                    {filter.labeling_status && (
                        <ActiveFilter>
                            <SelectedOption
                                onClear={() => resetFilterItem('labeling_status', null)}
                                title="Labeling status"
                                label={
                                    LABELING_STATUSES.find((status) => status.value === filter.labeling_status).label
                                }
                            />
                        </ActiveFilter>
                    )}
                    {filter.labeled_by && (
                        <ActiveFilter>
                            <SelectedOption
                                onClear={() => resetFilterItem('labeled_by', [])}
                                title="Labeled by"
                                label={filter.labeled_by.map((id) => UsersService.getUserById(id).username)}
                            />
                        </ActiveFilter>
                    )}
                    {filter.include_subcategories === 1 && (
                        <ActiveFilter>
                            <SelectedOption
                                onClear={() => resetFilterItem('include_subcategories', [])}
                                title="Include subcategories"
                                label={filter.include_subcategories === 1 ? 'True' : 'False'}
                            />
                        </ActiveFilter>
                    )}
                    {classificationKeys.map((key) => {
                        let matches = key.match(/\[(.*?)\]/);
                        let classification = ClassificationsService.getClassificationById(matches[1]);
                        if (key === `classifications${matches[0]}[model_id]` && classification) {
                            let values = filter[`classifications${matches[0]}[value]`];
                            if (!values) {
                                values = [];
                            }
                            return (
                                <ActiveFilter key={key}>
                                    <SelectedOption
                                        onClear={() =>
                                            resetClassificationItem(key, `classifications${matches[0]}[value]`, [])
                                        }
                                        title={classification.label}
                                        label={values.map((k) => (k ? k : ''))}
                                    />
                                </ActiveFilter>
                            );
                        }

                        return <React.Fragment key={key} />;
                    })}
                    {entitiesKeys.map((key) => {
                        let matches = key.match(/\[(.*?)\]/);
                        if (matches) {
                            return (
                                <ActiveFilter key={key}>
                                    <SelectedOption
                                        onClear={() => resetFilterItem(key, [])}
                                        title={matches[1]}
                                        label={filter[key].map((k) => (k ? k : ''))}
                                    />
                                </ActiveFilter>
                            );
                        }
                        return <React.Fragment key={key} />;
                    })}
                    {getSelectedOption(filter, 'infometer_total', 'Infometer total', resetFilterItem)}
                    {getSelectedOption(filter, 'infometer_relevance', 'Infometer relevance', resetFilterItem)}
                    {getSelectedOption(filter, 'infometer_social', 'Infometer social', resetFilterItem)}
                    {getSelectedOption(filter, 'infometer_reach', 'Infometer reach', resetFilterItem)}
                    {getSelectedOption(filter, 'url', 'URL', resetFilterItem)}

                    <ExportBtn onClick={onExportResults} disabled={!hasExportPermission}>
                        Export results {'>'}
                    </ExportBtn>
                </ActiveFiltersContainer>
            </Grid.FluidContainer>
        </Container>
    );
}

ActiveFilters.propTypes = {
    isCharts: PropTypes.bool,
    onExportResults: PropTypes.func,
};

export default ActiveFilters;
