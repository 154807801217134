import { toast } from 'react-toastify';
import Bugsnag from '@bugsnag/js';

export class ToastError extends Error {
    constructor(message) {
        super(message);
        this.name = 'ToastError';
    }
}

export const showGenericError = (error = 'An error has occurred') => {
    const errorMessage = error || 'An error has occurred';

    if (process.env.REACT_APP_BUGSNAG_API_KEY && process.env.REACT_APP_BUGSNAG_API_KEY.trim() !== '') {
        Bugsnag.notify(new ToastError(errorMessage));
    }

    toast.error(errorMessage);
};

export const showError = (message) => {
    toast.error(message);
};

export const showSuccess = (message) => {
    toast.success(message);
};
