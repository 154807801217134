import React from 'react';
import { Grid } from 'styles/components';
import { Images } from 'styles/constant';
import ProfileDropdown from '../ProfileDropdown';
import { Container, Logo } from './styles';
import Navigation from '../Navigation';
import { useHistory, useLocation } from 'react-router-dom';

function Header() {
    const history = useHistory();
    const location = useLocation();

    const navigate = (pathname) => {
        if (location.pathname === pathname) {
            history.go(0);
        } else {
            history.push({ pathname, search: location.search });
        }
    };
    return (
        <Container id="navigation-header">
            <Grid.Col flex={1}>
                <Logo src={Images.LOGO} onClick={() => navigate('/search')} />
            </Grid.Col>
            <Grid.Col alignItems="center" flex={1}>
                <Navigation />
            </Grid.Col>
            <Grid.Col alignItems="flex-end" flex={1}>
                <ProfileDropdown />
            </Grid.Col>
        </Container>
    );
}

export default Header;
