import styled from 'styled-components';
import { Colors } from 'styles/constant';
import { Grid } from 'styles/components';
export const Container = styled.div`
    width: 100%;
    display: flex;
    flex-flow: column wrap;
    flex-wrap: wrap;
`;
export const Content = styled.div`
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    > div {
        margin-bottom: 15px;
        &:last-of-type {
            margin-bottom: 0;
        }
    }
`;
export const AddLabel = styled.span`
    font-size: 14px;
    color: ${Colors.SECONDARY};
`;
export const Icon = styled.img``;
export const AddButton = styled(Grid.Row)`
    cursor: pointer;
    margin-top: 10px;
    display: inline-block;
    div {
        display: inline-block;
        vertical-align: top;
    }
`;
