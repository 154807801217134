import { SimilarArticlesTypes } from 'features/articles/types';
import { ArticlesService, SimilarArticlesService } from 'features/articles/services';
import { ToastService } from 'core/services';

export function getSimilarArticles(id, parentId, status) {
    return async (dispatch) => {
        try {
            dispatch({ type: SimilarArticlesTypes.GET_SIMILAR_ARTICLES_START });
            const { articles, total } = await SimilarArticlesService.getSimilarArticles(id, parentId, status);
            dispatch({
                type: SimilarArticlesTypes.GET_SIMILAR_ARTICLES,
                payload: { similarArticles: articles, count: total },
            });
        } catch (error) {
            const message = error?.response?.data?.message;
            ToastService.showGenericError(message);
            dispatch({ type: SimilarArticlesTypes.GET_SIMILAR_ARTICLES_ERROR });
        }
    };
}

export function voteSimilarArticle(id, parentId, vote, callback) {
    return async (dispatch) => {
        try {
            dispatch({ type: SimilarArticlesTypes.SIMILAR_ARTICLE_VOTE_START, payload: { id, parentId } });
            const { votes } = await ArticlesService.vote(id, parentId, vote);
            dispatch({ type: SimilarArticlesTypes.SIMILAR_ARTICLE_VOTE, payload: { id, parentId, votes } });
            ToastService.showSuccess('Article successfully voted');
            callback();
        } catch (error) {
            const message = error?.response?.data?.message;
            ToastService.showGenericError(message);
            dispatch({ type: SimilarArticlesTypes.SIMILAR_ARTICLE_VOTE_ERROR, payload: { id, parentId } });
        }
    };
}

export function hideForMeSimilarArticle(id, parentId, isHiddenForMe, callback) {
    return async (dispatch) => {
        try {
            dispatch({ type: SimilarArticlesTypes.SIMILAR_ARTICLE_HIDE_FOR_ME_START, payload: { id, parentId } });
            await ArticlesService.hide(id, parentId, false);
            dispatch({
                type: SimilarArticlesTypes.SIMILAR_ARTICLE_HIDE_FOR_ME,
                payload: { id, parentId, isHiddenForMe },
            });
            ToastService.showSuccess('Article successfully hided');
            callback();
        } catch (error) {
            const message = error?.response?.data?.message;
            ToastService.showGenericError(message);
            dispatch({ type: SimilarArticlesTypes.SIMILAR_ARTICLE_HIDE_FOR_ME_ERROR, payload: { id, parentId } });
        }
    };
}

export function hideForAllSimilarArticle(id, parentId, isHiddenForAll, callback) {
    return async (dispatch) => {
        try {
            dispatch({ type: SimilarArticlesTypes.SIMILAR_ARTICLE_HIDE_FOR_ALL_START, payload: { id, parentId } });
            await ArticlesService.hide(id, parentId, true);
            dispatch({
                type: SimilarArticlesTypes.SIMILAR_ARTICLE_HIDE_FOR_ALL,
                payload: { id, parentId, isHiddenForAll },
            });
            ToastService.showSuccess('Article successfully hided');
            callback();
        } catch (error) {
            const message = error?.response?.data?.message;
            ToastService.showGenericError(message);
            dispatch({ type: SimilarArticlesTypes.SIMILAR_ARTICLE_HIDE_FOR_ALL_ERROR, payload: { id, parentId } });
        }
    };
}

export function bookmarkSimilarArticle(id, parentId, isBookmarked, callback) {
    return async (dispatch) => {
        try {
            dispatch({ type: SimilarArticlesTypes.SIMILAR_ARTICLE_BOOKMARK_START, payload: { id, parentId } });
            await ArticlesService.bookmark(id, parentId);
            dispatch({ type: SimilarArticlesTypes.SIMILAR_ARTICLE_BOOKMARK, payload: { id, parentId, isBookmarked } });
            ToastService.showSuccess('Article successfully bookmarked');
            callback();
        } catch (error) {
            const message = error?.response?.data?.message;
            ToastService.showGenericError(message);
            dispatch({ type: SimilarArticlesTypes.SIMILAR_ARTICLE_BOOKMARK_ERROR, payload: { id, parentId } });
        }
    };
}
