import React from 'react';
import PropTypes from 'prop-types';
import { InputContainer } from './styles';
import { Grid } from '../../../styles/components';
import { Input, Button } from 'components';

const FilterListLayout = ({
    children,
    searchInputValue,
    onSearchInputChange,
    onCreateNewClick,
    createNewButtonDisabled,
}) => {
    return (
        <div>
            <Grid.FixedContainer>
                <Grid.Row mb={20}>
                    <InputContainer>
                        <Button onClick={onCreateNewClick} label="Create new" disabled={createNewButtonDisabled} />
                        <Input
                            style={{ width: 700, maxWidth: '100%' }}
                            onChange={(e) => onSearchInputChange(e.value)}
                            name="filterSearch"
                            value={searchInputValue}
                            placeholder="Search by keyword"
                        />
                    </InputContainer>
                </Grid.Row>
            </Grid.FixedContainer>
            {children}
        </div>
    );
};

FilterListLayout.propTypes = {
    children: PropTypes.node,
    searchInputValue: PropTypes.string,
    onSearchInputChange: PropTypes.func,
    onCreateNewClick: PropTypes.func,
    createNewButtonDisabled: PropTypes.bool,
};

export default FilterListLayout;
