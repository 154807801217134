import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox } from 'components';

export function ColumnsSelection({ data, checkedItems, setCheckedItems }) {
    const handleCheckboxChange = (key) => {
        setCheckedItems((prevState) => ({
            ...prevState,
            [key]: !prevState[key],
        }));
    };

    const columns = 4;
    const columnCount = 4;
    const itemsPerColumn = Math.ceil(Object.keys(data).length / columns);
    const columnData = [];

    for (let i = 0; i < columns; i++) {
        columnData.push(
            Object.keys(data)
                .slice(i * itemsPerColumn, (i + 1) * itemsPerColumn)
                .map((key) => ({ title: data[key].title, key, checked: false, disabled: data[key].disabled })),
        );
    }

    return (
        <div style={{ paddingTop: '10px', display: 'grid', gridTemplateColumns: `repeat(${columnCount}, 1fr)` }}>
            {columnData.map((column, index) => (
                <div key={index}>
                    {column.map((item) => (
                        <div style={{ padding: '2px' }} key={item.title}>
                            <Checkbox
                                onChange={() => handleCheckboxChange(item.key)}
                                label={item.title}
                                checked={checkedItems[item.key]}
                                name={item.key}
                            />
                        </div>
                    ))}
                </div>
            ))}
        </div>
    );
}

ColumnsSelection.propTypes = {
    data: PropTypes.object.isRequired,
    checkedItems: PropTypes.object.isRequired,
    setCheckedItems: PropTypes.func.isRequired,
};
