import styled from 'styled-components/macro';
import { Colors } from 'styles/constant';
import { Grid } from 'styles/components';
import { device } from 'styles/device';
import { MessageBox } from 'components';

export const Container = styled.div`
    background: white;
    width: 100%;
    padding: 20px 0 64px;
`;

export const Section = styled.div`
    padding: 15px;
    background-color: ${Colors.LIGHT_GREY};
    margin-bottom: 20px;

    &:last-of-type {
        margin-bottom: 0;
    }
`;

export const Title = styled.h1`
    font-weight: 700;
    font-size: 24px;
    line-height: 36px;
    color: ${Colors.BLACK};
    margin-bottom: 20px;
`;

export const Row = styled(Grid.Row)`
    flex-wrap: wrap;
`;

export const AuthorColWrapper = styled.div`
    margin-bottom: 20px;
`;

const Col = styled(Grid.Col)`
    margin-top: 10px;
    margin-right: 20px;

    @media ${device.tabletLandscape} {
        && {
            max-width: unset;
        }
    }
    @media ${device.mobileS} {
        && {
            margin-right: 0;
        }
    }
`;

export const AuthorCol = styled(Col)`
    max-width: 300px;
    min-width: 300px;
    @media ${device.mobileS} {
        min-width: 100%;
    }
`;

export const SelectColCSS = styled(Col)`
    max-width: 200px;
    min-width: 200px;
`;

export const NetworkColCSS = styled(Col)`
    max-width: 200px;
    min-width: 200px;
    margin-top: 0px !important;
`;

export const NetworkCol = styled(Col)`
    max-width: 200px;
    min-width: 200px;
`;

export const DateCol = styled(Col)`
    max-width: 200px;
    min-width: 200px;
`;

export const DomainRow = styled(Grid.Row)`
    max-width: 280px;
`;

export const Message = styled(MessageBox)`
    max-width: 855px;
`;

export const Content = styled.div`
    color: ${Colors.DANGER};
`;

export const Description = styled.p`
    font-size: 13px;
    color: ${Colors.SECONDARY};
    line-height: 18px;
`;

export const Footer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;
