import { HighlightTypes as types } from 'core/types';

const initialState = {
    parameters: {
        s1: [],
        s2: [],
        s3: [],
    },
    highlights: [],
    loading: false,
    error: null,
};

const highlightsReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.ADD_HIGHLIGHT_START:
        case types.REMOVE_HIGHLIGHT_START:
        case types.UPDATE_HIGHLIGHT_START:
            return { ...state, loading: true, error: null };

        case types.ADD_HIGHLIGHT_SUCCESS:
            return {
                ...state,
                loading: false,
                highlights: [...state.highlights, action.payload],
            };

        case types.ADD_HIGHLIGHTS:
            return {
                ...state,
                loading: false,
                highlights: [...state.highlights, ...action.payload],
            };

        case types.REMOVE_HIGHLIGHT_SUCCESS:
            return {
                ...state,
                loading: false,
                highlights: state.highlights.filter((highlight) => highlight.id !== action.payload),
            };

        case types.UPDATE_HIGHLIGHT: {
            const index = state.highlights.findIndex((h) => h.id === action.payload.id);
            if (index > -1) {
                const updatedHighlights = state.highlights.map((item, idx) => (idx === index ? action.payload : item));
                return {
                    ...state,
                    highlights: updatedHighlights,
                };
            } else {
                return {
                    ...state,
                    highlights: [...state.highlights, action.payload],
                };
            }
        }

        case types.ADD_HIGHLIGHT_PARAMETERS:
            return {
                ...state,
                parameters: {
                    ...state.parameters,
                    ...action.payload, // Assuming payload contains the updated parameters
                },
            };

        case types.REMOVE_ALL_HIGHLIGHTS:
            return {
                ...state,
                highlights: [], // Reset highlights to an empty array
            };

        case types.REMOVE_HIGHLIGHT:
            return {
                ...state,
                highlights: state.highlights.filter((highlight) => highlight.id !== action.payload.id),
            };

        case types.UPDATE_HIGHLIGHT_SUCCESS:
            return {
                ...state,
                loading: false,
                highlights: state.highlights.map((highlight) =>
                    highlight.id === action.payload.id ? action.payload : highlight,
                ),
            };

        case types.ADD_HIGHLIGHT_ERROR:
        case types.REMOVE_HIGHLIGHT_ERROR:
        case types.UPDATE_HIGHLIGHT_ERROR:
            return { ...state, loading: false, error: action.payload };

        default:
            return state;
    }
};

export default highlightsReducer;
