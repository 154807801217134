import update from 'immutability-helper';
import { ParametersTypes, AuthTypes, ParameterTypes } from 'core/types';

const initialState = {
    parameters: [],
    isLoading: false,
    isLoaded: false,
    isUpdating: false,
    isUpdated: false,
};

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case ParametersTypes.GET_PARAMETERS_START: {
            return update(state, {
                isLoading: { $set: true },
                isLoaded: { $set: false },
                isUpdated: { $set: false },
            });
        }

        case ParametersTypes.GET_PARAMETERS: {
            return update(state, {
                isLoading: { $set: false },
                isLoaded: { $set: true },
                isUpdated: { $set: false },
                parameters: { $set: payload.parameters },
            });
        }

        case ParameterTypes.ADD_PARAMETER: {
            const newParameters = state.parameters;
            const parameterIndex = newParameters.findIndex((parameter) => parameter.id === payload.parameter.id);
            parameterIndex === -1
                ? newParameters.push(payload.parameter)
                : (newParameters[parameterIndex] = payload.parameter);
            return update(state, {
                parameters: { $set: newParameters },
            });
        }

        case ParametersTypes.UPDATE_PARAMETERS_SUCCESS: {
            const params = state.parameters;
            const findParam = params.find((parameter) => parameter.id === payload.id);
            findParam.name = payload.parameter;
            const index = params.findIndex((parameter) => parameter.id === payload.id);
            return update(state, {
                parameters: {
                    [index]: {
                        $set: findParam,
                    },
                },
                isUpdating: { $set: false },
                isUpdated: { $set: true },
            });
        }

        case ParametersTypes.UPDATE_PARAMETERS_VALUE_SUCCESS: {
            const params = state.parameters;
            const findParam = params.find((parameter) => parameter.id === payload.parameterId);
            const findParamValueIndex = findParam.values.findIndex((v) => v.id === payload.parameterValue.id);
            findParam.values[findParamValueIndex].value = payload.parameterValue.value;
            const index = params.findIndex((parameter) => parameter.id === payload.parameterId);
            return update(state, {
                parameters: {
                    [index]: {
                        $set: findParam,
                    },
                },
                isUpdating: { $set: false },
                isUpdated: { $set: true },
            });
        }

        case ParametersTypes.GET_PARAMETERS_ERROR:
        case AuthTypes.LOGOUT: {
            return initialState;
        }

        default: {
            return state;
        }
    }
};
