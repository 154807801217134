import httpClient from '../httpClient';

const baseUrl = '/parameters-value';

export const updateParameterValue = async (id, parameterValue) => {
    return httpClient.put(`${baseUrl}/${id}`, parameterValue);
};

export const deleteParameterValue = (id) => {
    return httpClient.delete(`${baseUrl}/${id}`);
};

export const enableParameterValue = (id) => {
    return httpClient.get(`${baseUrl}/${id}/enable`);
};

export const disableParameterValue = (id) => {
    return httpClient.get(`${baseUrl}/${id}/disable`);
};
