import { useDispatch, useSelector } from 'react-redux';
import { ParameterActions, ParametersActions } from 'core/actions';

// import { InitialActions, ParametersActions } from 'core/actions';

function useAddParameter() {
    const dispatch = useDispatch();
    const { isLoading } = useSelector((state) => state.parameter);

    const getParameters = () => {
        dispatch(ParametersActions.getParameters());
    };

    const addParameterValue = (request, callback) => {
        dispatch(
            ParameterActions.addParameter(request, (response) => {
                getParameters(); // Call getParameters after the action is dispatched
                if (callback) {
                    callback(response); // Call the original callback if it exists
                }
            }),
        );
    };

    const addParameter = (request, callback) => {
        dispatch(ParameterActions.addParameter(request, callback));
    };

    // Wrap addParameterValue in a function that returns a Promise
    const addParameterValueAsync = (request) => {
        return new Promise((resolve) => {
            addParameterValue(request, (response) => {
                getParameters();
                resolve(response); // Resolve the Promise with the response
            });
        });
    };

    // Wrap addParameter in a function that returns a Promise
    const addParameterAsync = (request) => {
        return new Promise((resolve) => {
            addParameter(request, (response) => {
                getParameters();
                resolve(response); // Resolve the Promise with the response
            });
        });
    };

    return { isLoading, addParameterValue, addParameter, addParameterValueAsync, addParameterAsync };
}

export default useAddParameter;
