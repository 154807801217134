import React from 'react';
import PropTypes from 'prop-types';
import Input from 'components/Input';
import { Wrapper } from '../../styles';

const SearchFilter = ({ id, onSearchChange, value }) => {
    return (
        <Wrapper
            onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
            }}
        >
            <Input
                onChange={(e) => {
                    onSearchChange(e.value);
                }}
                name={`${id}-filter-input`}
                value={value}
            />
        </Wrapper>
    );
};

SearchFilter.propTypes = {
    id: PropTypes.string.isRequired,
    onSearchChange: PropTypes.func.isRequired,
    value: PropTypes.string,
};

export default SearchFilter;
