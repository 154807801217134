import styled from 'styled-components/macro';
import { Icon } from 'components';
import { Colors } from 'styles/constant';

export const StyledIcon = styled(Icon)`
    width: 40px;
    height: 40px;
    img {
        transform: unset;
        width: 20px;
    }
`;

export const SearchIcon = styled(Icon)`
    background: ${Colors.DANGER};
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 27px;
    height: 27px;
    img {
        width: 18px;
    }
    margin-right: 4px;
    &:hover {
        opacity: ${(props) => (props.disabled ? 0.6 : 0.75)};
    }
`;

export const SearchIconBig = styled(SearchIcon)`
    background: ${Colors.DANGER};
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    margin-right: 4px;

    &:hover {
        opacity: ${(props) => (props.disabled ? 0.6 : 0.75)};
    }
`;

export const UserStatus = styled.span`
    color: ${Colors.BLACK};
    display: inline-block;
    padding: 3px 5px;
    margin: 5px 5px 0 0;
    border-radius: 3px;
    background-color: ${(props) => (props.active ? Colors.GREEN : Colors.GRAY)};
`;
