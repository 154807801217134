import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Modal } from 'components';
import { Grid } from 'styles/components';
import PropTypes from 'prop-types';
import { Buttons } from 'styles/constant';

export const RouterPrompt = ({ title, onLeave, shouldBlockRoute }) => {
    const history = useHistory();

    const [showPrompt, setShowPrompt] = useState(false);
    const [currentPath, setCurrentPath] = useState('');
    const unblockRef = useRef();

    function handleShowModal() {
        setShowPrompt(true);
    }

    function onCancel() {
        setShowPrompt(false);
    }

    useEffect(() => {
        unblockRef.current = history.block((location) => {
            if (shouldBlockRoute) {
                setCurrentPath(location.pathname + location.search);
                handleShowModal();
                return false;
            }
            return true;
        });
        return () => {
            unblockRef.current && unblockRef.current();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [shouldBlockRoute]);

    function handleConfirm() {
        if (unblockRef) {
            unblockRef.current();
        }
        setShowPrompt(false);
        onLeave();
        history.push(currentPath);
    }

    const renderContent = () => (
        <div>
            <Grid.Row>{title}</Grid.Row>
        </div>
    );

    const renderFooter = () => (
        <Grid.Row>
            <Grid.Col flex={1}>
                <Button label="Cancel" type={Buttons.TERTIARY} onClick={onCancel} />
            </Grid.Col>
            <Grid.Col>
                <Button label="Proceed" onClick={handleConfirm} />
            </Grid.Col>
        </Grid.Row>
    );

    return (
        <Modal
            onClose={onCancel}
            visible={showPrompt}
            title=""
            renderContent={renderContent}
            renderFooter={renderFooter}
        />
    );
};

RouterPrompt.propTypes = {
    title: PropTypes.string,
    shouldBlockRoute: PropTypes.bool,
    onLeave: PropTypes.func,
    highlights: PropTypes.any,
    article: PropTypes.object,
    parameters: PropTypes.any,
};

export default RouterPrompt;
