import { httpClient } from 'core/services';
import { Mapper } from 'features/articles/utils';
import axios from 'axios';
import { store } from '../../../../core/store';

const baseUrl = '/articles';
const baseReservedUrl = '/reserved-articles';
const baseReserveUrl = '/reserve-articles';
const cancelReservedArticlesUrl = '/cancel-reserved-articles';
const exportFieldsUrl = '/export/fields';

export const getArticles = async (request) => {
    const { filter, paging } = request;
    const params = { ...paging, ...filter };

    if (params.labeling_status === 'Not labeled') {
        params.reserved = 0;
    }

    const { articles, total, chart } = await httpClient.get(baseUrl, { params });
    return { articles: articles.map((article) => Mapper.mapArticle(article)), total, chart };
};

export const getReservedArticles = async (request) => {
    const { paging, sort } = request;
    const params = { ...paging, ...sort };
    const { articles, total } = await httpClient.get(`${baseReservedUrl}`, { params });
    return { articles: articles.map((article) => Mapper.mapArticle(article)), total };
};

export const reserveArticles = async (request, selectedArticlesIds) => {
    const { filter, paging } = request;
    const params = { ...filter, ...paging };
    if (selectedArticlesIds.length > 0) params.article_ids = selectedArticlesIds;
    return httpClient.get(`${baseReserveUrl}`, { params });
};

export const cancelReservedArticles = async () => {
    return httpClient.get(`${cancelReservedArticlesUrl}`);
};

export const vote = async (id, parentId, vote) => {
    return httpClient.post(`${baseUrl}/${id}/${parentId}/vote`, { vote });
};

export const bookmark = async (id, parentId) => {
    return httpClient.post(`${baseUrl}/${id}/${parentId}/bookmark`);
};

export const hide = async (id, parentId, hideForAll) => {
    return httpClient.post(`${baseUrl}/${id}/${parentId}/hide`, { hideForAll });
};

export const bookmarkArticle = async () => {
    return Promise.resolve({});
};

export const unBookmarkArticle = async () => {
    return Promise.resolve({});
};

export const getReservedArticlesCount = async () => {
    const { total } = await httpClient.get(`${baseReservedUrl}`, { params: { size: 0 } });
    return { total };
};

export const getArticlesCount = async (request, labelingStatus) => {
    const { filter } = request;
    const params = { ...filter };
    params.labeling_status = labelingStatus;
    params.size = 0;

    if (labelingStatus === 'Not labeled') {
        params.reserved = 0;
    }

    params.sort && delete params['sort'];
    params.order && delete params['order'];

    const { total } = await httpClient.get(`${baseUrl}`, { params });
    return { total };
};

export const getExportFields = async () => {
    return await httpClient.get(exportFieldsUrl);
};

export const exportSearch = async (urlParams, exportOptions) => {
    const url = process.env.REACT_APP_BASE_URL;
    const { token, switchUser } = store.getState().auth;

    if (switchUser) {
        urlParams += `&_switch_user=${switchUser.username}`;
    }

    if (exportOptions.singleParameter) {
        urlParams += `&singleParameter=${exportOptions.singleParameter}`;
    }

    const exportRequestUrl = `${url}/api${baseUrl}/export?${urlParams}&type=${exportOptions.type}`;

    const postData = { columns: exportOptions.columns, type: exportOptions.type };

    const response = await axios.post(exportRequestUrl, postData, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });

    if (response) {
        if (!window.navigator.msSaveOrOpenBlob) {
            const title = response.headers['content-disposition']
                ? response.headers['content-disposition'].match(/filename="(.+)"/)[1]
                : 'export.csv';
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', title);
            document.body.appendChild(link);
            link.click();
        }
        return response.data;
    }
};
