import React, { useState } from 'react';
import { connect } from 'react-redux';
import { ImportedArticleActions } from 'features/article/actions';
import { Grid } from 'styles/components';
import PropTypes from 'prop-types';
import { Container, Section } from './styles';
import { Button } from 'components';
import { Buttons } from '../../styles/constant';
import { usePermission } from 'hooks';
import { NotFoundPage } from 'pages';
import FileUploader from './FileUploader';
import { Loader } from 'components';

const ManualImportFileForm = ({ history, isLoaded, isLoading, isImporting, importBatch, downloadTemplate }) => {
    const [selectedFile, setSelectedFile] = useState(null);
    const hasManualImportPermission = usePermission('ROLE_CREATE_MANUAL_IMPORTS');

    if (!hasManualImportPermission) {
        return <NotFoundPage />;
    }

    const onDownloadTemplate = async () => {
        await downloadTemplate()
            .then((text) => {
                const blob = new Blob([text], { type: 'text/csv' });
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'sample.csv');
                document.body.appendChild(link);
                link.click();
                link.parentNode.removeChild(link);
            })
            .catch((error) => {
                console.log('Error:', error);
            });
    };

    const handleUpload = async () => {
        if (selectedFile) {
            await importBatch(selectedFile).then((response) => {
                if (response) {
                    history.push('/import-publications');
                }
            });
        }
    };

    const onSetFiles = (files) => {
        if (files && files.length > 0) {
            setSelectedFile(files[0]);
        }
    };

    return (
        <Container>
            {isLoaded && !isImporting && (
                <Section>
                    <Grid.Row mb={20}>
                        <FileUploader setFiles={onSetFiles} />
                    </Grid.Row>
                    {selectedFile && (
                        <Grid.Row mb={20}>
                            <div className="selectedFile">
                                Selected file:<b> {selectedFile.name} </b>
                            </div>
                        </Grid.Row>
                    )}
                    <Grid.Row>
                        <Grid.Col mb="10">
                            <Button
                                label="Upload"
                                loading={isLoading}
                                type={Buttons.PRIMARY}
                                style={{
                                    margin: '0 6px 0 2px',
                                }}
                                onClick={handleUpload}
                            />
                        </Grid.Col>
                        <Grid.Col mb="10">
                            <Button
                                label="Cancel"
                                loading={isLoading}
                                type={Buttons.SECONDARY}
                                onClick={() => history.push('/import-publications')}
                                style={{
                                    margin: '0 6px 0 2px',
                                }}
                            />
                        </Grid.Col>
                        <Grid.ColLeft mb="10">
                            <Button
                                label="Download Template"
                                loading={isLoading}
                                type={Buttons.SECONDARY}
                                style={{
                                    margin: '0 6px 0 2px',
                                }}
                                onClick={onDownloadTemplate}
                            />
                        </Grid.ColLeft>
                    </Grid.Row>
                </Section>
            )}
            {isImporting && (
                <div>
                    <Grid.Row>
                        <Grid.Col>
                            <Loader />
                        </Grid.Col>
                        <Grid.Col ml="10">Importing, please wait...</Grid.Col>
                    </Grid.Row>
                </div>
            )}
        </Container>
    );
};

ManualImportFileForm.propTypes = {
    history: PropTypes.object,
    isLoaded: PropTypes.bool,
    isLoading: PropTypes.bool,
    isImporting: PropTypes.bool,
    downloadTemplate: PropTypes.func,
    importBatch: PropTypes.func,
};

function mapStateToProps(state) {
    const { languages, importedArticles } = state;
    return {
        languages: languages.languages,
        isImporting: importedArticles.isImporting,
    };
}

const mapDispatchToProps = {
    downloadTemplate: ImportedArticleActions.downloadTemplate,
    importBatch: ImportedArticleActions.importBatch,
};

export default connect(mapStateToProps, mapDispatchToProps)(ManualImportFileForm);
