import update from 'immutability-helper';
import { AuthTypes } from 'core/types';
import { ArticleTypes } from 'features/article/types';

const initialState = {
    importedArticles: null,
    isLoaded: false,
    isLoading: false,
    isImporting: false,
};

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case ArticleTypes.IMPORT_ARTICLE_START: {
            return update(state, {
                isImporting: { $set: true },
            });
        }

        case ArticleTypes.IMPORT_ARTICLE: {
            return update(state, {
                isImporting: { $set: false },
            });
        }

        case ArticleTypes.GET_IMPORTED_ARTICLES_START: {
            return update(state, {
                isLoading: { $set: true },
                isLoaded: { $set: false },
            });
        }

        case ArticleTypes.GET_IMPORTED_ARTICLES: {
            return update(state, {
                importedArticles: { $set: payload.importedArticles },
                isLoading: { $set: false },
                isLoaded: { $set: true },
            });
        }

        case AuthTypes.LOGOUT:
        case ArticleTypes.IMPORT_ARTICLE_ERROR: {
            return update(state, {
                isImporting: { $set: false },
                isLoading: { $set: false },
                isLoaded: { $set: true },
            });
        }
        case ArticleTypes.GET_IMPORTED_ARTICLES_ERROR: {
            return update(state, {
                isImporting: { $set: false },
                isLoading: { $set: false },
                isLoaded: { $set: true },
            });
        }

        default: {
            return state;
        }
    }
};
