/* eslint-disable prettier/prettier */
import PropTypes from 'prop-types';
import { Mapper, SharedTypes } from 'utils';
import update from 'immutability-helper';
import { connect } from 'react-redux';
import React, { useCallback, useEffect, useState, useRef, useMemo } from 'react';
import _ from 'lodash';
import useDeepCompareEffect from 'use-deep-compare-effect';
import { useProfile, useSearch } from 'hooks';
import { SET_PARAMETERS, CHECK_PARAMETERS } from './config';
import { Form, initialSentimentsOpen } from './Form';
import { getFromStorage, removeFromStorage, saveToStorage, STORAGE_KEY_LABELLING } from './utils';
import { useIsMounted, useComputeHighlights, useStorageHighlights, useHighlightComparison } from 'hooks';
const AUTOSAVE_INTERVAL = 1000 * 60;

const initialFormData = {
    parameters: [],
    sentiments: [],
    comment: null,
};

const getArticleKey = (article) => `id=${article.id}&parentId=${article.parentId}`;

const getLabeledArticleInitialData = (article, profile) => {
    const organisation = profile.organisation ? profile.organisation.id : null;
    return Mapper.mapLabelingFormData(article, organisation);
};

const getInitialData = (article, profile) => {
    const articleKey = article ? getArticleKey(article) : null;
    const formDataInStorage = articleKey ? getFromStorage(articleKey, STORAGE_KEY_LABELLING) : null;

    if (article?.labelingStatus === 'Labeled') {
        return formDataInStorage || getLabeledArticleInitialData(article, profile);
    }
    return formDataInStorage || initialFormData;
};

const getFormDataInStorage = (article) => {
    const articleKey = article ? getArticleKey(article) : null;
    return articleKey ? getFromStorage(articleKey, STORAGE_KEY_LABELLING) : null;
};

export const LabelingForm = ({
    parameters,
    article,
    isArticleLoaded,
    labelingData,
    similarArticles,
    articles,
    highlights,
}) => {
    const { profile } = useProfile();
    const isMounted = useIsMounted();
    const [formData, setFormData] = useState(initialFormData);
    const initialDataRef = useRef();
    const [showInitial, setShowInitial] = useState(initialSentimentsOpen);
    const { filter } = useSearch();
    const [skip, setSkip] = useState(filter.labeling_status !== 'not_labeled');
    const computedHighlights = useComputeHighlights(isMounted, article, parameters);
    const { fetchHighlights, isKeyPresent } = useStorageHighlights();
    const { compareHighlightsArrays } = useHighlightComparison();
    const [storageRemoved, setStorageRemoved] = useState(0);

    useEffect(() => {
        const initialData = getInitialData(article, profile);
        setFormData(initialData);
        initialDataRef.current = initialData;
    }, [article, profile]);

    const shouldBlockRoute = useMemo(() => {
        let shouldBlock = false;

        if (article && profile && isMounted && computedHighlights !== null) {
            let hasFormDataChanged = false;
            let hasSParametersChanged = false;

            if (formData) {
                hasFormDataChanged = article
                    ? !_.isEqual(
                          formData,
                          article.labelingStatus === 'Labeled'
                              ? getLabeledArticleInitialData(article, profile)
                              : initialFormData,
                      )
                    : false;
            }

            const highlightsFromStorage = fetchHighlights(article);

            if (highlightsFromStorage) {
                const highlightsArraysAreEqual = compareHighlightsArrays(computedHighlights, highlightsFromStorage);

                if (!highlightsArraysAreEqual) {
                    hasSParametersChanged = true;
                }
            }

            shouldBlock = hasFormDataChanged || hasSParametersChanged;
        }
        return shouldBlock;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isMounted, article, profile, formData, computedHighlights, highlights, isKeyPresent, storageRemoved]);

    const saveFormDataStateToStorage = useCallback(() => {
        if (article) {
            const articleKey = getArticleKey(article);
            const formDataInStorage = getFormDataInStorage(article);

            if (!_.isEqual(formData, initialDataRef.current) && !_.isEqual(formData, formDataInStorage)) {
                saveToStorage(formData, STORAGE_KEY_LABELLING, articleKey);
            }
        }
    }, [formData, article]);

    useEffect(() => {
        const interval = setInterval(() => {
            saveFormDataStateToStorage();
        }, AUTOSAVE_INTERVAL);

        return () => clearInterval(interval);
    }, [saveFormDataStateToStorage]);

    useDeepCompareEffect(() => {
        let newSkip =
            _.isEqual(_.omit(formData, ['time_spent']), _.omit(initialFormData, ['time_spent'])) &&
            !!filter.labeling_status;
        const { parameters, sentiments, comment } = formData;
        if ([...parameters, ...sentiments].every((item) => item.length === 0) && !comment) newSkip = true;
        setSkip(newSkip);
    }, [formData]);

    useDeepCompareEffect(() => {
        let newSkip = highlights && highlights.length > 0;
        setSkip(!newSkip);
        // Handle the change
    }, [isMounted, highlights]);

    useEffect(() => {
        const formDataInStorage = getFormDataInStorage(article);

        if (article && article.labelingStatus === 'Labeled') {
            const organisation = profile.organisation ? profile.organisation.id : null;
            const newData = Mapper.mapLabelingFormData(article, organisation);
            setFormData(formDataInStorage || newData);
        } else {
            setFormData(formDataInStorage || initialFormData);
        }

        setShowInitial(
            update(showInitial, {
                category: { $set: true },
                filter: { $set: true },
            }),
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [article]);

    useEffect(() => {
        if (article && labelingData) {
            setFormData(labelingData);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [labelingData]);

    const onClearStorageState = () => {
        // Then call the method to remove from storage
        removeFromStorage();

        // Increment the state first
        setStorageRemoved((prev) => prev + 1);
    };

    const buttonDisabled =
        !isArticleLoaded ||
        !article ||
        (!filter.labeling_status &&
            similarArticles.filter((a) => a.id === article.id && a.parentId === article.parentId && a.disabled).length >
                0 &&
            articles.filter((a) => a.id === article.id && a.parentId === article.parentId).length === 0);
    const disabled = buttonDisabled || filter.labeling_status === 'Not labeled';

    const clear = () => {
        setFormData(initialFormData);
    };

    const handleChange = (event) => {
        const { name, value } = event;
        setFormData(
            update(formData, {
                [name]: { $set: value ? value : null },
            }),
        );
    };

    const handleParameterValueAdd = (parameterValue) => {
        const { id, parameterId, type } = parameterValue;
        setFormData(
            update(formData, {
                parameters: { $set: [...formData.parameters, { id, parameterId, type }] },
            }),
        );
    };

    const handleSentimentChange = (sentiment) => {
        const { id, value, type } = sentiment;
        const existingIndex = formData.sentiments.findIndex(
            (sentiment) => sentiment.id === id && sentiment.type === type,
        );

        if (!value) {
            setFormData(
                update(formData, {
                    sentiments: {
                        $set: formData.sentiments.filter((sentiment) =>
                            sentiment.id === id ? sentiment.type !== type : sentiment,
                        ),
                    },
                }),
            );
        } else if (existingIndex === -1) {
            setFormData(
                update(formData, {
                    sentiments: { $set: [...formData.sentiments, { id, value, type }] },
                }),
            );
        } else {
            setFormData(
                update(formData, {
                    sentiments: { [existingIndex]: { $set: sentiment } },
                }),
            );
        }
    };

    const handleParameterValueClear = (parameterValueToClear) => {
        setFormData(
            update(formData, {
                parameters: {
                    $set: formData.parameters.filter(
                        (parameterValue) => !_.isEqual(parameterValueToClear, parameterValue),
                    ),
                },
            }),
        );
    };

    const handleTemplateChange = (parametersTemplate) => {
        const { data } = parametersTemplate;
        if (data.length) {
            // old templates
            const newParameters = SET_PARAMETERS(data);
            setFormData(
                update(formData, {
                    parameters: { $set: newParameters.parameters },
                    comment: { $set: newParameters.comment },
                    sentiments: { $set: [] },
                }),
            );
        } else {
            const newParameters = CHECK_PARAMETERS(data.parameters);
            setFormData(
                update(formData, {
                    parameters: { $set: newParameters },
                    comment: { $set: data.comment },
                    sentiments: { $set: data.sentiments },
                }),
            );
        }
    };

    const disableInitial = (type) => {
        setShowInitial(
            update(showInitial, {
                [type]: { $set: false },
            }),
        );
    };

    return (
        <Form
            article={article}
            formData={formData}
            highlights={highlights}
            disabled={disabled}
            handleParameterValueClear={handleParameterValueClear}
            disableInitial={disableInitial}
            handleSentimentChange={handleSentimentChange}
            filter={filter}
            handleTemplateChange={handleTemplateChange}
            buttonDisabled={buttonDisabled}
            isArticleLoaded={isArticleLoaded}
            skip={skip}
            parameters={parameters}
            handleParameterValueAdd={handleParameterValueAdd}
            handleChange={handleChange}
            showInitial={showInitial}
            initialFormData={initialFormData}
            clear={clear}
            shouldBlockRoute={shouldBlockRoute}
            onClearStorageState={onClearStorageState}
        />
    );
};

LabelingForm.propTypes = {
    languages: PropTypes.arrayOf(SharedTypes.OptionType).isRequired,
    users: PropTypes.arrayOf(SharedTypes.OptionType).isRequired,
    categories: PropTypes.arrayOf(SharedTypes.OptionType).isRequired,
    domains: PropTypes.arrayOf(SharedTypes.OptionType).isRequired,
    filters: PropTypes.arrayOf(SharedTypes.OptionType).isRequired,
    parameters: PropTypes.arrayOf(SharedTypes.ParameterType).isRequired,
    article: PropTypes.object,
    labelingData: PropTypes.object,
    similarArticles: PropTypes.array,
    articles: PropTypes.array,
    isArticleLoaded: PropTypes.bool.isRequired,
    highlights: PropTypes.any,
};

function mapStateToProps(state) {
    const {
        languages,
        domains,
        categories,
        filters,
        users,
        parameters,
        article,
        articles,
        similarArticles,
        highlights,
    } = state;
    return {
        languages: Mapper.mapLanguagesToOptions(languages.languages),
        users: Mapper.mapUsersToOptions(users.users),
        domains: Mapper.mapDomainsToOptions(domains.domains),
        categories: Mapper.mapCategoriesToOptions(categories.categories),
        filters: Mapper.mapFiltersToOptions(filters.filters),
        parameters: parameters.parameters,
        article: article.article,
        labelingData: Mapper.mapFailedLabelingForm(article.article, articles.articles),
        isArticleLoaded: article.isLoaded,
        similarArticles: similarArticles.similarArticles,
        articles: articles.articles,
        highlights: highlights.highlights,
    };
}

export default connect(mapStateToProps)(LabelingForm);
