import React from 'react';
import PropTypes from 'prop-types';
import * as Styles from './styles';
import SingleSelect from '../../SingleSelect';
import MultiSelect from '../../MultiSelect';
import { Grid } from 'styles/components';
import { Images } from 'styles/constant';
import Icon from '../../Icon';
import Input from '../../Input';

function ClassificationSelect(props) {
    const { onModelChange, value, onRemove, classifications, onChange, classificationIndex, onProbabilityChange } =
        props;

    const selectedModel = classifications.find((c) => c.value === value[classificationIndex].classifications.value);

    const handleModelChange = (event) => {
        const { value, label, external_id } = event;
        onModelChange(value, label, external_id, classificationIndex);
    };

    const handleOnChange = (event) => {
        const { value } = event;
        onChange(value, classificationIndex);
    };

    const handleInputChange = (event) => {
        const { name, value } = event;
        onProbabilityChange(name, value, classificationIndex);
    };

    return (
        <Styles.Container>
            <Grid.Row>
                <Grid.Col flex={1} mr={10} style={{ maxWidth: '100%' }}>
                    <Grid.Row mb={5}>
                        <SingleSelect
                            clearable={false}
                            placeholder="Search by name"
                            onChange={handleModelChange}
                            name={value[classificationIndex].classifications.label}
                            value={value[classificationIndex].classifications.value}
                            options={classifications}
                        />
                    </Grid.Row>
                    <Grid.Row>
                        <MultiSelect
                            placeholder="Add value"
                            onChange={handleOnChange}
                            name={`classifications[${value[classificationIndex].classifications.value}]`}
                            value={
                                value[classificationIndex].classifications.values
                                    ? value[classificationIndex].classifications.values
                                    : []
                            }
                            options={selectedModel.values}
                            hasSelectAllButton
                        />
                    </Grid.Row>
                    <Grid.Row>
                        <Input
                            maxLength={255}
                            autoComplete="off"
                            value={value[classificationIndex].classifications.min_probability}
                            placeholder={'min probability'}
                            onChange={handleInputChange}
                            name={'min_probability'}
                        />
                    </Grid.Row>
                </Grid.Col>
                <Grid.Col>
                    <Icon onClick={() => onRemove(classificationIndex)} title="Remove" source={Images.CLOSE_GREY} />
                </Grid.Col>
            </Grid.Row>
        </Styles.Container>
    );
}

ClassificationSelect.propTypes = {
    onChange: PropTypes.func.isRequired,
    onModelChange: PropTypes.func.isRequired,
    onProbabilityChange: PropTypes.func.isRequired,
    onRemove: PropTypes.func.isRequired,
    value: PropTypes.array,
    classificationIndex: PropTypes.number,
    classifications: PropTypes.array,
};

ClassificationSelect.defaultProps = {
    value: null,
};

export default ClassificationSelect;
