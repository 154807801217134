import PropTypes from 'prop-types';
import { Mapper, SharedTypes, TextUtils } from 'utils';
import { connect } from 'react-redux';
import { isEqual } from 'lodash';
import {
    ButtonWrapper,
    CloseIcon,
    CloseIconContainer,
    Container,
    Content,
    EntitiesSection,
    ExportButton,
    Footer,
    FooterSection,
    Header,
    Section,
    SliderRow,
    ClassificationsSection,
    Title,
    Wrapper,
} from './styles';
import { Buttons, Images } from 'styles/constant';
import { Grid } from 'styles/components';
import {
    Button,
    CustomExtractedEntitiesSelect,
    CustomParametersSelect,
    DatePicker,
    ExpandedMultiSelect,
    ExpandedSelect,
    GridContainer,
    Input,
    MultiSelect,
    Slider,
    CustomClassificationsSelect,
} from 'components';
import { LABELING_STATUSES } from 'constant';
import React, { useEffect, useState } from 'react';
import { useFilter, usePermissions, useIsMounted } from 'hooks';
import { ParametersService } from 'core/services';
import AddSavedSearchModal from '../AddSavedSearchModal';
import SavedSearchesModal from '../SavedSearchesModal';
import { ArticlesActions, ArticlesCountActions } from 'features/articles/actions';
import { ChartsActions, ClassificationsActions, MLActions } from 'core/actions';
import usePaging from 'features/articles/hooks/usePaging';

const isInputNumberValid = (value) => {
    const valueNum = +value;
    const isNotNumber = isNaN(valueNum);

    return !isNotNumber;
};

function Filter(props) {
    const {
        handleDateChange,
        languages,
        users,
        categories,
        domains,
        filters,
        parameters,
        visible,
        onClose,
        onShowHelpModal,
        isCharts,
        isLabeling,
        chart,
        getChart,
        getArticles,
        getExportFields,
        getReservedArticles,
        getLabeledArticlesCount,
        getReservedArticlesCount,
        getSkippedArticlesCount,
        getWaitingArticlesCount,
        onShowExportModal,
        localFilterMain,
        setLocalFilterMain,
        getClassifications,
        classifications,
        getAvailableTypes,
        availableTypes,
    } = props;
    const { filter, updateFilter } = useFilter();
    const [localFilter, setLocalFilter] = useState(filter);
    const [searchesVisible, setSearchesVisible] = useState(false);
    const [addSearchVisible, setAddSearchVisible] = useState(false);
    const [editingSearch, setEditingSearch] = useState(null);
    const parametersA1 = parameters ? parameters.filter((parameter) => parameter.type === 'A1') : [];
    const parametersA2 = parameters ? parameters.filter((parameter) => parameter.type === 'A2') : [];
    const parametersA3 = parameters ? parameters.filter((parameter) => parameter.type === 'A3') : [];
    const excludes = ['rows', 'value', 'columns', 'offset'];
    const [searchClicked, setSearchClicked] = useState(false);
    const [extractedEntities, setExtractedEntities] = useState({ extracted_entities: [] });
    const [localClassifications, setLocalClassifications] = useState({ classifications: [] });
    const { paging } = usePaging();
    const sort = { sort: filter.sort, order: filter.order };

    const [hasExportPermission, hasOverrideDomainsPermission, hasOverrideFiltersPermission] = usePermissions([
        'ROLE_EXPORT',
        'ROLE_OVERRIDE_DOMAINS',
        'CAN_OVERRIDE_FILTERS',
    ]);

    const isMounted = useIsMounted();

    useEffect(() => {
        setLocalFilter(filter);
    }, [filter]);

    useEffect(() => {
        let entities = {};
        for (let entity of extractedEntities.extracted_entities) {
            const type = Object.keys(entity)[0];
            const value = Object.keys(entity)[1];
            if (!entities[`extracted_entities[${entity[type]}]`]) {
                entities[`extracted_entities[${entity[type]}]`] = [entity[value]];
            } else if (entities[`extracted_entities[${entity[type]}]`]) {
                entities[`extracted_entities[${entity[type]}]`].push(entity[value]);
            }
        }

        const newFilter = { ...localFilter };
        for (const key in newFilter) if (key.startsWith('extracted_entities')) delete newFilter[key];
        setLocalFilter({ ...newFilter, ...entities });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [extractedEntities]);

    useEffect(() => {
        getAvailableTypes();
    }, [getAvailableTypes]);
    useEffect(() => {
        const newFilter = { ...localFilter };
        newFilter.date && delete newFilter.date;
        newFilter.from && delete newFilter.from;
        newFilter.to && delete newFilter.to;
        setLocalFilter({ ...localFilterMain, ...newFilter });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [localFilterMain]);

    useEffect(() => {
        if (searchClicked) {
            isCharts && chart.graph && getChart(chart.graph.id, filter);
            !isCharts && !isLabeling && getArticles({ filter, paging });
            if (isLabeling) {
                !filter.labeling_status ? getReservedArticles({ sort, paging }) : getArticles({ filter, paging });
                getReservedArticlesCount({ filter });
                getWaitingArticlesCount({ filter });
                getLabeledArticlesCount({ filter });
                getSkippedArticlesCount({ filter });
            }
            setSearchClicked(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchClicked]);

    useEffect(() => {
        getExportFields();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isMounted]);

    useEffect(() => {
        getClassifications();
    }, [getClassifications]);

    useEffect(() => {
        let classifications = {};
        for (let classification of localClassifications.classifications) {
            const value = Object.values(classification.classifications)[0];

            for (const key in classification.classifications) {
                if (key === 'label') {
                    classifications[`classifications[${[value]}][model_id]`] =
                        classification.classifications['externalId'];
                }
                if (key === 'values') {
                    classifications[`classifications[${[value]}][value]`] = classification.classifications['values'];
                }
                if (key === 'min_probability') {
                    classifications[`classifications[${[value]}][min_probability]`] =
                        classification.classifications['min_probability'];
                }
            }
        }

        const newFilter = { ...localFilter };
        for (const key in newFilter) if (key.startsWith('classifications')) delete newFilter[key];
        setLocalFilter({ ...newFilter, ...classifications });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [localClassifications]);

    const handleChange = (event) => {
        const { value, name } = event;

        setLocalFilter({ ...localFilter, [name]: value });
    };

    const getA2ParametersKeys = () => {
        return Object.keys(localFilter).filter(
            (key) => key.startsWith('parameters') && ParametersService.isA2Parameter(TextUtils.getParameterId(key)),
        );
    };

    const handleParameters2Change = (value) => {
        const parametersA2Values = {};

        for (let parameter of value) {
            const id = Object.keys(parameter)[0];
            parametersA2Values[`parameters[${id}]`] = parameter[id];
        }

        const newLocalFilter = localFilter;
        for (let key of Object.keys(newLocalFilter)) {
            if (ParametersService.isA2ParameterKey(key)) {
                delete newLocalFilter[key];
            }
        }

        setLocalFilter({ ...newLocalFilter, ...parametersA2Values });
    };

    const getParametersA2Value = () => {
        const value = [];
        for (let key of getA2ParametersKeys()) {
            const keyValue = localFilter[key].filter((value) => value !== '');
            value.push({ [TextUtils.getParameterId(key)]: keyValue });
        }
        return value;
    };

    const reset = () => {
        const date = 'today';
        setLocalFilterMain({ date });
        setLocalFilter({});
    };

    const getnonEmptyFilter = () => {
        for (const key in localFilterMain) if (key in localFilter) localFilterMain[key] = localFilter[key];
        const newFilter = localFilter;

        for (let param of excludes) {
            if (newFilter[param]) delete newFilter[param];
        }
        return Object.entries(newFilter).reduce((a, [k, v]) => (v === '' ? a : ((a[k] = v), a)), {});
    };

    const search = () => {
        const nonEmptyFilter = getnonEmptyFilter();
        if (isEqual(localFilter, filter)) setSearchClicked(true);
        updateFilter(nonEmptyFilter);
        onClose();
    };

    const onExportSearch = () => {
        const nonEmptyFilter = getnonEmptyFilter();
        if (isEqual(localFilter, filter)) setSearchClicked(true);
        updateFilter(nonEmptyFilter);
        onShowExportModal();
    };

    const onOpenSearch = (search) => {
        for (let param of excludes) {
            if (search[param]) delete search[param];
        }
        updateFilter(search);
        onClose();
    };

    const handleEdit = (search) => {
        setEditingSearch(search);
        setLocalFilter(search.filter);
    };

    const handleCancelEdit = () => {
        reset();
        setEditingSearch(null);
    };

    const handleEditAction = () => {
        setAddSearchVisible(true);
    };

    const handleSuccessEdit = () => {
        setEditingSearch(null);
        setAddSearchVisible(false);
    };

    const handleAvailableTypeChange = (value) => {
        setExtractedEntities({ extracted_entities: value });
    };

    const handleAddAvailableType = (value) => {
        setExtractedEntities((prevState) => ({
            ...prevState,
            extracted_entities: [...prevState.extracted_entities, ...value],
        }));
    };

    const renderActions = () => (
        <FooterSection>
            <Grid.Col mr={15}>
                <ButtonWrapper>
                    <ExportButton
                        type={Buttons.TERTIARY}
                        label="Export search"
                        onClick={onExportSearch}
                        disabled={!hasExportPermission}
                    />
                </ButtonWrapper>
            </Grid.Col>
            <Grid.Col mr={15}>
                <Button type={Buttons.TERTIARY} label="Clear all" onClick={reset} />
            </Grid.Col>
            <Grid.Col mr={15}>
                <Button type={Buttons.TERTIARY} onClick={() => setAddSearchVisible(true)} label="Save search" />
            </Grid.Col>
            <Grid.Col>
                <Button label="Search" onClick={search} />
            </Grid.Col>
        </FooterSection>
    );

    const renderEditActions = () => (
        <FooterSection>
            <Grid.Col mr={15}>
                <Button type={Buttons.TERTIARY} label="Cancel" onClick={handleCancelEdit} />
            </Grid.Col>
            <Grid.Col>
                <Button label="Update search" onClick={handleEditAction} />
            </Grid.Col>
        </FooterSection>
    );

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            search();
        }
    };

    const handleClassificationModelChange = (value) => {
        setLocalClassifications({ classifications: value });
    };
    const handleAddClassificationModel = (value) => {
        setLocalClassifications((prevState) => ({
            ...prevState,
            classifications: [...prevState.classifications, ...value],
        }));
    };

    return (
        <Container visible={visible}>
            <Wrapper>
                <Header>
                    <Title>Advanced search {editingSearch && ` (${editingSearch.name})`}</Title>
                    <CloseIconContainer onClick={onClose}>
                        <CloseIcon src={Images.CLOSE_WHITE_2} />
                    </CloseIconContainer>
                </Header>
                <Content>
                    <Section>
                        <Grid.Row mb={15}>
                            <Input
                                onKeyDown={handleKeyDown}
                                value={localFilter['filters[must]']}
                                onChange={(e) => handleChange(e)}
                                leftIcon={Images.SEARCH}
                                name="filters[must]"
                                help="Help"
                                onHelpClick={onShowHelpModal}
                                placeholder="Search ALL of these words"
                            />
                        </Grid.Row>
                        <GridContainer>
                            <Grid.Col justifyContent="flex-start">
                                <Grid.Row mb={10}>
                                    <DatePicker
                                        label="Date"
                                        value={{
                                            dateFrom: localFilter.from,
                                            dateTo: localFilter.to,
                                            date: localFilter.date,
                                        }}
                                        onChange={handleDateChange}
                                        name="date"
                                        placeholder="Select date range"
                                    />
                                </Grid.Row>
                            </Grid.Col>
                            <Grid.Col justifyContent="flex-start">
                                <Grid.Row mb={10}>
                                    <MultiSelect
                                        label="Languages"
                                        options={languages}
                                        value={localFilter.lang}
                                        onChange={(e) => handleChange(e)}
                                        name="lang"
                                        placeholder="Search by language"
                                        hasSelectAllButton
                                    />
                                </Grid.Row>
                            </Grid.Col>
                            <Grid.Col justifyContent="flex-start">
                                <Grid.Row mb={10}>
                                    <MultiSelect
                                        label="Categories"
                                        options={categories}
                                        value={localFilter.categories_ids}
                                        onChange={handleChange}
                                        name="categories_ids"
                                        placeholder="Search by name"
                                        hasSelectAllButton
                                    />
                                </Grid.Row>
                                <SliderRow>
                                    <Slider
                                        label="Include subcategories"
                                        onChange={handleChange}
                                        value={localFilter.include_subcategories}
                                        name="include_subcategories"
                                        disabled={!hasOverrideDomainsPermission}
                                    />
                                </SliderRow>
                            </Grid.Col>
                            <Grid.Col justifyContent="flex-start">
                                <Grid.Row mb={10}>
                                    <MultiSelect
                                        label="Domains"
                                        options={domains}
                                        value={localFilter.site_ids}
                                        onChange={handleChange}
                                        name="site_ids"
                                        placeholder="Search by name"
                                        hasSelectAllButton
                                    />
                                </Grid.Row>
                                <SliderRow disabled={!hasOverrideDomainsPermission}>
                                    <Slider
                                        label="Ignore my limitations by domains"
                                        onChange={handleChange}
                                        value={localFilter.override_user_domains}
                                        name="override_user_domains"
                                        disabled={!hasOverrideDomainsPermission}
                                    />
                                </SliderRow>
                            </Grid.Col>
                            <Grid.Col justifyContent="flex-start">
                                <Grid.Row mb={10}>
                                    <MultiSelect
                                        label="Filters"
                                        options={filters}
                                        value={localFilter.filters_ids}
                                        onChange={handleChange}
                                        name="filters_ids"
                                        placeholder="Search by name"
                                        hasSelectAllButton
                                    />
                                </Grid.Row>
                                <SliderRow disabled={!hasOverrideFiltersPermission}>
                                    <Slider
                                        label="Ignore my limitations by filters"
                                        onChange={handleChange}
                                        value={localFilter.override_user_filters}
                                        name="override_user_filters"
                                        disabled={!hasOverrideFiltersPermission}
                                    />
                                </SliderRow>
                            </Grid.Col>
                            <Grid.Col justifyContent="flex-start">
                                <Grid.Row mb={10}>
                                    <Input
                                        value={localFilter.url}
                                        onChange={handleChange}
                                        label="URL address"
                                        name="url"
                                    />
                                </Grid.Row>
                                <Grid.Row>
                                    <Slider
                                        label="Exact URL match"
                                        onChange={handleChange}
                                        value={localFilter.exact_url}
                                        name="exact_url"
                                    />
                                </Grid.Row>
                            </Grid.Col>
                        </GridContainer>
                    </Section>
                    <Section>
                        <GridContainer>
                            <ExpandedSelect
                                label="Labelling status"
                                options={LABELING_STATUSES}
                                value={localFilter.labeling_status}
                                onChange={handleChange}
                                name="labeling_status"
                            />
                            <MultiSelect
                                label="Labelled by"
                                options={users}
                                value={localFilter.labeled_by}
                                onChange={handleChange}
                                name="labeled_by"
                                placeholder="Search by name"
                                hasSelectAllButton
                            />
                            {parametersA2.length > 0 && (
                                <CustomParametersSelect
                                    onChange={handleParameters2Change}
                                    value={getParametersA2Value()}
                                    parameters={parametersA2}
                                />
                            )}
                        </GridContainer>
                    </Section>

                    <Section>
                        <EntitiesSection>
                            <CustomExtractedEntitiesSelect
                                onChange={handleAvailableTypeChange}
                                add={handleAddAvailableType}
                                values={extractedEntities.extracted_entities}
                                availableTypes={availableTypes}
                            />
                        </EntitiesSection>
                    </Section>

                    {classifications.length > 0 && (
                        <Section>
                            <ClassificationsSection>
                                <CustomClassificationsSelect
                                    onChange={handleClassificationModelChange}
                                    add={handleAddClassificationModel}
                                    values={localClassifications.classifications}
                                    classifications={classifications}
                                />
                            </ClassificationsSection>
                        </Section>
                    )}

                    <Section>
                        <GridContainer>
                            <Input
                                value={localFilter.infometer_total}
                                onChange={(e) => isInputNumberValid(e.value) && handleChange(e)}
                                label="Infometer total, minimum"
                                name="infometer_total"
                                placeholder="eg. 30.7"
                            />
                            <div />
                            <div />
                            <Input
                                value={localFilter.infometer_relevance}
                                onChange={(e) => isInputNumberValid(e.value) && handleChange(e)}
                                label="Infometer relevance, minimum"
                                name="infometer_relevance"
                                placeholder="eg. 30.7"
                            />
                            <Input
                                value={localFilter.infometer_reach}
                                onChange={(e) => isInputNumberValid(e.value) && handleChange(e)}
                                label="Infometer reach, minimum"
                                name="infometer_reach"
                                placeholder="eg. 30.7"
                            />
                            <Input
                                value={localFilter.infometer_social}
                                onChange={(e) => isInputNumberValid(e.value) && handleChange(e)}
                                label="Infometer social, minimum"
                                name="infometer_social"
                                placeholder="eg. 30.7"
                            />
                        </GridContainer>
                    </Section>

                    {parametersA3.length > 0 && (
                        <Section>
                            <GridContainer>
                                {parametersA3.map((parameter) => (
                                    <MultiSelect
                                        key={parameter.id}
                                        label={parameter.name}
                                        value={localFilter[`parameters[${parameter.id}]`]}
                                        options={Mapper.mapParameterValuesToOptions(parameter.values)}
                                        onChange={handleChange}
                                        name={`parameters[${parameter.id}]`}
                                        placeholder="Search by name"
                                        hasSelectAllButton
                                    />
                                ))}
                            </GridContainer>
                        </Section>
                    )}
                    <Section>
                        <GridContainer>
                            {parametersA1.map((parameter) => (
                                <ExpandedMultiSelect
                                    key={parameter.id}
                                    label={parameter.name}
                                    value={localFilter[`parameters[${parameter.id}]`]}
                                    options={Mapper.mapParameterValuesToOptions(parameter.values)}
                                    onChange={handleChange}
                                    name={`parameters[${parameter.id}]`}
                                />
                            ))}
                        </GridContainer>
                    </Section>
                </Content>
                <Footer>
                    <FooterSection>
                        <Grid.Col>
                            <Button
                                outline
                                leftIcon={Images.SAVE}
                                onClick={() => setSearchesVisible(true)}
                                type={Buttons.TERTIARY}
                                label="Saved searches"
                            />
                        </Grid.Col>
                    </FooterSection>
                    {editingSearch ? renderEditActions() : renderActions()}
                </Footer>
            </Wrapper>
            <Grid.Col flex={1} style={{ height: '100%' }} onClick={onClose} />
            <AddSavedSearchModal
                search={localFilter}
                editingSearch={editingSearch}
                visible={addSearchVisible}
                onClose={handleSuccessEdit}
            />
            <SavedSearchesModal
                onEdit={handleEdit}
                visible={searchesVisible}
                onSearch={onOpenSearch}
                onClose={() => setSearchesVisible(false)}
            />
        </Container>
    );
}

Filter.propTypes = {
    visible: PropTypes.bool.isRequired,
    onShowHelpModal: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    languages: PropTypes.arrayOf(SharedTypes.OptionType).isRequired,
    users: PropTypes.arrayOf(SharedTypes.OptionType).isRequired,
    categories: PropTypes.arrayOf(SharedTypes.OptionType).isRequired,
    domains: PropTypes.arrayOf(SharedTypes.OptionType).isRequired,
    filters: PropTypes.arrayOf(SharedTypes.OptionType).isRequired,
    parameters: PropTypes.arrayOf(SharedTypes.ParameterType).isRequired,
    isLabeling: PropTypes.bool,
    isCharts: PropTypes.bool,
    chart: PropTypes.object,
    getChart: PropTypes.func,
    getArticles: PropTypes.func,
    getExportFields: PropTypes.func,
    getReservedArticles: PropTypes.func,
    getReservedArticlesCount: PropTypes.func,
    getWaitingArticlesCount: PropTypes.func,
    getLabeledArticlesCount: PropTypes.func,
    getSkippedArticlesCount: PropTypes.func,
    onShowExportModal: PropTypes.func,
    setLocalFilterMain: PropTypes.func,
    handleDateChange: PropTypes.func,
    localFilterMain: PropTypes.object,
    getClassifications: PropTypes.func,
    classifications: PropTypes.array,
    getAvailableTypes: PropTypes.func,
    availableTypes: PropTypes.array,
};

function mapStateToProps(state) {
    const { languages, domains, categories, filters, users, parameters, charts, ml, classifications } = state;

    return {
        languages: Mapper.mapLanguagesToOptions(languages.languages),
        users: Mapper.mapUsersToOptions(users.users),
        domains: Mapper.mapDomainsToOptions(domains.domains),
        categories: Mapper.mapCategoriesToOptions(categories.categories),
        filters: Mapper.mapFiltersToOptions(filters.filters),
        parameters: parameters.parameters,
        chart: charts.chart,
        availableTypes: ml.availableTypes,
        classifications: classifications.classifications,
    };
}

const mapDispatchToProps = {
    getChart: ChartsActions.getChart,
    getArticles: ArticlesActions.getArticles,
    getExportFields: ArticlesActions.getExportFields,
    getReservedArticles: ArticlesActions.getReservedArticles,
    getReservedArticlesCount: ArticlesCountActions.getReservedArticlesCount,
    getWaitingArticlesCount: ArticlesCountActions.getWaitingArticlesCount,
    getLabeledArticlesCount: ArticlesCountActions.getLabeledArticlesCount,
    getSkippedArticlesCount: ArticlesCountActions.getSkippedArticlesCount,
    getAvailableTypes: MLActions.getAvailableTypes,
    getClassifications: ClassificationsActions.getClassifications,
};

export default connect(mapStateToProps, mapDispatchToProps)(Filter);
