export const advancedExportType = {
    SIMPLE: 'simple',
    BY_FILTERS: 'byFilters',
    BY_LABELING_PARAMETERS: 'byLabelingParameters',
    BY_ML_CLASSIFICATIONS: 'byMlClassifications',
    BY_NAMED_ENTITIES: 'byNamedEntities',
    BY_EXTERNAL_LINKS: 'byExternalLinks',
    BY_SINGLE_PARAMETER: 'bySingleParameter',
    TOP_WORDS: 'topWords',
};

export const AdvancedExportModalKey = 'AdvancedExportModal';
