export const useChartJsToPng = () => {
    function resizeChartToPng(chart, width, height) {
        const resizeCanvas = document.createElement('canvas');
        resizeCanvas.width = width;
        resizeCanvas.height = height;

        const resizeCtx = resizeCanvas.getContext('2d');
        resizeCtx.imageSmoothingEnabled = true;
        resizeCtx.drawImage(chart, 0, 0, width, height);

        return resizeCanvas.toDataURL('image/png');
    }

    function downloadImage(imageUrl, title) {
        const a = document.createElement('a');
        a.href = imageUrl;
        a.download = title;
        a.click();
        a.remove();
    }

    async function generatePng(chartId, name, printSize) {
        const chrt = document.getElementById(chartId);
        const width = chrt.getAttribute('width');
        const height = chrt.getAttribute('height');

        const title = `${name}.png`;
        const imgWidth = printSize * 2;
        let imgHeight = height * (imgWidth / width);

        if (printSize) {
            imgHeight = imgWidth * (height / width);
        }

        const image = resizeChartToPng(chrt, imgWidth, imgHeight);

        downloadImage(image, title);
    }

    return { generatePng };
};

export default useChartJsToPng;
