/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { Grid } from 'styles/components';
import { Loader, Tabs } from 'components';
import { useArticle } from 'features/article/hooks';
import { connect } from 'react-redux';
import { HighlightsActions } from 'core/actions';
import { useDispatch } from 'react-redux';
import { useIsMounted, useComputeHighlights, useStorageHighlights } from 'hooks';
import _ from 'lodash';
import {
    Categories,
    Filters,
    Source,
    Votes,
    Header,
    Content,
    TechnicalData,
    Actions,
} from 'features/article/components';
import PropTypes from 'prop-types';
import LabellingInfo from './components/LabellingInfo';
import './style.scss';

const TABS = [
    { key: 'article', title: 'Article' },
    { key: 'source', title: 'Source' },
    { key: 'votes', title: 'Votes' },
    { key: 'filters', title: 'Filters' },
    { key: 'categories', title: 'Categories' },
    { key: 'technicalData', title: 'Technical data' },
];

const ArticleDetails = (props) => {
    const { labeling, highlights, parameters, isArticleLoaded } = props;
    const { article, isLoading } = useArticle();
    const [fontSize, setFontSize] = useState(14);
    const [activeTab, setActiveTab] = useState('article');
    const isMounted = useIsMounted();
    const [clickTimestamp, setClickTimestamp] = useState(null);
    const [selectedRowData, setSelectedRowData] = useState(null);
    const dispatch = useDispatch();
    const [isTableMinimized, setIsTableMinimized] = useState(false);
    const [contentContainerHeight, setContentContainerHeight] = useState('79vh');

    // Define constants for maximum heights and row height
    const maxLabellingHeight = 163; // Maximum height of LabellingInfo in pixels
    const rowHeight = 35; // Height in pixels for each table row
    const baseHeight = 79; // Base height percentage for ContentContainer
    const labelingHeight = 67;
    const minimizedLabeling = 37;

    const computedHighlights = useComputeHighlights(isMounted, article, parameters);
    const { fetchHighlights, saveHighlights, removeAllFromStorage } = useStorageHighlights();

    const updateContentContainerHeight = () => {
        let labellingHeight;

        if (isTableMinimized) {
            // If the table is minimized, use a predefined minimized height
            labellingHeight = minimizedLabeling;
        } else {
            if (highlights.length >= 4) {
                labellingHeight = maxLabellingHeight;
            } else {
                // Calculate height based on number of rows
                labellingHeight = labelingHeight + Math.min(highlights.length * rowHeight, maxLabellingHeight);
            }
        }

        // Adjust ContentContainer height
        let newHeight = `calc(${baseHeight}vh - ${labellingHeight}px)`;
        setContentContainerHeight(newHeight);
    };

    useEffect(() => {
        updateContentContainerHeight();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [highlights.length, isTableMinimized]);

    useEffect(() => {
        const lang = article ? article.technicalData.lang : 'en';
        document.documentElement.lang = lang;
    }, [article]);

    // initial load
    useEffect(() => {
        if (!isMounted || !isArticleLoaded || !article?.labeling?.comments || !parameters) return;

        if (highlights.length === 0) {
            const highlightsFromStorage = fetchHighlights(article);
            if (highlightsFromStorage && highlightsFromStorage.length > 0) {
                dispatch(HighlightsActions.addHighlights(highlightsFromStorage));
            } else {
                removeAllFromStorage();
                // Load all computed highlights
                dispatch(HighlightsActions.addHighlights(computedHighlights));
            }
        }

        // Dependency on prevHighlights is not needed as it's used for comparison only and doesn't trigger the effect
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [article, isMounted, isArticleLoaded, computedHighlights]);

    useEffect(() => {
        //update highlightParameters
        const s1Parameters = parameters.filter((i) => i.type === 'S1');
        const s2Parameters = parameters.filter((i) => i.type === 'S2');
        const s3Parameters = parameters.filter((i) => i.type === 'S3');

        dispatch(
            HighlightsActions.addHighlightParameters({
                s1: s1Parameters,
                s2: s2Parameters,
                s3: s3Parameters,
            }),
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [parameters]);

    const handleUpdateOrAddHighlight = (highlight) => {
        if (isMounted && isArticleLoaded) {
            const existingHighlightIndex = highlights.findIndex((h) => h.id === highlight.id);

            // Check if the highlight exists
            if (existingHighlightIndex !== -1) {
                // Highlight exists, so we'll update it
                const newHighlights = highlights.map((h, index) => {
                    if (index === existingHighlightIndex) {
                        // This is the highlight to update
                        return _.isEqual(h, highlight) ? h : { ...highlight };
                    }
                    return h;
                });

                if (!_.isEqual(highlights, newHighlights)) {
                    saveHighlights(article, newHighlights);
                    // Clone made, now dispatch the update with the newHighlights array
                    dispatch(HighlightsActions.updateHighlight(highlight));
                    // If you need to update the entire highlights array instead, you might need a different action or approach
                }
            } else {
                // Highlight does not exist, so we'll add it
                const newHighlights = [...highlights, highlight];
                saveHighlights(article, newHighlights);
                dispatch(HighlightsActions.updateHighlight(highlight));
                // Similarly, if adding a single highlight is different from updating the array, adjust the dispatch accordingly
            }
        }
    };

    const handleRemoveHighlight = (highlight) => {
        if (isMounted && isArticleLoaded) {
            // Find the index of the highlight to be removed
            const existingHighlightIndex = highlights.findIndex((h) => h.id === highlight.id);

            // Check if the highlight exists
            if (existingHighlightIndex !== -1) {
                // Highlight exists, so we'll remove it
                const newHighlights = highlights.filter((h, index) => index !== existingHighlightIndex);

                // Save the updated highlights array
                saveHighlights(article, newHighlights);

                // Dispatch the remove action with the updated array or the highlight ID
                dispatch(HighlightsActions.removeHighlight(highlight));
            } else {
                console.log('Highlight not found for removal with id:', highlight.id);
            }
        }
    };

    const handleRowClick = (value) => {
        const highlight = highlights.find((item) => item.id === value);
        setSelectedRowData(highlight);
        setClickTimestamp(new Date().getTime());
    };

    if (isLoading) {
        return (
            <div className="LoaderContainer">
                <Loader size={40} />
            </div>
        );
    }

    if (!article) {
        return <React.Fragment />;
    }

    // Inline style based on labeling condition
    const articleDetailsStyle = {
        marginLeft: props.labeling ? '-415px' : '0',
        width: props.labeling ? 'calc(100% - 415px)' : undefined,
    };

    return (
        <div style={articleDetailsStyle} className="ArticleDetails">
            {labeling && (
                <LabellingInfo
                    setIsTableMinimized={setIsTableMinimized}
                    isTableMinimized={isTableMinimized}
                    onRowClick={handleRowClick}
                    handleRemoveHighlight={handleRemoveHighlight}
                />
            )}

            <div className="Container" style={{ overflowY: 'auto', maxHeight: contentContainerHeight }}>
                <Grid.Row mb={10}>
                    <Header article={article} />
                </Grid.Row>
                <Grid.Row mb={15}>
                    <Tabs onSelect={setActiveTab} active={activeTab} tabs={TABS} />
                </Grid.Row>
                <Grid.Row mb={15}>
                    <Actions fontSize={fontSize} zoomIn={setFontSize} zoomOut={setFontSize} />
                </Grid.Row>
                <div className="Wrapper">
                    {activeTab === 'article' && (
                        <Content
                            labelingForm={labeling}
                            fontSize={fontSize}
                            selectedRowData={selectedRowData}
                            clickTimestamp={clickTimestamp}
                            setSelectedRowData={setSelectedRowData}
                            handleUpdateOrAddHighlight={handleUpdateOrAddHighlight}
                            handleRemoveHighlight={handleRemoveHighlight}
                        />
                    )}
                    {activeTab === 'source' && <Source fontSize={fontSize} source={article.source} />}
                    {activeTab === 'categories' && <Categories categories={article.categories} />}
                    {activeTab === 'filters' && <Filters filters={article.filters} />}
                    {activeTab === 'votes' && <Votes votes={article.votes} />}
                    {activeTab === 'technicalData' && <TechnicalData article={article} />}
                </div>
            </div>
        </div>
    );
};

ArticleDetails.propTypes = {
    labeling: PropTypes.bool,
};

ArticleDetails.defaultProps = {
    labeling: false,
};

function mapStateToProps(state) {
    const { parameters, article, articles, similarArticles, highlights } = state;
    return {
        highlights: highlights.highlights,
        parameters: parameters.parameters,
        isArticleLoaded: article.isLoaded,
        similarArticles: similarArticles.similarArticles,
        articles: articles.articles,
        article: article.article,
    };
}

export default connect(mapStateToProps)(ArticleDetails);
