import React, { useEffect, useState } from 'react';
import { CALC_TYPES, CHART_OPTIONS, PERIODS } from '../../pages/CreateChart/config';
import update from 'immutability-helper';
import { Container, Section, Title } from '../../pages/CreateChart/styles';
import { Grid } from '../../styles/components';
import { Button, Checkbox, Input, MultiSelect, SingleSelect } from '../../components';
import { Buttons } from '../../styles/constant';
import PropTypes from 'prop-types';
import { SharedTypes } from '../../utils';
import { ChartsActions } from '../../core/actions';
import { connect } from 'react-redux';
import Translations from './Translations';

const errorMessage = 'This field is required';

const validateFields = (name, type, rows, values, calc) => {
    let errors = {};
    if (!name) {
        errors.name = errorMessage;
    }

    if (!type) {
        errors.type = errorMessage;
    }

    if (!rows.length) {
        errors.rows = errorMessage;
    }

    if (!values.length) {
        errors.values = errorMessage;
    }

    if (values.length) {
        values.forEach((value) => {
            if (!calc[value]) {
                errors[`calc-${value}`] = errorMessage;
            }
        });
    }

    return errors;
};

function ChartForm(props) {
    const { charts, organisations, types, createChart, history, updateChart, isUpdated, isUpdating, options } = props;
    const [chart, setChart] = useState(false);
    const [chartOptions, setChartOptions] = useState(CHART_OPTIONS(options));
    const [organisation, setOrganisation] = useState(null);
    const [includeZero, setIncludeZero] = useState(false);
    const [lastPeriod, setLastPeriod] = useState(false);
    const [name, setName] = useState('');
    const [type, setType] = useState('');
    const [columns, setColumns] = useState([]);
    const [rows, setRows] = useState([]);
    const [values, setValues] = useState([]);
    const [translations, setTranslations] = useState([]);
    const [period, setPeriod] = useState('');
    const [numberOfPeriods, setNumberOfPeriods] = useState(0);
    const [limit, setLimit] = useState(0);
    const [calc, setCalcs] = useState({});
    const [errors, setErrors] = useState({});

    // TODO
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => {
        const id = history.location.pathname.split('/').pop();
        const valuesArr = [];
        const calcs = {};

        isUpdated && history.push('/chart-list');
        if (!chart && id && id !== 'new') {
            const current = charts.find((c) => c.id == id);
            setChart(current);
            setRows(current.rows);
            setName(current.name);
            setType(current.type.id);
            current.columns && setColumns(current.columns);
            current.top && setLimit(current.top);
            setIncludeZero(current.include_zero);
            setLastPeriod(current.last_period);
            setPeriod(current.period);
            current.number_of_periods && setNumberOfPeriods(current.number_of_periods);
            setTranslations(
                current.translations.map((t) => {
                    return {
                        id: t.id,
                        slot: t.slot,
                        value: t.value,
                        language_id: t.language.id,
                        organisation_id: t.organisation ? t.organisation.id : null,
                    };
                }),
            );
            current.values.map((val) => {
                valuesArr.push(val.col);
                calcs[val.col] = val.calc;
            });
            setValues(valuesArr);
            setCalcs(calcs);
        }
    });

    useEffect(() => {
        document.title = chart.id ? `Debunk EU - Edit Chart - ${chart.name}` : 'Create New Chart';
    }, [chart]);

    const setCalc = (value, index) => {
        setCalcs(
            update(calc, {
                [index]: { $set: value },
            }),
        );
    };

    const onSubmit = () => {
        if (translations.find((t) => !t.language_id)) {
            window.alert('Translation language must be selected');
            return;
        }

        const errors = validateFields(name, type, rows, values, calc);
        if (Object.keys(errors).length) {
            setErrors(errors);
            return;
        }

        const valuess = [];
        const optionsArr = chartOptions.map((o) => o.value);
        values
            .filter((item) => optionsArr.includes(item))
            .map((value) => {
                valuess.push({ col: value, calc: calc[value] });
            });

        const data = {
            name,
            rows: rows.filter((item) => optionsArr.includes(item)),
            columns: columns.filter((item) => optionsArr.includes(item)),
            translations: translations.filter((t) => t.value && t.value.length > 0),
            top: parseInt(limit),
            last_period: lastPeriod,
            include_zero: includeZero,
            number_of_periods: parseInt(numberOfPeriods),
            type_id: type,
            valuess,
        };

        if (period && period.length) data.period = period;

        if (!chart) {
            createChart(data);
        } else {
            updateChart(chart.id, data);
        }
        setErrors({});
    };

    const updateTranslations = (val) => {
        const newTranslations = [];
        val.map((t) => {
            newTranslations.push({
                id: t.title.id,
                language_id: t.language,
                organisation_id: t.organisation,
                slot: 'title',
                value: t.title.value,
            });

            newTranslations.push({
                id: t.legend.id,
                language_id: t.language,
                organisation_id: t.organisation,
                slot: 'legend',
                value: t.legend.value,
            });

            newTranslations.push({
                id: t['xAxis[0]'].id,
                language_id: t.language,
                organisation_id: t.organisation,
                slot: 'xAxis[0]',
                value: t['xAxis[0]'].value,
            });

            newTranslations.push({
                id: t['yAxis[0]'].id,
                language_id: t.language,
                organisation_id: t.organisation,
                slot: 'yAxis[0]',
                value: t['yAxis[0]'].value,
            });

            newTranslations.push({
                id: t['yAxis[1]'].id,
                language_id: t.language,
                organisation_id: t.organisation,
                slot: 'yAxis[1]',
                value: t['yAxis[1]'].value,
            });
        });

        setTranslations(newTranslations);
    };

    const updateOrganisation = (organisationId) => {
        const visibleFields = [];
        const filtered = Object.entries(options).filter((el) => {
            let bool = true;
            if (el[1].organization_ids && organisationId) {
                bool = !!el[1].organization_ids.find((id) => id === organisationId);
            }
            return bool;
        });

        for (let key in filtered) {
            let item = filtered[key];
            visibleFields[item[0]] = item[1];
        }

        setChartOptions(CHART_OPTIONS(visibleFields));
        setOrganisation(organisationId);
    };

    return (
        <Container>
            <Grid.FixedContainer>
                <Grid.Row>
                    <Title>{chart ? 'Edit ' : 'Create new '}chart</Title>
                </Grid.Row>

                <Section>
                    <Grid.Row mb={20}>
                        <Input
                            label="Chart title"
                            name="Chart title"
                            value={name}
                            onChange={(e) => {
                                setName(e.value);
                                const newErrors = { ...errors };
                                delete newErrors.name;
                                setErrors(newErrors);
                            }}
                            error={errors.name}
                            dirty={!!errors.name}
                        />
                    </Grid.Row>
                    <Grid.Row mb={20}>
                        <SingleSelect
                            label="Organisation"
                            name="Organisation"
                            value={organisation}
                            onChange={(e) => updateOrganisation(e.value)}
                            options={organisations.map((o) => {
                                return { label: o.name, value: o.id };
                            })}
                        />
                    </Grid.Row>

                    <Grid.Row mb={20}>
                        <Grid.Col mr={10} flex={1}>
                            <SingleSelect
                                forceOpen
                                label="Type"
                                name="Type"
                                value={type}
                                onChange={(e) => {
                                    setType(e.value);
                                    const newErrors = { ...errors };
                                    delete newErrors.type;
                                    setErrors(newErrors);
                                }}
                                options={types.map((t) => {
                                    return { label: t.type, value: t.id };
                                })}
                                error={errors.type}
                                dirty={!!errors.type}
                            />
                        </Grid.Col>
                        <Grid.Col ml={10} flex={1}>
                            <MultiSelect
                                forceOpen
                                label="Columns"
                                name="Columns"
                                value={columns.filter((item) => chartOptions.map((o) => o.value).includes(item))}
                                onChange={(e) => setColumns(e.value)}
                                options={chartOptions}
                            />
                        </Grid.Col>
                    </Grid.Row>

                    <Grid.Row mb={20}>
                        <Grid.Col mr={10} flex={1}>
                            <MultiSelect
                                forceOpen
                                label="Rows"
                                name="Rows"
                                value={rows.filter((item) => chartOptions.map((o) => o.value).includes(item))}
                                onChange={(e) => {
                                    setRows(e.value);
                                    const newErrors = { ...errors };
                                    delete newErrors.rows;
                                    setErrors(newErrors);
                                }}
                                options={chartOptions}
                                error={errors.rows}
                                dirty={!!errors.rows}
                            />
                        </Grid.Col>
                        <Grid.Col ml={10} flex={1}>
                            <MultiSelect
                                forceOpen
                                label="Values"
                                name="Values"
                                value={values.filter((item) => chartOptions.map((o) => o.value).includes(item))}
                                onChange={(e) => {
                                    setValues(e.value);
                                    const newErrors = { ...errors };
                                    delete newErrors.values;
                                    setErrors(newErrors);
                                }}
                                options={chartOptions}
                                error={errors.values}
                                dirty={!!errors.values}
                            />
                        </Grid.Col>
                    </Grid.Row>

                    {values.map((v, i) => {
                        return (
                            <Grid.Row mb={20} key={i}>
                                <SingleSelect
                                    label={`Calc (${v})`}
                                    name="calc"
                                    value={calc[v]}
                                    onChange={(e) => {
                                        setCalc(e.value, v);
                                        const newErrors = { ...errors };
                                        delete newErrors[`calc-${v}`];
                                        setErrors(newErrors);
                                    }}
                                    options={CALC_TYPES}
                                    error={errors[`calc-${v}`]}
                                    dirty={!!errors[`calc-${v}`]}
                                />
                            </Grid.Row>
                        );
                    })}

                    <Grid.Row mb={20}>
                        <SingleSelect
                            label="Period"
                            name="period"
                            value={period}
                            onChange={(e) => setPeriod(e.value)}
                            options={PERIODS}
                        />
                    </Grid.Row>

                    {period && period.length > 0 && (
                        <Grid.Row mb={20}>
                            <Input
                                type="number"
                                label="Number of periods"
                                name="periods"
                                value={numberOfPeriods}
                                onChange={(e) => setNumberOfPeriods(e.value)}
                            />
                        </Grid.Row>
                    )}

                    <Grid.Row mb={20}>
                        <Input
                            type="number"
                            label="Top values limit"
                            name="limit"
                            value={limit}
                            onChange={(e) => setLimit(e.value)}
                        />
                    </Grid.Row>

                    <Grid.Row mb={40}>
                        <Grid.Col mr={20}>
                            <Checkbox
                                name="lastPeriod"
                                label="Last period"
                                checked={lastPeriod}
                                onChange={() => setLastPeriod(!lastPeriod)}
                            />
                        </Grid.Col>
                        <Grid.Col>
                            <Checkbox
                                name="includeZero"
                                label="Include zero"
                                checked={includeZero}
                                onChange={() => setIncludeZero(!includeZero)}
                            />
                        </Grid.Col>
                    </Grid.Row>
                </Section>

                <Translations
                    organisations={organisations}
                    translations={translations}
                    updateTranslations={updateTranslations}
                />

                <Grid.Row>
                    <Grid.Col mr={10}>
                        <Button label="Submit" loading={isUpdating} type={Buttons.PRIMARY} onClick={onSubmit} />
                    </Grid.Col>
                    <Grid.Col>
                        <Button label="Cancel" type={Buttons.TERTIARY} onClick={() => history.push('/chart-list')} />
                    </Grid.Col>
                </Grid.Row>
            </Grid.FixedContainer>
        </Container>
    );
}

ChartForm.propTypes = {
    charts: PropTypes.arrayOf(SharedTypes.ChartsListItemType).isRequired,
    options: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    types: PropTypes.array.isRequired,
    organisations: PropTypes.arrayOf(PropTypes.object),
    createChart: PropTypes.func.isRequired,
    updateChart: PropTypes.func.isRequired,
    isUpdated: PropTypes.bool,
    isUpdating: PropTypes.bool,
};

function mapStateToProps(state) {
    const { charts } = state;
    const { isUpdated, isUpdating } = charts;
    return { isUpdated, isUpdating };
}

const mapDispatchToProps = {
    createChart: ChartsActions.createChart,
    updateChart: ChartsActions.updateChart,
};

export default connect(mapStateToProps, mapDispatchToProps)(ChartForm);
