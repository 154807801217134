import React from 'react';
import ReactDOM from 'react-dom';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import { persistor, store } from 'core/store';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { PageLoader } from 'components';
import App from './App';

import 'react-toastify/dist/ReactToastify.css';
import 'react-datepicker/dist/react-datepicker.css';

import './assets/scss/base.scss';
import './assets/scss/animations.scss';
import './assets/scss/datepicker.scss';
import './assets/scss/charts.scss';
import './assets/scss/article.scss';
import './assets/scss/collapse.scss';

function shouldReportError(error) {
    const ignoredClasses = process.env.REACT_APP_BUGSNAG_IGNORE_CLASS;
    const ignoredMessages = process.env.REACT_APP_BUGSNAG_IGNORE_TEXTS;
    const errorMessage = error.errorMessage;
    const errorClass = error.errorClass;

    // Do not report by error classs value
    if (ignoredClasses && ignoredClasses.includes(errorClass)) {
        return false;
    }

    // Do not report by error text
    if (ignoredMessages && ignoredMessages.includes(errorMessage)) {
        return false;
    }

    // Report all other errors
    return true;
}

let ErrorBoundary;

// Only enable Bugsnag if REACT_APP_BUGSNAG_API_KEY is defined and not empty
if (process.env.REACT_APP_BUGSNAG_API_KEY && process.env.REACT_APP_BUGSNAG_API_KEY.trim() !== '') {
    Bugsnag.start({
        apiKey: process.env.REACT_APP_BUGSNAG_API_KEY,
        plugins: [new BugsnagPluginReact()],
        releaseStage: process.env.REACT_APP_BUGSNAG_RELEASE_STAGE,
        onError: function (event) {
            const errors = event.errors;

            // Check each error using the shouldReportError function
            const shouldReportAllErrors = errors.every(shouldReportError);

            // If shouldReportAllErrors is true, Bugsnag will report the error as usual
            return shouldReportAllErrors;
        },
    });

    // Create ErrorBoundary using Bugsnag only if Bugsnag is enabled
    ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React);
} else {
    // If Bugsnag is not enabled, use a fallback ErrorBoundary that simply renders children without error handling
    ErrorBoundary = ({ children }) => children;
}

ReactDOM.render(
    <Provider store={store}>
        <PersistGate loading={<PageLoader />} persistor={persistor}>
            <ErrorBoundary>
                <App />
            </ErrorBoundary>
        </PersistGate>
    </Provider>,
    document.getElementById('root'),
);
