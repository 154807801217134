import { MLTypes } from 'core/types';
import { MLService, ToastService } from 'core/services';

export function getAvailableTypes() {
    return async (dispatch) => {
        try {
            dispatch({ type: MLTypes.GET_EXTRACTED_ENTITIES_START });
            const availableTypes = await MLService.getAvailableTypes();
            dispatch({ type: MLTypes.GET_EXTRACTED_ENTITIES, payload: { availableTypes } });
        } catch (error) {
            const message = error?.response?.data?.message;
            ToastService.showGenericError(message);
            dispatch({ type: MLTypes.GET_EXTRACTED_ENTITIES_ERROR });
        }
    };
}
