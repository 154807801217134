import React from 'react';
import PropTypes from 'prop-types';
import { Buttons, Images } from 'styles/constant';
import { Content, ModalHeader, ModalHeaderWrapper, ButtonsWrapper } from './styles';
import { Button, Checkbox, Modal } from 'components';
import { Title, CloseIcon } from 'components/Modal/styles';

const ChartSettingsModal = (props) => {
    const { visible, onClose, charts, updateChart, handleModalOnChange, handleModalChildOnChange, selectAll } = props;

    const handleOnChange = (data, index) => {
        handleModalOnChange(data, index);
    };

    const handleChildOnChange = (data, index, parentIndex) => {
        handleModalChildOnChange(data, index, parentIndex);
    };

    const submit = () => {
        updateChart(charts);
        onClose();
    };

    const renderContent = () => (
        <Content>
            {charts.chartsData.map((key, index) => {
                return (
                    <div key={index}>
                        <Checkbox
                            style={{ padding: '10px' }}
                            name={key.label}
                            label={key.label}
                            checked={key.value}
                            onChange={() => handleOnChange(key, index)}
                        />
                        <div>
                            {key.children.map((k, i) => {
                                if (key.children.length > 1) {
                                    return (
                                        <Checkbox
                                            style={{ marginLeft: '50px', padding: '10px' }}
                                            key={i}
                                            name={k.label}
                                            label={k.label}
                                            checked={k.value}
                                            onChange={() => handleChildOnChange(k, i, index)}
                                        />
                                    );
                                }
                            })}
                        </div>
                    </div>
                );
            })}
        </Content>
    );

    const renderHeader = () => (
        <ModalHeaderWrapper>
            <ModalHeader>
                <Title>Please select parameters to show one the chart</Title>
                <CloseIcon onClick={onClose} src={Images.CLOSE_GREY} />
            </ModalHeader>
            <ButtonsWrapper>
                <Button label="Cancel" type={Buttons.TERTIARY} onClick={onClose} />
                <Button style={{ marginLeft: '20px' }} label="Update" onClick={submit} />
                <Button
                    style={{ marginLeft: '20px' }}
                    label={charts.isAllChecked ? 'Select None' : 'Select All'}
                    onClick={() => selectAll(!charts.isAllChecked)}
                />
            </ButtonsWrapper>
        </ModalHeaderWrapper>
    );

    return (
        <Modal
            style={{ maxHeight: '90vh', overflowY: 'scroll' }}
            renderHeader={renderHeader}
            renderContent={renderContent}
            onClose={onClose}
            visible={visible}
        />
    );
};

ChartSettingsModal.propTypes = {
    onClose: PropTypes.func.isRequired,
    visible: PropTypes.bool,
    charts: PropTypes.object.isRequired,
    updateChart: PropTypes.func.isRequired,
    handleModalOnChange: PropTypes.func.isRequired,
    handleModalChildOnChange: PropTypes.func.isRequired,
    selectAll: PropTypes.func,
};

ChartSettingsModal.defaultProps = {
    visible: false,
};

export default ChartSettingsModal;
