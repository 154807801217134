import React from 'react';
import * as Styles from './styles';
import { Images } from 'styles/constant';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { usePaging } from 'features/articles/hooks';

const getFromValue = (page, visiblePages, pagesCount) => {
    if (!pagesCount) {
        return 0;
    }
    if (visiblePages > 0) {
        if (page === pagesCount) {
            return Math.max(1, page - visiblePages - 3);
        }
        if (page === pagesCount - 1) {
            return Math.max(1, page - visiblePages);
        }
    }

    return Math.max(1, page - visiblePages + 1);
};

const getToValue = (page, visiblePages, pagesCount) => {
    if (!pagesCount) {
        return 0;
    }
    if (visiblePages > 0) {
        if (page === 1) {
            return Math.min(page + visiblePages + 3, pagesCount);
        }
        if (page === 2) {
            return Math.min(page + visiblePages, pagesCount);
        }
    }

    return Math.min(page + visiblePages, pagesCount);
};

function Paging(props) {
    const { visiblePages, showFirstLast } = props;
    const { onPrevious, previousDisabled, onPage, page, pageDisabled, onNext, nextDisabled, pagesCount } =
        usePaging(visiblePages);

    const from = getFromValue(page, visiblePages, pagesCount);
    const to = getToValue(page, visiblePages, pagesCount);

    const onFirst = () => {
        onPage(1);
    };

    const onLast = () => {
        onPage(pagesCount);
    };

    return (
        <Styles.Container>
            {!showFirstLast && (
                <Styles.Page onClick={onFirst} disabled={previousDisabled}>
                    <Styles.Icon src={Images.SKIP_LEFT} />
                </Styles.Page>
            )}
            {showFirstLast && page !== 1 && (
                <>
                    <Styles.Page onClick={onFirst} disabled={previousDisabled}>
                        <Styles.Icon src={Images.SKIP_LEFT} />
                    </Styles.Page>
                    <Styles.Page onClick={onPrevious} disabled={previousDisabled}>
                        <Styles.Icon src={Images.ARROW_LEFT} />
                    </Styles.Page>
                </>
            )}
            {_.range(from, to + 1).map((currentPage) => (
                <Styles.Page
                    onClick={() => onPage(currentPage)}
                    active={currentPage === page}
                    disabled={pageDisabled}
                    key={currentPage}
                >
                    <Styles.Label>{currentPage}</Styles.Label>
                </Styles.Page>
            ))}
            {!showFirstLast && (
                <Styles.Page onClick={onNext} disabled={nextDisabled}>
                    <Styles.Icon src={Images.ARROW_RIGHT} />
                </Styles.Page>
            )}
            {showFirstLast && page !== pagesCount && (
                <>
                    <Styles.Page onClick={onNext} disabled={nextDisabled}>
                        <Styles.Icon src={Images.ARROW_RIGHT} />
                    </Styles.Page>
                    <Styles.Page onClick={onLast} disabled={nextDisabled}>
                        <Styles.Icon src={Images.SKIP_RIGHT} />
                    </Styles.Page>
                </>
            )}
        </Styles.Container>
    );
}

Paging.propTypes = {
    visiblePages: PropTypes.number,
    showFirstLast: PropTypes.bool,
};

Paging.defaultProps = {
    visiblePages: 0,
};

export default Paging;
