import React from 'react';
import { Grid } from 'styles/components';
import { ManualImportForm } from 'containers';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Container } from './styles';
import { Loader } from 'components';
import { useHistory } from 'react-router-dom';

function ManualImport(props) {
    const { isLoaded, isLoading } = props;
    const history = useHistory();
    return (
        <>
            <Container>
                {isLoading && (
                    <Grid.Row mt={30} justifyContent="center">
                        <Loader />
                    </Grid.Row>
                )}
                {isLoaded && <ManualImportForm history={history} />}
            </Container>
        </>
    );
}

ManualImport.propTypes = {
    isLoading: PropTypes.bool,
    isLoaded: PropTypes.bool,
};

function mapStateToProps(state) {
    const { importedArticles } = state;
    const { isLoading, isLoaded } = importedArticles;

    return {
        isLoaded,
        isLoading,
    };
}

export default connect(mapStateToProps)(ManualImport);
