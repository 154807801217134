import { ParametersService } from 'core/services';
import _ from 'lodash';

export const mapCategoriesToOptions = (categories) => {
    return categories.map((category) => ({ value: category.id, label: category.name }));
};

export const mapLanguagesToOptions = (languages) => {
    return languages.map((language) => ({ value: language.iso, label: language.name }));
};

export const mapDomainsToOptions = (domains) => {
    return domains.map((domain) => {
        const lang = domain.lang
            ? domain.lang
                  .toUpperCase()
                  .split(',')
                  .map((l) => l.split(':')[0])
                  .join(', ')
            : null;
        const title = domain.full_domain + ` - ${lang} - ` + domain.name;
        return { value: domain.id, label: domain.full_domain, lang, description: domain.name, title };
    });
};

export const mapFiltersToOptions = (filters) => {
    return filters.map((filter) => {
        const lang = filter.lang ? filter.lang.map((l) => l.lang.iso.toUpperCase()).join(', ') : null;
        const title = filter.name + (lang ? ` - ${lang}` : null);
        return { value: filter.id, label: filter.name, title, lang };
    });
};

export const mapUsersToOptions = (users) => {
    return users.map((user) => ({ value: user.id, label: user.username }));
};

export const mapParameterValuesToOptions = (values, sortBy = 'count', order = 'desc') => {
    const vals = values.map((value) => ({ value: value.id, label: value.value, count: value.count }));
    return _.orderBy(vals, [sortBy], [order]);
};

export const mapParametersToOptions = (parameters) => {
    const params = parameters.map((parameter) => ({
        value: parameter.id,
        label: parameter.name,
        count: parameter.count,
    }));
    return _.orderBy(params, ['count'], ['desc']);
};

export const mapParametersTemplatesToOptions = (parametersTemplates) => {
    const templates = parametersTemplates.map((parametersTemplate) => ({
        value: parametersTemplate.id,
        label: parametersTemplate.name,
    }));
    return _.orderBy(templates, ['value'], ['desc']);
};

export const mapParameter = (parameter) => {
    const parameterById = ParametersService.getParameterById(parameter.parameter_id);
    return {
        id: parameter.value_id,
        parameterId: parameter.parameter_id,
        type: parameterById ? parameterById.type : '',
    };
};

export const mapLabelingFormData = (article, organisationId) => {
    const filteredParameters = article.labeling.parameters.filter(
        (parameter) => organisationId === parameter.project_id,
    );
    const parameters = filteredParameters.map((parameter) => mapParameter(parameter));
    const userComment = article.labeling.comments.find((comment) => comment.project_id === organisationId);
    const comment = userComment ? userComment.value : '';
    const sentiments = article.labeling.sentiments.filter((sentiment) => sentiment.project_id === organisationId);
    return { parameters, comment, sentiments };
};

export const mapFailedLabelingForm = (article, articles) => {
    if (!article) return null;
    const index = articles.findIndex((item) => item.id === article.id && item.parentId === article.parentId);
    if (index === -1) return null;
    return articles[index].labelingData;
};

export const mapAvailableTypesToOptions = (types) => {
    const availableTypes = types.map((t) => ({
        value: t,
        label: t,
    }));
    return _.orderBy(availableTypes, ['value'], ['desc']);
};
