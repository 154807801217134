import { ArticleTypes } from 'features/article/types';
import { ArticleService } from 'features/article/services';
import { ToastService } from 'core/services';
import { ArticlesTypes, SimilarArticlesTypes } from 'features/articles/types';

export function resetArticle() {
    return { type: ArticleTypes.RESET_ARTICLE };
}

export function getArticle(id, parentId) {
    return async (dispatch) => {
        try {
            dispatch({ type: ArticleTypes.GET_ARTICLE_START });
            const article = await ArticleService.getArticle(id, parentId);
            dispatch({ type: ArticleTypes.GET_ARTICLE, payload: { article } });
        } catch (error) {
            const message = error?.response?.data?.message;
            ToastService.showGenericError(message);
            dispatch({ type: ArticleTypes.GET_ARTICLE_ERROR });
        }
    };
}

export function labelArticle(id, parentId, data, isSkip, callback, status) {
    return async (dispatch) => {
        try {
            dispatch({ type: ArticleTypes.LABEL_ARTICLE_START, payload: { id, parentId, isSkip } });
            await ArticleService.labelArticle(id, parentId, data, status);
            dispatch({ type: ArticleTypes.LABEL_ARTICLE, payload: { id, parentId, isSkip } });
            ToastService.showSuccess('Article successfully labeled');
            setTimeout(() => {
                dispatch({ type: ArticlesTypes.REMOVE_ARTICLE, payload: { id, parentId } });
                dispatch({ type: SimilarArticlesTypes.REMOVE_SIMILAR_ARTICLE, payload: { id, parentId } });
            }, 5000);
            callback();
        } catch (error) {
            const message = error?.response?.data?.message;
            ToastService.showGenericError(message);
            dispatch({ type: ArticleTypes.LABEL_ARTICLE_ERROR, payload: { id, parentId, isSkip, data } });
        }
    };
}
