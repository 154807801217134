import { HighlightTypes as types } from 'core/types';

export const addHighlight = (highlightData) => async (dispatch) => {
    dispatch({ type: types.ADD_HIGHLIGHT_START });
    try {
        dispatch({ type: types.ADD_HIGHLIGHT_SUCCESS, payload: highlightData });
    } catch (error) {
        dispatch({ type: types.ADD_HIGHLIGHT_ERROR, payload: error.message });
    }
};

export const addHighlights = (highlights) => {
    return {
        type: types.ADD_HIGHLIGHTS,
        payload: highlights,
    };
};

export const removeHighlight = (highlight) => {
    return {
        type: types.REMOVE_HIGHLIGHT,
        payload: highlight,
    };
};

export const updateHighlight = (highlight) => {
    return {
        type: types.UPDATE_HIGHLIGHT,
        payload: highlight,
    };
};

export const removeAllHighlights = () => {
    return {
        type: types.REMOVE_ALL_HIGHLIGHTS,
    };
};

export const fetchHighlights = () => async (dispatch) => {
    dispatch({ type: types.FETCH_HIGHLIGHTS_START });
    try {
        dispatch({ type: types.FETCH_HIGHLIGHTS_SUCCESS, payload: [] });
    } catch (error) {
        dispatch({ type: types.FETCH_HIGHLIGHTS_ERROR, payload: error.message });
    }
};

export const addHighlightParameters = (params) => ({
    type: types.ADD_HIGHLIGHT_PARAMETERS,
    payload: params,
});
