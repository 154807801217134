import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import * as Styles from './styles';
import { Grid } from 'styles/components';
import ArticlesListItem from '../ArticlesListItem';
import { useArticles, useSimilarArticles } from 'features/articles/hooks';
import Sorting from '../Sorting';
import Paging from '../Paging';
import { Button, Loader, Modal } from 'components';
import { DynamicsChart } from 'containers';
import PropTypes from 'prop-types';
import LabelingTabs from '../LabelingTabs';
import { useSearch } from 'hooks';
import { Buttons } from 'styles/constant';
import ExpirationModal from 'features/articles/components/ExpirationModal';
import { readTranslateCookie } from 'utils';

const ArticlesList = (props) => {
    const translateElement = document.getElementById('google_translate_element');
    const { labeling } = props;
    const { filter } = useSearch();
    const {
        articles,
        isLoading,
        reserveArticles,
        cancelReservedArticles,
        isCancelingReserved,
        isReserving,
        selectedArticlesIds,
        selectArticleId,
        clearSelectedArticlesIds,
        total,
        chartData,
    } = useArticles({ labeling });
    const [similarParams, setSimilarParams] = useState({});
    const [expireVisible, setExpireVisible] = useState(false);
    const [showDynamics, setShowDynamics] = useState(false);
    const [showErrorModal, setShowErrorModal] = useState(false);

    const { similarArticles, isSimilarLoading, isLoaded, count } = useSimilarArticles(
        similarParams,
        labeling && !filter.labeling_status,
    );
    const { role } = useSelector((state) => state.auth.profile);

    useEffect(() => {
        const translateExists = readTranslateCookie();

        if (translateExists) {
            translateElement && translateElement.classList.add('show');
        }
    }, [translateElement]);

    const handleShowExpireModal = () => {
        if (expireVisible) return;
        setExpireVisible(true);
    };

    const renderLoader = () => (
        <Styles.LoaderContainer>
            <Loader size={40} />
        </Styles.LoaderContainer>
    );

    const renderFullPageLoader = () => (
        <Styles.FullPageLoaderContainer>
            <Loader size={40} />
        </Styles.FullPageLoaderContainer>
    );

    const renderSimilarArticles = () => {
        if (isSimilarLoading) return renderLoader();
        if (isLoaded && count === 0) return renderNoData(10);

        return (
            <Styles.SimilarArticlesContainer>
                {similarArticles.map((article) => {
                    return (
                        <Styles.ArticleContainer className="article-container" key={article.id}>
                            <ArticlesListItem
                                disabledSimilar={article.disabled}
                                showExpireModal={handleShowExpireModal}
                                onSelect={() => selectArticleId(article.id)}
                                selected={selectedArticlesIds.includes(article.id)}
                                labeling={labeling}
                                similar
                                article={article}
                            />
                        </Styles.ArticleContainer>
                    );
                })}
            </Styles.SimilarArticlesContainer>
        );
    };

    const renderArticles = () => {
        return articles.map((article) => (
            <Styles.ArticleContainer key={article.id}>
                <ArticlesListItem
                    filter={filter}
                    showExpireModal={handleShowExpireModal}
                    reserved={!filter.labeling_status && labeling}
                    onSelect={() => selectArticleId(article.id)}
                    selected={selectedArticlesIds.includes(article.id)}
                    labeling={labeling}
                    onSimilarClick={(skipLoad) =>
                        skipLoad
                            ? setSimilarParams({ id: null, parentId: null })
                            : setSimilarParams({ id: article.id, parentId: article.parentId })
                    }
                    article={article}
                    similarParams={similarParams}
                />
                {similarParams.id === article.id &&
                    similarParams.parentId === article.parentId &&
                    renderSimilarArticles()}
            </Styles.ArticleContainer>
        ));
    };

    const renderNoData = (mt = 0) => (
        <Grid.Row mt={mt} mb={10}>
            <Styles.NoData>No articles found</Styles.NoData>
        </Grid.Row>
    );

    const onReserveArticlesError = () => setShowErrorModal(true);

    const renderNotLabeledActions = () => (
        <Grid.Row mt={10}>
            <Grid.Col flex={1}>
                <Button
                    fullWidth
                    loading={isReserving}
                    onClick={() => reserveArticles(onReserveArticlesError)}
                    label={
                        selectedArticlesIds.length === 0
                            ? 'Reserve'
                            : `Reserve selected (${selectedArticlesIds.length})`
                    }
                    disabled={isCancelingReserved || isLoading}
                />
            </Grid.Col>
            {selectedArticlesIds.length > 0 && (
                <Grid.Col flex={1} ml={10}>
                    <Button
                        fullWidth
                        type={Buttons.SECONDARY}
                        onClick={clearSelectedArticlesIds}
                        label="Clear selection"
                        disabled={isReserving || isLoading}
                    />
                </Grid.Col>
            )}
        </Grid.Row>
    );

    const renderReservedActions = () => (
        <Grid.Row mt={10}>
            {articles.length === 0 && (
                <Grid.Col flex={1}>
                    <Button
                        fullWidth
                        loading={isReserving}
                        onClick={() => reserveArticles(onReserveArticlesError)}
                        label="Reserve"
                        disabled={isLoading}
                    />
                </Grid.Col>
            )}
            {articles.length > 0 && (
                <Grid.Col flex={1}>
                    <Button
                        fullWidth
                        type={Buttons.SECONDARY}
                        loading={isCancelingReserved}
                        disabled={isReserving || isLoading}
                        onClick={cancelReservedArticles}
                        label="Cancel reservations"
                    />
                </Grid.Col>
            )}
        </Grid.Row>
    );

    const renderLabelingHeader = () => (
        <Styles.LabelingHeader>
            <Grid.Row>
                <LabelingTabs />
            </Grid.Row>
            {!filter.labeling_status && renderReservedActions()}
            {filter.labeling_status === 'Not labeled' && renderNotLabeledActions()}
        </Styles.LabelingHeader>
    );

    return (
        <Styles.Container id="articles-list">
            <Styles.Header>
                <Grid.Col flex={1} mr={10}>
                    <Sorting />
                </Grid.Col>
                <Grid.Col>
                    <Paging />
                </Grid.Col>
            </Styles.Header>
            {labeling && renderLabelingHeader()}
            <Styles.Content>
                {!labeling && !isLoading && (
                    <Grid.Row mt={-20} mb={10} ml={-2} justifyContent="space-between">
                        <Grid.Col mr={10} ml={-20}>
                            <Styles.Label>
                                Found: {total.toLocaleString().replaceAll(',', ' ')} publications
                            </Styles.Label>
                        </Grid.Col>
                        {role !== 'user' && role !== 'moderator' && (
                            <Grid.Col>
                                <Styles.ShowChartButton onClick={() => setShowDynamics(!showDynamics)}>
                                    {showDynamics ? 'Hide ' : 'Show '} dynamics
                                </Styles.ShowChartButton>
                            </Grid.Col>
                        )}
                    </Grid.Row>
                )}
                {showDynamics && !labeling && chartData && chartData.labels && <DynamicsChart chartData={chartData} />}
                {isLoading && renderFullPageLoader()}
                {!isLoading && renderArticles()}
                {!isLoading && articles.length === 0 && renderNoData()}
            </Styles.Content>
            <Styles.Footer>
                <Paging visiblePages={2} showFirstLast />
            </Styles.Footer>
            <ExpirationModal visible={expireVisible} onClose={() => setExpireVisible(false)} />
            <Modal
                title="There are no articles to reserve for labelling."
                onClose={() => setShowErrorModal(false)}
                renderContent={() => (
                    <p>
                        Please check if your search (filtering) parameters are correct. If you experiencing this
                        frequently and have suspicions that some publications are missing, please contact
                        support@debunkeu.org. It may also be that you have labelled all the articles.
                    </p>
                )}
                visible={showErrorModal}
            />
        </Styles.Container>
    );
};

ArticlesList.propTypes = {
    labeling: PropTypes.bool,
    onSetForm: PropTypes.func,
};

ArticlesList.defaultProps = {
    labeling: false,
};

export default ArticlesList;
