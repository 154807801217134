export const INITIAL_STATE = {
    organisation: null,
    role: 'user',
    user_vote_weight: null,
    repost_weight: null,
    alexa_weight: null,
    facebook_comment_weight: null,
    facebook_share_weight: null,
    facebook_reaction_weight: null,
    languages: [],
    expires_at: null,
    agreement_signed: false,
    can_override_filters: false,
};

export const INITIAL_WEIGHTS = {
    user_vote_weight: true,
    repost_weight: true,
    alexa_weight: true,
    facebook_comment_weight: true,
    facebook_share_weight: true,
    facebook_reaction_weight: true,
    view_weight: true,
};

export const ROLES = [
    { label: 'user', value: 'user' },
    { label: 'moderator', value: 'moderator' },
    { label: 'analyst', value: 'analyst' },
    { label: 'admin', value: 'admin' },
    { label: 'api', value: 'api' },
    { label: 'god', value: 'god' },
];
