import React, { useEffect, useState } from 'react';
import { useCallback } from 'react';
import debounce from 'lodash.debounce';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import charts from './charts';
import { ChartsActions } from 'core/actions';
import ChartTop from './ChartTop';
import ChartMain from './ChartMain';
import ChartBottom from './ChartBottom';
import { Grid } from 'styles/components';
import { Item, Wrapper, Muted, Parent, Title } from 'pages/Charts/styles';
import update from 'immutability-helper';
import Loader from 'components/Loader';
import { useCurrentWidth, useFilter } from 'hooks';
import _ from 'lodash';
import { INITIAL_STATE, SET_CHARTS } from '../ChartSettingsModal/config';

const initialSettings = {
    printSize: 1920,
    initialWidth: 993,
    initialHeight: 993 * 0.6,
};

function ChartsContainer(props) {
    const {
        chartsArray,
        getChart,
        chart,
        originalChart,
        isChartLoading,
        isChartLoaded,
        error,
        languages,
        resetChartArticles,
        isDataLoaded,
        isLoaded,
        isLoading,
        setFilteredChart,
    } = props;
    const [graphId, setGraphId] = useState(false);
    const [updateChart, setUpdateChart] = useState(false);
    const [w, setW] = useState(false);
    const [h, setH] = useState(false);
    const [grouped, setGrouped] = useState(false);
    const [showChart, setShowChart] = useState(false);
    const { printSize, initialHeight, initialWidth } = initialSettings;
    const languageArr = [
        { label: 'No translate', value: 'not_translated' },
        ...languages.map((l) => {
            return { label: l.name, value: l.id };
        }),
    ];
    const [language, setLanguage] = useState('not_translated');
    const [title, setTitle] = useState('');
    const [loadedCharts, setLoadedCharts] = useState({});
    const [isLoadedChartNeedUpdate, setIsLoadedChartNeedUpdate] = useState(false);
    const [showLabels, setShowLabels] = useState(true);
    const [showRelativeValue, setShowRelativeValue] = useState(false);
    const [chartSettingsModal, setChartSettingsModal] = useState(false);
    const [type, setType] = useState();
    const currentWidth = useCurrentWidth();
    const { filter, updateFilter } = useFilter();
    const [prevFilter, setPrevFilter] = useState(filter);
    const [filteredChartData, setFilteredChartData] = useState([]);
    const [chartsModal, setChartsModal] = useState(INITIAL_STATE);
    const [currentId, setCurrentId] = useState(null);

    useEffect(() => {
        graphId && updateLanguage({ value: language });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showLabels, showRelativeValue]);

    useEffect(() => {
        graphId && resetChart();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentWidth]);

    useEffect(() => {
        graphId && updateFilter({ ...filter, chart: graphId });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [graphId]);

    const getChartData = (id, data) => getChart(id, data);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debouncedGetChart = useCallback(debounce(getChartData, 1000), []);

    // on filter change, that navigates to new url path
    useEffect(() => {
        if (filter.chart && !graphId) {
            setGraphId(filter.chart);
            setLoadedCharts({});
            debouncedGetChart(filter.chart, _.omit(filter, ['chart']));
        }
        if (!_.isEqual(_.omit(prevFilter, ['chart']), _.omit(filter, ['chart']))) {
            resetChart();
            setLoadedCharts({});
            graphId && debouncedGetChart(graphId, _.omit(filter, ['chart']));
            setPrevFilter(filter);
            setIsLoadedChartNeedUpdate(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filter]);

    useEffect(() => {
        isDataLoaded && resetChartArticles();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isDataLoaded]);

    useEffect(() => {
        error && setLoadedCharts({});
    }, [error]);

    // sets loaded chart a.k.a. cache
    useEffect(() => {
        if (isChartLoading) {
            resetChart();
            setIsLoadedChartNeedUpdate(true);
        }

        if (isChartLoaded && chart.graph && updateChart) {
            if (!loadedCharts[graphId] || isLoadedChartNeedUpdate) {
                setLoadedCharts(
                    update(loadedCharts, {
                        [graphId]: { $set: chart },
                    }),
                );
                setIsLoadedChartNeedUpdate(false);
            }

            setCurrentChart(chart);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [chart, updateChart, isChartLoaded, isChartLoading, isLoadedChartNeedUpdate]);

    // sets filtered chart: store.charts.chart
    useEffect(() => {
        let copyChart = _.cloneDeep(originalChart);
        if (filteredChartData.length > 0) {
            copyChart.data = chartFilter(copyChart.data, filteredChartData);
        }
        setFilteredChart(copyChart);
        resetChart();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filteredChartData]);

    // sets chart modal data
    useEffect(() => {
        if (!chart?.data) {
            return;
        }

        const isChartsModalEmpty =
            chartsModal.chartsData.length === 0 && isChartLoaded && Object.keys(chart?.data).length !== 0;
        const isDifferentChartLoaded = isChartLoaded && currentId !== chart?.graph?.id;
        // re-render after response from API
        const isChartModalNeedsUpdate = updateChart && !_.isEqual(loadedCharts[graphId]?.data, originalChart?.data);
        // re-render after Chart modal filter update event
        const isChartModalNeedsUpdateSecond = updateChart && !_.isEqual(originalChart?.data, chart?.data);

        if (
            isChartsModalEmpty ||
            isDifferentChartLoaded ||
            isChartModalNeedsUpdate ||
            isChartModalNeedsUpdateSecond ||
            updateChart
        ) {
            const chartData = SET_CHARTS(originalChart, chart);
            setCurrentId(chart.graph.id);
            setChartsModal(
                update(chartsModal, {
                    $set: chartData,
                }),
            );
            setUpdateChart(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [chartsModal, setChartsModal, isChartLoaded, chart]);

    const chartFilter = (data, criteriaArr) => {
        const chartFilterObject = Object.entries(data).reduce((acc, [key, value]) => {
            const index = criteriaArr?.findIndex((crit) => crit.label === key && crit.value);

            if (index > -1 && value?.children) {
                const isYearType = !!value?.children?.year;
                const isDateTimeType = !!value?.children?.articleDateTime;
                if (!isDateTimeType && !isYearType) {
                    return {
                        ...acc,
                        [key]: { ...value, children: chartFilter(value.children, criteriaArr[index].children) },
                    };
                }
            }

            if (key === 'debunkReachDomain' || key === 'week') {
                return {
                    ...acc,
                    [key]: { ...value },
                };
            }

            if (index > -1) {
                return {
                    ...acc,
                    [key]: { ...value },
                };
            }

            return acc;
        }, {});

        return chartFilterObject;
    };

    const setCurrentChart = (currentChart) => {
        const settings = chartsArray.find((c) => c.id === graphId);
        const type = settings.type.type;
        const show = type !== 'pie' && type !== 'donut';

        if (currentChart.data && Object.keys(currentChart.data).length > 0) {
            charts.setMainValues(
                settings,
                currentChart.data,
                settings.translations,
                graphId,
                show,
                showRelativeValue,
                currentChart.total,
            );
            const { width, height } = charts.getDimensions();
            charts.setOptionalValues(
                languages.find((l) => l.id === language),
                width,
                true,
                true,
            );

            setW(width);
            setH(height);
            setUpdateChart(false);
            setGrouped(type.includes('bar') && settings.rows.length > 1);
            setShowChart(currentChart.data && Object.keys(currentChart.data).length > 0);
            setShowLabels(show);
            setType(type);
        }
    };

    const updateLanguage = (e) => {
        const currentChart = chart;
        const lang = languages.find((l) => l.id === e.value);
        const settings = chartsArray.find((c) => c.id === graphId);

        if (currentChart.data && !currentChart.data.length) {
            charts.resetAll();
            charts.setMainValues(
                settings,
                currentChart.data,
                settings.translations,
                graphId,
                showLabels,
                showRelativeValue,
                currentChart.total,
            );
            charts.setOptionalValues(lang, w, true, true);
            charts.createCharts();
            title.length > 0 && charts.updateTitle(title);
            setLanguage(e.value);
        }
    };

    const renderChartList = () => {
        const onChangeChart = (id) => () => {
            setTitle('');
            setGraphId(id);
            setLanguage('not_translated');
            getChart(id, _.omit(filter, ['chart']));
        };

        return chartsArray.map((chart, i) => {
            return (
                <Item key={i} onClick={onChangeChart(chart.id)} active={graphId === chart.id}>
                    {chart.name}
                    <Muted> (ID {chart.id})</Muted>
                </Item>
            );
        });
    };

    const resetChart = () => {
        charts.resetAll();
        setShowChart(false);
        setW(false);
        setH(false);
        setUpdateChart(true);
    };

    const updateTitle = () => {
        charts.updateTitle(title);
    };

    const chartSettingsData = (data) => {
        setFilteredChartData(data.chartsData);
    };

    const handleOnChange = (data, index) => {
        if (data?.children?.length && !data.value) {
            const children = data.children.map((ch) => ({
                ...ch,
                value: true,
            }));

            return setChartsModal(
                update(chartsModal, {
                    chartsData: {
                        [index]: {
                            value: { $set: !data.value },
                            children: { $set: children },
                        },
                    },
                }),
            );
        }

        setChartsModal(
            update(chartsModal, {
                chartsData: {
                    [index]: {
                        value: { $set: !data.value },
                    },
                },
            }),
        );
    };

    const handleChildOnChange = (data, index, parentIndex) => {
        setChartsModal(
            update(chartsModal, {
                chartsData: {
                    [parentIndex]: {
                        children: {
                            [index]: {
                                value: { $set: !data.value },
                            },
                        },
                    },
                },
            }),
        );
    };

    const selectAll = (value) => {
        const newChartsModal = {
            chartsData: chartsModal.chartsData.map((item) => {
                return item.children
                    ? { ...item, value, children: item.children.map((child) => ({ ...child, value })) }
                    : {
                          ...item,
                          value,
                      };
            }),
        };
        setChartsModal(newChartsModal);
    };

    return (
        <>
            <div
                style={{
                    position: 'absolute',
                    width: 0,
                    height: 0,
                    overflow: 'hidden',
                    transform: 'translateY(-100000vh)',
                }}
            >
                <div
                    style={{ width: '1920px', height: 1920 * (type === 'pie' || type === 'donut' ? 0.4 : 0.6) + 'px' }}
                >
                    <canvas id="print" />
                </div>
            </div>

            <Grid.Row alignItems="flex-start">
                <Grid.Col>
                    <Wrapper side>
                        <Title>Charts</Title>
                        {isLoading && (
                            <Grid.Row mt={30} justifyContent="center">
                                <Loader />
                            </Grid.Row>
                        )}
                        {isLoaded && renderChartList()}
                    </Wrapper>
                </Grid.Col>
                <Grid.Col flex={1}>
                    <Wrapper padding>
                        <ChartTop
                            showChart={showChart}
                            search={filter}
                            error={error}
                            width={w}
                            chart={chart}
                            chartType={charts.getValues().type}
                            chartsGroupedData={charts.getGroupedData()}
                            chartsArray={chartsArray}
                            graphId={graphId}
                            grouped={grouped}
                            initialHeight={initialHeight}
                            initialWidth={initialWidth}
                            isChartLoaded={isChartLoaded}
                            isChartLoading={isChartLoading}
                            language={language}
                            languages={languageArr}
                            updateLanguage={updateLanguage}
                            printSize={printSize}
                            setChartSettingsModal={setChartSettingsModal}
                        />
                        <Grid.Row mb={20}>
                            <Parent
                                id={`parent-${graphId}`}
                                width={w || 0}
                                minHeight={h || 0}
                                height={grouped ? 'auto' : h || 0}
                            >
                                {isChartLoaded && showChart && graphId && (
                                    <ChartMain
                                        showLabels={showLabels}
                                        total={loadedCharts[graphId] ? loadedCharts[graphId].total : chart.total}
                                        title={title}
                                        printSize={printSize}
                                        initialHeight={initialHeight}
                                        initialWidth={initialWidth}
                                        grouped={grouped}
                                        graphId={graphId}
                                        width={w}
                                        height={h}
                                        chart={chart}
                                        originalChart={originalChart}
                                        chartSettingsModal={chartSettingsModal}
                                        setChartSettingsModal={setChartSettingsModal}
                                        chartSettingsData={(data) => chartSettingsData(data)}
                                        handleMainOnChange={(data, index) => handleOnChange(data, index)}
                                        handleMainChildOnChange={(data, index, parentIndex) =>
                                            handleChildOnChange(data, index, parentIndex)
                                        }
                                        chartsModal={chartsModal}
                                        selectAll={selectAll}
                                    />
                                )}
                            </Parent>
                        </Grid.Row>
                        {isChartLoaded && showChart && (
                            <ChartBottom
                                showLabels={showLabels}
                                showRelativeValue={showRelativeValue}
                                setShowLabels={() => setShowLabels(!showLabels)}
                                setShowRelativeValue={() => setShowRelativeValue(!showRelativeValue)}
                                total={loadedCharts[graphId] ? loadedCharts[graphId].total : chart.total}
                                title={title}
                                setTitle={setTitle}
                                updateTitle={updateTitle}
                                isAllChecked={chartsModal.isAllChecked}
                            />
                        )}
                    </Wrapper>
                </Grid.Col>
            </Grid.Row>
        </>
    );
}

ChartsContainer.propTypes = {
    error: PropTypes.bool.isRequired,
    isDataLoaded: PropTypes.bool.isRequired,
    chart: PropTypes.object,
    originalChart: PropTypes.object,
    search: PropTypes.object.isRequired,
    chartsArray: PropTypes.array.isRequired,
    languages: PropTypes.array.isRequired,
    isChartLoading: PropTypes.bool.isRequired,
    isChartLoaded: PropTypes.bool.isRequired,
    getChart: PropTypes.func.isRequired,
    setChart: PropTypes.func.isRequired,
    setFilteredChart: PropTypes.func.isRequired,
    resetChartArticles: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    isLoaded: PropTypes.bool.isRequired,
};

function mapStateToProps(state) {
    const { charts, search, languages } = state;
    const { isChartLoading, isChartLoaded, error, isDataLoaded, isLoaded, isLoading } = charts;
    return {
        isDataLoaded,
        isChartLoading,
        isChartLoaded,
        error,
        isLoaded,
        isLoading,
        chart: charts.chart,
        originalChart: charts.originalChart,
        search: search.search,
        languages: languages.languages,
    };
}

const mapDispatchToProps = {
    getChart: ChartsActions.getChart,
    setChart: ChartsActions.setChart,
    setFilteredChart: ChartsActions.setFilteredChart,
    resetChartArticles: ChartsActions.resetChartArticles,
};

export default connect(mapStateToProps, mapDispatchToProps)(ChartsContainer);
