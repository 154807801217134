import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { COLUMNS } from './config';
import { Container } from './styles';
import { EDIT, REMOVE } from 'styles/constant/images';
import { Grid } from 'styles/components';
import { Table, Button, Icon } from 'components';
import { EmailsActions } from 'core/actions';
import { StorageKeys } from 'constant';
import { usePermission } from 'hooks';

function EmailsList(props) {
    const { emails, deleteEmail, isUpdated, getEmails } = props;
    const history = useHistory();
    const hasCreateEmailsPermission = usePermission('ROLE_CREATE_EMAILS');

    useEffect(() => {
        isUpdated && getEmails();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isUpdated]);

    const removeEmail = (id) => {
        const confirm = window.confirm('Remove email?');
        confirm && deleteEmail(id);
    };

    return (
        <Container>
            <Grid.FixedContainer>
                <Grid.Row mb={20}>
                    <Button
                        onClick={() => history.push('/email/create')}
                        label="Create new"
                        disabled={!hasCreateEmailsPermission}
                    />
                </Grid.Row>
            </Grid.FixedContainer>
            <Table
                storageKey={StorageKeys.EmailsList}
                checkboxes={false}
                editableCells={[]}
                updateData={() => {}}
                columns={[
                    ...COLUMNS,
                    {
                        Header: 'Actions',
                        Cell: function CellRenderer(row) {
                            return (
                                <Grid.Row justifyContent="center">
                                    <Icon
                                        gray
                                        source={EDIT}
                                        title="Edit email"
                                        onClick={() => history.push(`/email/${row.row.original.id}`)}
                                        disabled={!hasCreateEmailsPermission}
                                    />
                                    <Icon
                                        gray
                                        source={REMOVE}
                                        title="Remove email"
                                        onClick={() => removeEmail(row.row.original.id)}
                                        iconSmall
                                        disabled={!hasCreateEmailsPermission}
                                    />
                                </Grid.Row>
                            );
                        },
                    },
                ]}
                data={emails}
                hiddenColumns={[]}
                type="emails"
                history={history}
                preSelected={[]}
            />
        </Container>
    );
}

EmailsList.propTypes = {
    isUpdating: PropTypes.bool.isRequired,
    isUpdated: PropTypes.bool.isRequired,
    emails: PropTypes.array,
    deleteEmail: PropTypes.func.isRequired,
    getEmails: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
    const { emails } = state;
    const { isUpdating, isUpdated } = emails;
    return { isUpdating, isUpdated, emails: emails.emails };
}

const mapDispatchToProps = {
    deleteEmail: EmailsActions.deleteEmail,
    getEmails: EmailsActions.getEmails,
};

export default connect(mapStateToProps, mapDispatchToProps)(EmailsList);
