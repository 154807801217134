import React, { useEffect, useRef, useState } from 'react';
import * as PropTypes from 'prop-types';
import { Container, TextInput, Label, Error, InputContainer, HelpIcon, LeftIcon, MaxLength, TextArea } from './styles';
import { Images } from 'styles/constant';
import { SharedTypes } from 'utils';

function Input(props) {
    const {
        onChange,
        onBlur,
        label,
        style,
        value,
        disabled,
        name,
        dirty,
        error,
        type,
        placeholder,
        help,
        leftIcon,
        onHelpClick,
        onFocus,
        autoComplete,
        maxLength,
        onKeyDown,
        rows,
        dir,
        autoFocus,
    } = props;
    const showCharsLeft = maxLength && value && value.length > 99;
    const [showMaxLengthError, setShowMaxLengthError] = useState(false);
    const inputElement = useRef(null);

    useEffect(() => {
        if (inputElement.current && autoFocus) {
            inputElement.current.focus();
        }
    }, [autoFocus]);

    const handleOnChange = (event) => {
        const { target } = event;
        const { value } = target;
        onChange({ name, value });
        setShowMaxLengthError(false);
    };

    const handleOnBlur = () => {
        onBlur({ name });
    };

    const handleKeyUp = (event) => {
        const { keycode } = event;
        if (keycode !== 46) {
            setShowMaxLengthError(value && maxLength && value.length >= maxLength);
            event.stopPropagation();
        } else {
            setShowMaxLengthError(false);
        }
    };

    const handlePaste = (event) => {
        const pastedValue = event.clipboardData.getData('Text');
        if (maxLength && pastedValue.length >= maxLength) {
            setShowMaxLengthError(true);
            event.stopPropagation();
        }
    };

    const InputComponent = rows ? TextArea : TextInput;

    return (
        <Container style={style}>
            {label && <Label>{label}</Label>}
            <InputContainer>
                {leftIcon && <LeftIcon src={leftIcon} />}
                <InputComponent
                    maxLength={maxLength}
                    onKeyDown={onKeyDown}
                    showCharsLeft={showCharsLeft}
                    onFocus={onFocus}
                    onPaste={handlePaste}
                    onKeyUp={handleKeyUp}
                    autoComplete={autoComplete}
                    leftIcon={leftIcon}
                    help={help}
                    placeholder={placeholder}
                    type={type}
                    name={name}
                    error={dirty && (error || showMaxLengthError)}
                    disabled={disabled}
                    onBlur={handleOnBlur}
                    value={value || value === 0 ? value : ''}
                    onChange={handleOnChange}
                    rows={rows}
                    dir={dir}
                    ref={inputElement}
                />
                {help && <HelpIcon onClick={onHelpClick} title="Help" source={Images.HELP} />}
                {showCharsLeft && <MaxLength>{`${value ? value.length : 0}/${maxLength}`}</MaxLength>}
            </InputContainer>
            {dirty && error && <Error>{error}</Error>}
            {showMaxLengthError && <Error>Maximum characters length 255</Error>}
        </Container>
    );
}

Input.propTypes = {
    onChange: PropTypes.func.isRequired,
    onHelpClick: PropTypes.func,
    onFocus: PropTypes.func,
    onKeyDown: PropTypes.func,
    onBlur: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    help: PropTypes.string,
    leftIcon: PropTypes.string,
    type: PropTypes.string,
    value: SharedTypes.NumberOrString,
    dirty: PropTypes.bool,
    error: PropTypes.string,
    label: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    maxLength: PropTypes.number,
    style: PropTypes.object,
    disabled: PropTypes.bool,
    autoComplete: PropTypes.string,
    rows: PropTypes.number,
    dir: PropTypes.string,
    autoFocus: PropTypes.bool,
};

Input.defaultProps = {
    style: {},
    onHelpClick: () => {},
    onBlur: () => {},
    leftIcon: null,
    label: null,
    value: null,
    onFocus: () => {},
    dirty: false,
    error: null,
    disabled: false,
    help: null,
    placeholder: null,
    type: 'text',
    autoComplete: 'on',
    maxLength: null,
    rows: null,
};

export default Input;
