import React from 'react';
import PropTypes from 'prop-types';
import { DateUtils } from 'utils';
import { ToastService } from 'core/services';
import { chartToCsvString } from '../csvConverter';
import { Images } from 'styles/constant';
import { Grid } from 'styles/components';
import { Button, Loader, SingleSelect, Tooltip } from 'components';
import { Img, Text } from './styles';

function Image() {
    return null;
}

import { useChartImageExport } from 'hooks/useChartImageExport';

Image.propTypes = {
    src: PropTypes.any,
    onClick: PropTypes.func,
};

function ChartTop(props) {
    const {
        graphId,
        error,
        chart,
        isChartLoading,
        isChartLoaded,
        grouped,
        search,
        printSize,
        initialHeight,
        initialWidth,
        showChart,
        updateLanguage,
        language,
        languages,
        setChartSettingsModal,
    } = props;

    const { generatePng } = useChartImageExport({
        printSize,
        initialWidth,
        initialHeight,
    });

    const getFileName = () => {
        let from = search.from;
        let to = search.to;
        if (search.date) {
            const date = DateUtils.getRelativeDateRange(search.date);
            from = date[0];
            to = date[1];
        }
        return `DebunkEU_chart${graphId}_${from}_${to}`;
    };

    const exportImg = async () => {
        const fileName = getFileName();

        let containerId = grouped ? `#parent-${graphId}` : 'print';

        await generatePng(grouped, containerId, fileName);
    };

    const RenderText = () => {
        let txt = 'Please select a chart';
        if (error) txt = 'Error, the chart could not be loaded';
        else if (chart && graphId && chart.data && chart.data.length === 0)
            txt = 'No data. Please change search parameters.';
        return isChartLoading ? '' : <Text>{txt}</Text>;
    };

    const chartSettings = () => {
        setChartSettingsModal(true);
    };

    const onCopyToClipboard = () => {
        const csvString = chartToCsvString(props.chartsGroupedData, props.chartType);
        if (csvString) {
            ToastService.showSuccess('Copied to clipboard.');
            navigator.clipboard.writeText(csvString);
        }
    };

    return (
        <>
            {isChartLoaded && showChart && chart.graph ? (
                <Grid.Row justifyContent="space-between" mb={20}>
                    <Grid.Col flex={1} mr={10}>
                        <Button onClick={exportImg} label="Download chart" />
                    </Grid.Col>
                    <Grid.Col flex={1} justifyContent="flex-start">
                        <Grid.Row>
                            <Tooltip tooltipContent="Copy to clipboard">
                                <Img onClick={onCopyToClipboard} src={Images.COPY} mr={10} />
                            </Tooltip>
                            <Img onClick={chartSettings} src={Images.SLIDERS} />
                        </Grid.Row>
                    </Grid.Col>
                    <Grid.Col flex={3}>
                        <Text>
                            {chart.graph.name} (ID {chart.graph.id})
                        </Text>
                    </Grid.Col>
                    <Grid.Col flex={2}>
                        <SingleSelect name="languages" onChange={updateLanguage} options={languages} value={language} />
                    </Grid.Col>
                </Grid.Row>
            ) : (
                <Grid.Row>
                    <RenderText />
                </Grid.Row>
            )}
            {isChartLoading && (
                <Grid.Row justifyContent="center" mt={20}>
                    <Loader />
                </Grid.Row>
            )}
        </>
    );
}

ChartTop.propTypes = {
    updateLanguage: PropTypes.func.isRequired,
    graphId: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]).isRequired,
    printSize: PropTypes.number.isRequired,
    initialHeight: PropTypes.number.isRequired,
    initialWidth: PropTypes.number.isRequired,
    error: PropTypes.bool.isRequired,
    chart: PropTypes.object.isRequired,
    chartType: PropTypes.string.isRequired,
    chartsGroupedData: PropTypes.object.isRequired,
    search: PropTypes.object.isRequired,
    isChartLoaded: PropTypes.bool.isRequired,
    isChartLoading: PropTypes.bool.isRequired,
    grouped: PropTypes.bool.isRequired,
    showChart: PropTypes.bool.isRequired,
    language: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    languages: PropTypes.array.isRequired,
    setChartSettingsModal: PropTypes.func,
};

export default ChartTop;
