import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import * as Styles from './styles';
import ReactHtmlParser from 'react-html-parser';
import { ArticleUtils } from 'utils';
import { RTLLanguages } from 'constant';
import Mark from 'mark.js';
import { useSelector } from 'react-redux';
import { Button } from 'components';
import { initDragElement, initResizeElement, generateUniqueId } from './utils';
import { useIsMounted } from 'hooks';
import LabelHighlightsModal from './components/LabelHighlightsModal';
import HighlightActionsPopup from './components/HighlightActionsPopup';
import HighlightWarningPopup from './components/HighlightWarningPopup';
import { connect } from 'react-redux';
import { useSearch } from 'hooks';
import { LabellingHighlightActions } from 'core/actions';
import { useDispatch } from 'react-redux';
import { Loader } from 'components';
import './style.scss';

import {
    xPathToNode,
    getTextBetweenNodesWithOffset,
    findStartNodeText,
    findEndNodeText,
    getPaddedText,
    computeXpath,
} from './labelHighlighter';

import {
    getTextNodes,
    getNodeIndicesOfText,
    findStringInArray,
    findOccurrences,
    extractSubArray,
    removeDuplicates,
    removeWhiteSpacesAndNewLines,
} from './filterHighlighter/index';
import { extractUniqueTextRange } from './filterHighlighter/uniqueTextRange';
import rangy from 'rangy';
import 'rangy/lib/rangy-core.js';
import 'rangy/lib/rangy-classapplier.js';
import 'rangy/lib/rangy-highlighter.js';
import 'rangy/lib/rangy-textrange.js';
import 'rangy/lib/rangy-serializer';
import 'rangy/lib/rangy-textrange';
import 'rangy/lib/rangy-selectionsaverestore';
import './filterHighlighter/style.scss';

const timestampRegex = /([0-9]{2}:[0-9]{2}:[0-9]{2})/g;

const removeDuplicateRegexMatchesExceptFirst = (string, regex) => {
    let count = 0;
    const replaceWith = ' ';

    return string.replace(regex, function (match) {
        count = count + 1;
        if (count === 1) {
            return string.indexOf(match) === 0 ? `${match} ` : replaceWith;
        } else {
            return replaceWith;
        }
    });
};

const Content = (props) => {
    const {
        className,
        fontSize,
        lang,
        filterHighlights,
        selections,
        labelingForm,
        isArticleLoaded,
        article,
        articles,
        similarArticles,
        selectedRowData,
        clickTimestamp,
        handleUpdateOrAddHighlight,
        handleRemoveHighlight,
        labellingHighlight,
    } = props;
    const content = removeWhiteSpacesAndNewLines(props.content);
    const { search } = useSelector((state) => state.search);

    const { filter } = useSearch();
    const [tooltipState, setTooltipState] = useState({ left: 0, top: 0, isVisible: false, text: '' });
    const [modalPosition, setModalPosition] = useState({ top: 0, left: 0 });
    const [modalWidth, setModalWidth] = useState('300px'); // Default modal width
    const [modalOpen, setModalOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [savedSelection, setSavedSelection] = useState(null);
    const [mousePosition, setMousePosition] = useState({ x: null, y: null });
    const [popupPosition, setPopupPosition] = useState({ top: 0, left: 0 });
    const [showPopup, setShowPopup] = useState(false);

    const [showHighlightWarning, setShowHighlightWarning] = useState(false);

    const [selectedText, setSelectedText] = useState('');

    const dispatch = useDispatch();

    const initialHighlight = {
        id: '',
        highlightedText: '',
        highlight: {
            startXpath: '',
            endXpath: '',
            startOffset: '',
            endOffset: '',
            startIndex: 0,
            endIndex: 0,
        },
        s1: [],
        s2: [],
        s3: [],
        comment: '',
    };

    const [isRangyInitialized, setIsRangyInitialized] = useState(false);
    const containerRef = useRef(null);
    const articleRef = useRef(null);
    const modalRef = useRef(null);
    const tooltipElementRef = useRef(null);
    const highlighterRef = useRef(null);
    const textNodesRef = useRef(null);
    const classApplierRef = useRef(null);
    const isMounted = useIsMounted();
    const buttonDisabled =
        !isArticleLoaded ||
        !article ||
        (!filter.labeling_status &&
            similarArticles.filter((a) => a.id === article.id && a.parentId === article.parentId && a.disabled).length >
                0 &&
            articles.filter((a) => a.id === article.id && a.parentId === article.parentId).length === 0);
    const disabled = buttonDisabled || filter.labeling_status === 'Not labeled';

    // Use state to manage whether highlighting is enabled
    const [isHighlightEnabled, setIsHighlightEnabled] = useState(true);

    const [parsedContent, setParsedContent] = useState(null);
    const [isParsingComplete, setIsParsingComplete] = useState(false);
    const [isLabelHighlightsApplied, setIsLabelHighlightsApplied] = useState(false);
    const [isVideoContent, setIsVideoContent] = useState(false);

    const SHOULD_LOG = false; // Toggle this as needed

    // Effect to add event listeners for mouse movement
    useEffect(() => {
        const handleMouseMove = (event) => {
            if (!showPopup) {
                setMousePosition({ x: event.pageX, y: event.pageY });
            }
        };

        document.addEventListener('mousemove', handleMouseMove);

        // Cleanup
        return () => {
            document.removeEventListener('mousemove', handleMouseMove);
        };
    }, []);

    useEffect(() => {
        // Asynchronously parse the content
        const parseContent = async () => {
            const result = await ReactHtmlParser(content, { transform });
            setParsedContent(result);
            setIsParsingComplete(true);
        };

        if (isMounted) {
            parseContent();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isMounted, content]); // Dependency array, re-run if content changes

    useEffect(() => {
        if (isMounted && isRangyInitialized && isArticleLoaded && isParsingComplete) {
            applyAllFilterHighlights();
            applyAllLabelHighlights();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isMounted, isRangyInitialized, article, isArticleLoaded, isParsingComplete]);

    useEffect(() => {
        // Early return if conditions are not met
        if (!isParsingComplete || !isVideoContent) {
            return;
        }

        // Function to handle click events
        const handleClick = (e) => {
            // Find the next sibling with the timecode attribute
            const nextSibling = e.target.nextElementSibling;
            const timeCode = nextSibling.getAttribute('timecode');

            // Replace HTML entities with their actual characters
            const decodedTimeCode = timeCode.replace(/&gt;/g, '>').replace(/&lt;/g, '<');

            // jumpTo the timecode
            jumpTo(decodedTimeCode.split(',')[0]);
        };

        // Get all elements with the 'videoTime' class within the article
        const timestamps = articleRef.current.querySelectorAll('.videoTime');

        // Add click event listener to each timestamp
        timestamps.forEach((timestamp) => {
            timestamp.addEventListener('click', handleClick);
        });

        // Cleanup function to remove event listeners
        return () => {
            timestamps.forEach((timestamp) => {
                timestamp.removeEventListener('click', handleClick);
            });
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isParsingComplete, isVideoContent]);

    useEffect(() => {
        if (document.getElementById('contentVideo')) {
            initDragElement();
            initResizeElement();
        }
    }, []);

    useEffect(() => {
        document.getElementById('article-content').setAttribute('translate', 'yes');
        // temporary disabled for better highlight
        // ArticleUtils.addImageButtons(id);
        ArticleUtils.updateLinks();
        ArticleUtils.updateTweets();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        // Get the current value of the containerRef
        const current = containerRef.current;

        // If the current value exists (i.e., the component has been fully rendered),
        if (current) {
            // Add the copyListener as a copy event handler for the current container
            current.addEventListener('copy', copyListener);

            // Return a cleanup function to remove the copy event listener when the component unmounts
            return () => {
                current.removeEventListener('copy', copyListener);
            };
        }
    }, []);

    useEffect(() => {
        window.addEventListener('scroll', handleScroll, true);
        return () => {
            window.removeEventListener('scroll', handleScroll, true);
        };
    }, []);

    // Initialize Rangy and set up highlighter on component mount
    useEffect(() => {
        rangy.init();
        setupHighlighter();
        setIsRangyInitialized(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (search['filters[must]']) {
            let markInstance = new Mark(document.querySelector('#article-content'));
            markInstance.unmark({
                done: () => {
                    markInstance.mark(search['filters[must]']);
                },
            });
        }
    });

    // Use a ref to store the current selections
    const prevSelectionsRef = useRef();

    useEffect(() => {
        // Check if an item was removed
        if (
            isMounted &&
            isParsingComplete &&
            prevSelectionsRef.current &&
            prevSelectionsRef.current.length > selections.length
        ) {
            const removedItem = prevSelectionsRef.current.find((item) => !selections.includes(item));
            removeHighlightById(removedItem.id);
        }

        // Update the ref with the current selections after checking
        prevSelectionsRef.current = selections;
    }, [isMounted, selections, isParsingComplete]);

    useEffect(() => {
        if (isMounted && isParsingComplete && selections?.length === 0) {
            removeAllHighlights();
        }
    }, [isMounted, selections, isParsingComplete]);

    useEffect(() => {
        const articleContent = articleRef.current;
        const parentElement = articleContent.parentNode.parentNode.parentNode;
        const conditionalHighlightText = (event) => {
            if (
                isParsingComplete &&
                isLabelHighlightsApplied &&
                isHighlightEnabled &&
                labelingForm &&
                !disabled &&
                !modalOpen
            ) {
                const { pageX, pageY } = event; // Capture the absolute mouse position

                const selectedText = window.getSelection().toString();

                if (selectedText.length > 3) {
                    setPopupPosition({ top: pageY, left: pageX });
                    onHighlightTextChange();
                }
            }
        };

        // Add event listener to the parent element
        parentElement.addEventListener('mouseup', conditionalHighlightText);

        // Cleanup function
        return () => {
            // Remove event listener from the parent element
            parentElement.removeEventListener('mouseup', conditionalHighlightText);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        isHighlightEnabled,
        modalOpen,
        selections,
        content,
        filter,
        filter.labeling_status,
        isParsingComplete,
        isLabelHighlightsApplied,
        showPopup,
    ]);

    useEffect(() => {
        const articleElement = articleRef.current;
        if (!articleElement) return;

        if (modalOpen) {
            articleElement.style.userSelect = 'none'; // Disable text selection
        } else {
            articleElement.style.userSelect = ''; // Enable text selection
        }
    }, [modalOpen]);

    useEffect(() => {
        if (isMounted && isArticleLoaded && isParsingComplete) {
            if (isAnyValueSelected(labellingHighlight)) {
                handleUpdateOrAddHighlight(labellingHighlight);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        isMounted,
        labellingHighlight.s1,
        labellingHighlight.s2,
        labellingHighlight.s3,
        labellingHighlight.comment,
        isParsingComplete,
    ]);

    useEffect(() => {
        try {
            if (isMounted && isParsingComplete && isArticleLoaded && selections?.length > 0 && selectedRowData) {
                restoreHighlight(selectedRowData.highlight, selectedRowData.id);
                deactivateAllActiveHighlights();
                activateHighlight(selectedRowData.id);
                showLabellingModalEdit(selectedRowData);
            }
        } catch (error) {
            console.log(error.message);
            setModalOpen(false);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isMounted, selectedRowData, clickTimestamp, isArticleLoaded, isParsingComplete]);

    useEffect(() => {
        if (modalOpen && savedSelection && labellingHighlight.id === '') {
            setTimeout(() => {
                const selectionId = generateUniqueId();

                let selectedText = null;
                let xpathInfo = null;

                try {
                    rangy.restoreSelection(savedSelection);

                    const selection = rangy.getSelection();

                    const range = selection.getRangeAt(0);

                    selectedText = range.toString();

                    if (!isSelectionAtWordStart(range)) {
                        adjustRangeToWordStart(range);
                    }

                    if (!isSelectionAtWordEnd(selection)) {
                        adjustRangeToWordEnd(range);
                    }

                    selectedText = range.toString();

                    applyTempHighligh(range, selectionId);

                    xpathInfo = computeXpath(customConsole, props, articleRef, rangy, range, selectedText);

                    if (xpathInfo != null) {
                        const updatedHighlight = {
                            ...initialHighlight,
                            id: selectionId,
                            ...xpathInfo,
                        };
                        dispatch(LabellingHighlightActions.setLabellingHighlight(updatedHighlight));
                    } else {
                        removeHighlightById(selectionId);
                        setModalOpen(false);
                        // Re-enable highlighting after operations are complete
                        setIsHighlightEnabled(true);
                        console.log('Error text selection');
                        console.log(`selectionId: ${selectionId}`);
                        console.log(`selectedText: ${selectedText}`);
                        console.log(`xpathInfo: ${JSON.stringify(xpathInfo)}`);
                        console.log(`content: ${props.content}`);
                        console.log(`articleId: ${article.id}`);
                    }

                    // Re-enable highlighting after operations are complete
                    setIsHighlightEnabled(true);
                    setIsLoading(false);
                } catch (error) {
                    console.error(`${error.name}: ${error.message}`);
                    console.log(`selectionId: ${selectionId}`);
                    console.log(`selectedText: ${selectedText}`);
                    console.log(`xpathInfo: ${JSON.stringify(xpathInfo)}`);
                    console.log(`content: ${props.content}`);
                    console.log(`articleId: ${article.id}`);
                    // Re-enable highlighting after operations are complete
                    removeHighlightById(selectionId);
                    setIsHighlightEnabled(true);
                    setIsLoading(false);
                    setModalOpen(false);
                }
            }, 100);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [modalOpen, savedSelection, labellingHighlight]);

    const applyAllLabelHighlights = () => {
        try {
            if (selections && selections.length > 0) {
                selections.forEach((selectedRowData) => {
                    restoreHighlight(selectedRowData.highlight, selectedRowData.id);
                });
                deactivateAllActiveHighlights();
            }
            // Set the state to true after the function completes
            setIsLabelHighlightsApplied(true);
        } catch (error) {
            console.log(error.message);
            setModalOpen(false);
            setIsLabelHighlightsApplied(true);
        }
    };

    const handleClose = (id) => {
        setModalOpen(false);
        if (!labellingHighlight.comment && !isAnyValueSelected(labellingHighlight)) {
            removeHighlightById(id);
            handleRemoveHighlight(labellingHighlight);
        }
    };

    function activateHighlight(dataId) {
        // Assuming articleRef is a ref to your article content container
        const articleContent = articleRef.current;

        // Check if articleContent is available
        if (!articleContent) {
            console.error('Article content not found');
            return;
        }

        // Find the element with the specified data-id within the article content
        const highlightElements = articleContent.querySelectorAll(`.selection-highlight[data-id="${dataId}"]`);

        // Iterate over the NodeList and remove the 'active' class from each element
        highlightElements.forEach((element) => {
            element.classList.add('active');
        });
    }

    function deactivateAllActiveHighlights() {
        // Assuming articleRef is a ref to your article content container
        const articleContent = articleRef.current;

        // Check if articleContent is available
        if (!articleContent) {
            console.error('Article content not found');
            return;
        }

        // Find all elements with the 'selection-highlight' class within the article content
        const highlightElements = articleContent.querySelectorAll('.selection-highlight');

        // Iterate over the NodeList and remove the 'active' class from each element
        highlightElements.forEach((element) => {
            element.classList.remove('active');
            element.classList.remove('temp');
        });
    }

    function removeAllHighlights() {
        // Assuming articleRef is a ref to your article content container
        const articleContent = articleRef.current;

        // Check if articleContent is available
        if (!articleContent) {
            console.error('Article content not found');
            return;
        }

        if (!articleRef.current) {
            console.warn('articleRef is not defined');
            return;
        }

        // Find all elements with the 'selection-highlight' class but not 'active' or 'tmp' classes
        const highlightElements = articleContent.querySelectorAll('.selection-highlight:not(.active):not(.tmp)');

        highlightElements.forEach((element) => {
            // Or to remove the highlighting effect (i.e., unwrap the content):
            const parent = element.parentNode;
            while (element.firstChild) {
                parent.insertBefore(element.firstChild, element);
            }
            parent.removeChild(element);
        });
    }

    const removeHighlightById = (dataId) => {
        if (!articleRef.current) {
            console.warn('articleRef is not defined');
            return;
        }

        // Find all elements with the 'selection-highlight' class and the specified data-id
        const articleHighlights = articleRef.current.querySelectorAll(`.selection-highlight[data-id="${dataId}"]`);

        articleHighlights.forEach((element) => {
            // Or to remove the highlighting effect (i.e., unwrap the content):
            const parent = element.parentNode;
            while (element.firstChild) {
                parent.insertBefore(element.firstChild, element);
            }
            parent.removeChild(element);
        });
    };

    const restoreHighlight = (highlight, highlightId) => {
        try {
            const { startXpath, endXpath, startOffset, endOffset } = highlight;
            // Create a new instance of DOMParser
            const parser = new DOMParser();
            const doc = parser.parseFromString(props.content, 'text/html');

            const startNode = xPathToNode(startXpath, doc);
            const endNode = xPathToNode(endXpath, doc);

            const searchText = getTextBetweenNodesWithOffset(rangy, startNode, endNode, startOffset, endOffset);

            const containerElement = articleRef.current;

            // this function finds text node by search text
            // this function returns only first node , I need array if ,multiple occurencies.
            const startNodeInfo = findStartNodeText(customConsole, searchText, containerElement);
            const endNodeInfo = findEndNodeText(customConsole, searchText, containerElement);

            if (startNodeInfo == null || endNodeInfo == null) {
                return;
            }

            let range = rangy.createRange();
            range.setStart(startNodeInfo.startNode, startNodeInfo.startOffset);
            range.setEnd(endNodeInfo.endNode, endNodeInfo.endOffset);

            const highlighter = rangy.createClassApplier('selection-highlight', {
                elementTagName: 'span',
                onElementCreate: function (element) {
                    element.setAttribute('data-id', highlightId);
                    // Attach mouseover event to the newly created element
                    // element.addEventListener('mouseover', (event) => showTooltip(event, filterInfo.tooltipText));
                    // element.addEventListener('mouseout', hideTooltip);
                },
            });
            highlighter.applyToRange(range);
        } catch (err) {
            console.log(err);
        }
    };

    const applyTempHighligh = (range, selectionId) => {
        // Check if the range is within the articleRef.current
        if (articleRef.current.contains(range.commonAncestorContainer)) {
            deactivateAllActiveHighlights();

            const highlighter = rangy.createClassApplier('selection-highlight', {
                elementTagName: 'span',
                onElementCreate: function (element) {
                    element.setAttribute('data-id', selectionId);
                    // Add the 'active' class initially
                    element.classList.add('active');
                    element.classList.add('temp');
                },
            });
            highlighter.applyToRange(range);
        } else {
            console.log('The selected range is not within the article element.');
        }
    };

    const isSelectionAtWordStart = (range) => {
        const preSelectionRange = range.cloneRange();
        preSelectionRange.collapse(true);
        preSelectionRange.setStart(range.commonAncestorContainer, 0);
        const textBefore = preSelectionRange.toString();
        const lastChar = textBefore.charAt(textBefore.length - 1);
        // Using Unicode property escapes to match any character that is not a letter or a number
        const isAtWordStart = lastChar.match(/\P{L}/u) !== null || textBefore.length === 0;
        return isAtWordStart;
    };

    const adjustRangeToWordStart = (range) => {
        let node = range.startContainer;
        let start = range.startOffset;

        // Function to find the start of the word in a text node
        const findWordStartInTextNode = (textNode, offset) => {
            while (offset > 0 && /\p{L}/u.test(textNode.textContent[offset - 1])) {
                offset--;
            }
            return offset;
        };

        // If the node is a text node, find the word start
        if (node.nodeType === Node.TEXT_NODE) {
            start = findWordStartInTextNode(node, start);
        } else {
            // Traverse the DOM to find the nearest text node
            while (node) {
                if (node.previousSibling) {
                    node = node.previousSibling;
                    while (node.nodeType !== Node.TEXT_NODE && node.lastChild) {
                        node = node.lastChild;
                    }
                    if (node.nodeType === Node.TEXT_NODE) {
                        start = findWordStartInTextNode(node, node.textContent.length);
                        break;
                    }
                } else {
                    node = node.parentNode;
                    if (node.nodeType === Node.TEXT_NODE) {
                        start = findWordStartInTextNode(node, node.textContent.length);
                        break;
                    }
                }
            }
        }

        // Adjust the range start
        if (node && node.nodeType === Node.TEXT_NODE) {
            range.setStart(node, start);
        }
    };

    const adjustRangeToWordEnd = (range) => {
        let node = range.endContainer;
        let end = range.endOffset;

        // Function to find the end of the word in a text node
        const findWordEndInTextNode = (textNode, offset) => {
            while (offset < textNode.textContent.length && /\p{L}/u.test(textNode.textContent[offset])) {
                offset++;
            }
            return offset;
        };

        // If the node is a text node, find the word end
        if (node.nodeType === Node.TEXT_NODE) {
            end = findWordEndInTextNode(node, end);
        } else {
            // Traverse the DOM to find the nearest text node
            while (node) {
                if (node.nextSibling) {
                    node = node.nextSibling;
                    while (node.nodeType !== Node.TEXT_NODE && node.firstChild) {
                        node = node.firstChild;
                    }
                    if (node.nodeType === Node.TEXT_NODE) {
                        end = findWordEndInTextNode(node, 0);
                        break;
                    }
                } else {
                    node = node.parentNode;
                    if (node.nodeType === Node.TEXT_NODE) {
                        end = findWordEndInTextNode(node, 0);
                        break;
                    }
                }
            }
        }

        // Adjust the range end
        if (node && node.nodeType === Node.TEXT_NODE) {
            range.setEnd(node, end);
        }
    };

    function isSelectionAtWordEnd(selection) {
        if (selection.rangeCount === 0) {
            console.log('No selection found.');
            return false;
        }

        var range = selection.getRangeAt(0).cloneRange();
        var selectionText = range.toString();

        if (!/[а-яА-ЯёЁa-zA-Z]$/.test(selectionText)) {
            return true; // If it doesn't end with a word character, consider it the end of a word.
        }

        // Extend the range to include the next character if within the same text node
        if (range.endContainer.nodeType === 3 && range.endOffset < range.endContainer.textContent.length) {
            var nextChar = range.endContainer.textContent[range.endOffset];
            // Next character within the same node
            return !/[а-яА-ЯёЁa-zA-Z]/.test(nextChar);
        }

        // If the selection ends at a boundary or in an element, check the next node for a word character
        var nextNode = getNextTextOrElementNode(range.endContainer, range.endOffset, articleRef);

        // if selection was whe last article text node
        if (nextNode === null) {
            return true;
        }

        //If next node or element found
        if (nextNode) {
            if (nextNode.nodeType === 3) {
                // Text node
                return !/[а-яА-ЯёЁa-zA-Z]/.test(nextNode.textContent.trim().charAt(0));
            } else if (nextNode.nodeType === 1) {
                // Element node
                var text = nextNode.textContent.trim();
                // Next element's trimmed text content

                // Specific case for block-level elements like <p>
                if (nextNode.tagName.toLowerCase() === 'p') {
                    // Next element is a <p>, considering as end of word.
                    return true;
                }

                return text.length === 0 || !/[а-яА-ЯёЁa-zA-Z]/.test(text.charAt(0));
            }
        }

        // Conservatively assuming not at the end of a word due to no next character or node.'
        return false;
    }

    function getNextTextOrElementNode(node, offset) {
        if (node.nodeType === 3 && node.textContent.length > offset) {
            // We're in a text node but not at the end, so no need to move to the next node
            return node;
        }

        // Move out of the current node to its sibling or parent's sibling, ensuring it's within the articleRef
        while (node && !node.nextSibling && node !== articleRef.current) {
            node = node.parentNode;
        }
        node = node && node !== articleRef.current ? node.nextSibling : null;

        // Skip non-significant nodes, ensuring traversal stays within the articleRef boundaries
        while (
            node &&
            (node.nodeType === 8 || (node.nodeType === 3 && !/\S/.test(node.textContent))) &&
            node !== articleRef.current
        ) {
            node = node.nextSibling;
        }

        // Stop if we've reached or gone outside the boundary of articleRef
        if (node && articleRef.current && !articleRef.current.contains(node)) {
            return null;
        }

        return node;
    }

    const onHighlightTextChange = () => {
        if (window.getSelection) {
            const selectedText = window.getSelection().toString();

            if (selectedText && selectedText !== '' && selectedText.length > 3) {
                setShowPopup(true);
                setSelectedText(selectedText);
            }
        }
    };

    const showLabellingModal = () => {
        const modalPosition = {
            top: 0, // Adjust this as needed
            left: 0, // Adjust this as needed
        };

        const containerWidth = articleRef.current.clientWidth;
        setModalPosition(modalPosition);
        setModalWidth(containerWidth);
        setModalOpen(true);
    };

    const showLabellingModalEdit = (data) => {
        dispatch(LabellingHighlightActions.setLabellingHighlight(data));
        const modalPosition = {
            top: 0, // Adjust this as needed
            left: 0, // Adjust this as needed
        };

        const containerWidth = articleRef.current.clientWidth;
        setModalPosition(modalPosition);
        setModalWidth(containerWidth);
        setModalOpen(true);
        setIsLoading(false);
    };

    const customConsole = {
        log: (...args) => {
            if (SHOULD_LOG) {
                console.log(...args);
            }
        },
        error: (...args) => {
            if (SHOULD_LOG) {
                console.error(...args);
            }
        },
    };

    // Configure Rangy highlighter
    const setupHighlighter = () => {
        const highlighter = rangy.createHighlighter();
        const classApplier = rangy.createClassApplier('filter-highlight', {
            ignoreWhiteSpace: true,
            tagNames: ['span', 'a'],
        });

        highlighter.addClassApplier(classApplier);
        highlighterRef.current = highlighter;
        classApplierRef.current = classApplier;
    };

    const showTooltip = (event, text) => {
        const x = event.clientX;
        const y = event.clientY;

        // Adjusting for tooltip dimensions if needed
        const top = y - tooltipElementRef.current.offsetHeight - 75;
        const left = x - tooltipElementRef.current.offsetWidth - 310;

        setTooltipState({
            left: left,
            top: top,
            isVisible: true,
            text: text,
        });
    };

    const hideTooltip = () => {
        setTooltipState({
            left: 0,
            top: 0,
            isVisible: false,
            text: '',
        });
    };

    // Function to create a range
    const createHighlightRange = (node, startPos, endPos) => {
        const range = rangy.createRange();
        range.setStart(node, startPos);
        range.setEnd(node, endPos);
        return range;
    };

    // Function to apply highlight ranges
    const applyHighlightRanges = (highlightRanges) => {
        highlightRanges.forEach((rangeDetails) => {
            const filterInfo = rangeDetails.highlightFilter;

            classApplierRef.current = rangy.createClassApplier('filter-highlight', {
                elementTagName: 'mark', // Using 'mark' for filter highlights
                ignoreWhiteSpace: true,
                tagNames: ['span', 'a', 'mark'], // Include 'mark' in tagNames
                onElementCreate: function (element) {
                    element.addEventListener('mouseover', (event) => showTooltip(event, filterInfo.tooltipText));
                    element.addEventListener('mouseout', hideTooltip);
                    element.classList.add('mark-filter-highlight');
                },
            });

            classApplierRef.current.applyToRange(rangeDetails.range);
        });
    };

    const applyHighlightError = (rangeDetails) => {
        const filterInfo = rangeDetails.highlightFilter;

        classApplierRef.current = rangy.createClassApplier('filter-highlight-error', {
            ignoreWhiteSpace: true,
            tagNames: ['span', 'a'],
            onElementCreate: function (element) {
                // Attach mouseover event to the newly created element
                element.addEventListener('mouseover', (event) => showTooltip(event, filterInfo.tooltipText));
                element.addEventListener('mouseout', hideTooltip);
            },
        });

        classApplierRef.current.applyToRange(rangeDetails.range);
    };

    const memoizedExtractUniqueTextRange = (() => {
        const cache = {};
        return (htmlRawString, startSubstring, endSubstring) => {
            // Create a unique cache key based on the function's input parameters
            const key = `${startSubstring}-${endSubstring}`;
            // Safely check if the key exists in the cache
            if (!Object.prototype.hasOwnProperty.call(cache, key)) {
                cache[key] = extractUniqueTextRange(htmlRawString, startSubstring, endSubstring);
            }
            return cache[key];
        };
    })();

    const getFilteredRanges = (highlightRanges) => {
        const filteredRanges = [];
        highlightRanges.forEach((highlightRange) => {
            const filterInfo = highlightRange.highlightFilter;

            const filterText = filterInfo.subString;

            const uniqueFilterText = memoizedExtractUniqueTextRange(props.content, filterInfo.start, filterInfo.end);

            const paddedRangeSize = uniqueFilterText.length - filterText.length + 10;

            const paddedRangeText = getPaddedText(articleRef, highlightRange.range, paddedRangeSize);

            let cleanedRangeText = paddedRangeText.replace(
                'Reverse image search on GoogleReverse image search on Tineye',
                '',
            );

            cleanedRangeText = cleanedRangeText.replace(/\s+/g, '');
            const cleanedUniqueFilterText = uniqueFilterText.replace(/\s+/g, '');

            if (cleanedRangeText === cleanedUniqueFilterText || cleanedRangeText.includes(cleanedUniqueFilterText)) {
                filteredRanges.push(highlightRange);
            }
        });

        return filteredRanges;
    };

    const getHighlightFilters = () => {
        if (filterHighlights == null || filterHighlights.length === 0) {
            return [];
        }

        const highlightFilters = filterHighlights.content
            .filter((filter) => !filter.overlapped)
            .map((filter) => {
                const tooltipInfo = filter.highlights[0].filter;
                const tooltipText = `${tooltipInfo.name} (${tooltipInfo.id})`;
                const subString = props.content.substring(filter.start, filter.end);
                const highlightedText = subString.replace(/&nbsp;/g, '\u00A0');

                return {
                    start: filter.start,
                    end: filter.end,
                    tooltipText: tooltipText,
                    subString: subString,
                    highlightedText: highlightedText,
                };
            });

        return highlightFilters;
    };

    // Apply highlights to matching nodes based on the search input
    const applyAllFilterHighlights = () => {
        const highlightFilters = getHighlightFilters();

        const maxLength = 100;

        if (highlightFilters.length === 0) {
            return;
        }

        if (highlightFilters.length > maxLength) {
            setShowHighlightWarning(true);
        }

        // Determine the maximum number of filters to process
        const maxFilters = highlightFilters.length > maxLength ? maxLength : highlightFilters.length;

        // Iterate only through the first maxFilters elements
        for (let index = 0; index < maxFilters; index++) {
            const highlightFilter = highlightFilters[index];
            try {
                if (highlightFilter.highlightedText !== '  ') {
                    const highlightRanges = getHighlightRanges(highlightFilter);
                    const filteredRanges = getFilteredRanges(highlightRanges);

                    if (filteredRanges.length > 0) {
                        applyHighlightRanges(filteredRanges);
                    } else {
                        applyRangeError(highlightFilter, index);
                    }
                }
            } catch (Err) {
                customConsole.log(Err);
                applyRangeError(highlightFilter, index);
                setModalOpen(false);
            }
        }
    };

    const applyRangeError = (highlightFilter, index) => {
        const allTextNodes = getTextNodes(articleRef.current);

        // Filter out the text nodes that are empty or whitespace-only
        const filteredTextNodes = allTextNodes.filter((node) => node.value.trim() !== '');

        let startNodeIndex = 0;
        if (filteredTextNodes.length > index) {
            startNodeIndex = index;
        }

        const startNode = filteredTextNodes[startNodeIndex].node;
        const endNode = filteredTextNodes[startNodeIndex].node;
        const rangyRange = rangy.createRange();

        rangyRange.setStart(startNode, 0);
        rangyRange.setEnd(endNode, endNode.length);

        applyHighlightError({
            range: rangyRange,
            highlightFilter: { tooltipText: `(Position Error) ${highlightFilter.tooltipText}` },
        });
    };

    // Store highlights ranges to matching nodes based on the search input
    const getHighlightRanges = (highlightFilter) => {
        const highlightRanges = [];

        textNodesRef.current = getTextNodes(articleRef.current);

        // Log each textNodes in documentfor debugging
        textNodesRef.current.forEach((item, index) => {
            customConsole.log(`node #${index}:`, item);
        });

        // Find the node ranges that match the input search text and remove duplicated ranges
        const nodeIndicesOfText = getNodeIndicesOfText(highlightFilter.highlightedText, textNodesRef.current);

        const matchedNodeRangesArray = removeDuplicates(nodeIndicesOfText);

        // Log the search input for debugging purposes
        customConsole.log(`Searching for text: "${highlightFilter.highlightedText}"`);
        customConsole.log(`Found ${matchedNodeRangesArray.length} matching ranges`);

        // Log each matching range for debugging
        matchedNodeRangesArray.forEach((range, index) => {
            customConsole.log(`Range #${index}:`, range);
        });

        // Process each matched node range to apply highlights
        matchedNodeRangesArray.forEach((matchedNodeRanges, rangeIndex) => {
            customConsole.log(
                `Processing range #${rangeIndex} for text "${highlightFilter.highlightedText}":`,
                matchedNodeRanges,
            );

            // Extract the sub-array of text nodes that fall within the current matched node range
            const matchedArray = extractSubArray(
                textNodesRef.current,
                matchedNodeRanges.startNode,
                matchedNodeRanges.endNode,
            );

            customConsole.log('Matched Array of nodes:', matchedArray);

            // Find the exact indices within the text nodes where the input text matches
            const matchedIndices = findStringInArray(
                highlightFilter.highlightedText,
                matchedArray.map((i) => i.value),
            );

            customConsole.log(
                `Matched indices for "${highlightFilter.highlightedText}" within node range #${rangeIndex}:`,
                matchedIndices,
            );

            // Apply highlights to each matching portion of the text nodes
            matchedArray.forEach((textNode, index) => {
                if (textNode.nodeType !== 'ARTIFICIAL_WHITESPACE') {
                    // Existing processing logic for real nodes

                    customConsole.log(
                        `Processing matched item #${index} for text "${highlightFilter.highlightedText}":`,
                        textNode,
                    );

                    const { substring } = matchedIndices[index]; // Extract the matching substring for the current text node

                    let startPos;

                    const stringOccurrences = findOccurrences(textNode.value, substring);

                    // Define the start and end points of the range based on the calculated positions
                    const node = textNode.reference;

                    const isOnlyOneNode = matchedNodeRanges.startNode === matchedNodeRanges.endNode;

                    if (isOnlyOneNode && stringOccurrences.length > 1) {
                        stringOccurrences.sort((a, b) => b.start - a.start);

                        stringOccurrences.forEach((item) => {
                            const range = createHighlightRange(node, item.start, item.stop);
                            highlightRanges.push({
                                range: range,
                                highlightFilter: highlightFilter,
                            });
                        });
                    } else {
                        // Determine the start position of the substring within the current text node
                        if (index === matchedArray.length - 1) {
                            startPos = textNode.value.indexOf(substring);
                        } else {
                            startPos = textNode.value.lastIndexOf(substring);
                        }

                        const endPos = startPos + substring.length; // Calculate the end position based on the start position and substring length

                        customConsole.log(
                            `Processing substring: "${substring}" from position ${startPos} to ${endPos} within item #${index}`,
                        );

                        // Check to ensure that the calculated end position doesn't exceed the length of the current text node
                        if (endPos > textNode.value.length) {
                            customConsole.error(
                                `Error for search text "${highlightFilter.highlightedText}": End position exceeds the node length`,
                                {
                                    endPos,
                                    nodeLength: textNode.value.length,
                                    textNode,
                                    substring,
                                },
                            );
                            return; // Exit the current loop iteration if an error is encountered
                        }
                        const range = createHighlightRange(node, startPos, endPos);
                        highlightRanges.push({
                            range: range,
                            highlightFilter: highlightFilter,
                        });
                    }
                } else {
                    // Handle artificial whitespace nodes (e.g., skip them or treat differently)
                }
            });
        });

        return highlightRanges;
    };

    const handleScroll = () => {
        let videoDiv = document.getElementById('contentVideoParentDiv');
        let video = document.getElementById('contentVideo');
        let article = document.getElementById('article-content');
        const header = document.getElementById('contentVideoHeader');
        if (videoDiv && video) {
            let articleY = article.getBoundingClientRect().y;
            let videoDivHeight = videoDiv.getBoundingClientRect().height;
            let contentY = articleY + videoDivHeight - 160;

            if (window.location.pathname === '/labelling') {
                if (contentY < 0) {
                    video.classList.add('minimizedVideoLabelling');
                    header.classList.add('contentVideoHeader');
                }
                if (contentY > 0) {
                    video.classList.remove('minimizedVideoLabelling');
                    header.classList.remove('contentVideoHeader');
                }
            }
            if (window.location.pathname === '/search') {
                if (contentY < 0) {
                    video.classList.add('minimizedVideo');
                    header.classList.add('contentVideoHeader');
                }
                if (contentY > 0) {
                    video.classList.remove('minimizedVideo');
                    header.classList.remove('contentVideoHeader');
                }
            }
        }
    };

    const copyListener = (e) => {
        const range = window.getSelection().getRangeAt(0);
        const rangeContents = range.cloneContents();
        const helper = document.createElement('div');

        helper.appendChild(rangeContents);

        e.clipboardData.setData(
            'text/plain',
            `${removeDuplicateRegexMatchesExceptFirst(helper.innerText, timestampRegex)}\n`,
        );
        e.clipboardData.setData('text/html', `${helper.innerHTML}<br>`);
        e.preventDefault();
    };

    const scrollToTimestamp = () => {
        let video = document.getElementById('video');
        let timestamps = document.querySelectorAll('.videoTime');
        let timestampArray = [];
        timestamps.forEach((el) => {
            timestampArray.push(el.id);
        });

        let closest = timestampArray.reverse().find((e) => e <= video.currentTime);

        let getTimestamp = document.getElementById(closest);

        getTimestamp && getTimestamp.scrollIntoView({ block: 'end', inline: 'nearest' });
    };

    const contentVideo = (node) => {
        return (
            <div key={node.attribs.src} id="contentVideoParentDiv">
                <div id="contentVideo">
                    <div className="videoHeader" id="contentVideoHeader" />
                    <div className="videoContainer">
                        <video id="video" controls key={node.attribs.src}>
                            <source src={node.attribs.src} />
                        </video>
                    </div>
                    <Button onClick={scrollToTimestamp} type="SECONDARY" label="Scroll to timestamp" />
                </div>
            </div>
        );
    };

    const jumpTo = (time) => {
        let seconds = timeToSeconds(time);
        let video = document.getElementById('video');
        video.pause();
        video.currentTime = seconds;
        video.play();
    };

    const timeToSeconds = (time) => {
        let a = time.split(':');
        let seconds = +a[0] * 60 * 60 + +a[1] * 60 + +a[2];

        return seconds;
    };

    // eslint-disable-next-line no-unused-vars
    const transform = (node, index) => {
        if (node.type === 'tag' && node.name === 'video') {
            setIsVideoContent(true);
            return contentVideo(node);
        }
        if (node.type === 'tag' && node.name === 'img') {
            return <img key={node.attribs.src} alt={node.attribs.alt} src={node.attribs.src} />;
        }
    };

    // Helper function to check selection within a section
    const checkSelection = (section) => {
        return section.length > 0;
    };

    function isAnyValueSelected(data) {
        if (data.comment !== '') {
            return true;
        }

        // Check in s1, s2, and s3
        return checkSelection(data.s1) || checkSelection(data.s2) || checkSelection(data.s3);
    }

    const handleCopy = () => {
        // Assuming selectedText is the state variable you want to copy to the clipboard
        if (navigator.clipboard && window.isSecureContext) {
            // Use the Clipboard API to copy the text
            navigator.clipboard
                .writeText(removeDuplicateRegexMatchesExceptFirst(selectedText, timestampRegex))
                .then(() => {
                    console.log('Text copied to clipboard');
                })
                .catch((err) => {
                    console.error('Failed to copy text: ', err);
                });
        } else {
            // Clipboard API not available (might be HTTP or an older browser), fallback to a manual method
            console.error('Clipboard API not available.');
        }

        setShowPopup(false); // Hide the popup as before
    };

    const handleApplyHighlight = () => {
        setShowPopup(false);

        if (!isHighlightEnabled) return;
        setIsHighlightEnabled(false);
        setIsLoading(true);

        dispatch(LabellingHighlightActions.setLabellingHighlight(initialHighlight));

        const selection = rangy.getSelection();

        const range = selection.getRangeAt(0);

        const rangeText = range.toString();

        if (
            range &&
            rangeText !== '' &&
            rangeText.length > 3 &&
            articleRef.current.contains(range.commonAncestorContainer)
        ) {
            if (!selection.isCollapsed) {
                // Save the selection right here, after adjustments and checks
                setSavedSelection(rangy.saveSelection());
                showLabellingModal();
                return;
            }
        } else {
            setIsHighlightEnabled(true);
        }
    };

    const handleHighlightActionsPopupClose = () => {
        setShowPopup(false);
    };

    return (
        <Styles.Container className={className} ref={containerRef}>
            <ul id="tweetlist"></ul>
            <Styles.Content
                id="article-content"
                lang={lang}
                fontSize={fontSize}
                dir={RTLLanguages.includes(lang) ? 'rtl' : 'ltr'}
                ref={articleRef}
            >
                {!isParsingComplete ||
                    (!isLabelHighlightsApplied && (
                        <div className="loadingOverlay">
                            <div style={{ fontWeight: 'bold', padding: '10px' }}>
                                Loading article data, please wait...
                            </div>
                            <Loader size={40} />
                        </div>
                    ))}
                {parsedContent}
            </Styles.Content>

            <HighlightActionsPopup
                position={popupPosition}
                onMousePosition={mousePosition}
                onCopy={handleCopy}
                onHighlight={handleApplyHighlight}
                onClose={handleHighlightActionsPopupClose}
                isVisible={showPopup}
            />

            {showHighlightWarning && (
                <HighlightWarningPopup
                    position={popupPosition}
                    onMousePosition={mousePosition}
                    onClose={() => {
                        setShowHighlightWarning(false);
                    }}
                />
            )}

            {article && isArticleLoaded && modalOpen && (
                <LabelHighlightsModal
                    onClose={handleClose}
                    initialX={0}
                    initialY={modalPosition.top}
                    width={modalWidth}
                    isLoading={isLoading}
                    ref={modalRef}
                />
            )}
            {modalOpen && <div className="modal-backdrop"></div>}


            <span
                ref={tooltipElementRef}
                className={`filter-highlight-tooltip ${tooltipState.isVisible ? 'showTooltip' : ''}`}
                translate="no"
                style={{ left: `${tooltipState.left}px`, top: `${tooltipState.top}px` }}
            >
                {tooltipState.text}
                <br />
            </span>
        </Styles.Container>
    );
};

Content.propTypes = {
    labelingForm: PropTypes.any,
    className: PropTypes.string,
    content: PropTypes.string,
    fontSize: PropTypes.number,
    lang: PropTypes.string,
    filterHighlights: PropTypes.any,
    parameters: PropTypes.any,
    isArticleLoaded: PropTypes.bool.isRequired,
    article: PropTypes.object,
    similarArticles: PropTypes.array,
    articles: PropTypes.array,
    selections: PropTypes.any,
    handleUpdateOrAddHighlight: PropTypes.any,
    handleRemoveHighlight: PropTypes.any,
    selectedRowData: PropTypes.any,
    clickTimestamp: PropTypes.any,
    labellingHighlight: PropTypes.any,
};

Content.defaultProps = {
    className: null,
    content: null,
    fontSize: 16,
    lang: 'en',
};

function mapStateToProps(state) {
    const { parameters, article, articles, similarArticles, labellingHighlight, highlights } = state;
    return {
        parameters: parameters.parameters,
        similarArticles: similarArticles.similarArticles,
        labellingHighlight: labellingHighlight,
        selections: highlights.highlights,
        articles: articles.articles,
        article: article.article,
        filterHighlights: article.article.filterHighlights,
        content: article.article.content,
        lang: article.article.technicalData.lang,
        isArticleLoaded: article.isLoaded,
    };
}

export default connect(mapStateToProps)(Content);
