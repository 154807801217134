import { httpClient } from 'core/services';
import { Mapper } from 'features/article/utils';

const baseUrl = '/articles';
const labelingUrl = '/labeling';

export const getArticle = async (id, parentId) => {
    const article = await httpClient.get(`${baseUrl}/${id}/${parentId}`);
    return Mapper.mapArticle(article);
};

export const labelArticle = async (id, parentId, data, status) => {
    const postData = mapToLabelRequest(data, status);
    return await httpClient.post(`${labelingUrl}/${id}/${parentId}`, postData);
};

function transformSelectionData(data) {
    const inputData = data.filter((item) => {
        return !(item.s1.length === 0 && item.s2.length === 0 && item.s3.length === 0 && item.comment === '');
    });

    // Initialize the output object
    let output = {
        section_parameters: [],
    };

    // Process each item in the input array (assuming there could be more than one)
    inputData.forEach((item) => {
        let parameters = [];
        const processSection = (section, type) => {
            section.forEach((param) => {
                parameters.push({
                    parameter_id: param.parameter_id,
                    value_id: param.value_id !== undefined ? param.value_id : null,
                    type: type,
                });
            });
        };

        // Process sections s1, s2, and s3
        processSection(item.s1, 'S1');
        processSection(item.s2, 'S2');
        processSection(item.s3, 'S3');

        // Add the comment and selection data
        output.section_parameters.push({
            parameters: parameters,
            comment: item.comment,
            selection: {
                start: item.highlight.startXpath,
                startOffset: item.highlight.startOffset,
                end: item.highlight.endXpath,
                endOffset: item.highlight.endOffset,
            },
        });
    });

    return output.section_parameters;
}

export const mapToLabelRequest = (data, status) => {
    const section_parameters = transformSelectionData(data.section_parameters);
    const parameters = data.parameters || [];
    const sentiments = data.sentiments || [];
    return {
        updating: status === 'Labeled' || status === 'Skipped' ? 1 : 0,
        comment: data.comment,
        time_spent: data.time_spent,
        parameters: parameters
            .filter((i) => i.type !== '')
            .map((parameter) => ({
                parameter_id: parameter.parameterId,
                value_id: parameter.id,
                type: parameter.type,
            })),
        sentiments: sentiments.map((sentiment) => ({
            id: sentiment.id,
            value: sentiment.value,
            type: sentiment.type,
        })),
        section_parameters: section_parameters,
    };
};

export const importArticle = async (data) => {
    return await httpClient.post('/manual-import', data);
};

export const getImportedArticles = async () => {
    return await httpClient.get('/manual-import');
};

export const downloadTempalte = async () => {
    return await httpClient.get('/manual-import/sample');
};

export const importBatch = async (data) => {
    const formData = new FormData();
    formData.append('file', data);
    return await httpClient.post('/manual-import/batch', formData);
};
