import { applyMiddleware, createStore, compose } from 'redux';
import thunk from 'redux-thunk';
import { persistReducer, persistStore } from 'redux-persist';
import { rootReducer } from 'core/reducers';
import localforage from 'localforage';

const persistConfig = {
    key: 'root',
    storage: localforage,
    whitelist: ['auth'],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const composeEnhancers =
    (process.env.NODE_ENV === 'development' || window.location.hostname === 'localhost') &&
    typeof window === 'object' &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
        ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
        : compose;

const enhancer = composeEnhancers(applyMiddleware(thunk));
const store = createStore(persistedReducer, enhancer);
const persistor = persistStore(store);

export { store, persistor };
